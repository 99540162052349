import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricCycleDocumentListInput, GqlPerformanceScorecardMetricCycleDocumentListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/performance-scorecard-metric-cycle-document-fragment.graphql'

export const queryPerformanceScorecardMetricCycleDocumentList = async (input: GqlPerformanceScorecardMetricCycleDocumentListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT}

      query PerformanceScorecardMetricCycleDocumentList($input: PerformanceScorecardMetricCycleDocumentListInput!) {
        performanceScorecardMetricCycleDocumentList(input: $input) {
          items {
            ...PerformanceScorecardMetricCycleDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleDocumentList
}
