import React, { memo } from 'react'
import classNames from 'classnames'
import { upperFirst } from 'lodash'
import { useParams } from 'react-router-dom'
import { IconType } from '@cotiss/common/components/icon.component'
import { StepButton, StepButtonVariant } from '@cotiss/common/components/step-button.component'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

const getIcon = (step: TenderFlowStep): IconType => {
  switch (step) {
    case 'planning':
      return 'lightbulb-05'
    case 'active':
      return 'lightning-01'
    case 'review':
      return 'clipboard-check'
    case 'evaluate':
      return 'bar-chart-10'
    case 'shortlist':
      return 'list'
    case 'selection':
      return 'star-01'
    case 'report':
      return 'file-check-02'
    default:
      return 'lock'
  }
}

type Props = {
  className?: string
  step: TenderFlowStep
  tender?: TenderPopulatedModel
  variant?: StepButtonVariant
  onStepChange: (step: TenderFlowStep) => void
}

export const TenderFlowStepButton = memo(({ className, step, tender, variant = 'default', onStepChange }: Props) => {
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)
  const classes = classNames(className, { 'w-[150px]': isOldEvaluationsEnabled, 'w-[250px]': !isOldEvaluationsEnabled })
  const { step: currentStep } = useParams<{ step: TenderFlowStep }>()

  return (
    <StepButton
      className={classes}
      onClick={() => onStepChange(step)}
      variant={variant}
      icon={getIcon(step)}
      isActive={step === currentStep}
      isLast={isOldEvaluationsEnabled ? step === 'report' : step === 'shortlist'}
    >
      {upperFirst(step)}
    </StepButton>
  )
})
