import React, { memo, useEffect } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { filterService } from '@cotiss/common/services/filter.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { TabLabelWithCount } from '@cotiss/common/containers/tabs/tab-label-with-count.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { AdvancedFiltersDropdown } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown.component'
import { AdvancedFiltersDropdownContent } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-content.component'
import { AdvancedFiltersDropdownTrigger } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-trigger.component'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { AdvancedFiltersProvider, useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import {
  ContractSearchFiltersContextProvider,
  useContractSearchFiltersContext,
} from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { ContractListSearchInput } from '@cotiss/contract/components/contract-list-search-input.component'
import { ContractListStatusCards } from '@cotiss/contract/components/contract-list-status-cards.component'
import { ContractListTabs } from '@cotiss/contract/components/contract-list-tabs.component'
import { ContractList } from '@cotiss/contract/components/contract-list.component'
import { CONTRACT_LIST_PRIMARY_TAB_FILTERS, ContractListPrimaryTabKeys } from '@cotiss/contract/contract.constants'
import { ContractCreateDrawer } from '@cotiss/contract/drawers/contract-create.drawer'
import { useContractListFilterTotals } from '@cotiss/contract/hooks/use-contract-list-filter-totals.hook'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

const DEPRECATED_CONTRACT_LIST_TABS: TabModel<ContractListPrimaryTabKeys>[] = [
  { id: 'active', label: 'All contracts' },
  { id: 'archived', label: 'Archive' },
]

const ContractListPageInternal = memo(() => {
  const { openDrawer } = useCallout()
  const { permissions } = useUserAccess()
  const { track } = useAnalytics()
  const { validAdvancedFilters, isInitialized } = useAdvancedFiltersContext()
  const { queryState } = useContractSearchFiltersContext()
  const isContractListStatusCardsEnabled = useFeature('enable-contract-list-status-cards')

  useEffect(() => {
    track('contract_list_view')
  }, [queryState.primaryTab])

  const { totals } = useContractListFilterTotals({
    totalFilters: CONTRACT_LIST_PRIMARY_TAB_FILTERS,
  })

  const CONTRACT_LIST_TABS: TabModel<ContractListPrimaryTabKeys>[] = [
    { id: 'active', label: <TabLabelWithCount count={totals?.active?.total} label="All contracts" /> },
    { id: 'mine', label: <TabLabelWithCount count={totals?.mine?.total} label="My contracts" /> },
    { id: 'archived', label: <TabLabelWithCount count={totals?.archived?.total} label="Archive" /> },
  ]

  const tabs = isContractListStatusCardsEnabled ? CONTRACT_LIST_TABS : DEPRECATED_CONTRACT_LIST_TABS

  if (!permissions.hasContractAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Contract repository
        </Text>
        <Button variant="primary" size="md" onClick={() => openDrawer(<ContractCreateDrawer />)}>
          + Create contract
        </Button>
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <ContractListTabs tabs={tabs} tab={queryState.primaryTab} />
        <VerticalDivider className="h-7" />
        <ContractListSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdown onOpen={() => track('contract_list_filters_dropdown_open')}>
          <AdvancedFiltersDropdownTrigger>
            <Button size="xs" variant="secondary" state="translucent" className="gap-1" isDisabled={!isInitialized}>
              <Icon icon="filter-funnel-01" />
              <span>Filters ({validAdvancedFilters?.length})</span>
            </Button>
          </AdvancedFiltersDropdownTrigger>
          <AdvancedFiltersDropdownContent title="Filter contracts" />
        </AdvancedFiltersDropdown>
      </div>
      <PageContent>
        <div className="flex flex-col gap-5">
          {isInitialized && validAdvancedFilters.length ? <AdvancedFiltersPillList /> : null}
          {isContractListStatusCardsEnabled && queryState.primaryTab !== 'archived' ? <ContractListStatusCards /> : null}
          <ContractList />
        </div>
      </PageContent>
    </Page>
  )
})

export const ContractListPage = () => {
  return (
    <ContractSearchFiltersContextProvider>
      <AdvancedFiltersProvider initialFilters={filterService.getFiltersFromUrl()}>
        <ContractListPageInternal />
      </AdvancedFiltersProvider>
    </ContractSearchFiltersContextProvider>
  )
}
