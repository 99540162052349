import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { EvaluationStatus } from '@cotiss/evaluation/evaluation.models'

type UpdateEvaluationBody = {
  status?: EvaluationStatus
}

export const useMutateEvaluation = () => {
  const { mutate } = useMutate()

  return {
    updateEvaluation: async (id: string, body: UpdateEvaluationBody) => {
      return await mutate({
        route: `/v2/evaluations/${id}`,
        body,
        invalidate: ['/v2/evaluations', '/v2/reports/evaluations-by-tender-response', '/v2/procurements'],
      })
    },
    resendEvaluationInvitation: async (id: string) => {
      return await mutate({ route: `/v2/evaluations/${id}/resend` })
    },
  }
}
