import { gql } from '@apollo/client'
import { GqlPerformanceScorecardDocumentListInput, GqlPerformanceScorecardDocumentListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-document/performance-scorecard-document-fragment.graphql'

export const queryPerformanceScorecardDocumentList = async (input: GqlPerformanceScorecardDocumentListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT}

      query PerformanceScorecardDocumentList($input: PerformanceScorecardDocumentListInput!) {
        performanceScorecardDocumentList(input: $input) {
          items {
            ...PerformanceScorecardDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardDocumentList
}
