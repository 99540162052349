import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ContractingEntityModel } from '@cotiss/contract/contract.model'

export const useListContractingEntities = () => {
  const { data, ...rest } = useFetch<PaginatedModel<ContractingEntityModel>>('/v2/contract-shells', {
    route: `/v2/contract-shells/contracting-entities`,
    params: { includeOwnOrganisation: true },
  })

  return { contractingEntities: data?.items, ...rest }
}
