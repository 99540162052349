import React, { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { SettingsTab } from '@cotiss/settings/pages/settings.page'
import { SettingsModulesContactTab } from '@cotiss/settings/tabs/settings-modules-contact.tab'
import { SettingsModulesContractTab } from '@cotiss/settings/tabs/settings-modules-contract.tab'
import { SettingsModulesPerformanceTab } from '@cotiss/settings/tabs/settings-modules-performance.tab'
import { SettingsModulesProcurementTab } from '@cotiss/settings/tabs/settings-modules-procurement.tab'

export type SettingsModulesTab = 'contract' | 'procurement' | 'contact' | 'performance'

export const SettingsModulesTab = memo(() => {
  const { push, replace } = useHistory()
  const isProcurementsEnabled = useFeature('procurements')
  const isContractManagementEnabled = useFeature('contract-management-v1')
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { tab, nestedTab } = useParams<{ tab: SettingsTab; nestedTab?: SettingsModulesTab }>()

  const SETTINGS_MODULES_TABS: VerticalTabModel<SettingsModulesTab>[] = useMemo(
    () =>
      filter(
        [
          { id: 'contract', label: 'Contract', isHidden: !isContractManagementEnabled },
          { id: 'procurement', label: 'Procurement', isHidden: !isProcurementsEnabled },
          { id: 'contact', label: 'Contact' },
          { id: 'performance', label: 'Performance', isHidden: !isPerformanceScorecardEnabled },
        ],
        ({ isHidden }) => !isHidden
      ),
    [isContractManagementEnabled, isProcurementsEnabled]
  )

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(SETTINGS_MODULES_TABS, ({ id }) => id === nestedTab) + 1 })

  useEffect(() => {
    if (!nestedTab || !find(SETTINGS_MODULES_TABS, (tab) => tab.id === nestedTab)) {
      replace(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab: SETTINGS_MODULES_TABS[0].id }))
    }

    const newStep = findIndex(SETTINGS_MODULES_TABS, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [nestedTab])

  const activeTabId = useMemo(() => SETTINGS_MODULES_TABS[step - 1].id, [step, SETTINGS_MODULES_TABS])

  return (
    <div className="flex items-start">
      <VerticalTabs<SettingsModulesTab>
        className="sticky top-28 mr-8"
        tab={activeTabId}
        tabs={SETTINGS_MODULES_TABS}
        onChange={({ id }) => push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab: id }))}
      />

      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="w-full max-w-5xl" transition={transition}>
          {activeTabId === 'contract' && <SettingsModulesContractTab />}
          {activeTabId === 'procurement' && <SettingsModulesProcurementTab />}
          {activeTabId === 'contact' && <SettingsModulesContactTab />}
          {activeTabId === 'performance' && <SettingsModulesPerformanceTab />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
