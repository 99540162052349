import { gql } from '@apollo/client'
import { GqlEvaluationEnvelopeViewInput, GqlEvaluationEnvelopeOverviewViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-criteria-breakdown-fragment.graphql'
import { EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-fragment.graphql'
import { EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-submission-breakdown-fragment.graphql'

export const queryEvaluationEnvelopeOverviewView = async (input: GqlEvaluationEnvelopeViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeOverviewViewQuery>({
    query: gql`
      ${EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT}

      query EvaluationEnvelopeOverviewView($input: EvaluationEnvelopeViewInput!) {
        evaluationEnvelopeOverviewView(input: $input) {
          ...EvaluationEnvelopeOverviewFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeOverviewView
}
