import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import {
  OrganisationCertificationType,
  OrganisationCountryCode,
  OrganisationCountryCodeCombined,
  OrganisationCountryCodeSubdivision,
  OrganisationEntityType,
} from '@cotiss/organisation/organisation.models'

export const ORGANISATION_QUERY_KEYS = ['/v2/organisations', '/v2/public/organisations', '/v2/organisations/search'] as const

export const ORGANISATION_CERTIFICATION_OPTIONS: SelectOption_DEPRECATED<OrganisationCertificationType>[] = [
  { value: 'Sustainability', label: 'Sustainability' },
  { value: 'Health & Safety', label: 'Health & Safety' },
  { value: 'Diversity', label: 'Diversity' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Financial', label: 'Financial' },
  { value: 'Other', label: 'Other' },
]

export const ORGANISATION_ENTITY_TYPE_OPTIONS: SelectOption_DEPRECATED<OrganisationEntityType>[] = [
  { value: 'Sole trader', label: 'Sole trader' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'LLC', label: 'Limited liability company' },
  { value: 'Registered charity', label: 'Registered charity' },
  { value: 'Other', label: 'Other' },
]

export const ORGANISATION_COUNTRY_OPTIONS: SelectOption_DEPRECATED<OrganisationCountryCode>[] = [
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'Albania', value: 'AL' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Austria', value: 'AT' },
  { label: 'Australia', value: 'AU' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Canada', value: 'CA' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'China', value: 'CN' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Curacao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Germany', value: 'DE' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Spain', value: 'ES' },
  { label: 'France', value: 'FR' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'India', value: 'IN' },
  { label: 'Iceland', value: 'IS' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Malta', value: 'MT' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Norway', value: 'NO' },
  { label: 'Nepal', value: 'NP' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Panama', value: 'PA' },
  { label: 'Peru', value: 'PE' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Poland', value: 'PL' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Russia', value: 'RU' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Uganda', value: 'UG' },
  { label: 'United States', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Virgin Islands (US)', value: 'VI' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Samoa', value: 'WS' },
  { label: 'Kosovo', value: 'XK' },
  { label: 'South Africa', value: 'ZA' },
]

export const ORGANISATION_CANADA_SUBDIVISION_OPTIONS: SelectOption_DEPRECATED<OrganisationCountryCodeSubdivision>[] = [
  { label: 'Canada - Alberta', value: 'CA-AB' },
  { label: 'Canada - British Columbia', value: 'CA-BC' },
  { label: 'Canada - Manitoba', value: 'CA-MB' },
  { label: 'Canada - New Brunswick', value: 'CA-NB' },
  { label: 'Canada - Newfoundland and Labrador', value: 'CA-NL' },
  { label: 'Canada - Nova Scotia', value: 'CA-NS' },
  { label: 'Canada - Ontario', value: 'CA-ON' },
  { label: 'Canada - Prince Edward Island', value: 'CA-PE' },
  { label: 'Canada - Quebec', value: 'CA-QC' },
  { label: 'Canada - Saskatchewan', value: 'CA-SK' },
  { label: 'Canada - Northwest Territories', value: 'CA-NT' },
  { label: 'Canada - Nunavut', value: 'CA-NU' },
  { label: 'Canada - Yukon', value: 'CA-YT' },
  { label: 'Canada - Federal', value: 'CA-FE' },
  { label: 'Canada - All', value: 'CA-ALL' },
]

export const ORGANISATION_US_SUBDIVISION_OPTIONS: SelectOption_DEPRECATED<OrganisationCountryCodeSubdivision>[] = [
  { label: 'United States - Alaska', value: 'US-AK' },
  { label: 'United States - Alabama', value: 'US-AL' },
  { label: 'United States - Arkansas', value: 'US-AR' },
  { label: 'United States - American Samoa', value: 'US-AS' },
  { label: 'United States - Arizona', value: 'US-AZ' },
  { label: 'United States - California', value: 'US-CA' },
  { label: 'United States - Colorado', value: 'US-CO' },
  { label: 'United States - Connecticut', value: 'US-CT' },
  { label: 'United States - Washington, D.C.', value: 'US-DC' },
  { label: 'United States - Delaware', value: 'US-DE' },
  { label: 'United States - Florida', value: 'US-FL' },
  { label: 'United States - Georgia', value: 'US-GA' },
  { label: 'United States - Hawaii', value: 'US-HI' },
  { label: 'United States - Iowa', value: 'US-IA' },
  { label: 'United States - Idaho', value: 'US-ID' },
  { label: 'United States - Illinois', value: 'US-IL' },
  { label: 'United States - Indiana', value: 'US-IN' },
  { label: 'United States - Kansas', value: 'US-KS' },
  { label: 'United States - Kentucky', value: 'US-KY' },
  { label: 'United States - Louisiana', value: 'US-LA' },
  { label: 'United States - Massachusetts', value: 'US-MA' },
  { label: 'United States - Maryland', value: 'US-MD' },
  { label: 'United States - Maine', value: 'US-ME' },
  { label: 'United States - Michigan', value: 'US-MI' },
  { label: 'United States - Minnesota', value: 'US-MN' },
  { label: 'United States - Missouri', value: 'US-MO' },
  { label: 'United States - Mississippi', value: 'US-MS' },
  { label: 'United States - Montana', value: 'US-MT' },
  { label: 'United States - North Carolina', value: 'US-NC' },
  { label: 'United States - North Dakota', value: 'US-ND' },
  { label: 'United States - Nebraska', value: 'US-NE' },
  { label: 'United States - New Hampshire', value: 'US-NH' },
  { label: 'United States - New Jersey', value: 'US-NJ' },
  { label: 'United States - New Mexico', value: 'US-NM' },
  { label: 'United States - Nevada', value: 'US-NV' },
  { label: 'United States - New York', value: 'US-NY' },
  { label: 'United States - Ohio', value: 'US-OH' },
  { label: 'United States - Oklahoma', value: 'US-OK' },
  { label: 'United States - Oregon', value: 'US-OR' },
  { label: 'United States - Pennsylvania', value: 'US-PA' },
  { label: 'United States - Rhode Island', value: 'US-RI' },
  { label: 'United States - South Carolina', value: 'US-SC' },
  { label: 'United States - South Dakota', value: 'US-SD' },
  { label: 'United States - Tennessee', value: 'US-TN' },
  { label: 'United States - Texas', value: 'US-TX' },
  { label: 'United States - Utah', value: 'US-UT' },
  { label: 'United States - Virginia', value: 'US-VA' },
  { label: 'United States - Vermont', value: 'US-VT' },
  { label: 'United States - Washington', value: 'US-WA' },
  { label: 'United States - Wisconsin', value: 'US-WI' },
  { label: 'United States - West Virginia', value: 'US-WV' },
  { label: 'United States - Wyoming', value: 'US-WY' },
]

export const ORGANISATION_REGISTRY_NUMBER_TYPE: Record<OrganisationCountryCodeCombined, string | undefined> = {
  'CA-AB': 'Business number',
  'CA-ALL': 'Business number',
  'CA-BC': 'Business number',
  'CA-FE': 'Business number',
  'CA-MB': 'Business number',
  'CA-NB': 'Business number',
  'CA-NL': 'Business number',
  'CA-NS': 'Business number',
  'CA-NT': 'Business number',
  'CA-NU': 'Business number',
  'CA-ON': 'Business number',
  'CA-PE': 'Business number',
  'CA-QC': 'Business number',
  'CA-SK': 'Business number',
  'CA-YT': 'Business number',
  'US-AK': 'Entity Number',
  'US-AL': 'Entity ID Number',
  'US-AR': 'Filing #',
  'US-AS': undefined,
  'US-AZ': 'Entity ID',
  'US-CA': 'Entity Number',
  'US-CO': 'ID Number',
  'US-CT': 'Business ID',
  'US-DC': 'File #',
  'US-DE': 'ID Number',
  'US-FL': 'Document Number',
  'US-GA': 'Control Number',
  'US-HI': 'File Number',
  'US-IA': 'Business No.',
  'US-ID': 'File Number',
  'US-IL': 'File Number',
  'US-IN': 'Business ID',
  'US-KS': 'Business Entity ID number',
  'US-KY': 'Organization Number',
  'US-LA': 'Charter Number',
  'US-MA': 'Company number',
  'US-MD': 'Department ID',
  'US-ME': 'Charter number',
  'US-MI': 'ID Number',
  'US-MN': 'File Number',
  'US-MO': 'Charter number',
  'US-MS': 'Business ID',
  'US-MT': 'Filing #',
  'US-NC': 'SOS ID',
  'US-ND': 'SOS Control ID#',
  'US-NE': 'SOS Account Number',
  'US-NH': 'Business ID',
  'US-NJ': 'Entity ID',
  'US-NM': 'Company number',
  'US-NV': 'NV Business ID',
  'US-NY': 'DOS ID',
  'US-OH': 'Entity Number',
  'US-OK': 'Filing number',
  'US-OR': 'Registry Number',
  'US-PA': 'Entity Number',
  'US-RI': 'ID Number',
  'US-SC': undefined,
  'US-SD': 'Business ID',
  'US-TN': 'Control number',
  'US-TX': 'Filing Number',
  'US-UT': 'Entity Number',
  'US-VA': 'Entity ID',
  'US-VT': 'Business ID',
  'US-WA': 'UBI#',
  'US-WI': 'DFI Entity ID#',
  'US-WV': 'Control number',
  'US-WY': 'Filing ID',
  AE: 'Business License Number',
  AL: undefined,
  AM: undefined,
  AT: 'Firmenbuchnummer / Commercial register number',
  AU: 'ACN',
  AW: 'Chamber of Commerce number:',
  AZ: 'TIN',
  BA: 'Registration number of the Subject of Entry (RNS)',
  BB: 'Number',
  BE: "Numéro d'entreprise / Ondernemingsnummer / Unternehmensnummer",
  BH: 'CR No.',
  BN: 'Company number',
  BR: 'CNPJ (Cadastro Nacional da Pessoa Jurídica)',
  BW: 'UIN',
  CH: 'UID / UID Register',
  CN: 'Unified Social Credit Identifier',
  CO: 'Numero de Matricula',
  CR: 'Identificador',
  CW: 'Registration number',
  CY: 'Reg. Number without letters',
  CZ: 'Identifikační číslo',
  DE: 'Handelsregister number',
  DK: 'CVR-nummer',
  DO: 'RNC o cédula figures only ( no dashes)',
  EE: 'Registrikood',
  ES: 'NIF (Número de Identificação Fiscal)',
  FR: 'SIREN',
  GB: 'Company Number',
  GE: 'Identification code / personal number',
  GG: 'Entity Reg Number',
  GI: undefined,
  HK: 'Company Registration Number (CR No.)',
  HR: 'OIB Number',
  IE: 'Company Number',
  IL: 'Company Number',
  IM: 'Company Number',
  IN: 'CIN number',
  IS: 'Social Security Number',
  IT: 'REA Number or Codice Fiscale',
  JE: 'Registration Number: (without the letters)',
  JM: 'Number',
  JP: 'Corporate Number',
  KH: 'Identifier',
  LI: 'Unternehmensnummer',
  LS: 'Identifier',
  LT: 'Company Registration Code',
  LU: 'RCS number',
  LV: 'Registration Number',
  MC: 'RCI NUMBER',
  ME: 'Registration number',
  MH: 'Entity Number',
  MK: 'company number',
  MM: 'Registration number',
  MT: 'Registration number ( without the letters)',
  MU: 'File No.',
  MY: 'Company Number',
  NL: 'KVK-nummer',
  NO: 'Organisasjonsnummer: / Organisation number',
  NP: 'Registration No.',
  NZ: 'NZBN',
  PA: 'Folio No.',
  PE: 'Número de RUC:',
  PK: 'Incorporation number (CUIN):',
  PL: 'KRS number',
  PR: undefined,
  PT: 'NIF (Número de Identificação Fiscal)',
  QA: 'QFC number',
  RO: 'CIF (formally CUI)',
  RS: 'Registration Number',
  RU: 'OGRN',
  SB: 'Entity number',
  SE: 'Organisationsnummer',
  SG: 'UEN (Unique Entity Number)',
  SI: 'Registration number',
  SK: 'registration number',
  SN: 'Paragraph (Ninéa)',
  TH: 'Juristic Person Registration Number',
  TN: 'Unique Identifier',
  TO: 'Identifier',
  TW: 'Uniform Number (統一編號)',
  UG: 'Name reservation No.',
  UY: undefined,
  VI: 'Licence number',
  VN: 'Enterprise code:',
  VU: 'Identifier',
  WS: 'Company number',
  XK: 'Business number (Emri tregtar)',
  ZA: 'Enterprise No.',
}
