import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { includes, map } from 'lodash'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Badge } from '@cotiss/common/components/badge.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { PerformanceScorecardMetricCreateFormData } from '@cotiss/performance/drawers/performance-scorecard-metric-create.drawer'
import { usePerformanceMetric } from '@cotiss/performance/hooks/use-performance-metric.hook'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { Radio } from '@cotiss/common/components/radio.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { userService } from '@cotiss/user/user.service'

type Props = {
  formData: PerformanceScorecardMetricCreateFormData
  setFormData: (formData: PerformanceScorecardMetricCreateFormData) => void
}

export const PerformanceScorecardMetricCreateMetricStep = memo(({ formData, setFormData }: Props) => {
  const { openToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { performanceScorecard } = usePerformanceScorecard()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceMetrics, queryPerformanceMetricList } = usePerformanceMetric()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceMetricList({
        filter: { isArchived: false },
        pagination: { page: currentPage, pageSize: 20 },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [currentPage])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(performanceMetrics, (performanceMetric) => ({
          content: () => (
            <Radio
              value={performanceMetric.id}
              name="performance-scorecard-metric"
              onChange={() => setFormData({ ...formData, performanceMetricId: performanceMetric.id })}
              isChecked={performanceMetric.id === formData.performanceMetricId}
              isDisabled={includes(performanceScorecard?.performanceMetricIds || [], performanceMetric.id)}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Metric',
        rows: map(performanceMetrics, (performanceMetric) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: includes(performanceScorecard?.performanceMetricIds || [], performanceMetric.id) && (
            <Tooltip_DEPRECATED tooltip="This metric has already been added.">
              <Icon icon="lock" />
            </Tooltip_DEPRECATED>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceMetrics, ({ group }) => ({
          content: () => <Text>{group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceMetrics, ({ description }) => ({
          content: () => <Text>{description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceMetrics, ({ createdByUser }) => ({
          content: () => (createdByUser ? <Avatar>{userService.getInitials(createdByUser)}</Avatar> : '--'),
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecard, performanceMetrics, formData])

  return (
    <>
      <TableHeader className="flex items-center justify-between">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Search and select metric
          </Text>
          <Text size="sm">Select an option below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 1 of 2
        </Badge>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
