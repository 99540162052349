import React, { memo, useEffect, useState } from 'react'
import { DragEndEvent, DragOverlay, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { AnimatePresence, motion } from 'framer-motion'
import { find, findIndex, map } from 'lodash'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventEnvelopeListItem } from '@cotiss/evaluation-event/components/evaluation-event-envelope-list-item.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  isEditable?: boolean
  isSortable?: boolean
}

export const EvaluationEventEnvelopeList = memo(({ isEditable, isSortable }: Props) => {
  const { openToast } = useToast()
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const sensors = useSensors(mouseSensor, touchSensor)
  const { evaluationEnvelopes, mutateUpdateEvaluationEnvelopeOrders } = useEvaluationEnvelope()
  const [activeEnvelope, setActiveEnvelope] = useState<GqlEvaluationEnvelopeFieldsFragment | null>(null)
  const [envelopes, setEnvelopes] = useState<GqlEvaluationEnvelopeFieldsFragment[]>(evaluationEnvelopes || [])

  useEffect(() => {
    setEnvelopes(evaluationEnvelopes)
  }, [evaluationEnvelopes])

  const handleSubmit = async (sortedEnvelopes: GqlEvaluationEnvelopeFieldsFragment[]) => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelopes_reorder_submit')

    try {
      setIsSaving(true)

      await mutateUpdateEvaluationEnvelopeOrders({
        evaluationEventId: evaluationEvent.id,
        envelopes: map(sortedEnvelopes, (envelope, index) => ({
          evaluationEnvelopeId: envelope.id,
          order: index + 1,
        })),
      })

      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setEnvelopes((envelopes) => {
        const oldIndex = findIndex(envelopes, { id: active.id as string })
        const newIndex = findIndex(envelopes, { id: over.id as string })

        const sortedEnvelopes = arrayMove(envelopes, oldIndex, newIndex)

        handleSubmit(sortedEnvelopes)

        return sortedEnvelopes
      })
    }

    setActiveEnvelope(null)
  }

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      onDragStart={({ active }) => setActiveEnvelope(find(envelopes, { id: active.id as string }) || null)}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={envelopes}>
        <AnimatePresence mode={!activeEnvelope ? 'popLayout' : 'sync'}>
          {map(envelopes, (envelope, index) => (
            <div key={envelope.id} className="flex items-center justify-between w-full">
              {isSortable && envelopes.length > 1 && <Text className="font-semibold w-4 mr-4">{index + 1}.</Text>}
              <motion.div className="w-full" animate={{ x: 0, opacity: 1 }} exit={{ x: '-20px', opacity: 0 }} transition={{ type: 'tween' }} layout>
                <EvaluationEventEnvelopeListItem
                  className={index ? 'mt-2' : ''}
                  evaluationEnvelope={envelope}
                  isActive={Boolean(activeEnvelope && activeEnvelope.id === envelope.id)}
                  isEditable={isEditable}
                  isSortable={isSortable && envelopes.length > 1}
                  isDisabled={isSaving}
                />
              </motion.div>
            </div>
          ))}
        </AnimatePresence>
      </SortableContext>
      <DragOverlay dropAnimation={{ duration: 100 }}>
        {activeEnvelope ? <EvaluationEventEnvelopeListItem evaluationEnvelope={activeEnvelope} isSortable={isSortable} isInOverlay /> : null}
      </DragOverlay>
    </DndContext>
  )
})
