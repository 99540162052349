import React, { memo, useMemo, useState } from 'react'
import ListCheckboxPlaceholder from '@assets/svg/list-checkbox-placeholder.svg'
import classNames from 'classnames'
import { compact, filter, includes, isEqual, map, size, sortBy, toLower } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import REGIONS from '@cotiss/common/data/regions.data.json'
import { GroupedRegion, SingularRegion, regionService } from '@cotiss/common/services/region.service'

const regionsWithParentAsSubRegion = map(REGIONS, (region) => {
  const subRegions = [region, ...(region.options || [])] // NOTE: Add the parent region itself to the list of region options

  return {
    ...region,
    options: subRegions,
  }
})

type Props = {
  selectedRegions: string[]
  onChange?: (regions: string[]) => void | Promise<void>
}

export const RegionsModal = memo(({ selectedRegions, onChange }: Props) => {
  const [q, setQ] = useState('')
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [activeRegion, setActiveRegion] = useState<GroupedRegion | null>(null)
  const [stateRegions, setStateRegions] = useState<GroupedRegion[]>(regionService.processRegions(selectedRegions))

  const filteredRegions = useMemo(() => {
    const qToUse = toLower(q)
    const processedRegionData = qToUse
      ? map(regionsWithParentAsSubRegion, ({ options, label, value }) => {
          const filteredSubRegions = filter(options, ({ label, value }) => includes(toLower(label), qToUse) || includes(toLower(value), qToUse))

          if (filteredSubRegions.length || includes(toLower(label), qToUse) || includes(toLower(value), qToUse)) {
            return {
              options: filteredSubRegions,
              label,
              value,
            }
          }

          return null
        })
      : regionsWithParentAsSubRegion

    return compact(processedRegionData)
  }, [regionsWithParentAsSubRegion, q])

  const subRegionsToRender = useMemo(() => {
    if (!activeRegion) {
      return []
    }

    const qToUse = toLower(q)
    return qToUse
      ? filter(activeRegion.options, ({ label, value }) => includes(toLower(label), qToUse) || includes(toLower(value), qToUse))
      : activeRegion.options
  }, [activeRegion, filteredRegions, q])

  const isDirty = useMemo(() => {
    return !isEqual(
      selectedRegions,
      map(stateRegions, ({ value }) => value)
    )
  }, [selectedRegions, stateRegions])

  const handleConfirm = async () => {
    setIsSaving(true)
    const regionValues = map(stateRegions, ({ value }) => value)

    if (onChange) {
      onChange(regionValues)
    }

    closeModal()
  }

  const handleSubRegionChange = (subRegion: SingularRegion, isSelected: boolean) => {
    setStateRegions(sortBy(isSelected ? [...stateRegions, subRegion] : filter(stateRegions, (region) => region.value !== subRegion.value), 'label'))
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-700 bg-opacity-50 p-20 z-[1001]">
      <div className="flex flex-col bg-white rounded-lg max-w-[900px] h-[500px] w-full py-4">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center w-full">
            <Text className="shrink-0 font-semibold" size="h7">
              Select Region
            </Text>

            <div className="flex items-center border-l border-gray-200 w-full ml-3 pl-3">
              <Icon icon="search" variant="light" />
              <Input
                className="w-full"
                value={q}
                onChange={({ target }) => setQ(target.value)}
                placeholder="Search by country or region"
                state="ghost"
              />
            </div>
          </div>

          <div className="flex items-center shrink-0 ml-2">
            <Button className="mr-2" onClick={handleConfirm} variant="secondary" size="sm" isLoading={isSaving} isDisabled={!isDirty}>
              Confirm Selection
            </Button>
            <Button onClick={() => closeModal()} state="ghost" shape="square" isDisabled={isSaving}>
              <Icon icon="x-close" />
            </Button>
          </div>
        </div>
        <div className="flex items-stretch h-[calc(100%-42px)] mt-4">
          <div className="flex flex-col w-2/3 mr-4">
            <div className="flex items-center justify-between bg-secondary-100 rounded-r-lg py-3 px-6">
              <div className="w-1/3 mr-4">
                <Text className="font-medium uppercase" size="xs">
                  Regions ({size(filteredRegions)})
                </Text>
              </div>
              <div className="flex items-center justify-between w-2/3">
                <Text className="font-medium uppercase" size="xs">
                  Sub Regions{activeRegion && ` (${size(activeRegion.options)})`}
                </Text>
                <Text className="font-medium uppercase" size="xs">
                  Code
                </Text>
              </div>
            </div>
            <div className="flex h-[calc(100%-42px)] pl-4">
              <div className="overflow-y-auto border-r border-gray-100 w-1/3 pr-2 mr-2">
                {map(filteredRegions, (region, index) => {
                  const { value, label, options } = region
                  const isActive = Boolean(activeRegion?.value === value)
                  const classes = classNames('text-left flex items-center justify-between text-xs rounded w-full p-2', {
                    'mt-4': !index,
                    'mt-0.5': index,
                    'bg-secondary-100': isActive,
                  })

                  return (
                    <Button key={value} className={classes} onClick={() => setActiveRegion(region)} state="raw" isDisabled={isSaving}>
                      <Text className="mr-2" variant={isActive ? 'secondary' : 'dark'}>
                        {label} ({options.length})
                      </Text>
                      <Text className="flex-shrink-0 w-6" variant="light">
                        {value}
                      </Text>
                    </Button>
                  )
                })}
              </div>
              <div className="overflow-y-auto pl-2 w-2/3">
                {activeRegion ? (
                  map(subRegionsToRender, (subRegion, index) => {
                    const id = `cotiss-region-${subRegion.value}`
                    const { value, label } = subRegion
                    const isSelected = Boolean(stateRegions.find((region) => region.value === value))
                    const classes = classNames('flex items-center justify-between bg-gray-50 text-xs cursor-pointer w-full p-2.5', {
                      'mt-4': index === 0,
                      'mt-0.5': index > 0,
                    })

                    return (
                      <label key={value} className={classes} htmlFor={id}>
                        <Text className="mr-2">{label}</Text>
                        <Text className="flex items-center text-light">
                          {value}
                          <Checkbox
                            id={id}
                            className="ml-2"
                            isChecked={isSelected}
                            onChange={() => handleSubRegionChange(subRegion, !isSelected)}
                            isDisabled={isSaving}
                          />
                        </Text>
                      </label>
                    )
                  })
                ) : (
                  <div className="flex justify-center items-center h-full w-full">
                    <NoDataPlaceholder label="Select a region from the list and browse options here" variant="transparent" orientation="vertical" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full pr-6 pb-4">
            {stateRegions.length > 0 ? (
              <div className="bg-secondary-50 border border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                <Text className="font-medium uppercase" size="xs">
                  Selected ({stateRegions.length})
                </Text>
                {map(stateRegions, (region) => (
                  <div key={region.value} className="mt-2">
                    <Pill onRemove={() => handleSubRegionChange(region, false)}>{region.label}</Pill>
                  </div>
                ))}
              </div>
            ) : (
              <div className="border border-dashed flex flex-col justify-center items-center text-center border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                {activeRegion && (
                  <>
                    <ListCheckboxPlaceholder className="mb-4" />
                    <Text className="font-semibold mb-2">No subregions selected</Text>
                    <Text size="xs" variant="light">
                      Tick the options that you would like added
                    </Text>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
