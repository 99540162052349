import { add, isBefore, isDate, parseISO } from 'date-fns'
import { filter, forEach, map, orderBy, sum } from 'lodash'
import { OcdsPeriodWithTimestampPopulatedModel } from '@cotiss/common/models/ocds.model'
import { utilService } from '@cotiss/common/services/util.service'
import { TenderFieldWithHistory, TenderModel, TenderPopulatedModel } from '@cotiss/tender/tender.models'

type NonPriceCriterion = {
  weight: number | string
}

type NonPriceQuestionCriterion = {
  weight: number | string
  score?: number | string
}

type NonPriceCriterionWithQuestions = {
  weight: number | string
  questions: NonPriceQuestionCriterion[]
}

type ProcessCriteriaParam = {
  nonPriceCriteria?: NonPriceCriterion[]
  nonPriceCriteriaWithQuestions?: NonPriceCriterionWithQuestions[]
  nonPriceWeight?: number
  priceWeight?: number
}

type ProcessedNonPriceCriterion = {
  absolute: number
  weight: number
  overallWeight: number
  percentage: string
  overallPercentage: string
}

export type ProcessedNonPriceCriterionWithQuestionsItem = {
  absolute: number
  weight: number
  overallWeight: number
  percentage: string
  overallPercentage: string
  score: number
  questions: ProcessedNonPriceCriterion[]
}

type ProcessedNonPriceCriterionWithQuestions = {
  criteria: ProcessedNonPriceCriterionWithQuestionsItem[]
  overallScore: number
  totalScore: number
}

type ProcessedCriteriaWeight = {
  absolute: number
  weight: number
  percentage: string
}

export type ProcessedCriteriaModel = {
  nonPriceCriteria: ProcessedNonPriceCriterion[]
  nonPriceCriteriaWithQuestions: ProcessedNonPriceCriterionWithQuestions
  nonPrice: ProcessedCriteriaWeight
  price: ProcessedCriteriaWeight
}

class TenderService {
  isTenderClosed = (tender: TenderModel | TenderPopulatedModel, isGracePeriodIncluded = true) => {
    const endDate = tender.tenderPeriod?.endDate && parseISO(tender.tenderPeriod?.endDate)

    if (!endDate || !isDate(endDate)) {
      return false
    }

    const endDateToCheck = isGracePeriodIncluded && tender.gracePeriod ? add(endDate as Date, { hours: tender.gracePeriod }) : endDate

    return isBefore(endDateToCheck, new Date())
  }

  // TODO: Split this into separate methods, we don't need to do all this heavy lifting in a single call.
  // TODO: Move this to the tender-criteria domain.
  processCriteria = (param: ProcessCriteriaParam): ProcessedCriteriaModel => {
    const { nonPriceCriteria = [], nonPriceCriteriaWithQuestions = [], nonPriceWeight: nonPriceAbsolute = 0, priceWeight: priceAbsolute = 0 } = param
    const [nonPriceWeight, priceWeight] = this.getWeightsFromAbsolutes([nonPriceAbsolute, priceAbsolute])

    return {
      nonPriceCriteria: this.processNonPriceCriteria(nonPriceCriteria, nonPriceWeight),
      nonPriceCriteriaWithQuestions: this.processNonPriceCriteriaWithQuestions(nonPriceCriteriaWithQuestions, nonPriceWeight),
      nonPrice: {
        absolute: nonPriceAbsolute,
        weight: nonPriceWeight,
        percentage: utilService.formatAsPercentage(nonPriceWeight * 100),
      },
      price: {
        absolute: priceAbsolute,
        weight: priceWeight,
        percentage: utilService.formatAsPercentage(priceWeight * 100),
      },
    }
  }

  getWeightsFromAbsolutes = (absolutes: (number | string)[]) => {
    const total = sum(absolutes)

    return map(absolutes, (absolute) => Number(absolute) / total)
  }

  processNonPriceCriteria = (nonPriceCriteria: NonPriceCriterion[], nonPriceWeight: number): ProcessedNonPriceCriterion[] => {
    const total = sum(map(nonPriceCriteria, ({ weight: absolute }) => Number(absolute)))

    return map(nonPriceCriteria, ({ weight: absoluteStr }) => {
      const absolute = Number(absoluteStr)
      const weight = absolute / total || 0
      const overallWeight = weight * nonPriceWeight
      const percentage = utilService.formatAsPercentage(weight * 100)
      const overallPercentage = utilService.formatAsPercentage(overallWeight * 100)

      return { absolute, weight, overallWeight, percentage, overallPercentage }
    })
  }

  processNonPriceCriteriaWithQuestions = (
    nonPriceCriteriaWithQuestions: NonPriceCriterionWithQuestions[],
    nonPriceWeight: number
  ): ProcessedNonPriceCriterionWithQuestions => {
    const total = sum(map(nonPriceCriteriaWithQuestions, ({ weight: absoluteStr }) => Number(absoluteStr)))
    const criteria = map(nonPriceCriteriaWithQuestions, ({ weight: absoluteStr, questions }) => {
      let score = 0
      const absolute = Number(absoluteStr)
      const weight = absolute / total || 0
      const overallWeight = weight * nonPriceWeight
      const percentage = utilService.formatAsPercentage(weight * 100)
      const overallPercentage = utilService.formatAsPercentage(overallWeight * 100)

      const questionTotal = sum(map(questions, ({ weight: absoluteStr }) => Number(absoluteStr)))

      const processedQuestions = map(questions, ({ weight: questionAbsoluteStr, score: questionScoreStr }) => {
        const questionAbsolute = Number(questionAbsoluteStr)
        const questionWeight = questionAbsolute / questionTotal || 0
        const questionOverallWeight = questionWeight * weight
        const questionPercentage = utilService.formatAsPercentage(questionWeight * 100)
        const questionOverallPercentage = utilService.formatAsPercentage(questionOverallWeight * 100)

        score += Number(questionScoreStr) ? Number(questionScoreStr) * questionWeight || 0 : 0

        return {
          absolute: questionAbsolute,
          weight: questionWeight,
          overallWeight: questionOverallWeight,
          percentage: questionPercentage,
          overallPercentage: questionOverallPercentage,
        }
      })

      return { absolute, weight, overallWeight, percentage, overallPercentage, score, questions: processedQuestions }
    })

    let overallScore = 0
    forEach(criteria, ({ weight, score }) => {
      overallScore += score * weight
    })

    return { criteria, overallScore, totalScore: overallScore * nonPriceWeight }
  }

  hasEvaluation = (tender?: TenderModel | TenderPopulatedModel) => {
    if (!tender?.evaluationPanelType) {
      return false
    }

    return tender.evaluationPanelType !== 'none'
  }

  getPeriodEndDateHistory = ({ tender, field }: { tender: TenderPopulatedModel; field: TenderFieldWithHistory }) => {
    const allPeriods =
      field === 'tenderPeriod'
        ? [...(tender.previousTenderPeriods || []), tender.tenderPeriod]
        : [...(tender.previousForumCloseDates || []), tender.forumCloseDate]
    const periodsWithEndDates = filter(allPeriods, (period) => period?.endDate !== undefined) as OcdsPeriodWithTimestampPopulatedModel[]
    const history = []

    for (let i = 0; i < periodsWithEndDates.length; i++) {
      const currentPeriod = periodsWithEndDates[i]
      const previousPeriod = i ? periodsWithEndDates[i - 1] : undefined

      if (currentPeriod && !previousPeriod) {
        history.push({
          type: 'added',
          value: currentPeriod.endDate,
          timestamp: currentPeriod.createdAt,
          actionedBy: currentPeriod.createdBy,
        })
      } else if (currentPeriod && previousPeriod) {
        history.push({
          type: 'changed',
          value: currentPeriod.endDate,
          timestamp: currentPeriod.createdAt,
          actionedBy: currentPeriod.createdBy,
        })
      }
    }

    // Note: to handle periods that don't have timestamps, return an empty string so they are sorted to the end of the array.
    return orderBy(history, (historyItem) => (historyItem.timestamp ? historyItem.timestamp : ''), 'desc')
  }
}

export const tenderService = new TenderService()
