import React, { FormEvent, memo, useEffect, useState } from 'react'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { SignUpSupplierStepCompany } from '@cotiss/auth/components/sign-up-supplier-step-company.component'
import { SignUpSupplierStepCompanyDetails } from '@cotiss/auth/components/sign-up-supplier-step-company-details.component'
import { SignUpSupplierStepCompanyDetailsExtended } from '@cotiss/auth/components/sign-up-supplier-step-company-details-extended.component'
import { SignUpSupplierStepPassword } from '@cotiss/auth/components/sign-up-supplier-step-password.component'
import { SignUpSupplierStepUser } from '@cotiss/auth/components/sign-up-supplier-step-user.component'
import { authService } from '@cotiss/auth/auth.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { CotissSquares } from '@cotiss/common/components/cotiss-squares.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { utilService } from '@cotiss/common/services/util.service'
import { useMutateAccount } from '@cotiss/account/resources/use-mutate-account.resource'
import { SignUpPromoBox } from '@cotiss/auth/components/sign-up-promo-box.component'
import { OrganisationCountryCode, OrganisationCountryCodeSubdivision, OrganisationEntityType } from '@cotiss/organisation/organisation.models'
import { organisationService } from '@cotiss/organisation/organisation.service'

export type SignUpSupplierFormData = {
  email: string
  companyName: string
  businessNumber?: string
  taxNumber: string
  entityType?: OrganisationEntityType
  countryCode?: OrganisationCountryCode
  countryCodeSubdivision?: OrganisationCountryCodeSubdivision
  industry: string
  firstname: string
  surname: string
  phone: string
  password: string
  passwordConfirm: string
  termsAgreement: boolean
}

export const SignUpSupplierPage = memo(() => {
  const { push } = useHistory()
  const { login, logout } = useAuth()
  const { createAccount } = useMutateAccount()
  const [formError, setFormError] = useState('')
  const [submitRetryCount, setSubmitRetryCount] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const authRedirectRoute = localStorageService.getItem('auth-redirect-route')
  const search = authRedirectRoute && new URL(`${process.env.APP_DOMAIN}${authRedirectRoute}`).search
  const searchParams = search && utilService.getUrlSearchParams({ search, params: ['invitation-token', 'invitation-email'] })
  const invitationEmail = searchParams ? searchParams['invitation-email'] || '' : ''
  const invitationToken = searchParams ? searchParams['invitation-token'] || '' : ''
  const showAcceptInvitationText = Boolean(invitationEmail || invitationToken)
  const [formData, setFormData] = useState<SignUpSupplierFormData>({
    email: invitationEmail || '',
    companyName: '',
    taxNumber: '',
    industry: '',
    firstname: '',
    surname: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    termsAgreement: false,
  })
  const processedCountryCode = formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

  useEffect(() => {
    logout()
  }, [])

  useEffect(() => {
    if (submitRetryCount > 0) {
      setSubmitRetryCount(0)
    }
    if (formError) {
      setFormError('')
    }
  }, [processedCountryCode, formData.businessNumber])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (step < 5) {
      onTransition({ step: step + 1 })
      return
    }

    setFormError('')

    if (!formData.termsAgreement) {
      setFormError('You must agree to terms and conditions to continue.')
      return
    }

    if (formData.password !== formData.passwordConfirm) {
      setFormError('Passwords do not match.')
      return
    }

    if (!authService.isPasswordValid(formData.password)) {
      setFormError('Password must be at least 8 characters long and contain at least one letter and one number.')
      return
    }

    try {
      setIsSaving(true)
      // If the user has already tried to submit the form, and the sign-up has returned an error, proceed without validation.
      // As not to block sign up flow in case of issues with the registry
      const validateBusiness = submitRetryCount < 1
      await createAccount(
        {
          firstname: formData.firstname,
          surname: formData.surname,
          email: formData.email,
          password: formData.password,
          phone: formData.phone,
          name: formData.companyName,
          businessNumber: formData.businessNumber,
          taxNumber: formData.taxNumber,
          entityType: formData.entityType,
          countryCode: formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode,
          productSupplier: 'SupplierFree',
        },
        validateBusiness
      )

      await login(formData.email, formData.password)
      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
    } catch (error: any) {
      if (error.message === 'organisation_claimed') {
        const urlBase = routerService.getHref('/join-account/:email', { email: formData.email })
        const queryParams = utilService.generateUrlSearchParams({
          businessNumber: formData.businessNumber,
          countryCode: formData.countryCode,
        })
        const url = `${urlBase}${queryParams}`

        push(url)
        return
      } else if (
        error.message === 'organisation_search_error_no_results' ||
        error.message === 'organisation_search_error_multiple_results' ||
        error.message === 'organisation_search_error_bad_request'
      ) {
        onTransition({ step: 3 })
        setSubmitRetryCount(submitRetryCount + 1)
        setFormError(
          `No business found. Please ensure your ${organisationService.getRegistryNumberType(processedCountryCode)} and country are correct.`
        )
        setIsSaving(false)
        return
      }

      sentryService.captureException({ exception: error })
      setFormError(error.message)
      setIsSaving(false)
    }
  }

  const handleOnBack = () => {
    onTransition({ step: step - 1, transition: 'left' })
  }

  const stepProps = {
    formData,
    transition,
    setFormData,
    isDisabled: isTransitioning || isSaving,
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="relative bg-white w-5/12 h-full p-4 pr-10">
          <div className="bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight mt-48">
              Better decisions.
              <br />
              Simplified process.
            </h1>
            <SignUpPromoBox className="bottom-24 right-0">
              <Text variant="white" size="h4">
                Supply securely through Cotiss. The all-in-one procurement platform.
              </Text>
              <Text className="mt-2" variant="white" size="h5">
                Grow your business today
              </Text>
            </SignUpPromoBox>
          </div>
        </div>
        <div className="bg-white w-7/12 h-full p-12">
          <Button className={step === 1 ? 'invisible' : ''} onClick={handleOnBack} state="ghost" variant="link">
            <Icon icon="chevron-left" />
            Back
          </Button>

          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-[350px]">
              <div className="flex items-center">
                <CotissSquares className="mr-2" size="sm" variant="info" />
                <CotissLogoDarkSvg className="h-3 mr-1" />
                <Text size="h7">for suppliers</Text>
              </div>
              <Text className="font-semibold mt-2" size="h3">
                Sign up
              </Text>
              <Text className="mt-2" variant="light">
                Sign up to Cotiss for suppliers and start bidding to win contracts.
              </Text>
              {Boolean(formError) && (
                <Text className="text-sm my-4" variant="danger">
                  {formError}
                </Text>
              )}
              <Form className="mt-8" onSubmit={handleSubmit} isHubSpot>
                <AnimatePresence mode="wait" initial={false}>
                  {step === 1 && <SignUpSupplierStepCompany {...stepProps} />}
                  {step === 2 && <SignUpSupplierStepCompanyDetails {...stepProps} />}
                  {step === 3 && <SignUpSupplierStepCompanyDetailsExtended {...stepProps} willProceedWithoutValidation={Boolean(submitRetryCount)} />}
                  {step === 4 && <SignUpSupplierStepUser {...stepProps} />}
                  {step === 5 && <SignUpSupplierStepPassword {...stepProps} isInvitation={showAcceptInvitationText} />}
                </AnimatePresence>

                <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
                  <Text className="mr-1" isInline>
                    Already have an account?
                  </Text>
                  <Button
                    className="inline-block text-sm"
                    state="text"
                    variant="link"
                    href={routerService.getHref('/login')}
                    isDisabled={isSaving}
                    isLink
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
})
