import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'

type CreateProcurementResponseUserBody = {
  procurementResponseId: string
  userId?: string
}

export const useMutateProcurementResponseUser = () => {
  const { mutate } = useMutate()

  return {
    createProcurementResponseUser: async (body: CreateProcurementResponseUserBody) => {
      return await mutate({ route: '/v2/procurement-response-users', body, invalidate: ['/v2/procurement-response'] })
    },
    deleteProcurementResponseUser: async (procurementResponseUserId: string) => {
      return await mutate({
        route: `/v2/procurement-response-users/${procurementResponseUserId}`,
        method: 'DELETE',
        invalidate: ['/v2/procurement-response'],
      })
    },
  }
}
