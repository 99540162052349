import { gql } from '@apollo/client'
import {
  GqlUpsertEvaluationScoreMutation,
  GqlUpsertEvaluationScoreMutationVariables,
  GqlEvaluationScoreFieldsFragment,
  GqlUpsertEvaluationScoreInput,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SCORE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-score/evaluation-score-fragment.graphql'

export const mutateUpsertEvaluationScore = async (input: GqlUpsertEvaluationScoreInput) => {
  const { data } = await apolloService.mutate<GqlUpsertEvaluationScoreMutation, GqlUpsertEvaluationScoreMutationVariables>({
    mutation: gql`
      ${EVALUATION_SCORE_FRAGMENT}

      mutation UpsertEvaluationScore($input: UpsertEvaluationScoreInput!) {
        upsertEvaluationScore(input: $input) {
          ...EvaluationScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data?.upsertEvaluationScore as GqlEvaluationScoreFieldsFragment
}
