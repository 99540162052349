import { gql } from '@apollo/client'
import {
  GqlUpdateEvaluationUserInput,
  GqlUpdateEvaluationUserMutation,
  GqlUpdateEvaluationUserMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateUpdateEvaluationUser = async (input: GqlUpdateEvaluationUserInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationUserMutation, GqlUpdateEvaluationUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation UpdateEvaluationUser($input: UpdateEvaluationUserInput!) {
        updateEvaluationUser(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationUser as GqlEvaluationUserFieldsFragment
}
