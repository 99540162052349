import React, { memo } from 'react'
import { map } from 'lodash'
import { Td_DEPRECATED } from '@cotiss/common/components/deprecated/td.component'
import { TrVariant_DEPRECATED, Tr_DEPRECATED } from '@cotiss/common/components/deprecated/tr.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  rows?: number
  columns: number
  variant?: TrVariant_DEPRECATED
}

export const ListItemsSkeleton = memo(({ rows = 3, columns, variant = 'white' }: Props) => (
  <>
    {map(Array(rows), (_, index) => (
      <Tr_DEPRECATED key={index} variant={variant}>
        {map(Array(columns), (_, index) => (
          <Td_DEPRECATED key={index}>
            <Skeleton className="w-full h-2" variant={variant === 'white' ? 'primary' : 'gray'} />
          </Td_DEPRECATED>
        ))}
      </Tr_DEPRECATED>
    ))}
  </>
))
