import React, { memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { Header } from '@cotiss/common/components/header.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ApprovalRejectConfirmModal } from '@cotiss/approval/modals/approval-reject-confirm.modal'
import { useGetApproval } from '@cotiss/approval/resources/use-get-approval.resource'
import { useMutateApproval } from '@cotiss/approval/resources/use-mutate-approval.resource'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useGetProject } from '@cotiss/project/resources/use-get-project.resource'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'

type Props = {
  breadcrumbs?: BreadcrumbModel[]
}

export const ApprovalViewHeader = memo(({ breadcrumbs }: Props) => {
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { approval, isLoading: isApprovalLoading } = useGetApproval(id)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(approval?.tender.procurement)
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)
  const { openModal } = useCallout()
  const { updateApproval } = useMutateApproval()

  const isLoading = isApprovalLoading || isProcurementLoading

  return (
    <Header>
      <div className="flex justify-between items-center">
        <div>
          <Breadcrumb breadcrumbs={breadcrumbs} />
          {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
          {!isLoading && (
            <div className="flex items-center">
              {approval?.tender.tenderType && <TenderTypeBadge className="mr-2" tenderType={approval.tender.tenderType} />}
              <Text className="font-semibold" size="h5" variant="heading">
                {procurement?.title}
              </Text>
            </div>
          )}
          {isProjectLoading && <Skeleton className="h-3.5 w-24 mt-1" variant="gray" />}
          {!isProjectLoading && (
            <Text className="mt-0.5" size="sm" variant="light">
              {project?.title}
            </Text>
          )}
        </div>
        <div>
          {approval?.status === 'incomplete' && (
            <div className="ml-4">
              <Button
                className="mr-2"
                onClick={() => openModal(<ApprovalRejectConfirmModal approval={approval} />)}
                state="translucent"
                variant="secondary"
                size="sm"
              >
                Reject
              </Button>
              <Button
                size="sm"
                onClick={() =>
                  openModal(
                    <ConfirmModal
                      heading="Approve procurement plan"
                      description="Are you sure you want to approve this request?"
                      onSubmit={async () => {
                        await updateApproval(approval._id, { status: 'approved' })
                        push(routerService.getHref('/approval/view/:id', { id }))
                      }}
                    />
                  )
                }
              >
                Approve
              </Button>
            </div>
          )}
        </div>
      </div>
    </Header>
  )
})
