import { gql } from '@apollo/client'
import { GqlCreatePlanEventInput, GqlCreatePlanEventMutation, GqlCreatePlanEventMutationVariables, GqlPlanEventFieldsFragment } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event/plan-event-fragment.graphql'

export const mutateCreatePlanEvent = async (input: GqlCreatePlanEventInput) => {
  const { data } = await apolloService.mutate<GqlCreatePlanEventMutation, GqlCreatePlanEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      mutation CreatePlanEvent($input: CreatePlanEventInput!) {
        createPlanEvent(input: $input) {
          ...PlanEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPlanEvent as GqlPlanEventFieldsFragment
}
