import React, { FormEvent, memo, useState } from 'react'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'

type FormData = {
  label: string
}

type Props = {
  value?: string
  onSubmit: (value: string) => void
}

export const SettingsModulesMetafieldCreateUpdateModal = memo(({ value, onSubmit }: Props) => {
  const { closeModal } = useCallout()
  const [formData, setFormData] = useState<FormData>({
    label: value ?? '',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(formData.label)
    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={value ? 'Edit dropdown option' : 'Add dropdown option'} />
      <ModalContent>
        <Label>Option label</Label>
        <Input
          value={formData.label}
          onChange={({ target }) => setFormData({ ...formData, label: target.value })}
          placeholder="Enter option label"
          maxLength={100}
          isRequired
          autoFocus
        />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
