import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  GqlCurrencyCode,
  GqlPerformanceScorecardMetricFieldsFragment,
  GqlPerformanceScorecardMetricFrequency,
  GqlUpdatePerformanceScorecardMetricInput,
} from '@gql'
import { findIndex, includes, map, upperFirst } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { CURRENCY_DISPLAY_NAME_MAP } from '@cotiss/common/constants/currency.constants'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { MONTHS_WITH_30_DAYS, MONTH_CONFIG, MONTH_OPTIONS, Month, WEEKDAY_OPTIONS, Weekday } from '@cotiss/common/constants/datetime.constants'
import { SelectOption_DEPRECATED, Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { PerformanceScorecardMetricCycleExplanation } from '@cotiss/performance/components/performance-scorecard-metric-cycle-explanation.component'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { PERFORMANCE_METRIC_FREQUENCY_MONTH_REPEAT_OPTIONS, PERFORMANCE_METRIC_FREQUENCY_OPTIONS } from '@cotiss/performance/performance.constants'
import { performanceService } from '@cotiss/performance/performance.service'
import { PerformanceScorecardMetricNumberInput } from '@cotiss/performance/components/performance-scorecard-metric-number-input.component'

export type PerformanceScorecardMetricUpdateFormData = {
  startDate: Date | null
  target: string
  frequency: GqlPerformanceScorecardMetricFrequency
  frequencyEndOnDay: string
  frequencyEndOnMonth: Month
  frequencyEndOnWeekday: Weekday
  frequencyMonthRepeat: string
}

type Props = {
  performanceScorecardMetricId: string
  onSubmit: () => Promise<void>
  isEditing?: boolean
}

export const PerformanceScorecardMetricViewUpdateDrawer = memo((props: Props) => {
  const { performanceScorecardMetricId, onSubmit, isEditing: isInitialEditing = false } = props
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { performanceScorecard } = usePerformanceScorecard()
  const [isEditing, setIsEditing] = useState(isInitialEditing)
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { performanceScorecardMetric, mutateUpdatePerformanceScorecardMetric, queryPerformanceScorecardMetricView } = usePerformanceScorecardMetric()
  const [formData, setFormData] = useState<PerformanceScorecardMetricUpdateFormData>({
    startDate: null,
    target: '',
    frequency: 'annually',
    frequencyEndOnDay: '1',
    frequencyEndOnMonth: 'January',
    frequencyEndOnWeekday: 'Monday',
    frequencyMonthRepeat: '',
  })

  const { isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    return { isOwner }
  }, [performanceScorecardUserInSession])

  useEffect(() => {
    const { frequency, frequencyEndOnMonth, frequencyEndOnDay } = formData

    if (!frequencyEndOnDay) {
      setFormData({ ...formData, frequencyEndOnDay: '1' })
    }

    if (frequency === 'annually') {
      if (includes(MONTHS_WITH_30_DAYS, frequencyEndOnMonth) && Number(frequencyEndOnDay) > 30) {
        setFormData({ ...formData, frequencyEndOnDay: '30' })
      } else if (frequencyEndOnMonth === 'February' && Number(frequencyEndOnDay) > 28) {
        setFormData({ ...formData, frequencyEndOnDay: '28' })
      }
    }
  }, [formData])

  const frequencyEndOnDayOptions: SelectOption_DEPRECATED[] = useMemo(() => {
    if (formData.frequency === 'annually') {
      return map(Array(MONTH_CONFIG[formData.frequencyEndOnMonth].numberOfDays), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    } else {
      return map(Array(31), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    }
  }, [formData.frequency, formData.frequencyEndOnMonth])

  const resetFormData = (performanceScorecardMetric: GqlPerformanceScorecardMetricFieldsFragment) => {
    const formData: PerformanceScorecardMetricUpdateFormData = {
      startDate: datetimeService.parse(performanceScorecardMetric.startDate, 'yyyy-MM-dd'),
      target: performanceScorecardMetric.target.toString(),
      frequency: performanceScorecardMetric.frequency,
      frequencyEndOnDay: '1',
      frequencyEndOnMonth: 'January',
      frequencyEndOnWeekday: 'Monday',
      frequencyMonthRepeat: '1',
    }

    if (performanceScorecardMetric.frequency === 'annually') {
      formData.frequencyEndOnMonth = performanceScorecardMetric.frequencyEndOnMonth
        ? MONTH_OPTIONS[performanceScorecardMetric.frequencyEndOnMonth - 1]?.value || 'January'
        : 'January'
      formData.frequencyEndOnDay = performanceScorecardMetric.frequencyEndOnDay?.toString() || '1'
    } else if (performanceScorecardMetric.frequency === 'monthly') {
      formData.frequencyEndOnDay = performanceScorecardMetric.frequencyEndOnDay?.toString() || '1'
      formData.frequencyMonthRepeat = performanceScorecardMetric.frequencyMonthRepeat?.toString() || '1'
    } else if (performanceScorecardMetric.frequency === 'weekly') {
      formData.frequencyEndOnWeekday = performanceScorecardMetric.frequencyEndOnWeekday
        ? WEEKDAY_OPTIONS[performanceScorecardMetric.frequencyEndOnWeekday - 1]?.value || 'Monday'
        : 'Monday'
    }

    setFormData(formData)
  }

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      resetFormData(await queryPerformanceScorecardMetricView({ performanceScorecardMetricId }))
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [performanceScorecardMetricId])

  const handleSubmit = async () => {
    if (!performanceScorecard) {
      return
    }

    if (!isEditing) {
      return setIsEditing(true)
    }

    // This should not be possible, just adding so typescript doesn't complain.
    if (!formData.startDate) {
      return
    }

    setIsSaving(true)

    try {
      const input: GqlUpdatePerformanceScorecardMetricInput = {
        performanceScorecardMetricId,
        target: Number(formData.target),
      }

      if (performanceScorecard.status === 'draft') {
        input.startDate = datetimeService.format(formData.startDate, 'yyyy-MM-dd')
        input.frequency = formData.frequency

        if (formData.frequency === 'annually') {
          input.frequencyEndOnMonth = findIndex(MONTH_OPTIONS, ({ value }) => value === formData.frequencyEndOnMonth)
          input.frequencyEndOnDay = Number(formData.frequencyEndOnDay)
        } else if (formData.frequency === 'monthly') {
          input.frequencyEndOnDay = Number(formData.frequencyEndOnDay)

          // If the repeats is over 1, then we pass the repeat configuration.
          if (formData.frequencyMonthRepeat && Number(formData.frequencyMonthRepeat) > 1) {
            input.frequencyMonthRepeat = Number(formData.frequencyMonthRepeat)

            // Calculate the repeat start month based on the start date.
            const frequencyMonthRepeatStartOnMonth = datetimeService.format(formData.startDate, 'MMMM')
            input.frequencyMonthRepeatStartOnMonth = findIndex(MONTH_OPTIONS, ({ value }) => value === frequencyMonthRepeatStartOnMonth)
          }
        } else if (formData.frequency === 'weekly') {
          input.frequencyEndOnWeekday = findIndex(WEEKDAY_OPTIONS, ({ value }) => value === formData.frequencyEndOnWeekday) + 1
        }
      }

      resetFormData(await mutateUpdatePerformanceScorecardMetric(input))
      await onSubmit()
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const handleCancel = () => {
    performanceScorecardMetric && resetFormData(performanceScorecardMetric)
    setIsEditing(false)
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      View metric
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button variant="secondary" state={isEditing ? 'filled' : 'outline'} type="submit" isLoading={isSaving}>
        {isEditing ? 'Save' : 'Edit'}
      </Button>
      {isEditing && (
        <Button className="ml-2" onClick={handleCancel} state="text" variant="link">
          Cancel
        </Button>
      )}
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={isOwner && renderFooter()} onSubmit={handleSubmit}>
      {performanceScorecardMetric && (
        <Text size="sm" variant="light">
          {performanceScorecardMetric.performanceMetric.group}
        </Text>
      )}
      {performanceScorecardMetric && (
        <Text className="font-medium mt-2" size="lg">
          {performanceScorecardMetric.performanceMetric.name}
        </Text>
      )}
      <Hr className="my-4" />
      <Field label="Description" supplementary="Outlines this metric in more detail.">
        {performanceScorecardMetric && <Text>{performanceScorecardMetric.performanceMetric.description}</Text>}
      </Field>
      <Field className="mt-8" label="Methodology" supplementary="Outlines how to gather the value of this metric.">
        {performanceScorecardMetric && <Text>{performanceScorecardMetric.performanceMetric.methodology}</Text>}
      </Field>
      <Field className="mt-8" label="Metric type (unit)">
        {performanceScorecardMetric && (
          <div className="flex items-center">
            <Text>
              {upperFirst(performanceScorecardMetric.performanceMetric.metricUnit)}
              {performanceScorecardMetric.performanceMetric.metricUnit === 'currency' && ': '}
            </Text>
            {performanceScorecardMetric.performanceMetric.metricUnit === 'currency' &&
              performanceScorecardMetric.performanceMetric.metricUnitType && (
                <Text className="ml-1" variant="light">
                  {performanceScorecardMetric.performanceMetric.metricUnitType} (
                  {CURRENCY_DISPLAY_NAME_MAP[performanceScorecardMetric.performanceMetric.metricUnitType as GqlCurrencyCode]})
                </Text>
              )}
          </div>
        )}
      </Field>
      <Field className="mt-8" label="Target value" supplementary="The target value of this metric.">
        {isEditing && performanceScorecardMetric && (
          <PerformanceScorecardMetricNumberInput
            metricUnit={performanceScorecardMetric?.performanceMetric.metricUnit}
            metricUnitType={performanceScorecardMetric?.performanceMetric.metricUnitType}
            onValueChange={({ value }) => setFormData({ ...formData, target: value })}
            value={formData.target}
            isDisabled={isSaving || isLoading}
            isRequired
            placeholder="Enter the scorecard target value"
          />
        )}
        {!isEditing && performanceScorecardMetric && (
          <Text>
            {performanceService.formatPerformanceValue({
              value: performanceScorecardMetric.target,
              performanceMetric: performanceScorecardMetric.performanceMetric,
            })}
          </Text>
        )}
      </Field>
      <Hr className="my-6" />
      <Field label="Frequency" supplementary="Set a recurring frequency for this metric.">
        {isEditing && performanceScorecard?.status === 'draft' && (
          <Select_DEPRECATED<GqlPerformanceScorecardMetricFrequency>
            value={formData.frequency}
            options={PERFORMANCE_METRIC_FREQUENCY_OPTIONS}
            onChange={(frequency) => setFormData({ ...formData, frequency })}
            isDisabled={isSaving || isLoading}
            isRequired
          />
        )}
        {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
          <Text>{upperFirst(performanceScorecardMetric.frequency)}</Text>
        )}
      </Field>
      {formData.frequency === 'annually' && (
        <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
          {isEditing && performanceScorecard?.status === 'draft' && (
            <div className="flex items-center">
              <Select_DEPRECATED
                className="mr-4"
                value={formData.frequencyEndOnDay}
                options={frequencyEndOnDayOptions}
                onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
                isDisabled={isSaving || isLoading}
                isRequired
              />
              <Select_DEPRECATED
                value={formData.frequencyEndOnMonth}
                options={MONTH_OPTIONS}
                onChange={(frequencyEndOnMonth) => setFormData({ ...formData, frequencyEndOnMonth })}
                isDisabled={isSaving || isLoading}
                isRequired
              />
            </div>
          )}
          {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
            <Text>
              {performanceScorecardMetric.frequencyEndOnDay} {formData.frequencyEndOnMonth}
            </Text>
          )}
        </Field>
      )}
      {formData.frequency === 'monthly' && (
        <>
          <Field className="mt-8" label="Repeats every" supplementary="Set cadence for the monthly frequency.">
            {isEditing && performanceScorecard?.status === 'draft' && (
              <Select_DEPRECATED
                value={formData.frequencyMonthRepeat}
                options={PERFORMANCE_METRIC_FREQUENCY_MONTH_REPEAT_OPTIONS}
                onChange={(frequencyMonthRepeat) => setFormData({ ...formData, frequencyMonthRepeat })}
                isDisabled={isSaving || isLoading}
                isRequired
              />
            )}
            {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
              <Text>
                {performanceScorecardMetric.frequencyMonthRepeat || '1'} month{Number(performanceScorecardMetric.frequencyMonthRepeat) > 1 ? 's' : ''}
              </Text>
            )}
          </Field>
          <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
            {isEditing && performanceScorecard?.status === 'draft' && (
              <Select_DEPRECATED
                value={formData.frequencyEndOnDay}
                options={frequencyEndOnDayOptions}
                onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
                isDisabled={isSaving || isLoading}
                isRequired
              />
            )}
            {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
              <Text>{performanceScorecardMetric.frequencyEndOnDay}</Text>
            )}
          </Field>
        </>
      )}
      {formData.frequency === 'weekly' && (
        <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
          {isEditing && performanceScorecard?.status === 'draft' && (
            <Select_DEPRECATED<Weekday>
              value={formData.frequencyEndOnWeekday}
              options={WEEKDAY_OPTIONS}
              onChange={(frequencyEndOnWeekday) => setFormData({ ...formData, frequencyEndOnWeekday })}
              isDisabled={isSaving || isLoading}
              isRequired
            />
          )}
          {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
            <Text>{performanceScorecardMetric.frequencyEndOnWeekday}</Text>
          )}
        </Field>
      )}
      <Field className="mt-8" label="Start date" supplementary="Select a date for the first frequency cycle to open.">
        {isEditing && performanceScorecard?.status === 'draft' && (
          <DatetimeInput
            value={formData.startDate}
            onChange={(startDate) => setFormData({ ...formData, startDate })}
            placeholder="Select start date..."
            dateFormat="do MMM yyyy"
            isDisabled={isSaving || isLoading}
            isTimeVisible={false}
            isFuture
            isRequired
          />
        )}
        {performanceScorecardMetric && (!isEditing || performanceScorecard?.status !== 'draft') && (
          <Text>{datetimeService.format(performanceScorecardMetric.startDate, 'do MMM yyyy')}</Text>
        )}
      </Field>
      <PerformanceScorecardMetricCycleExplanation className="mt-6" {...formData} />
    </Drawer>
  )
})
