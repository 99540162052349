import React, { memo, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Page } from '@cotiss/common/components/page.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text } from '@cotiss/common/components/text.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAuth } from '@cotiss/auth/auth.provider'

export const MasqueradePage = memo(() => {
  const { push } = useHistory()
  const [error, setError] = useState('')
  const { masquerade, logout } = useAuth()
  const { token } = useParams<{ token: string }>()

  useEffect(() => {
    logout()

    handleMasquerade(token)
  }, [])

  const handleMasquerade = async (token: string) => {
    try {
      await masquerade(token)
      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setError(error.message)
    }
  }

  return (
    <Page className="flex items-center justify-center">
      <div>
        <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        {Boolean(error) && (
          <Text className="text-sm my-4" variant="danger">
            {error}
          </Text>
        )}
      </div>
    </Page>
  )
})
