import React, { memo } from 'react'
import folderDocumentsPng from '@assets/images/folder-documents.png'
import { every, filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ApprovalApprover } from '@cotiss/approval/components/approval-approver.component'
import { useListApproval } from '@cotiss/approval/resources/use-list-approval.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'

type Props = {
  tenderId: string
}

export const TenderFlowPlannedApprovalGate = memo(({ tenderId }: Props) => {
  const { openModal } = useCallout()
  const { tender } = useGetTender(tenderId)
  const { progressTender } = useMutateTender()
  const { approvals } = useListApproval({ tenderId })
  const filteredApprovals = filter(approvals, { approvalType: 'go-to-market' })

  const getConfirmDescription = () => {
    const description = 'Are you sure you want to publish your request?'

    return tender?.visibility === 'public' ? (
      <>
        {description}
        <Text className="mt-4" variant="light">
          Because your tender is public, this request will also be published on the Cotiss marketplace.
        </Text>
      </>
    ) : (
      description
    )
  }

  return (
    <div className="flex items-start justify-center max-w-[600px] mx-auto mt-36">
      <img className="mr-8" src={folderDocumentsPng} alt="Folder documents" />
      <div>
        <Text className="font-semibold" size="h5">
          Great! Your{' '}
          <Text className="font-semibold" variant="link" size="h5" isInline>
            Request
          </Text>{' '}
          has been sent for approval
        </Text>
        <div className="mt-4">
          {map(filteredApprovals, (approval) => (
            <ApprovalApprover key={approval._id} className="mt-6" approval={approval} />
          ))}
        </div>
        <Button
          className="mt-8"
          onClick={() =>
            openModal(
              <ConfirmModal heading="Publish request" description={getConfirmDescription()} onSubmit={async () => await progressTender(tenderId)} />
            )
          }
          isDisabled={!every(filteredApprovals, { status: 'approved' })}
        >
          Publish request
        </Button>
      </div>
    </div>
  )
})
