import { gql } from '@apollo/client'
import {
  GqlDeleteEvaluationSubmissionDocumentInput,
  GqlDeleteEvaluationSubmissionDocumentMutation,
  GqlDeleteEvaluationSubmissionDocumentMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationSubmissionDocument = async (input: GqlDeleteEvaluationSubmissionDocumentInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationSubmissionDocumentMutation, GqlDeleteEvaluationSubmissionDocumentMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationSubmissionDocument($input: DeleteEvaluationSubmissionDocumentInput!) {
        deleteEvaluationSubmissionDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationSubmissionDocument as boolean
}
