import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { SupplierProfileModel } from '@cotiss/supplier/supplier.models'

export const useGetSupplierProfile = (organisationId?: string) => {
  const { data: supplierProfile, ...rest } = useFetch<SupplierProfileModel>('/v2/supplier-profiles', {
    route: `/v2/supplier-profiles/${organisationId}`,
    isEnabled: Boolean(organisationId),
  })

  return { supplierProfile, ...rest }
}
