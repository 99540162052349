import React, { memo, useMemo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { authService } from '@cotiss/auth/auth.service'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'
import { useMutateOrganisation } from '@cotiss/organisation/resources/use-mutate-organisation.resource'
import { PreferredSupplierInvitationLinkRegenerateConfirmationModal } from '@cotiss/preferred-supplier/modals/preferred-supplier-invitation-link-regenerate-confirmation.modal'

type Props = {
  organisationId: string
  willRegenerateLinkOnOpen?: boolean
}

export const PreferredSupplierInvitationLinkModal = memo(({ organisationId, willRegenerateLinkOnOpen }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { organisation } = useGetOrganisation(organisationId)
  const { generatePreferredSupplierInvitationToken } = useMutateOrganisation()
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isCopyingLink, setIsCopyingLink] = useState(false)
  const [isGeneratingLink, setIsGeneratingLink] = useState(false)
  const [willRegenerateLink, setWillRegenerateLink] = useState(willRegenerateLinkOnOpen)

  useAsyncEffect(async () => {
    if (!organisation || (organisation.preferredSupplierInvitationToken && !willRegenerateLink)) {
      return
    }

    setIsGeneratingLink(true)
    try {
      await generatePreferredSupplierInvitationToken(organisationId)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsGeneratingLink(false)
    setWillRegenerateLink(false)
  }, [organisation, willRegenerateLink])

  const invitationLink = useMemo(() => {
    if (!organisation?.preferredSupplierInvitationToken) {
      return
    }

    const urlBase = routerService.getHref('/supplier/invitation')
    const queryParams = utilService.generateUrlSearchParams({
      'invitation-token': organisation.preferredSupplierInvitationToken,
    })
    const url = `${process.env.APP_DOMAIN}${urlBase}${queryParams}`
    return url
  }, [organisation])

  const isInvitationLinkExpired = useMemo(() => {
    if (!organisation?.preferredSupplierInvitationToken) {
      return
    }

    return !authService.isTokenValid(organisation.preferredSupplierInvitationToken)
  }, [organisation])

  const resetIsLinkCopied = () => {
    setTimeout(() => {
      setIsLinkCopied(false)
    }, 3000)
  }

  const handleCopyToClipboard = async () => {
    if (!invitationLink) {
      // Copy link button will be disabled
      return
    }

    setIsCopyingLink(true)
    try {
      await navigator.clipboard.writeText(invitationLink)
      openToast('Link copied to clipboard', 'success')
      resetIsLinkCopied()
    } catch (error) {
      setIsLinkCopied(false)
      openToast('Failed to copy to clipboard', 'danger')
    }

    setIsCopyingLink(false)
  }

  return (
    <div className="relative min-w-[450px] max-w-[450px]">
      <ModalHeader heading="Copy invite link" supplementary="Invite link will be active for 6 months" />
      <ModalContent>
        <Label>Invite link</Label>
        <Input
          value={invitationLink}
          placeholder={organisation?.preferredSupplierInvitationToken || 'Generating link...'}
          isReadOnly={true}
          isError={isInvitationLinkExpired}
        />
        {isInvitationLinkExpired && (
          <Text className="mt-1" variant="danger" size="sm">
            Invite link has expired, regenerate to create a new link
          </Text>
        )}
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button
          className="mr-4"
          variant="secondary"
          state="translucent"
          onClick={() => openModal(<PreferredSupplierInvitationLinkRegenerateConfirmationModal organisationId={organisationId} />)}
          isDisabled={!invitationLink}
          isLoading={Boolean(invitationLink && isGeneratingLink)}
        >
          <Icon icon="refresh-cw-03" className="mr-1" /> Regenerate link
        </Button>
        <Button variant="secondary" onClick={handleCopyToClipboard} isDisabled={!invitationLink || isLinkCopied} isLoading={isCopyingLink}>
          <Icon icon="link-03" className="mr-1" /> Copy link
        </Button>
      </ModalFooter>
    </div>
  )
})
