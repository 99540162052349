import React, { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
}

export const TenderResponsePriceDocumentUploadModal = memo(({ tenderResponse }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTenderResponse } = useMutateTenderResponse()
  const [isSaving, setIsSaving] = useState(false)
  const [priceAttachments, setPriceAttachments] = useState<DocumentModel[]>([])
  const { track } = useAnalytics()

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    track('procurement_response_event_upload_document_submit')
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, {
        priceAttachments: [...map(priceAttachments, ({ _id }) => _id), ...map(tenderResponse.priceAttachments, ({ _id }) => _id)],
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload price forms" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={priceAttachments} onChange={setPriceAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
