import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { PreferencesEmailNotificationPeriod } from '@cotiss/preferences/preferences.models'

export const PREFERENCES_QUERY_KEYS = ['/v2/preferences'] as const

export const PREFERENCES_EMAIL_NOTIFICATION_PERIOD_TYPE_OPTIONS: SelectOption_DEPRECATED<PreferencesEmailNotificationPeriod>[] = [
  {
    value: 'hourly',
    label: 'Hourly',
  },
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'never',
    label: 'Never',
  },
]
