import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ProcurementPublicModel } from '@cotiss/procurement/procurement.models'

type Props = {
  organisationId?: string
  open?: boolean
  limit?: number
  offset?: number
}

type UseListPublicProcurementResponseType = PaginatedModel<ProcurementPublicModel, { privateListingsCount: number }>

export const useListPublicProcurement = ({ organisationId, open, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<UseListPublicProcurementResponseType>('/v2/public/procurements', {
    params: { organisationId, open, limit, offset },
    isEnabled: Boolean(organisationId && open !== undefined),
  })

  return { publicProcurements: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
