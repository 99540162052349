import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'

export type UpdateTenderQuestionItem = {
  questionId?: string
  question?: string
  weight?: number
  index?: number
}

type UpdateTenderQuestionBulkBody = {
  tenderId: string
  criterionId: string
  updates: UpdateTenderQuestionItem[]
}

type UpdateTenderQuestionScoreBody = {
  score?: number | null
  commentary?: string
  groupScore?: number
  groupCommentary?: string
}

export const useMutateTenderQuestion = () => {
  const { mutate } = useMutate()

  return {
    updateTenderQuestionBulk: async (body: UpdateTenderQuestionBulkBody) => {
      return await mutate({ route: `/v2/tender-questions/bulk`, body, invalidate: ['/v2/tender-questions', '/v2/tenders'] })
    },
    updateTenderQuestionScore: async (id: string, body: UpdateTenderQuestionScoreBody) => {
      return await mutate({
        route: `/v2/tender-question-scores/${id}`,
        body,
        invalidate: ['/v2/tender-question-scores', '/v2/reports/evaluations-by-tender-response'],
      })
    },
  }
}
