import React, { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { Input, InputProps } from './input.component'

export type NumberInputFormat = 'currency' | 'integer'

type Props = Omit<InputProps, 'onChange' | 'value' | 'type' | 'defaultValue'> &
  Omit<NumericFormatProps, 'disabled'> & {
    isDisabled?: boolean
    format?: NumberInputFormat
  }

const formatters: Record<NumberInputFormat, Partial<Props>> = {
  currency: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2,
  },
  integer: {
    decimalScale: 0,
  },
}

export const NumberInput = forwardRef<HTMLInputElement, Props>(({ isDisabled, onValueChange, format, ...rest }, ref) => {
  const formatter = format ? formatters[format] : {}
  return (
    <NumericFormat {...(formatter ?? {})} {...rest} onValueChange={onValueChange} isDisabled={isDisabled} customInput={Input} getInputRef={ref} />
  )
})
