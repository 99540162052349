import React, { memo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { Section } from '@cotiss/common/components/section.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text } from '@cotiss/common/components/text.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useMutatePreferredSupplier } from '@cotiss/preferred-supplier/resources/use-mutate-preferred-supplier.resource'

export const SupplierInvitationPage = memo(() => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const { validatePreferredSupplierInvitation } = useMutatePreferredSupplier()
  const searchParams = utilService.getUrlSearchParams({ search, params: ['invitation-token', 'invitation-email'] })

  useAsyncEffect(async () => {
    try {
      const invitationToken = searchParams['invitation-token']

      if (!invitationToken) {
        setIsError(true)
        return
      }

      const { organisationId } = await validatePreferredSupplierInvitation(invitationToken)
      openToast('Invitation has been accepted')
      push(routerService.getHref('/buyer/view/:id/:tab?', { id: organisationId }))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsError(true)
    }
  }, [])

  const renderContent = () => {
    if (isError) {
      return (
        <Section className="text-center" isCentered>
          <Icon className="mx-auto mb-2" icon="x-close" variant="danger" />
          <Text className="text-center font-semibold" size="h5">
            Failed to verify invitation
          </Text>

          <Text className="text-center mt-2" variant="light">
            Please get in touch with the organisation to get a new invitation
          </Text>
        </Section>
      )
    }

    return (
      <Section isCentered>
        <Spinner className="mx-auto mb-2" colour={COLOUR.primary['500']} />
        <Text className="text-center font-semibold" size="h5">
          Verifying invitation
        </Text>

        <Text className="text-center mt-2" variant="light">
          Please do not refresh the page
        </Text>
      </Section>
    )
  }

  return <Page className="flex items-center">{renderContent()}</Page>
})
