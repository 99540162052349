import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricUserInput,
  GqlCreatePerformanceScorecardMetricUserMutation,
  GqlCreatePerformanceScorecardMetricUserMutationVariables,
  GqlPerformanceScorecardMetricUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-user/performance-scorecard-metric-user-fragment.graphql'

export const mutateCreatePerformanceScorecardMetricUser = async (input: GqlCreatePerformanceScorecardMetricUserInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricUserMutation,
    GqlCreatePerformanceScorecardMetricUserMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      mutation CreatePerformanceScorecardMetricUser($input: CreatePerformanceScorecardMetricUserInput!) {
        createPerformanceScorecardMetricUser(input: $input) {
          ...PerformanceScorecardMetricUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricUser as GqlPerformanceScorecardMetricUserFieldsFragment
}
