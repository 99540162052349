import React, { memo } from 'react'
import { TenderResponseForumQuestions } from '@cotiss/tender-response/components/tender-response-forum-questions.component'
import { TenderResponseForumUpdates } from '@cotiss/tender-response/components/tender-response-forum-updates.component'

type Props = {
  tenderId: string
}

export const TenderResponseForumTab = memo(({ tenderId }: Props) => (
  <div className="flex items-stretch">
    <TenderResponseForumQuestions className="w-1/2 mr-4" tenderId={tenderId} />
    <TenderResponseForumUpdates className="w-1/2" tenderId={tenderId} />
  </div>
))
