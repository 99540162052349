import React, { ReactNode, HTMLProps, memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode
  className?: string
  onClick?: () => void
}

export const Td_DEPRECATED = memo(({ className, children, onClick, ...rest }: Props) => {
  const classes = classNames(className, 'whitespace-nowrap py-3 px-4 first:rounded-l last:rounded-r')

  const renderContent = () => {
    if (onClick) {
      return (
        <Button className="outline-none" onClick={onClick} state="raw">
          {children}
        </Button>
      )
    }

    return <>{children}</>
  }

  return (
    <td {...rest} className={classes}>
      {renderContent()}
    </td>
  )
})
