import React, { FormEvent, memo, useEffect, useState } from 'react'
import CotissIconSecondarySvg from '@assets/svg/cotiss-icon-secondary.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { useGetIsEmailSso } from '@cotiss/auth/resources/use-get-is-email-sso.resource'

export const LoginPage = memo(() => {
  const { push } = useHistory()
  const { login, logout } = useAuth()
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isSsoRedirecting, setIsSsoRedirecting] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const authRedirectRoute = localStorageService.getItem('auth-redirect-route')
  const search = authRedirectRoute && new URL(`${process.env.APP_DOMAIN}${authRedirectRoute}`).search
  const searchParams = search && utilService.getUrlSearchParams({ search, params: ['invitation-token', 'invitation-email'] })
  const invitationEmail = searchParams ? searchParams['invitation-email'] || '' : ''
  const invitationToken = searchParams ? searchParams['invitation-token'] || '' : ''
  const showAcceptInvitationText = Boolean(invitationEmail || invitationToken)
  const [formData, setFormData] = useState({ email: invitationEmail || '', password: '', token: '', otp: '' })
  const { refetch: refetchIsEmailSso } = useGetIsEmailSso({ email: formData.email })

  useEffect(() => {
    logout()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')

    try {
      setIsSaving(true)

      if (step === 1) {
        const { data } = await refetchIsEmailSso()
        // If the email they just entered is used in sso
        if (data?.enabled && data?.redirectUrl) {
          setIsSsoRedirecting(true)
          setIsSaving(false)
          window.location.href = data?.redirectUrl
          return
        }

        // Otherwise they'll need to enter their password
        onTransition({ step: 2 })
        setIsSaving(false)
        return
      }

      const response = await login(formData.email, formData.password, formData.otp)
      if (response === 'OTP') {
        onTransition({ step: 3 })
        setIsSaving(false)
        return
      }

      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setFormError(error.message)
      setIsSaving(false)
      setIsSsoRedirecting(false)
    }
  }

  const handleOnBack = () => {
    setFormData({ ...formData, password: '' })
    onTransition({ step: step - 1, transition: 'left' })
  }

  const handleSignUp = () => {
    // Make sure to save the invitation email from the form data
    // Since it isn't provided if using the magic invitation link
    const pathname = new URL(`${process.env.APP_DOMAIN}${authRedirectRoute}`).pathname
    const queryParams = utilService.generateUrlSearchParams({
      'invitation-token': invitationToken,
      'invitation-email': formData.email || invitationEmail,
    })
    const newAuthRedirectRoute = `${pathname}${queryParams}`
    localStorageService.setItem('auth-redirect-route', newAuthRedirectRoute)
    push(routerService.getHref('/sign-up'))
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="bg-white w-1/2 h-full p-12">
          <Button className={step === 1 ? 'invisible' : ''} onClick={handleOnBack} state="ghost" variant="link">
            <Icon icon="chevron-left" />
            Back
          </Button>
          <div className="flex items-center justify-center w-full h-full">
            {!isSsoRedirecting && (
              <div className="w-full max-w-[350px]">
                <>
                  <Text className="font-semibold" size="h3">
                    {showAcceptInvitationText ? 'You have been invited to Cotiss.' : 'Welcome Back.'}
                  </Text>
                  <Text className="mt-2" variant="light">
                    {showAcceptInvitationText ? 'Select an option to continue' : 'Log into your account to continue using Cotiss'}
                  </Text>
                  {Boolean(formError) && (
                    <Text className="text-sm my-4" variant="danger">
                      {formError}
                    </Text>
                  )}
                  <Form className="mt-8" onSubmit={handleSubmit}>
                    <AnimatePresence mode="wait" initial={false}>
                      <TransitionContainer key={step} transition={transition}>
                        {step === 1 && (
                          <>
                            <Label>Email </Label>
                            <Input
                              className="mb-4"
                              value={formData.email}
                              onChange={({ target }) => setFormData({ ...formData, email: target.value })}
                              placeholder="Enter your email address"
                              type="email"
                              autoComplete="username"
                              maxLength={320}
                              isError={Boolean(formError)}
                              isDisabled={isSaving}
                              isRequired
                            />
                            {showAcceptInvitationText ? (
                              <>
                                <Button className="mt-4 w-full" variant="primary" onClick={handleSignUp}>
                                  Create account to accept invitation
                                  <Icon icon="chevron-right" variant="light" />
                                </Button>
                                <div className="bg-secondary-100 text-center rounded mt-4">
                                  <Button className="mr-1 text-secondary-500" variant="link" type="submit" isLoading={isSaving}>
                                    Login to accept invitation
                                    <Icon icon="chevron-right" variant="light" />
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
                                  Continue
                                  <Icon icon="chevron-right" variant="light" />
                                </Button>
                                <div className="bg-secondary-100 text-center rounded mt-4">
                                  <Button className="mr-1 text-secondary-500" variant="link" href={routerService.getHref('/sign-up')} isLink>
                                    Don&apos;t have an account?
                                    <Icon icon="chevron-right" variant="light" />
                                  </Button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {step === 2 && (
                          <>
                            <Label className="mt-6">Password</Label>
                            <Input
                              value={formData.password}
                              placeholder="Enter your password"
                              onChange={({ target }) => setFormData({ ...formData, password: target.value })}
                              type="password"
                              autoComplete="current-password"
                              isError={Boolean(formError)}
                              isDisabled={isSaving}
                              isRequired
                            />

                            <div className="flex items-center justify-end mt-2">
                              <Button
                                className="text-sm"
                                state="text"
                                variant="link"
                                href={routerService.getHref('/forgot-password')}
                                isDisabled={isSaving}
                                isLink
                              >
                                Forgot your password?
                              </Button>
                            </div>
                            <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
                              Log in{showAcceptInvitationText && ' to accept invitation'}
                            </Button>
                          </>
                        )}
                        {step === 3 && (
                          <>
                            <Label>One-time password</Label>
                            <Input
                              value={formData.otp}
                              placeholder="Enter your one-time password"
                              onChange={({ target }) => setFormData({ ...formData, otp: target.value })}
                              type="text"
                              isError={Boolean(formError)}
                              isDisabled={isSaving}
                              isRequired
                            />
                            <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
                              Log in
                            </Button>
                          </>
                        )}
                      </TransitionContainer>
                    </AnimatePresence>
                  </Form>
                </>
              </div>
            )}
            {Boolean(isSsoRedirecting) && (
              <>
                <div className="w-32 flex flex-col items-center">
                  <CotissIconSecondarySvg />
                  <Text className="mt-8">Redirecting you...</Text>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bg-white w-1/2 h-full p-4">
          <div className="flex flex-col items-start justify-between bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight">
              Better decisions.
              <br />
              Simplified process.
            </h1>
          </div>
        </div>
      </div>
    </Page>
  )
})
