import React, { memo, useMemo, useRef } from 'react'
import { find, map, some } from 'lodash'
import { useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { ButtonReadMore } from '@cotiss/common/components/button-read-more.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { ContractApprovalHeader } from '@cotiss/contract/components/contract-approval-header.component'
import { ContractApproversTable } from '@cotiss/contract/components/contract-approvers-table.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const ContractApprovalPage = memo(() => {
  const { contractId, contractShellId, approvalId } = useParams<{ contractShellId: string; contractId: string; approvalId?: string }>()
  const { contractShell, isLoading: isContractShellLoading } = useGetContractShell(contractShellId)
  const { user, isLoading: isUserLoading } = useGetLoggedInUser()
  const shortDescriptionRef = useRef<HTMLDivElement>(null)

  const { contract, approval } = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)
    let approval = null
    if (approvalId) {
      approval = find(contract?.approvals, ({ _id }) => _id === approvalId)
    }

    if (!approvalId) {
      approval = contractService.getApproval(contract?.approvals, ['PENDING_APPROVAL', 'APPROVED', 'REJECTED', 'DRAFTING'])
    }

    // Only show when drafting if rejected/changes requested
    if (approval?.status === 'DRAFTING' && !some(approval.approvers, { status: 'REQUESTED_CHANGES' })) {
      approval = null
    }

    // only allow view contract if all approvers have not approved yet

    return { contract, approval }
  }, [contractShell, user])

  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'contract' }),
      },
      {
        label: 'Contract approval',
      },
    ]
  }, [])

  if (isContractShellLoading || isUserLoading) {
    return (
      <Page>
        <ContractApprovalHeader contractShellId={contractShellId} contractId={contractId} breadcrumbs={breadcrumbs} />
        <PageContent>
          <Card>
            <CardHeader>
              <Skeleton className="h-6 w-1/3 mt-1" />
            </CardHeader>
            {map(Array(7), (_, index) => (
              <div className="flex" key={index}>
                <Skeleton className="h-10 w-1/3 mt-8 mr-8" />
                <Skeleton className="h-10 w-2/3 mt-8" />
              </div>
            ))}
          </Card>
        </PageContent>
      </Page>
    )
  }

  if (!contract || !approval) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <ContractApprovalHeader contractShellId={contractShellId} contractId={contractId} breadcrumbs={breadcrumbs} />
      <PageContent>
        <Card>
          <CardHeader>
            {contractShell && (
              <Text className="font-semibold" size="h5">
                {contract?.cessationDate ? 'Cessation request' : 'Approval request'}
              </Text>
            )}
          </CardHeader>
          {contractShell && (
            <div>
              <Field label="Contract shell name">
                <Text className="font-semibold">{contractShell?.title || '-'}</Text>
              </Field>
              <Field label="Short description" className="mt-8">
                <div ref={shortDescriptionRef} className="line-clamp-3">
                  <Text className="whitespace-pre-wrap">{contractShell?.description || '-'}</Text>
                </div>
                <ButtonReadMore
                  modalContent={<Text className="whitespace-pre-wrap">{contractShell.description}</Text>}
                  modalTitle="Short description"
                  readMoreRef={shortDescriptionRef}
                />
              </Field>
              <Field label="Procurement name" className="mt-8">
                <Text className="font-semibold">{contractShell?.procurement?.title || '-'}</Text>
              </Field>
              <Field label="Contracting entity" className="mt-8">
                <Text>{contract?.metadata?.contractingEntity?.name || '-'}</Text>
              </Field>
              <Field label="Counterparties" className="mt-8">
                <Text>{contract?.metadata?.suppliers.length ? contract.metadata.suppliers.map(({ name }) => name).join(', ') : '-'}</Text>
              </Field>
              <Field label="Organisation name" className="mt-8">
                <Text>{contractShell?.supplier?.name || '-'}</Text>
              </Field>
              <Field label="Date" className="mt-8">
                <Text>{(contractShell?.createdAt && datetimeService.format(contractShell?.createdAt, 'MMM yyyy')) || '-'}</Text>
              </Field>
              <Field label="Requested by" className="mt-8">
                {contractShell?.createdBy && <UserLineItem {...contractShell?.createdBy} />}
              </Field>
              <Field label="Approval task" className="mt-8">
                <Button
                  href={routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?', {
                    contractShellId,
                    approvalId: approval._id,
                    contractId,
                  })}
                  state="text"
                  variant="link"
                  isLink
                  isTruncated
                >
                  View changes
                </Button>
              </Field>
              <Field label="Requester comments" className="mt-8" supplementary="Notes or comments from the requestor">
                <Text className="break-words whitespace-pre-wrap">{approval?.comment || '--'}</Text>
              </Field>
              <TableHeader className="mt-8">
                <Text className="font-semibold">Approvals</Text>
              </TableHeader>
              <ContractApproversTable contractShellId={contractShellId} contractId={contractId} approvalId={approval._id} />
            </div>
          )}
        </Card>
      </PageContent>
    </Page>
  )
})
