import { useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

export function useDebouncedChangeHandler(value: string, onChange: (value: string) => void, debounceTime = 500) {
  const [tempValue, setTempValue] = useState(value)

  const debouncedHandler = useDebounceCallback(onChange, debounceTime)

  function handleDebouncedChange(value: string) {
    setTempValue(value)
    debouncedHandler(value)
  }

  function handleClear() {
    setTempValue('')
    onChange('')
  }

  return { tempValue, handleDebouncedChange, handleClear }
}
