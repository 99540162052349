import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlApprovalTemplateFieldsFragment,
  GqlApprovalTemplateListInput,
  GqlApprovalTemplateViewInput,
  GqlCreateApprovalTemplateInput,
  GqlDeleteApprovalTemplateInput,
  GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdInput,
  GqlUpdateApprovalTemplateInput,
  GqlUpdateApprovalTemplateOrdersInput,
} from '@gql'
import { mutateCreateApprovalTemplate } from '@cotiss/approval-template/graphql/mutate-create-approval-template.graphql'
import { mutateDeleteApprovalTemplatesByApprovalTemplateGroupId } from '@cotiss/approval-template/graphql/mutate-delete-approval-template-by-approval-template-group-id.graphql'
import { mutateDeleteApprovalTemplate } from '@cotiss/approval-template/graphql/mutate-delete-approval-template.graphql'
import { mutateUpdateApprovalTemplateOrders } from '@cotiss/approval-template/graphql/mutate-update-approval-template-orders.graphql'
import { mutateUpdateApprovalTemplate } from '@cotiss/approval-template/graphql/mutate-update-approval-template.graphql'
import { queryApprovalTemplateList } from '@cotiss/approval-template/graphql/query-approval-template-list.graphql'
import { queryApprovalTemplateView } from '@cotiss/approval-template/graphql/query-approval-template-view.graphql'

const approvalTemplateVar = makeVar<GqlApprovalTemplateFieldsFragment | null>(null)

export const useApprovalTemplate = () => {
  const approvalTemplate = useReactiveVar(approvalTemplateVar)

  return {
    approvalTemplate,
    setApprovalTemplate: approvalTemplateVar,
    queryApprovalTemplateList: async (input: GqlApprovalTemplateListInput) => {
      const { items: approvalTemplates, pagination } = await queryApprovalTemplateList(input)

      return { approvalTemplates, pagination }
    },
    queryApprovalTemplateView: async (input: GqlApprovalTemplateViewInput) => {
      const approvalTemplate = await queryApprovalTemplateView(input)

      approvalTemplateVar(approvalTemplate)

      return approvalTemplate
    },
    mutateCreateApprovalTemplate: async (input: GqlCreateApprovalTemplateInput) => {
      const createdApprovalTemplate = await mutateCreateApprovalTemplate(input)

      approvalTemplateVar(createdApprovalTemplate)

      return createdApprovalTemplate
    },
    mutateUpdateApprovalTemplate: async (input: GqlUpdateApprovalTemplateInput) => {
      const updatedApprovalTemplate = await mutateUpdateApprovalTemplate(input)

      approvalTemplateVar(updatedApprovalTemplate)

      return updatedApprovalTemplate
    },
    mutateUpdateApprovalTemplateOrders: async (input: GqlUpdateApprovalTemplateOrdersInput) => {
      const updatedApprovalTemplates = await mutateUpdateApprovalTemplateOrders(input)

      return updatedApprovalTemplates
    },
    mutateDeleteApprovalTemplate: async (input: GqlDeleteApprovalTemplateInput) => {
      await mutateDeleteApprovalTemplate(input)
    },

    mutateDeleteApprovalTemplatesByApprovalTemplateGroupId: async (input: GqlDeleteApprovalTemplatesByApprovalTemplateGroupIdInput) => {
      await mutateDeleteApprovalTemplatesByApprovalTemplateGroupId(input)
    },
  }
}

export const clearReactiveApprovalTemplate = async () => {
  approvalTemplateVar(null)
}
