import React, { memo } from 'react'
import { GqlEvaluationUserEnvelopeStatus } from '@gql'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

const getVariant = (status?: GqlEvaluationUserEnvelopeStatus): BaseVariant => {
  switch (status) {
    case 'inProgress':
      return 'warning'
    case 'complete':
      return 'success'
    case 'notStarted':
    default:
      return 'neutral'
  }
}

const getCopy = (status?: GqlEvaluationUserEnvelopeStatus) => {
  switch (status) {
    case 'complete':
      return 'Submitted'
    case 'inProgress':
      return 'In progress'
    case 'notStarted':
    default:
      return 'Not started'
  }
}

type Props = {
  className?: string
  status?: GqlEvaluationUserEnvelopeStatus
  size?: BadgeSize
}

export const EvaluationEventEnvelopeUserStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="dot">
    {getCopy(status)}
  </Badge>
))
