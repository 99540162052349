import React, { memo, useMemo, useState } from 'react'
import { map, startCase, toLower } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { UserAddUpdateModal } from '@cotiss/user/modals/user-add-update.modal'
import { UserDeleteConfirmModal } from '@cotiss/user/modals/user-delete-confirm.modal'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useListPendingUser } from '@cotiss/user/resources/use-list-pending-user.resource'
import { useListRequestedUser } from '@cotiss/user/resources/use-list-requested-user.resource'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { useMutateUser } from '@cotiss/user/resources/use-mutate-user.resource'
import { UserModel, UserPermission } from '@cotiss/user/user.models'
import { userService } from '@cotiss/user/user.service'

type AccountMemberTableUserStatus = 'REQUESTED' | 'ACTIVE' | 'PENDING'

type AccountMemberTableUser = {
  _id: string
  firstname?: string
  surname?: string
  email: string
  status: AccountMemberTableUserStatus
  permissions: UserPermission[]
}

const ACCOUNT_MEMBER_STATUS_BADGE_MAP: Record<AccountMemberTableUserStatus, BaseVariant> = {
  ACTIVE: 'success',
  REQUESTED: 'danger',
  PENDING: 'warning',
}

type Props = {
  statusFilter: AccountMemberTableUserStatus
}

export const SettingsMembersTable = memo(({ statusFilter }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { users, isLoading: isUsersLoading } = useListUser()
  const { pendingUsers, isLoading: isPendingUsersLoading } = useListPendingUser()
  const { requestedUsers, isLoading: isRequestedUsersLoading } = useListRequestedUser()
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const { resendPendingUserInvite, deletePendingUserInvite } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)
  const isLoading = useMemo(
    () => isUsersLoading && isPendingUsersLoading && isRequestedUsersLoading && isLoggedInUserLoading,
    [isUsersLoading, isPendingUsersLoading, isRequestedUsersLoading, isLoggedInUserLoading]
  )
  const { acceptRequestedUser, rejectRequestedUser } = useMutateUser()

  const allUsers: AccountMemberTableUser[] = useMemo(() => {
    const allUsers: AccountMemberTableUser[] = []

    if (statusFilter === 'ACTIVE') {
      const tempUsers: AccountMemberTableUser[] = users.map((user) => {
        return { ...user, status: 'ACTIVE' }
      })
      allUsers.push(...tempUsers)
    }

    if (statusFilter === 'PENDING') {
      const tempUsers: AccountMemberTableUser[] = pendingUsers.map((user) => {
        return { ...user, status: 'PENDING' }
      })
      allUsers.push(...tempUsers)
    }

    if (statusFilter === 'REQUESTED') {
      const tempUsers: AccountMemberTableUser[] = requestedUsers.map((user) => {
        return { ...user, permissions: [], status: 'REQUESTED' }
      })
      allUsers.push(...tempUsers)
    }
    return allUsers
  }, [users, pendingUsers, requestedUsers])

  const handleResendInvite = async (user: AccountMemberTableUser) => {
    try {
      setIsSaving(true)
      await resendPendingUserInvite(user._id)
      openToast(
        <>
          An invitation has been sent to <strong>{user.email}</strong>
        </>
      )
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleDeleteInvite = async (user: AccountMemberTableUser) => {
    try {
      setIsSaving(true)
      await deletePendingUserInvite(user._id)
      openToast(
        <>
          Invitation to <strong>{user.email}</strong> has been deleted
        </>
      )
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleAccept = async (user: AccountMemberTableUser) => {
    try {
      setIsSaving(true)
      await acceptRequestedUser(user._id)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleReject = async (user: AccountMemberTableUser) => {
    try {
      setIsSaving(true)
      await rejectRequestedUser(user._id)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Email',
      rows: map(allUsers, (user) => ({
        content: () => (
          <Text className="truncate" title={user.email}>
            {user.email}
          </Text>
        ),
        cta: (
          <TableRowCta
            isDisabled={isSaving || isLoading}
            actions={[
              ...(user.status === 'ACTIVE' && permissions.isAdmin
                ? [
                    {
                      label: 'Edit',
                      onClick: () => openModal(<UserAddUpdateModal user={users.find((u) => u._id === user._id)} />),
                    },
                    {
                      label: 'Delete',
                      isDisabled: loggedInUser?.ssoEnabled || !users.find((u) => u._id === user._id),
                      onClick: () => openModal(<UserDeleteConfirmModal user={users.find((u) => u._id === user._id) as UserModel} />),
                    },
                  ]
                : []),
              ...(user.status === 'PENDING' && permissions.isAdmin
                ? [
                    {
                      label: 'Resend invite',
                      onClick: () => handleResendInvite(user),
                    },
                    {
                      label: 'Delete invite',
                      onClick: () => handleDeleteInvite(user),
                    },
                  ]
                : []),
              ...(user.status === 'REQUESTED' && permissions.isAdmin
                ? [
                    {
                      label: 'Accept',
                      onClick: () => handleAccept(user),
                    },
                    {
                      label: 'Reject',
                      onClick: () => handleReject(user),
                    },
                  ]
                : []),
            ]}
          />
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Name',
      rows: map(allUsers, ({ firstname, surname }) => ({
        content: () => (
          <Text className="w-72 truncate" title={firstname || surname ? userService.getFullName({ firstname, surname }) : ''}>
            {firstname || surname ? userService.getFullName({ firstname, surname }) : '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Roles',
      isWrapped: true,
      rows: map(allUsers, ({ permissions }) => ({
        content: () => (
          <div>
            {!permissions.length && '--'}
            {map(permissions, (permission) => (
              <Badge key={permission} className="mr-1" state="translucent" variant="secondary">
                {startCase(toLower(permission))}
              </Badge>
            ))}
          </div>
        ),
      })),
    },
    {
      heading: 'Status',
      thClassName: 'w-24',
      rows: map(allUsers, ({ status }) => ({
        content: () => (
          <Badge className="mr-1" state="dot" variant={ACCOUNT_MEMBER_STATUS_BADGE_MAP[status]}>
            {startCase(toLower(status))}
          </Badge>
        ),
      })),
    },
  ]

  if (!allUsers.length) {
    return <NoDataPlaceholder illustration="dot-list" label="No users" />
  }

  return <ScrollableTable isLoading={isLoading} fixedColumns={fixedColumns} columns={columns} />
})
