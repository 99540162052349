import React, { memo } from 'react'
import { map } from 'lodash'
import { Table_DEPRECATED } from '@cotiss/common/components/deprecated/table.component'
import { Tbody_DEPRECATED } from '@cotiss/common/components/deprecated/tbody.component'
import { Th_DEPRECATED } from '@cotiss/common/components/deprecated/th.component'
import { Thead_DEPRECATED } from '@cotiss/common/components/deprecated/thead.component'
import { ShortListDueDiligenceListItem } from '@cotiss/short-list/components/short-list-due-diligence-list-item.component'
import { ShortListPopulatedModel } from '@cotiss/short-list/short-list.models'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  className?: string
  shortLists: ShortListPopulatedModel[]
  tenderResponseReports: TenderResponseReportModel[]
  onView?: (shortList: ShortListPopulatedModel) => void
  isEditable?: boolean
  isScoreVisible?: boolean
}

export const ShortListDueDiligenceList = memo(({ className, shortLists, tenderResponseReports, onView, isEditable, isScoreVisible }: Props) => (
  <Table_DEPRECATED className={className} isSeparate>
    <Thead_DEPRECATED>
      {isScoreVisible && <Th_DEPRECATED className="w-16">Score</Th_DEPRECATED>}
      <Th_DEPRECATED>Supplier name</Th_DEPRECATED>
      <Th_DEPRECATED className="w-24">Status</Th_DEPRECATED>
      <Th_DEPRECATED className="w-56"></Th_DEPRECATED>
    </Thead_DEPRECATED>
    <Tbody_DEPRECATED>
      {map(shortLists, (shortList) => (
        <ShortListDueDiligenceListItem
          key={shortList._id}
          shortList={shortList}
          tenderResponseReports={tenderResponseReports}
          onView={onView}
          isEditable={isEditable}
          isScoreVisible={isScoreVisible}
        />
      ))}
    </Tbody_DEPRECATED>
  </Table_DEPRECATED>
))
