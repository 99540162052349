import React, { memo, useMemo, useState } from 'react'
import { first, map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useListProcurementResponse } from '@cotiss/procurement-response/resources/use-list-procurement-response.resource'
import { TenderStatusProgression } from '@cotiss/tender/components/tender-status-progression.component'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TENDER_TYPE_NAME_MAP } from '@cotiss/tender/tender.constants'
import { TenderModel } from '@cotiss/tender/tender.models'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'
import { useListTenderInvitation } from '@cotiss/tender-invitation/resources/use-list-tender-invitation.resource'
import { TenderResponseStatusBadge } from '@cotiss/tender-response/components/tender-response-status-badge.component'
import { TenderResponseStatus } from '@cotiss/tender-response/tender-response.models'

type SortKey = 'organisation'

export const ProcurementOverviewAllActivityTab = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { deleteTender } = useMutateTender()
  const [currentPage, setCurrentPage] = useState(1)
  const { procurementId } = useParams<{ procurementId: string }>()
  const { procurement, isLoading } = useGetProcurement(procurementId)
  const { tenderInvitations } = useListTenderInvitation({ procurementId })
  const { procurementResponses } = useListProcurementResponse({ procurementId })
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'organisation', initialSortDirection: 'desc' })

  const handleRemoveTender = async (tenderId: string) => {
    try {
      await deleteTender(tenderId)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  // We need to merge the invitations list and the responses list to find the unique suppliers and the status
  // of their interaction. If they have a response and an invitation we override the invitation status with
  // the response status.
  const groupedSuppliers = useMemo(() => {
    const groupedSuppliers: Record<
      string,
      { preferredSupplierId: string; organisationName: string; tenders: { tenderId: string; status: TenderResponseStatus }[] }
    > = {}

    procurementResponses?.forEach((response) => {
      const supplierId = response.supplier._id
      const supplierName = response.supplier.name

      const tenders = response.tenderResponses.map((tenderResponse) => {
        return {
          tenderId: tenderResponse.tender._id.toString(),
          status: tenderResponse.status,
        }
      })

      if (!groupedSuppliers[supplierId]) {
        groupedSuppliers[supplierId] = {
          preferredSupplierId: response._id,
          organisationName: supplierName,
          tenders: [...tenders],
        }
      } else {
        groupedSuppliers[supplierId].tenders.push(...tenders)
      }
    })

    tenderInvitations?.forEach((invitation) => {
      const supplierId = invitation.preferredSupplier.supplierOrganisation
        ? invitation.preferredSupplier?.supplierOrganisation._id
        : invitation.preferredSupplier?._id

      const supplierName = invitation.preferredSupplier.supplierOrganisation
        ? invitation.preferredSupplier.supplierOrganisation.name
        : invitation.preferredSupplier.organisationName

      if (!groupedSuppliers[supplierId] && supplierName) {
        groupedSuppliers[supplierId] = {
          preferredSupplierId: invitation._id,
          organisationName: supplierName,
          tenders: [
            {
              tenderId: invitation.tender.toString(),
              status: 'invited',
            },
          ],
        }
      } else {
        groupedSuppliers[supplierId].tenders.push({
          tenderId: invitation.tender.toString(),
          status: 'invited',
        })
      }
    })
    return groupedSuppliers
  }, [procurementResponses, tenderInvitations])

  const { processedSuppliers, pagination } = useMemo(() => {
    const result = Object.values(groupedSuppliers).sort((a, b) => {
      if (sortKey === 'organisation') {
        return sortService.sortString(a.organisationName, b.organisationName)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedSuppliers, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedSuppliers, pagination }
  }, [sortKey, sortDirection, currentPage, groupedSuppliers])

  const formattedSuppliers: (TenderModel & {
    items: {
      name: string
      status?: TenderResponseStatus
    }[]
  })[] = useMemo(() => {
    const data = procurement?.tenders.map((tender) => {
      const items = processedSuppliers.map((value) => {
        return {
          name: value.organisationName,
          status: value.tenders.find(({ tenderId }) => tenderId === tender._id)?.status,
        }
      })

      return {
        ...tender,
        items,
      }
    })

    if (!data) {
      return []
    }

    return data
  }, [processedSuppliers, procurement])

  if (!isLoading && (!formattedSuppliers.length || !first(formattedSuppliers)?.items.length)) {
    return (
      <div className="flex items-center justify-center h-[calc(100%-64px)]">
        <NoDataPlaceholder label="No suppliers have been invited or responded yet." />
      </div>
    )
  }

  const columns: ScrollableTableColumn[] = map(formattedSuppliers, (tender) => {
    return {
      thClassName: 'h-40',
      actions: [
        {
          name: 'Edit',
          onClick: () =>
            // TODO: We should change this to an href but requires some updates to the dropdown
            push(
              routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', {
                tenderId: tender._id,
                step: tenderFlowService.getActiveStep(tender),
              })
            ),
        },
        {
          name: 'Remove',
          onClick: () =>
            openModal(
              <ConfirmModal
                heading="Remove request"
                description="Confirm you would like to remove this request. This action cannot be undone."
                onSubmit={() => handleRemoveTender(tender._id)}
              />
            ),
          isDisabled: tender.status !== 'planning',
        },
      ],
      heading: (
        <div className="w-full normal-case">
          <div className="border-b pb-2 mb-2">
            <TenderTypeBadge className="mb-1" tenderType={tender.tenderType} />
          </div>
          <Text className="font-semibold line-clamp-2 whitespace-pre-wrap h-10 mb-2" title={tender.title || TENDER_TYPE_NAME_MAP[tender.tenderType]}>
            {tender.title || TENDER_TYPE_NAME_MAP[tender.tenderType]}
          </Text>
          <TenderStatusProgression tenderId={tender._id} />
        </div>
      ),

      rows: map(tender.items, (item) => {
        return {
          content: () => (item.status ? <TenderResponseStatusBadge status={item.status} /> : <>-</>),
        }
      }),
    }
  })

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Supplier (A-Z)',
      onSort: () => onSort('organisation'),
      thClassName: 'h-40 flex items-end border-b-0',
      rows: processedSuppliers.map((value) => {
        return {
          content: () => (
            <Text className="truncate" title={value.organisationName}>
              {value.organisationName}
            </Text>
          ),
        }
      }),
    },
  ]

  return <ScrollableTable columns={columns} fixedColumns={fixedColumns} isLoading={isLoading} pagination={pagination} onPageChange={setCurrentPage} />
})
