import React, { memo } from 'react'
import classNames from 'classnames'
import { isBefore, addMonths } from 'date-fns'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { Icon, IconType } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { CONTRACT_LIST_EXPIRY_WARNING_PERIOD_MONTHS } from '@cotiss/contract/contract.constants'
import { ContractPriceDurationModel } from '@cotiss/contract/contract.model'
import { contractService } from '@cotiss/contract/contract.service'

type Props = {
  priceDurations: ContractPriceDurationModel[]
  className?: string
}

export const ContractNextExpirationDate = memo(({ className, priceDurations }: Props) => {
  const nextExpiry: string = contractService.getNextExpirationDate(priceDurations)
  const nextExpiryDate = new Date(nextExpiry)
  const warningLimitDate = addMonths(new Date(), CONTRACT_LIST_EXPIRY_WARNING_PERIOD_MONTHS)
  const showWarning = datetimeService.isDateBetween(nextExpiryDate, { dateFrom: new Date(), dateTo: warningLimitDate })
  const expired = isBefore(nextExpiryDate, new Date())

  let indicator: {
    icon: IconType
    tooltip: string
    width: number
  } = {
    icon: 'flag',
    tooltip: 'Next expiry is within 3 months',
    width: 190,
  }

  if (expired) {
    indicator = {
      icon: 'clock',
      tooltip: 'Next expiry in the past. This contract has expired',
      width: 160,
    }
  }

  const classes = classNames(className, 'flex justify-between items-end gap-3')

  return (
    <Text size="sm" variant={showWarning ? 'danger' : 'light'} className={classes}>
      {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
      {(showWarning || expired) && (
        <Tooltip_DEPRECATED tooltip={indicator.tooltip} width={indicator.width} className="z-10">
          <Icon icon={indicator.icon}></Icon>
        </Tooltip_DEPRECATED>
      )}
    </Text>
  )
})
