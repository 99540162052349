import { gql } from '@apollo/client'
import { GqlDeleteEvaluationCriteriaInput, GqlDeleteEvaluationCriteriaMutation, GqlDeleteEvaluationCriteriaMutationVariables } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationCriteria = async (input: GqlDeleteEvaluationCriteriaInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationCriteriaMutation, GqlDeleteEvaluationCriteriaMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationCriteria($input: DeleteEvaluationCriteriaInput!) {
        deleteEvaluationCriteria(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationCriteria as boolean
}
