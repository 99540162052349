import React, { memo, ReactNode } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  className?: string
  children: ReactNode
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  isDisabled?: boolean
  isLoading?: boolean
}

export const ModalConfirmationButton = memo(({ className, children, type = 'button', onClick, isLoading, isDisabled }: Props) => {
  const classes = classNames(className, 'flex items-center justify-center rounded-t-none rounded-b w-full py-2 px-10', {
    'bg-secondary-500 text-white hover:bg-secondary-600': !isDisabled,
    'bg-gray-200 text-gray-400': isDisabled,
  })

  return (
    <Button className={classes} onClick={onClick} type={type} state="raw" isLoading={isLoading} isDisabled={isDisabled}>
      {children}
    </Button>
  )
})
