import { gql } from '@apollo/client'
import { GqlDeleteApprovalTemplateInput, GqlDeleteApprovalTemplateMutation, GqlDeleteApprovalTemplateMutationVariables } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteApprovalTemplate = async (input: GqlDeleteApprovalTemplateInput) => {
  const { data } = await apolloService.mutate<GqlDeleteApprovalTemplateMutation, GqlDeleteApprovalTemplateMutationVariables>({
    mutation: gql`
      mutation DeleteApprovalTemplate($input: DeleteApprovalTemplateInput!) {
        deleteApprovalTemplate(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteApprovalTemplate as boolean
}
