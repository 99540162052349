import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ConflictOfInterestModel } from '@cotiss/conflict-of-interest/conflict-of-interest.model'

type Props = {
  limit?: number
  offset?: number
  procurementId?: string
  isAllConflicts?: boolean
}

export const useListConflictOfInterest = ({ limit = -1, offset = 0, procurementId, isAllConflicts }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ConflictOfInterestModel>>('/v2/conflict-of-interest', {
    params: { limit, offset, procurementId, isAllConflicts },
  })

  return { conflictOfInterests: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
