import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
}

export const DocumentButtonSkeleton = memo(({ className }: Props) => (
  <Skeleton className={classNames(className, 'flex items-center bg-gray-200 p-4')} variant="none">
    <Skeleton className="shrink-0 bg-primary-200 h-6 w-6 mr-2" variant="none" />
    <div className="w-full">
      <Skeleton className="bg-primary-200 h-2.5 w-2/3" variant="none" />
      <Skeleton className="bg-primary-200 h-2 w-1/3 mt-1" variant="none" />
    </div>
  </Skeleton>
))
