import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
}

export const AccordionSkeleton = memo(({ className }: Props) => (
  <div className={classNames(className, 'flex items-center justify-between w-full')}>
    <Skeleton className="shrink-0 w-4 h-4 mr-2" isRounded />
    <Skeleton className="flex items-center justify-between bg-gray-200 w-full p-4" variant="none">
      <div className="w-full">
        <Skeleton className="bg-primary-200 h-2 w-full" variant="none" />
        <Skeleton className="bg-primary-200 h-2 w-2/3 mt-1" variant="none" />
      </div>
      <Skeleton className="bg-primary-200 h-3 w-12 ml-4" variant="none" />
    </Skeleton>
  </div>
))
