import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const SupplierOnboardingStepComplete = () => {
  const { push } = useHistory()
  const { user } = useGetLoggedInUser()
  const { closeFullModal } = useCallout()

  useEffect(() => {
    // TODO: Add confetti.
    // makeConfetti()
  }, [])

  if (!user?.account?.organisation?._id) {
    return null
  }

  const handleClick = async () => {
    if (!user?.account?.organisation?._id) {
      return null
    }

    push(routerService.getHref('/supplier/view/:id/:tab?', { id: user.account.organisation._id }))
    closeFullModal()
  }

  return (
    <TransitionContainer transition="right">
      <div className="flex flex-col items-center justify-center">
        <Text className="font-medium">Profile basics set up!</Text>
        <div className="text-center mt-6">
          <Text className="font-semibold">Congrats {user.firstname}! You&apos;re ready to start</Text>
        </div>

        <Button className="mt-8" onClick={handleClick} size="lg" variant="secondary">
          View my profile
        </Button>
      </div>
    </TransitionContainer>
  )
}
