import { gql } from '@apollo/client'
import { GqlPerformanceScorecardListCsvQuery, GqlPerformanceScorecardListInput } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const queryPerformanceScorecardListCsv = async (input: GqlPerformanceScorecardListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardListCsvQuery>({
    query: gql`
      query PerformanceScorecardListCsv($input: PerformanceScorecardListInput!) {
        performanceScorecardListCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardListCsv
}
