import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEnvelopeDocumentInput,
  GqlCreateEvaluationEnvelopeDocumentMutation,
  GqlCreateEvaluationEnvelopeDocumentMutationVariables,
  GqlEvaluationEnvelopeDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope-document/evaluation-envelope-document-fragment.graphql'

export const mutateCreateEvaluationEnvelopeDocument = async (input: GqlCreateEvaluationEnvelopeDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEnvelopeDocumentMutation, GqlCreateEvaluationEnvelopeDocumentMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationEnvelopeDocument($input: CreateEvaluationEnvelopeDocumentInput!) {
        createEvaluationEnvelopeDocument(input: $input) {
          ...EvaluationEnvelopeDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEnvelopeDocument as GqlEvaluationEnvelopeDocumentFieldsFragment
}
