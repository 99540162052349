import React, { memo, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { Header } from '@cotiss/common/components/header.component'
import { Page } from '@cotiss/common/components/page.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { EvaluationEventEvaluateEnvelopeUserEvaluationTab } from '@cotiss/evaluation-event/tabs/evaluation-event-evaluate-envelope-user-evaluation.tab'
import { EvaluationEventEvaluateEnvelopeUserResourcesTab } from '@cotiss/evaluation-event/tabs/evaluation-event-evaluate-envelope-user-resources.tab'
import { userService } from '@cotiss/user/user.service'

type EvaluationEventEvaluateEnvelopeUserTab = 'evaluation' | 'resources'
const TABS: TabModel<EvaluationEventEvaluateEnvelopeUserTab>[] = [
  { id: 'evaluation', label: 'Evaluation' },
  { id: 'resources', label: 'Resources' },
]

export const EvaluationEventEvaluateEnvelopeUserPage = memo(() => {
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { evaluationEnvelope, queryEvaluationEnvelopeView } = useEvaluationEnvelope()
  const { evaluationUser, queryEvaluationUserView, queryEvaluationUserInSessionView } = useEvaluationUser()
  const { evaluationEventId, evaluationEnvelopeId, evaluationUserId, tab } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationUserId: string
    tab?: EvaluationEventEvaluateEnvelopeUserTab
  }>()
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(TABS, ({ id }) => id === tab) + 1 })

  const backHref = routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: routerService.getHref('/evaluation-event/list/:tab?'),
    },
    {
      label: evaluationEvent?.name || '',
      href: backHref,
      isLoading,
    },
    {
      label: evaluationEnvelope ? `Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}` : '',
      isLoading,
    },
  ]

  useAsyncEffect(async () => {
    try {
      const [evaluationEvent, evaluationUserInSession] = await Promise.all([
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
        queryEvaluationEnvelopeView({ evaluationEnvelopeId }),
        queryEvaluationUserView({ evaluationUserId }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId, evaluationEnvelopeId, parentEvaluationCriteriaId: null } }),
      ])

      if (evaluationEvent?.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        replace(backHref)
        return
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!tab) {
      replace(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?', {
          evaluationEventId,
          evaluationEnvelopeId,
          evaluationUserId,
          tab: TABS[0].id,
        })
      )
    }

    const newStep = findIndex(TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start truncate">
            <div className="truncate">
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationEnvelope && evaluationUser && (
                <div className="flex items-center truncate">
                  <Text
                    className="font-medium truncate"
                    size="h7"
                    variant="heading"
                    title={`Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}`}
                  >
                    Envelope {evaluationEnvelope.order}. {evaluationEnvelope.name}:
                  </Text>
                  <Text className="font-medium truncate ml-1" size="h7" variant="light" title={userService.getFullName(evaluationUser.user)}>
                    {userService.getFullName(evaluationUser.user)}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm overflow-x-auto px-8 z-10">
        <Tabs<EvaluationEventEvaluateEnvelopeUserTab>
          className="w-full"
          tab={tab}
          tabs={TABS}
          onChange={({ id: tab }) =>
            push(
              routerService.getHref(
                '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
                {
                  evaluationEventId,
                  evaluationEnvelopeId,
                  evaluationUserId,
                  tab,
                }
              )
            )
          }
          variant="underline"
        />
      </div>
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <EvaluationEventEvaluateEnvelopeUserEvaluationTab isInitialised={!isLoading} />}
            {step === 2 && <EvaluationEventEvaluateEnvelopeUserResourcesTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
