import { compact, includes, isArray, join } from 'lodash'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { utilService } from '@cotiss/common/services/util.service'
import { MetafieldFieldType, MetafieldModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type RenderFieldValueParam = {
  metafield: MetafieldModel
  metafieldValue?: MetafieldValueModel
  isEditable?: boolean
}

class MetafieldValueService {
  renderFieldValue = ({ metafield, metafieldValue, isEditable }: RenderFieldValueParam) => {
    if (!metafieldValue) {
      return isEditable ? null : '--'
    }

    const { fieldType } = metafield
    const { fieldValue } = metafieldValue

    if (includes<MetafieldFieldType>(['SHORT_STRING', 'LONG_STRING', 'HYPERLINK', 'ARRAY_STRING'], fieldType)) {
      return !fieldValue?.value ? (isEditable ? null : '--') : fieldValue.value
    }

    if (fieldType === 'MULTI_ARRAY_STRING') {
      if (!fieldValue?.value || !isArray(fieldValue.value) || fieldValue.value.length === 0) {
        return isEditable ? null : '--'
      }

      return join(compact(fieldValue.value), ', ')
    }

    if (fieldType === 'DATETIME' || fieldType === 'DATE') {
      if (!fieldValue?.value) {
        return isEditable ? null : '--'
      }

      return datetimeService.format(fieldValue.value, fieldType === 'DATETIME' ? 'd MMMM yyyy h:mm aaa' : 'do MMM yyyy')
    }

    if (fieldType === 'NUMBER') {
      if (!fieldValue?.value) {
        return isEditable ? null : '--'
      }

      return utilService.formatAsNumber(Number(fieldValue.value) || 0)
    }
  }
}

export const metafieldValueService = new MetafieldValueService()
