import React from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
  isLoading?: boolean
  isFull?: boolean
}

export const InputSkeleton = ({ className, isLoading = false, isFull = true }: Props) => {
  const classes = classNames(className, 'h-11', {
    'w-full': isFull,
  })

  return <Skeleton className={classes} isLoading={isLoading} variant="gray" isRounded={false} />
}
