import { gql } from '@apollo/client'
import { GqlEvaluationEnvelopeDownloadCsvQuery, GqlEvaluationEnvelopeViewInput } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const queryEvaluationEnvelopeDownloadCsv = async (input: GqlEvaluationEnvelopeViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeDownloadCsvQuery>({
    query: gql`
      query EvaluationEnvelopeDownloadCsv($input: EvaluationEnvelopeViewInput!) {
        evaluationEnvelopeDownloadCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeDownloadCsv
}
