import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { SignUpBuyerFormData } from '@cotiss/auth/pages/sign-up-buyer.page'

type Props = {
  formData: SignUpBuyerFormData
  transition: TransitionType
  setFormData: (formData: SignUpBuyerFormData) => void
  isDisabled?: boolean
}

export const SignUpBuyerStepUser = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>First name</Label>
    <Input
      value={formData.firstname}
      onChange={({ target }) => setFormData({ ...formData, firstname: target.value })}
      name="First name"
      placeholder="Enter your First name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Last name</Label>
    <Input
      className="mb-4"
      value={formData.surname}
      onChange={({ target }) => setFormData({ ...formData, surname: target.value })}
      name="Last name"
      placeholder="Enter your last name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Email address</Label>
    <Input
      value={formData.email}
      onChange={({ target }) => setFormData({ ...formData, email: target.value })}
      placeholder="Enter your email address"
      type="email"
      autoComplete="username"
      maxLength={320}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Contact number (optional)</Label>
    <Input
      value={formData.phone}
      onChange={({ target }) => setFormData({ ...formData, phone: target.value })}
      name="Phone number"
      placeholder="Enter your contact number"
      isDisabled={isDisabled}
    />
    <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
      Next
    </Button>
  </TransitionContainer>
))
