import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderMandatoryCriterionScoreModel } from '@cotiss/tender-mandatory-criteria/tender-mandatory-criteria.models'

type Props = {
  tenderResponseId?: string
  limit?: number
  offset?: number
}

export const useListTenderMandatoryCriteriaScore = ({ tenderResponseId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderMandatoryCriterionScoreModel>>('/v2/tender-mandatory-criteria-score', {
    route: `/v2/tender-mandatory-criteria-score`,
    params: { tenderResponseId, offset, limit },
    isEnabled: Boolean(tenderResponseId),
  })

  return { tenderMandatoryCriteriaScores: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
