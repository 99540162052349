import React, { ReactNode, MouseEvent as ReactMouseEvent, memo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { utilService } from '@cotiss/common/services/util.service'

type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = {
  children: ReactNode
  className?: string
  onClick?: ButtonClickEvent
  href?: string
  hasPadding?: boolean
  isLink?: boolean
  isOpenNewTab?: boolean
  isExternalLink?: boolean
  isDisabled?: boolean
}

export const DropdownContent = memo((props: Props) => {
  const { children, className, onClick, href, hasPadding = true, isLink, isOpenNewTab, isExternalLink, isDisabled } = props
  const classes = classNames(
    className,
    'relative block outline-none text-gray-700 text-sm text-left w-full whitespace-nowrap first:rounded-t-md last:rounded-b-md',
    {
      'cursor-pointer hover:bg-primary-50': onClick && !isDisabled,
      'pointer-events-none opacity-50 cursor-not-allowed': isDisabled,
      'px-3 py-2': hasPadding,
    }
  )

  if (onClick) {
    return (
      <button className={classes} onClick={onClick} type="button">
        {children}
      </button>
    )
  }

  if (isExternalLink && href) {
    return (
      <a className={classes} href={utilService.getExternalHref(href)} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  if (isLink && href) {
    return (
      <Link className={classes} to={href} target={isOpenNewTab ? '_blank' : undefined}>
        {children}
      </Link>
    )
  }

  return <div className={classes}>{children}</div>
})
