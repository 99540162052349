import React, { memo, useMemo } from 'react'
import { filter, map, sumBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderPlanMethodologyFormData } from '@cotiss/tender/tabs/tender-plan-methodology.tab'
import { RATING_SCALE_NAME_MAP, RATING_SCALE_OPTIONS } from '@cotiss/tender/tender.constants'
import { tenderService } from '@cotiss/tender/tender.service'
import { TenderCriteriaTable } from '@cotiss/tender-criteria/components/tender-criteria-table.component'
import { useListTenderCriteria } from '@cotiss/tender-criteria/resources/use-list-tender-criteria.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  tenderId: string
  formData: TenderPlanMethodologyFormData
  setFormData: (formData: TenderPlanMethodologyFormData) => void
  setIsEditing: (isEditing: boolean) => void
  isEditing?: boolean
  isEditable?: boolean
  isDisabled?: boolean
}

export const TenderEvaluationMethodologyFormWeighedAttribute = memo((props: Props) => {
  const { formData, setFormData, isEditing, tenderId, setIsEditing, isEditable, isDisabled } = props
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { tenderCriteria, isLoading: isTenderCriteriaLoading } = useListTenderCriteria({ tenderId })
  const isLoading = isTenderLoading || isTenderCriteriaLoading
  const { nonPriceCriteria, nonPrice, price } = useMemo(() => {
    return tenderService.processCriteria({
      nonPriceCriteria: formData.criteria,
      nonPriceWeight: Number(formData.envelopeWeight) || 0,
      priceWeight: Number(formData.priceEnvelopeWeight) || 0,
    })
  }, [formData])

  const handleUpdateCriteriaName = (indexToUpdate: number, name: string) => {
    const criteria = map(formData.criteria, (criteria, index) => (index === indexToUpdate ? { ...criteria, name } : criteria))
    setFormData({ ...formData, criteria })
  }

  const handleUpdateCriteriaWeight = (indexToUpdate: number, weight: string) => {
    const criteria = map(formData.criteria, (criteria, index) => (index === indexToUpdate ? { ...criteria, weight } : criteria))
    setFormData({ ...formData, criteria })
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => setIsEditing(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const overallWeight = useMemo(() => {
    return sumBy(formData.criteria, ({ weight }) => Number(weight) || 0)
  }, [formData.criteria])

  if (isEditing) {
    return (
      <>
        <Field
          label="Number of envelopes"
          supplementary="Select if price and non-price criteria are viewed together in one envelope, or separately in two envelopes."
        >
          <div className="flex items-center mt-4">
            <Radio<boolean>
              className="mr-4"
              value={false}
              onChange={(isTwoEnvelope) => setFormData({ ...formData, isTwoEnvelope })}
              name="is-two-envelope"
              isChecked={formData.isTwoEnvelope === false}
              isDisabled={isDisabled}
            >
              One envelope
            </Radio>
            <Radio<boolean>
              value={true}
              onChange={(isTwoEnvelope) => setFormData({ ...formData, isTwoEnvelope })}
              name="is-two-envelope"
              isChecked={formData.isTwoEnvelope}
              isDisabled={isDisabled}
            >
              Two envelopes
            </Radio>
          </div>
        </Field>
        <Hr className="my-8" />

        <>
          <Field label="Price as a weighted criteria" supplementary="Do you want price to be a weighted factor in evaluating submissions?">
            <div className="flex items-center mt-4">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(isPriceWeighted) => setFormData({ ...formData, isPriceWeighted })}
                name="is-price-weighted"
                isChecked={formData.isPriceWeighted}
                isDisabled={isDisabled}
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(isPriceWeighted) => setFormData({ ...formData, isPriceWeighted })}
                name="is-price-weighted"
                isChecked={formData.isPriceWeighted === false}
                isDisabled={isDisabled}
              >
                No
              </Radio>
            </div>
          </Field>
          <Hr className="my-8" />
        </>

        {formData.isPriceWeighted && (
          <>
            <Label>Price vs non-price weighting</Label>
            <Text className="w-1/3 mt-1" variant="light" size="sm">
              Select the relative weights for the price and non-price criteria for your evaluation.
            </Text>

            <div className="grid grid-cols-4 gap-4 items-center mt-8">
              <Text className="uppercase" variant="light" size="sm">
                Description
              </Text>
              <Text className="uppercase" variant="light" size="sm">
                Value (1 - 100)
              </Text>
              <Text className="text-right uppercase col-span-2" variant="light" size="sm">
                Weighting
              </Text>

              <Text>Non-price criteria</Text>
              <Input
                className="w-28"
                value={formData.envelopeWeight}
                onChange={({ target }) => setFormData({ ...formData, envelopeWeight: target.value })}
                type="number"
                min={0}
                max={100}
                isFull={false}
                isDisabled={isDisabled}
                isRequired
              />
              <div className="col-span-2 justify-self-end">
                <div className="text-center text-secondary-500 border-b-2 border-secondary-500 h-8 w-20">
                  {utilService.formatAsPercentage(nonPrice.weight * 100)}
                </div>
              </div>
              <Text>Price criteria</Text>
              <Input
                className="w-28"
                value={formData.priceEnvelopeWeight}
                onChange={({ target }) => setFormData({ ...formData, priceEnvelopeWeight: target.value })}
                type="number"
                min={0}
                max={100}
                isFull={false}
                isDisabled={isDisabled}
                isRequired
              />
              <div className="col-span-2 justify-self-end">
                <div className="text-center text-secondary-500 border-b-2 border-secondary-500 h-8 w-20">
                  {utilService.formatAsPercentage(price.weight * 100)}
                </div>
              </div>
            </div>
            <Hr className="my-8" />
          </>
        )}
        <Label>Non-price criteria</Label>
        <Text className="w-1/3 mt-1" variant="light" size="sm">
          Add the criteria you will use to evaluate the non-price aspects of the tender and assign weights to each.
        </Text>
        <div className="grid grid-cols-9 gap-x-4 items-end mt-8 mb-4">
          <Text className="col-span-4 uppercase" variant="light" size="sm">
            Criteria (max 100 characters)
          </Text>

          <Text className="col-span-2 uppercase" variant="light" size="sm">
            Value (1 - 100)
          </Text>

          <div>
            {formData.isPriceWeighted ? (
              <Text className="text-right" variant="light" size="sm">
                Non-price
              </Text>
            ) : (
              <Text className="text-right" variant="light" size="sm">
                Overall
              </Text>
            )}
            <Text className="text-right uppercase" variant="light" size="sm">
              Weighting
            </Text>
          </div>
          <div>
            {formData.isPriceWeighted && (
              <>
                <Text className="text-right" variant="light" size="sm">
                  Overall
                </Text>
                <Text className="text-right uppercase" variant="light" size="sm">
                  Weighting
                </Text>
              </>
            )}
          </div>
        </div>

        {map(formData.criteria, (criteria, index) => (
          <div key={index} className="mb-4">
            <div className="grid grid-cols-9 gap-x-4 items-center justify-center">
              <Input
                className="col-span-4"
                value={criteria.name}
                onChange={({ target }) => handleUpdateCriteriaName(index, target.value)}
                placeholder="Criteria name"
                isDisabled={isDisabled}
              />
              <Input
                className="col-span-2 self-end w-24"
                value={criteria.weight}
                onChange={({ target }) => handleUpdateCriteriaWeight(index, target.value)}
                type="number"
                min={0}
                max={100}
                isFull={false}
                isDisabled={isDisabled}
              />
              <div className="self-end justify-self-end mb-3">
                {nonPriceCriteria.length === formData.criteria.length && (
                  <>
                    <Text className="whitespace-nowrap text-right" variant="link">
                      {nonPriceCriteria[index].percentage}
                    </Text>
                    <Text className="whitespace-nowrap text-right" size="sm" variant="light">
                      ({nonPriceCriteria[index].absolute} / {overallWeight})
                    </Text>
                  </>
                )}
              </div>
              {formData.isPriceWeighted && (
                <div className="self-end justify-self-end mb-3">
                  {nonPriceCriteria.length === formData.criteria.length && (
                    <>
                      <Text className="whitespace-nowrap text-right" variant="link">
                        {nonPriceCriteria[index].overallPercentage}
                      </Text>
                      <Text className="whitespace-nowrap text-right" size="sm" variant="light">
                        ({utilService.formatAsPercentage(nonPriceCriteria[index].weight * 100, 0) || 0} x{' '}
                        {utilService.formatAsPercentage(nonPrice.weight * 100, 0) || 0})
                      </Text>
                    </>
                  )}
                </div>
              )}
              {formData.criteria.length > 1 && (
                <Button
                  className="justify-self-end"
                  onClick={() => setFormData({ ...formData, criteria: filter(formData.criteria, (_, indexToRemove) => index !== indexToRemove) })}
                  shape="square"
                  size="sm"
                  state="ghost"
                  isDisabled={isDisabled}
                >
                  <Icon icon="x-close" variant="light" />
                </Button>
              )}
            </div>
          </div>
        ))}
        <Button
          onClick={() => setFormData({ ...formData, criteria: [...formData.criteria, { name: '', weight: '' }] })}
          size="sm"
          state="translucent"
          variant="secondary"
          isDisabled={isDisabled}
        >
          + Add
        </Button>
        <div className="grid grid-cols-9 gap-x-4 items-end mb-4">
          <div className="col-span-4" />
          <Text className="col-span-2" variant="light" size="sm">
            Value
          </Text>
          <Text className="text-right" variant="light" size="sm">
            Total
          </Text>
          {formData.isPriceWeighted && (
            <Text className="text-right" variant="light" size="sm">
              Total
            </Text>
          )}
        </div>
        <div className="grid grid-cols-9 gap-x-4 items-center mb-4 bg-primary-50 py-3">
          <Text className="col-span-4 ml-4 capitalize font-medium" size="sm">
            Totals
          </Text>
          <Text className="col-span-2 uppercase" variant="link">
            {overallWeight}
          </Text>
          <Text className="text-right uppercase" variant="link">
            {overallWeight === 0 ? '0%' : '100%'}
          </Text>
          {formData.isPriceWeighted && (
            <Text className="text-right uppercase" variant="link">
              {utilService.formatAsPercentage(nonPrice.weight * 100)}
            </Text>
          )}
        </div>
        <Hr className="my-8" />

        <Field label="Rating scale" supplementary="Choose the rating scale through which you will evaluate the submissions.">
          <Select_DEPRECATED
            className="w-2/3"
            value={formData.ratingScale}
            options={RATING_SCALE_OPTIONS}
            onChange={(ratingScale) => setFormData({ ...formData, ratingScale })}
            isDisabled={isDisabled}
            placeholder
          />
        </Field>
        <Hr className="my-8" />

        <Field label="Non-price criteria visibility" supplementary="Do you want criteria weightings to be visible to your suppliers?">
          <div className="flex items-center mt-4">
            <Radio<boolean>
              className="mr-4"
              value={true}
              onChange={(criteriaWeightingsVisible) => setFormData({ ...formData, criteriaWeightingsVisible })}
              name="criteria-weightings-visible"
              isChecked={formData.criteriaWeightingsVisible}
              isDisabled={isDisabled}
            >
              Yes
            </Radio>
            <Radio<boolean>
              value={false}
              onChange={(criteriaWeightingsVisible) => setFormData({ ...formData, criteriaWeightingsVisible })}
              name="criteria-weightings-visible"
              isChecked={formData.criteriaWeightingsVisible === false}
              isDisabled={isDisabled}
            >
              No
            </Radio>
          </div>
        </Field>
      </>
    )
  }

  return (
    <>
      <Field
        className="mt-8"
        label="Number of envelopes"
        supplementary="Select if price and non-price criteria are viewed together in one envelope, or separately in two envelopes."
      >
        <Text>
          {isLoading && <Skeleton className="h-3 w-12" />}
          {!isLoading && (
            <>
              {tender?.isTwoEnvelope === undefined && isEditable && renderToggleLink('+ Add number of envelopes')}
              {tender?.isTwoEnvelope !== undefined && <Text>{tender.isTwoEnvelope ? 'Two envelopes' : 'One envelope'}</Text>}
            </>
          )}
        </Text>
      </Field>
      <Hr className="my-8" />

      <>
        <Field
          className="mt-8"
          label="Price as a weighted criteria"
          supplementary="Do you want price to be a weighted factor in evaluating submissions?"
        >
          <Text>
            {isLoading && <Skeleton className="h-3 w-12" />}
            {!isLoading && (
              <>
                {tender?.isPriceWeighted === undefined && isEditable && renderToggleLink('+ Add price as a weighted criteria')}
                {tender?.isPriceWeighted === undefined && !isEditable && 'No'}
                {tender?.isPriceWeighted !== undefined && <Text>{tender.isPriceWeighted ? 'Yes' : 'No'}</Text>}
              </>
            )}
          </Text>
        </Field>
        <Hr className="my-8" />
      </>
      {tender?.isPriceWeighted && (
        <>
          <Field className="mt-8" label="Non-price weighting">
            {isLoading && <Skeleton className="h-3 w-12" />}
            {!isLoading && (
              <>
                {!nonPrice.weight === undefined && isEditable && renderToggleLink('+ Add non-price weighting')}
                {nonPrice.weight !== undefined && <Text>{nonPrice.percentage}</Text>}
              </>
            )}
          </Field>
          <Field className="mt-8" label="Price weighting">
            {isLoading && <Skeleton className="h-3 w-12" />}
            {!isLoading && (
              <>
                {price.weight === undefined && isEditable && renderToggleLink('+ Add price weighting')}
                {price.weight !== undefined && <Text>{price.percentage}</Text>}
              </>
            )}
          </Field>
          <Hr className="my-8" />
        </>
      )}
      <Field
        className="mt-8"
        label="Non-price criteria"
        supplementary="Add the criteria you will use to evaluate the non-price aspects of the tender and assign weights to each."
      >
        {isLoading && <Skeleton className="h-3 w-12" />}
        {!isLoading && (
          <>
            {!tenderCriteria.length && isEditable && renderToggleLink('+ Add non-price criteria')}
            {Boolean(tenderCriteria.length) && tender && <TenderCriteriaTable tenderId={tenderId} />}
          </>
        )}
      </Field>
      <Hr className="my-8" />
      <Field className="mt-8" label="Rating scale" supplementary="Choose the rating scale through which you will evaluate the submissions.">
        {isLoading && <Skeleton className="h-3 w-12" />}
        {!isLoading && (
          <>
            {!tender?.ratingScale && isEditable && renderToggleLink('+ Add rating scale')}
            {tender?.ratingScale && <Text>{RATING_SCALE_NAME_MAP[tender.ratingScale]}</Text>}
          </>
        )}
      </Field>
      <Hr className="my-8" />
      <Field className="mt-8" label="Non-price criteria visibility" supplementary="Do you want criteria weightings to be visible to your suppliers?">
        <Text>
          {isLoading && <Skeleton className="h-3 w-12" />}
          {!isLoading && (
            <>
              {tender?.criteriaWeightingsVisible === undefined && isEditable && renderToggleLink('+ Add non-price criteria visibility')}
              {tender?.criteriaWeightingsVisible === undefined && !isEditable && 'No'}
              {tender?.criteriaWeightingsVisible !== undefined && <Text>{tender.criteriaWeightingsVisible ? 'Yes' : 'No'}</Text>}
            </>
          )}
        </Text>
      </Field>
    </>
  )
})
