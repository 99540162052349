import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderUpdateCommentModel } from '@cotiss/tender-update/tender-update.models'

type Props = {
  tenderUpdateId?: string
  limit?: number
  offset?: number
}

export const useListTenderUpdateComment = ({ tenderUpdateId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderUpdateCommentModel>>('/v2/tender-update-comments', {
    route: `/v2/tender-update-comments`,
    params: { tenderUpdateId, offset, limit },
    isEnabled: Boolean(tenderUpdateId),
  })

  return { tenderUpdateComments: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
