import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { QueryKey } from '@cotiss/common/services/query.service'
import { DocumentModel } from '@cotiss/document/document.models'

type CreateDocumentParam = {
  file: File
  controller?: AbortController
  invalidate?: QueryKey[]
}

export const useMutateDocument = () => {
  const { mutate } = useMutate()

  return {
    createDocument: async ({ file, controller, invalidate }: CreateDocumentParam) => {
      const body = new FormData()
      body.append('file', file)

      return await mutate<DocumentModel>({ route: `/v2/documents`, body, type: 'form-data', controller, invalidate })
    },
  }
}
