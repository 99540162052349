import React, { memo } from 'react'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { ContractApprovalHistory } from '@cotiss/contract/components/contract-approval-history.component'
import { ContractModel } from '@cotiss/contract/contract.model'
import { contractService } from '@cotiss/contract/contract.service'

type Props = {
  contract: ContractModel
}

export const ContractRejectedFeedbackModal = memo(({ contract }: Props) => {
  // REJECTED here = REQUEST_CHANGES, just hasn't been updated on BE yet
  const approval = contractService.getApproval(contract?.approvals, ['REVOKED'])

  if (!approval?.approvers?.length) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  return (
    <>
      <ModalHeader heading="Feedback for review" />
      <ModalContent isScrollable className="min-w-[650px] max-w-[650px]">
        <ContractApprovalHistory contractShellId={contract.contractShell} contractId={contract._id} approvalId={approval?._id} />
      </ModalContent>
    </>
  )
})
