import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreateEvaluationEventDocumentInput,
  GqlCreateEvaluationEventDocumentsInput,
  GqlDeleteEvaluationEventDocumentInput,
  GqlEvaluationEventDocumentFieldsFragment,
  GqlEvaluationEventDocumentListInput,
} from '@gql'
import { filter, uniqBy } from 'lodash'
import { mutateCreateEvaluationEventDocument } from '@cotiss/evaluation-event/graphql/evaluation-event-document/mutate-create-evaluation-event-document.graphql'
import { mutateCreateEvaluationEventDocuments } from '@cotiss/evaluation-event/graphql/evaluation-event-document/mutate-create-evaluation-event-documents.graphql'
import { mutateDeleteEvaluationEventDocument } from '@cotiss/evaluation-event/graphql/evaluation-event-document/mutate-delete-evaluation-event-document.graphql'
import { queryEvaluationEventDocumentList } from '@cotiss/evaluation-event/graphql/evaluation-event-document/query-evaluation-event-document-list.graphql'

const evaluationEventDocumentVar = makeVar<GqlEvaluationEventDocumentFieldsFragment | null>(null)
const evaluationEventDocumentsVar = makeVar<GqlEvaluationEventDocumentFieldsFragment[]>([])

export const useEvaluationEventDocument = () => {
  const evaluationEventDocument = useReactiveVar(evaluationEventDocumentVar)
  const evaluationEventDocuments = useReactiveVar(evaluationEventDocumentsVar)

  return {
    evaluationEventDocument,
    evaluationEventDocuments,
    setEvaluationEventDocument: evaluationEventDocumentVar,
    setEvaluationEventDocuments: evaluationEventDocumentsVar,
    queryEvaluationEventDocumentList: async (input: GqlEvaluationEventDocumentListInput) => {
      const { items: evaluationEventDocuments, pagination } = await queryEvaluationEventDocumentList(input)

      evaluationEventDocumentsVar(evaluationEventDocuments)

      return { evaluationEventDocuments, pagination }
    },
    mutateCreateEvaluationEventDocument: async (input: GqlCreateEvaluationEventDocumentInput) => {
      const createdEvaluationEventDocument = await mutateCreateEvaluationEventDocument(input)

      evaluationEventDocumentsVar(uniqBy([createdEvaluationEventDocument, ...evaluationEventDocuments], 'id'))

      return createdEvaluationEventDocument
    },
    mutateCreateEvaluationEventDocuments: async (input: GqlCreateEvaluationEventDocumentsInput) => {
      const createdEvaluationEventDocuments = await mutateCreateEvaluationEventDocuments(input)

      evaluationEventDocumentsVar(uniqBy([...createdEvaluationEventDocuments, ...evaluationEventDocuments], 'id'))

      return createdEvaluationEventDocuments
    },
    mutateDeleteEvaluationEventDocument: async (input: GqlDeleteEvaluationEventDocumentInput) => {
      await mutateDeleteEvaluationEventDocument(input)

      evaluationEventDocumentsVar(filter(evaluationEventDocuments, ({ id }) => id !== input.evaluationEventDocumentId))
    },
  }
}

export const clearReactiveEvaluationEventDocument = async () => {
  evaluationEventDocumentVar(null)
  evaluationEventDocumentsVar([])
}
