import { gql } from '@apollo/client'
import { GqlEvaluationEnvelopeListInput, GqlEvaluationEnvelopeOverviewListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-criteria-breakdown-fragment.graphql'
import { EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-fragment.graphql'
import { EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-overview-submission-breakdown-fragment.graphql'

export const queryEvaluationEnvelopeOverviewList = async (input: GqlEvaluationEnvelopeListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeOverviewListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}
      ${EVALUATION_ENVELOPE_OVERVIEW_SUBMISSION_BREAKDOWN_FRAGMENT}

      query EvaluationEnvelopeOverviewList($input: EvaluationEnvelopeListInput!) {
        evaluationEnvelopeOverviewList(input: $input) {
          items {
            ...EvaluationEnvelopeOverviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeOverviewList
}
