import React, { memo } from 'react'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { SettingsPreferencesFormEmailNotificationPeriod } from '@cotiss/settings/components/settings-preferences-form-email-notification-period.component'
import { SettingsPreferencesFormNotificationPeriod } from '@cotiss/settings/components/settings-preferences-form-notification-period.component'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export const SettingsPreferencesTab = memo(() => {
  const { permissions } = useUserAccess()

  return (
    <Card>
      {permissions.hasSupplierAccess && (
        <>
          <SettingsPreferencesFormNotificationPeriod />
          <Hr className="my-4" />
        </>
      )}
      <SettingsPreferencesFormEmailNotificationPeriod />
    </Card>
  )
})
