import { ApprovalModel } from '@cotiss/approval/approval.models'

class ApprovalService {
  getDisplayApprovalType = (approval?: ApprovalModel) => {
    if (approval?.approvalType === 'go-to-market') {
      return 'Go to market'
    }

    if (approval?.approvalType === 'recommendation') {
      return 'Recommendation report'
    }

    return '–'
  }
}

export const approvalService = new ApprovalService()
