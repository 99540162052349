import React, { memo, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ApprovalViewHeader } from '@cotiss/approval/components/approval-view-header.component'
import { useGetApproval } from '@cotiss/approval/resources/use-get-approval.resource'
import { TenderRequest } from '@cotiss/tender/components/tender-request.component'

export const ApprovalViewRequestPage = memo(() => {
  const { replace } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { approval } = useGetApproval(id)

  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'approval' }),
      },
      {
        label: 'Approval request',
        href: routerService.getHref('/approval/view/:id', { id }),
      },
      {
        label: 'Request details',
      },
    ]
  }, [])

  return (
    <Page>
      <ApprovalViewHeader breadcrumbs={breadcrumbs} />
      <PageContent>
        {approval && (
          <TenderRequest
            tenderId={approval.tender._id}
            isOverviewVisible
            onTabChange={(tab) => replace(routerService.getHref('/approval/view/:id/request/:tab?', { id, tab }))}
          />
        )}
      </PageContent>
    </Page>
  )
})
