import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'

type Props = {
  limit?: number
  offset?: number
  pending?: boolean
  isArchived?: boolean
  isEnabled?: boolean
}

export const useListPreferredSupplier = ({ limit = -1, offset = 0, pending, isArchived = false, isEnabled = true }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<PreferredSupplierPopulatedModel>>('/v2/preferred-suppliers', {
    route: `/v2/preferred-suppliers`,
    params: { limit, offset, pending, isArchived },
    isEnabled,
  })

  return { preferredSuppliers: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
