import { gql } from '@apollo/client'
import { GqlPlanEventViewInput, GqlPlanEventViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event/plan-event-fragment.graphql'

export const queryPlanEventView = async (input: GqlPlanEventViewInput) => {
  const { data } = await apolloService.query<GqlPlanEventViewQuery>({
    query: gql`
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      query PlanEventView($input: PlanEventViewInput!) {
        planEventView(input: $input) {
          ...PlanEventFields
        }
      }
    `,
    variables: { input },
  })

  return data.planEventView
}
