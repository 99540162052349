import { gql } from '@apollo/client'
import {
  GqlDeletePerformanceScorecardDocumentInput,
  GqlDeletePerformanceScorecardDocumentMutation,
  GqlDeletePerformanceScorecardDocumentMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeletePerformanceScorecardDocument = async (input: GqlDeletePerformanceScorecardDocumentInput) => {
  const { data } = await apolloService.mutate<GqlDeletePerformanceScorecardDocumentMutation, GqlDeletePerformanceScorecardDocumentMutationVariables>({
    mutation: gql`
      mutation DeletePerformanceScorecardDocument($input: DeletePerformanceScorecardDocumentInput!) {
        deletePerformanceScorecardDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePerformanceScorecardDocument as boolean
}
