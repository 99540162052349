import { compact } from 'lodash'
import { AccountLocationModel } from '@cotiss/account/account.models'

class AccountService {
  formatLocation = (location?: AccountLocationModel) => {
    return compact([
      location?.address || '',
      location?.city || '',
      `${location?.region || ''} ${location?.postcode || ''}`,
      location?.country || '',
    ]).join(', ')
  }
}

export const accountService = new AccountService()
