import React, { memo } from 'react'
import classNames from 'classnames'
import { includes } from 'lodash'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TenderStatusBadge } from '@cotiss/tender/components/tender-status-badge.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

type Props = {
  tenderId: string
}

export const TenderStatusProgression = memo(({ tenderId }: Props) => {
  const { tender, isLoading } = useGetTender(tenderId)
  const tenderFlowSteps = tenderFlowService.getTenderFlowSteps(tender)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  if (!tender || isLoading) {
    return (
      <div>
        <Skeleton className="h-5 w-20 mt-4" isRounded />
        <div className="flex space-x-1.5 mt-4">
          {Array.from({ length: tenderFlowSteps.length }, (_, i) => (
            <div key={i} className="bg-primary-100 rounded-full h-1 w-8" />
          ))}
        </div>
      </div>
    )
  }

  const stepUnlockedIndex = tenderFlowService.getUnlockedRequestStepIndex(tender)

  const barClasses = classNames('rounded-full h-1 w-8', {
    'bg-orange-500': includes(['planning', 'planning-approval', 'planned', 'planned-approval'], tender.status),
    'bg-green-500': includes(['active', 'complete'], tender.status),
    'bg-blue-500': includes(['review', 'shortlist', 'evaluate', 'consensus', 'price', 'selection', 'selection-approval', 'report'], tender.status),
    'bg-pink-500': includes(['cancelled', 'unsuccessful', 'withdrawn'], tender.status),
  })

  return (
    <>
      <TenderStatusBadge status={tender.status === 'evaluate' && !isOldEvaluationsEnabled ? 'shortlist' : tender.status} />
      <div className="flex space-x-1.5 mt-4">
        {Array.from({ length: stepUnlockedIndex }, (_, i) => (
          <div key={i} className={barClasses} />
        ))}
        {Array.from({ length: tenderFlowSteps.length - stepUnlockedIndex }, (_, i) => (
          <div key={i} className="bg-primary-200 rounded-full h-1 w-8" />
        ))}
      </div>
    </>
  )
})
