import { makeVar, useReactiveVar } from '@apollo/client'
import { GqlCreatePlanEventInput, GqlPlanEventFieldsFragment, GqlPlanEventListInput, GqlPlanEventViewInput, GqlUpdatePlanEventInput } from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreatePlanEvent } from '@cotiss/plan-event/graphql/plan-event/mutate-create-plan-event.graphql'
import { mutateUpdatePlanEvent } from '@cotiss/plan-event/graphql/plan-event/mutate-update-plan-event.graphql'
import { queryPlanEventList } from '@cotiss/plan-event/graphql/plan-event/query-plan-event-list.graphql'
import { queryPlanEventView } from '@cotiss/plan-event/graphql/plan-event/query-plan-event-view.graphql'

const planEventVar = makeVar<GqlPlanEventFieldsFragment | null>(null)
const planEventsVar = makeVar<GqlPlanEventFieldsFragment[]>([])

export const usePlanEvent = () => {
  const planEvent = useReactiveVar(planEventVar)
  const planEvents = useReactiveVar(planEventsVar)

  return {
    planEvent,
    planEvents,
    setPlanEvent: planEventVar,
    setPlanEvents: planEventsVar,
    queryPlanEventList: async (input: GqlPlanEventListInput) => {
      const { items: planEvents, pagination } = await queryPlanEventList(input)

      planEventsVar(planEvents)

      return { planEvents, pagination }
    },
    queryPlanEventView: async (input: GqlPlanEventViewInput) => {
      const planEvent = await queryPlanEventView(input)

      planEventVar(planEvent)
      planEventsVar(uniqBy([planEvent, ...planEvents], 'id'))

      return planEvent
    },

    mutateCreatePlanEvent: async (input: GqlCreatePlanEventInput) => {
      const createdPlanEvent = await mutateCreatePlanEvent(input)

      planEventsVar(uniqBy([createdPlanEvent, ...planEvents], 'id'))

      return createdPlanEvent
    },
    mutateUpdatePlanEvent: async (input: GqlUpdatePlanEventInput) => {
      const updatedPlanEvent = await mutateUpdatePlanEvent(input)

      planEventVar(updatedPlanEvent)
      planEventsVar(uniqBy([updatedPlanEvent, ...planEvents], 'id'))

      return updatedPlanEvent
    },
  }
}

export const clearReactivePlanEvent = async () => {
  planEventVar(null)
  planEventsVar([])
}
