import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardInput,
  GqlPerformanceScorecardFieldsFragment,
  GqlPerformanceScorecardListInput,
  GqlPerformanceScorecardViewInput,
  GqlPublishPerformanceScorecardInput,
  GqlUpdatePerformanceScorecardInput,
} from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecard } from '@cotiss/performance/graphql/performance-scorecard/mutate-create-performance-scorecard.graphql'
import { mutatePublishPerformanceScorecard } from '@cotiss/performance/graphql/performance-scorecard/mutate-publish-performance-scorecard.graphql'
import { mutateUpdatePerformanceScorecard } from '@cotiss/performance/graphql/performance-scorecard/mutate-update-performance-scorecard.graphql'
import { queryPerformanceScorecardListCsv } from '@cotiss/performance/graphql/performance-scorecard/query-performance-scorecard-list-csv.graphql'
import { queryPerformanceScorecardList } from '@cotiss/performance/graphql/performance-scorecard/query-performance-scorecard-list.graphql'
import { queryPerformanceScorecardView } from '@cotiss/performance/graphql/performance-scorecard/query-performance-scorecard-view.graphql'

const performanceScorecardVar = makeVar<GqlPerformanceScorecardFieldsFragment | null>(null)
const performanceScorecardsVar = makeVar<GqlPerformanceScorecardFieldsFragment[]>([])

export const usePerformanceScorecard = () => {
  const performanceScorecard = useReactiveVar(performanceScorecardVar)
  const performanceScorecards = useReactiveVar(performanceScorecardsVar)

  return {
    performanceScorecard,
    performanceScorecards,
    setPerformanceScorecard: performanceScorecardVar,
    setPerformanceScorecards: performanceScorecardsVar,
    queryPerformanceScorecardList: async (input: GqlPerformanceScorecardListInput) => {
      const { items: performanceScorecards, pagination } = await queryPerformanceScorecardList(input)

      performanceScorecardsVar(performanceScorecards)

      return { performanceScorecards, pagination }
    },
    queryPerformanceScorecardView: async (input: GqlPerformanceScorecardViewInput) => {
      const performanceScorecard = await queryPerformanceScorecardView(input)

      performanceScorecardVar(performanceScorecard)
      performanceScorecardsVar(uniqBy([performanceScorecard, ...performanceScorecards], 'id'))

      return performanceScorecard
    },
    queryPerformanceScorecardListCsv: async (input: GqlPerformanceScorecardListInput) => {
      return await queryPerformanceScorecardListCsv(input)
    },
    mutateCreatePerformanceScorecard: async (input: GqlCreatePerformanceScorecardInput) => {
      const createdPerformanceScorecard = await mutateCreatePerformanceScorecard(input)

      performanceScorecardsVar(uniqBy([createdPerformanceScorecard, ...performanceScorecards], 'id'))

      return createdPerformanceScorecard
    },
    mutateUpdatePerformanceScorecard: async (input: GqlUpdatePerformanceScorecardInput) => {
      const updatedPerformanceScorecard = await mutateUpdatePerformanceScorecard(input)

      performanceScorecardVar(updatedPerformanceScorecard)
      performanceScorecardsVar(uniqBy([updatedPerformanceScorecard, ...performanceScorecards], 'id'))

      return updatedPerformanceScorecard
    },
    mutatePublishPerformanceScorecard: async (input: GqlPublishPerformanceScorecardInput) => {
      const publishedPerformanceScorecard = await mutatePublishPerformanceScorecard(input)

      performanceScorecardsVar(uniqBy([publishedPerformanceScorecard, ...performanceScorecards], 'id'))

      return publishedPerformanceScorecard
    },
  }
}

export const clearReactivePerformanceScorecard = async () => {
  performanceScorecardVar(null)
  performanceScorecardsVar([])
}
