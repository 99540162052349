import React, { memo, useState } from 'react'
import { parseISO } from 'date-fns'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { MetafieldDatetimeModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type Props = {
  className?: string
  metafield: MetafieldDatetimeModel
  metafieldValue?: MetafieldValueModel
  onChange: (value: any) => void
}

export const MetafieldFormDatetime = memo(({ className, metafield, metafieldValue, onChange }: Props) => {
  const [formData, setFormData] = useState(metafieldValue?.fieldValue?.value ? parseISO(metafieldValue?.fieldValue?.value) : null)

  const handleChange = (value: Date | null) => {
    setFormData(value)
    onChange({ value })
  }

  return (
    <DatetimeInput
      className={className}
      value={formData}
      onChange={(value) => handleChange(value)}
      placeholder={metafield.validation?.placeholder || metafield.fieldLabel}
      dateFormat="d MMMM yyyy h:mm aaa"
      isPast={metafield.validation?.isPast}
      isFuture={metafield.validation?.isFuture}
      isRequired={metafield.isRequired}
    />
  )
})
