import React, { memo } from 'react'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { ConflictOfInterestList } from '@cotiss/conflict-of-interest/components/conflict-of-interest-list.component'

type Props = {
  isAllConflicts?: boolean
}

export const ConflictOfInterestListPage = memo(({ isAllConflicts }: Props) => (
  <Page>
    <Header className="flex items-center justify-between">
      <Text className="font-semibold" size="h5" variant="heading">
        Conflict of interest
      </Text>
    </Header>
    <PageContent>
      <ConflictOfInterestList isAllConflicts={isAllConflicts} />
    </PageContent>
  </Page>
))
