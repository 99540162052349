import React, { memo, useMemo, useState } from 'react'
import classNames from 'classnames'
import { compact, filter, find, map, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { mutateResendEvaluationInvitation } from '@cotiss/evaluation-event/graphql/evaluation-event/mutate-resend-evaluation-invitation.graphql'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTableColumn, ScrollableTableRow } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventEnvelopeUserStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-envelope-user-status-badge.component'
import { EvaluationEventModerationStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-moderation-status-badge.component'
import { EvaluationEventViewTrackingEnvelope } from '@cotiss/evaluation-event/components/evaluation-event-view-tracking-envelope.component'
import { EvaluationEventSubmissionImportTypeModal } from '@cotiss/evaluation-event/modals/evaluation-event-submission-import-type.modal'
import { userService } from '@cotiss/user/user.service'

export const EvaluationEventViewTrackingTab = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const [totalSubmissions, setTotalSubmissions] = useState(0)
  const { evaluationEventId } = useParams<{ evaluationEventId: string }>()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { evaluationUserInSession, evaluationUsers, evaluationUserEnvelopes, queryEvaluationUserList, queryEvaluationUserEnvelopeList } =
    useEvaluationUser()
  const { evaluationEnvelopes, evaluationEnvelopeOverviews, queryEvaluationEnvelopeList, queryEvaluationEnvelopeOverviewList } =
    useEvaluationEnvelope()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      // We have to re-query these resources when the `evaluationEvent.isSetupComplete` changes, so this tab updates when the user clicks the `Start
      // evaluation` button.
      const [{ pagination: submissionPagination }] = await Promise.all([
        queryEvaluationSubmissionList({ filter: { evaluationEventId } }),
        queryEvaluationUserList({ filter: { evaluationEventId } }),
        queryEvaluationUserEnvelopeList({ filter: { evaluationEventId } }),
        queryEvaluationEnvelopeList({ filter: { evaluationEventId } }),
        queryEvaluationEnvelopeOverviewList({ filter: { evaluationEventId } }),
      ])

      setTotalSubmissions(submissionPagination.totalCount)
      track('evaluation_event_view_tracking_tab_view')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [evaluationEvent?.isSetupComplete])

  const handleAddSubmission = () => {
    push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab: 'submissions' }))
    openModal(<EvaluationEventSubmissionImportTypeModal />)
  }

  const trackingData = useMemo(() => {
    if (!evaluationEvent || !evaluationUserInSession) {
      return []
    }

    const trackingData = map(evaluationEnvelopes, (evaluationEnvelope) => {
      const evaluationEnvelopeOverview = find(evaluationEnvelopeOverviews, { evaluationEnvelopeId: evaluationEnvelope.id })

      if (!evaluationEnvelopeOverview) {
        return null
      }

      // This is the access control matrix for viewing evaluations and moderations.
      // |                   | Evaluation Stage |           |           | Moderation Stage |           |           | Envelope Complete |           |       |
      // |-------------------|:----------------:|:---------:|:---------:|:----------------:|:---------:|:---------:|:-----------------:|:---------:|:-----:|
      // |                   | Evaluator        | Moderator | Owner     | Evaluator        | Moderator | Owner     | Evaluator         | Moderator | Owner |
      // | Your evaluation   | Write            |           |           | Read             |           |           | Read              |           |       |
      // | Other evaluations | No access        | No access | No access | No access        | Read      | Read      | No access         | Read      | Read  |
      // | Moderation        | No access        | No access | No access | No access        | Write     | No access | Read              | Read      | Read  |
      const accessControl = { resourceType: 'envelope', resourceId: evaluationEnvelope.id }
      const moderateEvaluationUser = find(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'moderate' }))
      const evaluateEvaluationUsers = filter(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'evaluate' }))
      const isUserInSessionOwner = evaluationUserInSession.role === 'owner'
      const isUserInSessionModerator = evaluationUserInSession.id === moderateEvaluationUser?.id
      const isUserInSessionInEnvelope = some(evaluationUserInSession.accessControls, { ...accessControl, access: 'evaluate' })
      let canViewModeration = false
      switch (evaluationEnvelope.status) {
        case 'moderate':
          canViewModeration = isUserInSessionModerator
          break
        case 'complete':
          canViewModeration = isUserInSessionModerator || isUserInSessionOwner || isUserInSessionInEnvelope
          break
        default:
          canViewModeration = false
          break
      }

      const fixedEvaluationUserRows: ScrollableTableRow[] = map(evaluateEvaluationUsers, (evaluationUser) => {
        const isUserInSessionEvaluator = evaluationUserInSession.id === evaluationUser.id
        let canViewEvaluation = false
        if (evaluationEvent.status !== 'draft') {
          switch (evaluationEnvelope.status) {
            case 'evaluate':
              canViewEvaluation = isUserInSessionEvaluator
              break
            case 'moderate':
            case 'complete':
              canViewEvaluation = isUserInSessionEvaluator || isUserInSessionModerator || isUserInSessionOwner
              break
            default:
              canViewEvaluation = false
              break
          }
        }

        return {
          content: () => (
            <div className="flex items-center truncate">
              <Text className="truncate">{userService.getFullName(evaluationUser.user)}</Text>
              {evaluationUserInSession?.id === evaluationUser.id && (
                <Text className="ml-1" variant="light">
                  (you)
                </Text>
              )}
            </div>
          ),
          cta: canViewEvaluation ? (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref(
                  '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
                  {
                    evaluationEventId,
                    evaluationEnvelopeId: evaluationEnvelope.id,
                    evaluationUserId: evaluationUser.id,
                  }
                ),
              }}
            />
          ) : (isUserInSessionModerator || isUserInSessionOwner) && evaluationEnvelope.status === 'evaluate' ? (
            <>
              <TableRowCta
                actions={[
                  {
                    label: 'Resend invite',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading={`Are you sure you want to send a reminder to ${userService.getFullName(evaluationUser.user)}`}
                          description="This evaluator will receive and email reminder to complete this evaluation"
                          onSubmit={async () => {
                            await mutateResendEvaluationInvitation({ evaluationEventId: evaluationEvent.id, evaluationUserId: evaluationUser.id })
                          }}
                        />
                      ),
                  },
                ]}
              />
            </>
          ) : (
            <Icon icon="lock" variant="light" />
          ),
        }
      })

      const accessRows: ScrollableTableRow[] = map(evaluateEvaluationUsers, () => ({
        content: () => (
          <Badge state="outline" variant="secondary">
            Evaluate
          </Badge>
        ),
      }))

      const statusRows: ScrollableTableRow[] = map(evaluateEvaluationUsers, ({ id }) => ({
        content: () => (
          <EvaluationEventEnvelopeUserStatusBadge
            status={find(evaluationUserEnvelopes, { evaluationEnvelopeId: evaluationEnvelope.id, evaluationUserId: id })?.status}
          />
        ),
      }))

      const progressRows: ScrollableTableRow[] = map(evaluateEvaluationUsers, ({ id }) => ({
        content: () => (
          <Text size="sm">
            {find(evaluationUserEnvelopes, { evaluationEnvelopeId: evaluationEnvelope.id, evaluationUserId: id })?.completedEvaluationCount}/
            {totalSubmissions}
          </Text>
        ),
      }))

      if (moderateEvaluationUser) {
        fixedEvaluationUserRows.push({
          content: () => (
            <div className="flex items-center truncate">
              <Text className="truncate">{userService.getFullName(moderateEvaluationUser.user)}</Text>
              {evaluationUserInSession?.id === moderateEvaluationUser.id && (
                <Text className="ml-1" variant="light">
                  (you)
                </Text>
              )}
            </div>
          ),
          cta: canViewModeration ? (
            <TableRowCta
              cta={{
                label: (
                  <>
                    {evaluationEnvelope.status === 'complete' ? 'View' : 'Moderate'} <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
                  evaluationEventId,
                  evaluationEnvelopeId: evaluationEnvelope.id,
                }),
              }}
            />
          ) : (
            <Icon icon="lock" variant="light" />
          ),
        })

        accessRows.push({
          content: () => (
            <Badge state="outline" variant="warning">
              Moderate
            </Badge>
          ),
        })

        statusRows.push({
          content: () => <EvaluationEventModerationStatusBadge status={evaluationEnvelope.status} />,
        })

        progressRows.push({
          content: () => (
            <Text size="sm">
              {filter(evaluationEnvelopeOverview.submissionBreakdown, { status: 'reviewed' }).length}/{totalSubmissions}
            </Text>
          ),
        })
      }

      const fixedColumns: ScrollableTableColumn[] = [
        {
          heading: 'users',
          rows: fixedEvaluationUserRows,
        },
      ]

      const columns: ScrollableTableColumn[] = [
        {
          heading: 'tasks',
          rows: accessRows,
        },
        {
          heading: 'status',
          rows: statusRows,
        },
        {
          heading: 'progress',
          rows: progressRows,
        },
      ]

      return { evaluationEnvelope, evaluationEnvelopeOverview, evaluationUsers: evaluateEvaluationUsers, fixedColumns, columns }
    })

    return compact(trackingData)
  }, [
    evaluationEvent,
    evaluationUsers,
    evaluationUserEnvelopes,
    evaluationEnvelopes,
    evaluationEnvelopeOverviews,
    evaluationUserInSession,
    totalSubmissions,
  ])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  if (!evaluationEvent) {
    return null
  }

  if (!evaluationSubmissions.length) {
    return (
      <NoDataPlaceholder
        label="There are no submissions uploaded to this evaluation yet. Once uploaded, you can track your evaluation here."
        ctaLabel={evaluationUserInSession?.role === 'owner' && !evaluationEvent?.isArchived ? 'Add submission' : undefined}
        onCtaClick={handleAddSubmission}
      />
    )
  }

  return (
    <>
      {map(trackingData, ({ evaluationEnvelope, evaluationEnvelopeOverview, fixedColumns, columns }, index) => (
        <EvaluationEventViewTrackingEnvelope
          key={evaluationEnvelope.id}
          className={classNames({ 'mt-8': index })}
          evaluationEvent={evaluationEvent}
          evaluationEnvelope={evaluationEnvelope}
          evaluationEnvelopeOverview={evaluationEnvelopeOverview}
          fixedColumns={fixedColumns}
          columns={columns}
        />
      ))}
    </>
  )
})
