import React, { FormEvent, memo, useState } from 'react'
import { GqlEvaluationSubmissionFieldsFragment } from '@gql'
import { Banner } from '@cotiss/common/components/banner.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'

type Props = {
  evaluationSubmission: GqlEvaluationSubmissionFieldsFragment
}

export const EvaluationEventSubmissionDeleteModal = memo(({ evaluationSubmission }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { mutateDeleteEvaluationSubmission } = useEvaluationSubmission()
  const [isSaving, setIsSaving] = useState(false)
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      track('evaluation_event_view_submissions_tab_delete_active_submit')
      await mutateDeleteEvaluationSubmission({ evaluationSubmissionId: evaluationSubmission.id })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Delete submission" />
      <ModalContent>
        <Banner icon="alert-circle" variant="danger">
          <div>
            <Text className="font-medium">Warning</Text>
            <Text className="mt-1" size="sm">
              Deleting this submission will delete all associated scores
            </Text>
          </div>
        </Banner>
        <label className="flex mt-6 cursor-pointer" htmlFor="evaluation-submission-delete">
          <Checkbox
            id="evaluation-submission-delete"
            className="mr-2 mt-1"
            isChecked={isAcknowledged}
            onChange={() => setIsAcknowledged(!isAcknowledged)}
          />

          <Text>I acknowledge all scores associated with this submission will be removed</Text>
        </label>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving} isDisabled={!isAcknowledged}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
