import React, { memo } from 'react'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import {
  ORGANISATION_CANADA_SUBDIVISION_OPTIONS,
  ORGANISATION_COUNTRY_OPTIONS,
  ORGANISATION_ENTITY_TYPE_OPTIONS,
  ORGANISATION_US_SUBDIVISION_OPTIONS,
} from '@cotiss/organisation/organisation.constants'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { PreferredSupplierSearchMethod } from '@cotiss/organisation/resources/use-list-organisation-search.resource'
import { PreferredSupplierAddFormData } from '@cotiss/preferred-supplier/drawers/preferred-supplier-add.drawer'

type Props = {
  formData: PreferredSupplierAddFormData
  setFormData: (formData: PreferredSupplierAddFormData) => void
  isSaving: boolean
  isDisabled: boolean
}

export const PreferredSupplierAddOverviewStep = memo(({ formData, setFormData, isSaving, isDisabled }: Props) => {
  const processedCountryCode = formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

  return (
    <>
      <Label>Entity type</Label>
      <Select_DEPRECATED
        value={formData.entityType}
        options={ORGANISATION_ENTITY_TYPE_OPTIONS}
        onChange={(entityType) => setFormData({ ...formData, entityType })}
        placeholder
        isRequired
        isDisabled={isDisabled || isSaving}
      />
      <Label className="mt-6">Country</Label>
      <Select_DEPRECATED
        value={formData.countryCode}
        options={ORGANISATION_COUNTRY_OPTIONS}
        onChange={(countryCode) => setFormData({ ...formData, countryCode, countryCodeSubdivision: undefined })}
        placeholder
        isRequired
        isDisabled={isDisabled || isSaving}
      />
      {(formData.countryCode === 'CA' || formData.countryCode === 'US') && (
        <>
          <Label className="mt-6">Region</Label>
          <Select_DEPRECATED
            value={formData.countryCodeSubdivision}
            options={formData.countryCode === 'CA' ? ORGANISATION_CANADA_SUBDIVISION_OPTIONS : ORGANISATION_US_SUBDIVISION_OPTIONS}
            onChange={(countryCodeSubdivision) => setFormData({ ...formData, countryCodeSubdivision })}
            placeholder
            isRequired
            isDisabled={isDisabled || isSaving}
          />
        </>
      )}
      <Label className="mt-6">
        Search contact by:{' '}
        <Text variant="light" isInline>
          (select one)
        </Text>
      </Label>
      <Radio<PreferredSupplierSearchMethod>
        isChecked={formData.searchMethod === 'name'}
        value="name"
        onChange={(searchMethod) => setFormData({ ...formData, searchMethod })}
        name="search-method"
        isDisabled={isDisabled || isSaving || !processedCountryCode}
        isRequired
      >
        Contact name
      </Radio>
      <Radio<PreferredSupplierSearchMethod>
        className="ml-8"
        isChecked={formData.searchMethod === 'businessNumber'}
        value="businessNumber"
        onChange={(searchMethod) => setFormData({ ...formData, searchMethod })}
        name="search-method"
        isDisabled={isDisabled || isSaving || !processedCountryCode}
        isRequired
      >
        {organisationService.getRegistryNumberType(processedCountryCode)}
      </Radio>
      {formData.searchMethod === 'name' && (
        <>
          <Label className="mt-6">Contact name</Label>
          <Input
            value={formData.organisationName}
            onChange={({ target }) => setFormData({ ...formData, organisationName: target.value })}
            placeholder="Enter contact name"
            maxLength={100}
            isRequired
            isDisabled={isDisabled || isSaving}
          />
        </>
      )}
      {formData.searchMethod === 'businessNumber' && (
        <>
          <Label className="mt-6">{organisationService.getRegistryNumberType(processedCountryCode)}</Label>
          <Input
            value={formData.businessNumber}
            onChange={({ target }) => setFormData({ ...formData, businessNumber: target.value })}
            placeholder={`Enter ${organisationService.getRegistryNumberType(processedCountryCode)}`}
            maxLength={100}
            isRequired
            isDisabled={isDisabled || isSaving}
          />
        </>
      )}
    </>
  )
})
