import React, { memo } from 'react'
import { GqlEvaluationEnvelopeStatus } from '@gql'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

const getVariant = (status: GqlEvaluationEnvelopeStatus): BaseVariant => {
  switch (status) {
    case 'complete':
      return 'success'
    case 'moderate':
      return 'warning'
    case 'evaluate':
    case 'locked':
    default:
      return 'neutral'
  }
}

const getCopy = (status: GqlEvaluationEnvelopeStatus) => {
  switch (status) {
    case 'complete':
      return 'Submitted'
    case 'moderate':
      return 'In progress'
    case 'evaluate':
    case 'locked':
    default:
      return 'Not started'
  }
}

type Props = {
  className?: string
  status: GqlEvaluationEnvelopeStatus
  size?: BadgeSize
}

export const EvaluationEventModerationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="dot">
    {getCopy(status)}
  </Badge>
))
