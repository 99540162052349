import { gql } from '@apollo/client'
import { GqlDeleteEvaluationUserInput, GqlDeleteEvaluationUserMutation, GqlDeleteEvaluationUserMutationVariables } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationUser = async (input: GqlDeleteEvaluationUserInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationUserMutation, GqlDeleteEvaluationUserMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationUser($input: DeleteEvaluationUserInput!) {
        deleteEvaluationUser(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationUser as boolean
}
