import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { SupplierProfilesOnboardingStage } from '@cotiss/supplier/supplier.models'

type UpdateSupplierProfileBody = {
  regions?: string[]
  categories?: string[]
  description?: string
}

type UpdateSupplierProfilesOnboardingBody = {
  stage?: SupplierProfilesOnboardingStage
  completed?: boolean
}

export const useMutateSupplier = () => {
  const { mutate } = useMutate()

  return {
    updateSupplierProfile: async (body: UpdateSupplierProfileBody) => {
      return await mutate({ route: '/v2/supplier-profiles', body })
    },
    updateSupplierProfilesOnboarding: async (body: UpdateSupplierProfilesOnboardingBody) => {
      return await mutate({ route: '/v2/supplier-profiles-onboarding', body })
    },
  }
}
