import { gql } from '@apollo/client'
import { GqlResendEvaluationInvitationInput } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateResendEvaluationInvitation = async (input: GqlResendEvaluationInvitationInput) => {
  const { data } = await apolloService.mutate({
    mutation: gql`
      mutation resendEvaluationInvitation($input: ResendEvaluationInvitationInput!) {
        resendEvaluationInvitation(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.resendEvaluationInvitation as boolean
}
