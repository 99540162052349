import React, { memo, useMemo } from 'react'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { sortService } from '@cotiss/common/services/sort.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { TenderResponseReviewListCta } from '@cotiss/tender-response/components/tender-response-review-list-cta.component'
import { TenderResponseReviewStatusBadge } from '@cotiss/tender-response/components/tender-response-review-status-badge.component'
import { TenderResponseSubmissionCreateDrawer } from '@cotiss/tender-response/drawers/tender-response-submission-create.drawer'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

type ReviewTableSortKey = 'name' | 'updatedAt' | 'reviewStatus'

type Props = {
  className?: string
  tenderId: string
  onView: (tenderResponse: TenderResponsePopulatedModel) => void
}

export const TenderResponseReviewList = memo(({ className, tenderId, onView }: Props) => {
  const { openDrawer } = useCallout()
  const { tender } = useGetTender(tenderId)
  const { tenderResponses, isLoading } = useListTenderResponse({ tenderId, status: 'submitted' })
  const { sortKey, sortDirection, onSort } = useSortTable<ReviewTableSortKey>({ initialKey: 'name' })

  const sortedTenderResponses = useMemo(() => {
    const result = tenderResponses.sort((a, b) => {
      if (sortKey === 'name') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'updatedAt') {
        return sortService.sortDate(a.updatedAt, b.updatedAt)
      } else if (sortKey === 'reviewStatus') {
        return sortService.sortString(a.reviewStatus, b.reviewStatus)
      }

      return 0
    })

    return sortDirection === 'asc' ? result : result.reverse()
  }, [tenderResponses, sortKey, sortDirection])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Contact',
      onSort: () => onSort('name'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => (
          <Text className="truncate" title={tenderResponse.procurementResponse.supplier.name}>
            {tenderResponse.procurementResponse.supplier.name}
          </Text>
        ),
        cta: <TenderResponseReviewListCta tenderResponse={tenderResponse} onView={onView} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Review status',
      onSort: () => onSort('reviewStatus'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <TenderResponseReviewStatusBadge status={tenderResponse.reviewStatus} />,
      })),
    },
    {
      heading: 'Submitted by',
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <Text>{tenderResponse?.isBuyerSubmission ? 'Buyer' : 'Vendor'}</Text>,
      })),
    },
    {
      heading: 'Date submitted',
      onSort: () => onSort('updatedAt'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <Text>{datetimeService.format(tenderResponse.updatedAt, 'do MMM yyyy')}</Text>,
      })),
    },
  ]

  return (
    <div className={className}>
      <TableHeader className="flex items-center justify-between" variant="white">
        <Text className="font-medium" size="lg">
          Submissions
        </Text>
        {tenderFlowService.getActiveStep(tender) !== 'shortlist' && (
          <Button
            onClick={() => openDrawer(<TenderResponseSubmissionCreateDrawer tenderId={tenderId} />)}
            size="xs"
            variant="secondary"
            state="translucent"
            isDisabled={isLoading}
          >
            <Icon className="mr-1" icon="plus-01" variant="secondary" /> Add submission
          </Button>
        )}
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
    </div>
  )
})
