import React, { memo, MouseEvent as ReactMouseEvent } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownButton } from '@cotiss/common/components/dropdown-button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TenderStatusProgression } from '@cotiss/tender/components/tender-status-progression.component'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TENDER_TYPE_DESCRIPTION_MAP, TENDER_TYPE_NAME_MAP } from '@cotiss/tender/tender.constants'
import { TenderModel } from '@cotiss/tender/tender.models'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

type Props = {
  className?: string
  tender: TenderModel
  onChange?: (tender: TenderModel) => void
  isSelected?: boolean
  // If this component is being used in the TenderFlow, we want to show a button to resume the tender flow by navigating them to the currently active
  // step of the tender.
  isTenderFlow?: boolean
}

export const TenderSummaryCard = memo(({ className, tender, onChange, isSelected, isTenderFlow = true }: Props) => {
  const activeStep = tenderFlowService.getActiveStep(tender)
  const classes = classNames(className, 'inline-flex flex-col shrink-0 bg-white rounded-lg shadow-lg w-60 p-4')
  const { deleteTender } = useMutateTender()
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { track } = useAnalytics()

  const handleRemoveTender = async (tenderId: string) => {
    try {
      await deleteTender(tenderId)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <RadioCard className={classes} onChange={() => onChange && onChange(tender)} isSelected={isSelected}>
      <div className="flex justify-between mb-4">
        <div>
          <div className="flex justify-between space-x-2 items-start">
            <Text className="font-semibold line-clamp-2" title={tender.title || TENDER_TYPE_NAME_MAP[tender.tenderType]} isInline>
              <TenderTypeBadge className="mr-1" tenderType={tender.tenderType} /> {tender.title || TENDER_TYPE_NAME_MAP[tender.tenderType]}
            </Text>
            {tender.status === 'planning' && (
              <DropdownButton
                dropdownContent={
                  <Button
                    state="text"
                    onClick={(event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault()
                      event.stopPropagation()
                      openModal(
                        <ConfirmModal
                          heading="Remove request"
                          description="Confirm you would like to remove this request. This action cannot be undone."
                          onSubmit={() => handleRemoveTender(tender._id)}
                        />
                      )
                    }}
                  >
                    Remove
                  </Button>
                }
              >
                <Icon icon="dots" className="text-primary-400" />
              </DropdownButton>
            )}
          </div>

          <Text
            className="line-clamp-3 mt-2"
            variant="light"
            size="sm"
            title={tender.briefDescription || TENDER_TYPE_DESCRIPTION_MAP[tender.tenderType]}
          >
            {tender.briefDescription || TENDER_TYPE_DESCRIPTION_MAP[tender.tenderType]}
          </Text>
        </div>
        {onChange && <Radio className="ml-2" isChecked={isSelected} />}
      </div>
      <div className="mt-auto">
        <TenderStatusProgression tenderId={tender._id} />
        <div className="flex items-center mt-4">
          <Text className="mr-1" size="sm" variant="light">
            Created:
          </Text>
          <Text size="sm">{datetimeService.format(tender.createdAt, 'do MMM yyyy')}</Text>
        </div>
        {isTenderFlow && (
          <Button
            className="w-full mt-4"
            onClick={() => track('procurement_event_requests_resume_request_view')}
            href={routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId: tender._id, step: activeStep })}
            state="translucent"
            variant="secondary"
            size="sm"
            isLink
          >
            Resume request
          </Button>
        )}
      </div>
    </RadioCard>
  )
})
