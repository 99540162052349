import React from 'react'
import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { supplierService } from '@cotiss/supplier/supplier.service'

type Props = {
  categoryCodes: string[]
  onOpenModal: () => void
  onChange: (categoryCodes: string[]) => void
  isSaving?: boolean
}

export const SupplierOnboardingStepUnspscCategories = ({ categoryCodes, onOpenModal, onChange, isSaving }: Props) => (
  <TransitionContainer transition="right">
    <Text size="h4">Add UNSPSC category tags to your profile.</Text>
    <Text variant="light">Click the plus icon to add</Text>

    <div className="flex justify-between border-b border-gray-300 cursor-pointer p-4" onClick={() => onOpenModal()}>
      <div>
        {map(supplierService.processCategoryCodes(categoryCodes), (category) => (
          <Pill key={category.code} className="mr-2" onRemove={() => onChange(filter(categoryCodes, (c) => c !== category.code))}>
            {category.name}
          </Pill>
        ))}
      </div>
      <Button state="ghost" shape="square" isDisabled={isSaving}>
        <Icon icon="circle-add" />
      </Button>
    </div>
  </TransitionContainer>
)
