import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { AccordionIcon } from '@cotiss/common/components/accordion-icon.component'

type Props = {
  className?: string
  contentClassName?: string
  children: ReactNode
  onClick: () => void
  isExpanded?: boolean
}

export const AccordionToggle = memo(({ className, contentClassName, children, onClick, isExpanded }: Props) => {
  const classes = classNames(className, 'relative flex items-center cursor-pointer w-full')
  const contentClasses = classNames(contentClassName, 'rounded-t-lg w-full px-4 py-2', {
    'rounded-b-lg': !isExpanded,
  })

  return (
    <div className={classes} onClick={onClick}>
      <AccordionIcon className="mr-2" isExpanded={isExpanded} />
      <div className={contentClasses}>{children}</div>
    </div>
  )
})
