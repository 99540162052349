import React, { memo, useMemo, useState } from 'react'
import { map } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { PreferredSupplierBuyerPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'

type Props = {
  isLoading: boolean
  preferredSupplierBuyers: PreferredSupplierBuyerPopulatedModel[]
}

type PreferredSupplierBuyerListSortKey = 'name' | 'date'

export const PreferredSupplierBuyerList = memo(({ isLoading, preferredSupplierBuyers }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<PreferredSupplierBuyerListSortKey>({ initialKey: 'name' })

  const { processedPreferredSupplierBuyers, pagination } = useMemo(() => {
    const result = preferredSupplierBuyers.sort((a, b) => {
      if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      if (sortKey === 'name') {
        return sortService.sortString(a.account.organisation.name, b.account.organisation.name)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedPreferredSupplierBuyers, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedPreferredSupplierBuyers, pagination }
  }, [preferredSupplierBuyers, sortKey, sortDirection])

  const fixedColumns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: 'Name',
        onSort: () => onSort('name'),
        rows: map(processedPreferredSupplierBuyers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" title={preferredSupplier.account.organisation.name || '--'}>
              {preferredSupplier.account.organisation.name || '--'}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref('/buyer/view/:id/:tab?', { id: preferredSupplier.account.organisation._id }),
              }}
            />
          ),
        })),
      },
    ],
    [preferredSupplierBuyers, onSort]
  )

  const columns: ScrollableTableColumn[] = useMemo(() => {
    const columns = [
      {
        heading: 'Date created',
        onSort: () => onSort('date'),
        rows: map(preferredSupplierBuyers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return columns
  }, [processedPreferredSupplierBuyers, onSort])

  if (!isLoading && !processedPreferredSupplierBuyers.length) {
    return (
      <div>
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label="Organisations will appear here once you are added to their supplier lists"
          />
        </div>
      </div>
    )
  }

  return (
    <ScrollableTable
      fixedColumnsWidth={500}
      isLoading={isLoading}
      fixedColumns={fixedColumns}
      columns={columns}
      pagination={pagination}
      onPageChange={setCurrentPage}
    />
  )
})
