import { gql } from '@apollo/client'
import {
  GqlReplaceEvaluationUserInEvaluationEnvelopeInput,
  GqlReplaceEvaluationUserInEvaluationEnvelopeMutation,
  GqlReplaceEvaluationUserInEvaluationEnvelopeMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateReplaceEvaluationUserInEvaluationEnvelope = async (input: GqlReplaceEvaluationUserInEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<
    GqlReplaceEvaluationUserInEvaluationEnvelopeMutation,
    GqlReplaceEvaluationUserInEvaluationEnvelopeMutationVariables
  >({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation ReplaceEvaluationUserInEvaluationEnvelope($input: ReplaceEvaluationUserInEvaluationEnvelopeInput!) {
        replaceEvaluationUserInEvaluationEnvelope(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.replaceEvaluationUserInEvaluationEnvelope as GqlEvaluationUserFieldsFragment
}
