import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { PreferencesEmailNotificationPeriod, PreferencesModel, PreferencesNotificationMethodType } from '@cotiss/preferences/preferences.models'

type UpdatePreferencesBody = {
  emailNotificationPeriod?: PreferencesEmailNotificationPeriod
  notification_buyer_listing_interaction?: PreferencesNotificationMethodType
  notification_buyer_supplier_invitation_accepted?: PreferencesNotificationMethodType
  notification_buyer_preferred_supplier_request?: PreferencesNotificationMethodType
  notification_supplier_preferred_supplier_accepted?: PreferencesNotificationMethodType
}

export const useMutatePreferences = () => {
  const { mutate } = useMutate()

  return {
    updatePreferences: async (body: UpdatePreferencesBody) => {
      return await mutate<PreferencesModel>({ route: '/v2/preferences', body })
    },
  }
}
