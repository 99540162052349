import { gql } from '@apollo/client'
import {
  GqlRegressEvaluationEnvelopeInput,
  GqlRegressEvaluationEnvelopeMutation,
  GqlRegressEvaluationEnvelopeMutationVariables,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-fragment.graphql'

export const mutateRegressEvaluationEnvelope = async (input: GqlRegressEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<GqlRegressEvaluationEnvelopeMutation, GqlRegressEvaluationEnvelopeMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      mutation RegressEvaluationEnvelope($input: RegressEvaluationEnvelopeInput!) {
        regressEvaluationEnvelope(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data?.regressEvaluationEnvelope as GqlEvaluationEnvelopeFieldsFragment
}
