import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricCycleScoreListInput, GqlPerformanceScorecardMetricCycleScoreListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/performance-scorecard-metric-cycle-score-fragment.graphql'

export const queryPerformanceScorecardMetricCycleScoreList = async (input: GqlPerformanceScorecardMetricCycleScoreListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleScoreListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT}

      query PerformanceScorecardMetricCycleScoreList($input: PerformanceScorecardMetricCycleScoreListInput!) {
        performanceScorecardMetricCycleScoreList(input: $input) {
          items {
            ...PerformanceScorecardMetricCycleScoreFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleScoreList
}
