import React, { memo } from 'react'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { OrganisationClaimedStatus } from '@cotiss/organisation/organisation.models'
import { organisationService } from '@cotiss/organisation/organisation.service'

type Props = {
  organisation: {
    logo?: string
    name?: string
    claimedStatus?: OrganisationClaimedStatus
    organisationId?: string
    city?: string
    country?: string
    website?: string
    contactPhone?: string
  }
  cta?: React.ReactNode
}

export const PreferredSupplierOrganisationCard = memo(
  ({ cta, organisation: { logo, name, claimedStatus, organisationId, city, country, website, contactPhone } }: Props) => {
    return (
      <div className="bg-primary-50 rounded-lg p-4 flex justify-between items-center">
        <div className="flex">
          <OrganisationLogo className="mr-4" size="sm" organisation={logo ? { logo } : undefined} />
          <div className="flex flex-col space-y-4">
            <div>
              {name && <Text className="font-medium">{name}</Text>}
              {!name && <Skeleton className="w-2/3 h-3.5" variant="gray" />}
              <div className="flex mt-2 items-center">
                {claimedStatus && (
                  <Badge variant={claimedStatus === 'CLAIMED' ? 'success' : 'neutral'} state="outline">
                    {organisationService.getClaimedStatusText(claimedStatus)}
                  </Badge>
                )}
                {!claimedStatus && <Skeleton className="w-1/2 h-4" variant="gray" />}
                {organisationId && (
                  <Button
                    state="text"
                    variant="link"
                    size="sm"
                    className="ml-4"
                    href={routerService.getHref('/supplier/view/:id/:tab?', { id: organisationId })}
                    isLink
                  >
                    View profile
                  </Button>
                )}
              </div>
            </div>
            {((city && country) || website || contactPhone) && (
              <div className="space-y-2">
                {city && country && (
                  <Text size="sm" variant="light">
                    {city} • {country}
                  </Text>
                )}
                {website && (
                  <Text size="sm" variant="light">
                    {website}
                  </Text>
                )}
                {contactPhone && (
                  <Text size="sm" variant="light">
                    {contactPhone}
                  </Text>
                )}
              </div>
            )}
          </div>
        </div>
        {cta}
      </div>
    )
  }
)
