import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { PerformanceScorecardListPage } from '@cotiss/performance/pages/performance-scorecard-list.page'
import { PerformanceScorecardMetricCycleViewPage } from '@cotiss/performance/pages/performance-scorecard-metric-cycle-view.page'
import { PerformanceScorecardMetricViewPage } from '@cotiss/performance/pages/performance-scorecard-metric-view.page'
import { PerformanceScorecardViewPage } from '@cotiss/performance/pages/performance-scorecard-view.page'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export const PerformanceLayout = memo(() => {
  const { permissions } = useUserAccess()

  if (!permissions.hasEvaluationAccess) {
    return <FourOhFourPage />
  }

  return (
    <Switch>
      <Route path="/performance/scorecard/list/:tab?" exact>
        <PerformanceScorecardListPage />
      </Route>
      <Route
        path="/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId/cycle/:performanceScorecardMetricCycleId"
        exact
      >
        <PerformanceScorecardMetricCycleViewPage />
      </Route>
      <Route path="/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId" exact>
        <PerformanceScorecardMetricViewPage />
      </Route>
      <Route path="/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?" exact>
        <PerformanceScorecardViewPage />
      </Route>
      <Redirect from="/performance" to="/performance/scorecard" exact />
      <Redirect from="/performance/scorecard" to="/performance/scorecard/list/:tab?" exact />
    </Switch>
  )
})
