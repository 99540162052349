import React, { memo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { useDownloadReport } from '@cotiss/report/hooks/use-download-report.hook'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderResponseReportResultGraph } from '@cotiss/tender-response/components/tender-response-report-result-graph.component'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type TenderResponseReportResultTab = 'overall' | 'non-price'
const TABS: TabModel<TenderResponseReportResultTab>[] = [
  { id: 'overall', label: 'Overall' },
  { id: 'non-price', label: 'Non-price criteria' },
]

type Props = {
  className?: string
  tenderId: string
  tenderResponseReports: TenderResponseReportModel[]
}

export const TenderResponseReportResult = memo(({ className, tenderId, tenderResponseReports }: Props) => {
  const { tender, isLoading } = useGetTender(tenderId)
  const { step, transition, onTransition } = useTransition()
  const { downloadNonPriceReport, downloadPricingTableReport, isDownloading } = useDownloadReport(tenderId)
  const handleTabChange = (tab: TenderResponseReportResultTab) => {
    const newStep = findIndex(TABS, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <Card className={className}>
      <CardHeader className="mb-8" hasSeparator={false}>
        <Tabs<TenderResponseReportResultTab>
          className="border-b border-gray-300"
          tabs={TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <div className="space-x-4">
          {tender?.priceTableEnabled && (
            <Button onClick={downloadPricingTableReport} size="sm" state="translucent" variant="secondary" isDisabled={isDownloading || isLoading}>
              <Icon className="mr-1" icon="download-01" />
              Export pricing table report
            </Button>
          )}
          <Button onClick={downloadNonPriceReport} size="sm" state="translucent" variant="secondary" isDisabled={isDownloading || isLoading}>
            <Icon className="mr-1" icon="download-01" />
            Export non-price report
          </Button>
        </div>
      </CardHeader>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <TenderResponseReportResultGraph tenderResponseReports={tenderResponseReports} type="overall" />}
          {step === 2 && <TenderResponseReportResultGraph tenderResponseReports={tenderResponseReports} type="non-price" />}
        </TransitionContainer>
      </AnimatePresence>
    </Card>
  )
})
