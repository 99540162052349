import React, { memo, useMemo } from 'react'
import { find } from 'lodash'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractApprovalModal } from '@cotiss/contract/modals/contract-approval.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  contractShellId: string
  contractId: string
  breadcrumbs: BreadcrumbModel[]
}

export const ContractApprovalHeader = memo(({ contractShellId, contractId, breadcrumbs }: Props) => {
  const { openModal } = useCallout()
  const { user, isLoading: isUserLoading } = useGetLoggedInUser()
  const { contractShell, isLoading: isContractShellLoading } = useGetContractShell(contractShellId)

  const { contract, contractApprover, approval } = useMemo(() => {
    const contract = find(contractShell?.contracts, { _id: contractId })
    const approval = contractService.getApproval(contract?.approvals, ['PENDING_APPROVAL', 'APPROVED', 'REJECTED'])
    const contractApprover = find(approval?.approvers, ({ assigned }) => assigned._id === user?._id)

    return { contract, contractApprover, approval }
  }, [contractShell, user])

  return (
    <Header>
      <div className="flex items-center justify-between">
        <div>
          <Breadcrumb breadcrumbs={breadcrumbs} />
          <Text className="font-semibold" size="h5" variant="heading">
            New contract request
          </Text>
        </div>
        {(isContractShellLoading || isUserLoading) && (
          <div className="flex">
            <Skeleton className="h-10 w-24 mr-2" />
            <Skeleton className="h-10 w-24" />
          </div>
        )}
        {contractShell && (
          <div className="flex items-center flex-0">
            {contractApprover?.status === 'PENDING_APPROVAL' && contract && (
              <>
                <Button
                  state="translucent"
                  variant="secondary"
                  size="sm"
                  className="ml-6 mr-4"
                  isDisabled={isContractShellLoading || isUserLoading}
                  onClick={() => openModal(<ContractApprovalModal contract={contract} action="request-changes" approvalId={approval?._id} />)}
                >
                  Reject
                </Button>
                <Button
                  size="sm"
                  isDisabled={isContractShellLoading || isUserLoading}
                  onClick={() => openModal(<ContractApprovalModal contract={contract} action="approved" approvalId={approval?._id} />)}
                >
                  Approve
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </Header>
  )
})
