import React, { memo, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Icon } from '@cotiss/common/components/icon.component'
import { useGetWindowDimensions } from '@cotiss/common/hooks/use-get-window-dimensions.hook'

type Props = {
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
  children: React.ReactNode
}

export const AppSideNavWrapper = memo(({ isExpanded, setIsExpanded, children }: Props) => {
  const { windowWidth } = useGetWindowDimensions()

  useEffect(() => {
    // 1280px is tailwindcss `xl` breakpoint: https://tailwindcss.com/docs/responsive-design
    if (windowWidth < 1280) {
      setIsExpanded(false)
    }
    if (windowWidth >= 1280) {
      setIsExpanded(true)
    }
  }, [windowWidth])

  return (
    <div className="flex flex-col bg-white border-r border-gray-100 h-full">
      <div className="flex-grow">{children}</div>
      {isExpanded && (
        <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} className="flex items-center xl:hidden mb-4 justify-start ml-4">
          <Icon icon="chevron-left" className="hover:cursor-pointer" size={32} onClick={() => setIsExpanded(false)} />
        </motion.div>
      )}
      {!isExpanded && (
        <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} className="flex items-center xl:hidden mb-4 justify-center">
          <Icon icon="chevron-right" className="hover:cursor-pointer" size={32} onClick={() => setIsExpanded(true)} />
        </motion.div>
      )}
    </div>
  )
})
