import { gql } from '@apollo/client'
import { GqlEvaluationEnvelopeDocumentListInput, GqlEvaluationEnvelopeDocumentListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope-document/evaluation-envelope-document-fragment.graphql'

export const queryEvaluationEnvelopeDocumentList = async (input: GqlEvaluationEnvelopeDocumentListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeDocumentListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT}

      query EvaluationEnvelopeDocumentList($input: EvaluationEnvelopeDocumentListInput!) {
        evaluationEnvelopeDocumentList(input: $input) {
          items {
            ...EvaluationEnvelopeDocumentFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeDocumentList
}
