import React, { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { filter, forEach, map, uniqBy } from 'lodash'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Button } from '@cotiss/common/components/button.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { QueryKey } from '@cotiss/common/services/query.service'
import { utilService } from '@cotiss/common/services/util.service'
import { DocumentUploadItem } from '@cotiss/document/components/document-upload-item.component'
import {
  DOCUMENT_ALL_FILE_TYPES,
  DOCUMENT_ERROR_MESSAGES_MAP,
  DocumentFileType,
  SUPPORTED_DOCUMENT_ERROR_CODES,
} from '@cotiss/document/document.constants'
import { DocumentModel } from '@cotiss/document/document.models'
import { useMutateDocument } from '@cotiss/document/resources/use-mutate-document.resource'

const MAX_MB = 256
const DOCUMENT_UPLOAD_CANCEL_REASON = 'Document upload has been cancelled.'

export type FileUploadStatus = 'saving' | 'success' | 'error'
type FileUploadConfig = {
  id: string
  file?: File
  status: FileUploadStatus
  controller?: AbortController
  document?: DocumentModel
}

type Props = {
  className?: string
  documents?: DocumentModel[]
  onChange?: (documents: DocumentModel[]) => void
  onUpload?: (file: File) => Promise<void>
  onIsUploadingChange?: (isUploading: boolean) => void
  onRejectedSupportedError?: (errorCode: SUPPORTED_DOCUMENT_ERROR_CODES) => void
  maxMb?: number
  acceptedFileTypes?: DocumentFileType
  invalidateOnUpload?: QueryKey[]
  isSingle?: boolean
  isDisabled?: boolean
  showUploadedFiles?: boolean
}

export const DocumentUpload = memo((props: Props) => {
  const {
    className,
    documents,
    onChange,
    onUpload,
    onIsUploadingChange,
    onRejectedSupportedError,
    maxMb = MAX_MB,
    acceptedFileTypes = DOCUMENT_ALL_FILE_TYPES,
    isSingle,
    isDisabled,
    showUploadedFiles = true,
  } = props
  const isDropDisabled = isDisabled || Boolean(isSingle && documents?.length)
  const dropzoneClasses = classNames('flex flex-col items-center justify-center bg-primary-50 border border-dashed border-gray-300 rounded-lg p-5', {
    'cursor-pointer': !isDropDisabled,
    'cursor-not-allowed': isDropDisabled,
  })
  const classes = classNames(className)
  const { createDocument } = useMutateDocument()
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])
  const [fileConfigs, setFileConfigs] = useState<FileUploadConfig[]>(
    map(documents, (document) => ({ id: utilService.generateUid(), document, status: 'success' }))
  )
  const { getRootProps, getInputProps } = useDropzone({
    disabled: isDropDisabled,
    maxSize: maxMb * 1024 * 1024,
    multiple: !isSingle,
    accept: acceptedFileTypes,
    maxFiles: isSingle ? 1 : 0,
    onDrop: (droppedFiles, rejectedFiles = []) => {
      const fileConfigsToSave: FileUploadConfig[] = map(droppedFiles, (file) => ({
        id: utilService.generateUid(),
        status: 'saving',
        controller: new AbortController(),
        file,
      }))

      setFileConfigs([...fileConfigs, ...fileConfigsToSave])

      forEach(fileConfigsToSave, (fileConfig) => {
        handleUpload(fileConfig)
      })

      // Human readable error messages
      forEach(rejectedFiles, (rejectedFile) => {
        if (DOCUMENT_ERROR_MESSAGES_MAP[rejectedFile.errors[0].code]) {
          onRejectedSupportedError && onRejectedSupportedError(rejectedFile.errors[0].code as SUPPORTED_DOCUMENT_ERROR_CODES)
          rejectedFile.errors[0].message = DOCUMENT_ERROR_MESSAGES_MAP[rejectedFile.errors[0].code]
        }
      })

      setRejectedFiles(uniqBy(rejectedFiles, ({ errors }) => errors[0].message))
    },
  })

  useEffect(() => {
    if (!onChange && !onIsUploadingChange) {
      return
    }

    let isUploading = false
    const documents: DocumentModel[] = []
    forEach(fileConfigs, ({ status, document }) => {
      if (status === 'saving') {
        isUploading = true
      }

      if (status === 'success' && document) {
        documents.push(document)
      }
    })

    onChange && onChange(documents)
    onIsUploadingChange && onIsUploadingChange(isUploading)
  }, [fileConfigs])

  // Keep a ref of fileConfigs to be used in the cleanup function
  const fileConfigsRef = React.useRef<FileUploadConfig[]>(fileConfigs)

  // Update the ref when fileConfigs changes
  useEffect(() => {
    fileConfigsRef.current = fileConfigs
  }, [fileConfigs])

  // Cancel any un-uploaded files when the component is unmounted
  useEffect(() => {
    return () => {
      forEach(fileConfigsRef.current, (file) => {
        handleCancel(file)
      })
    }
  }, [])

  const handleUpload = async (fileConfig: FileUploadConfig) => {
    const { id, file, controller } = fileConfig

    if (!file) {
      setFileConfigs((prevFileConfigs) => uniqBy([{ ...fileConfig, status: 'error' }, ...prevFileConfigs], 'id'))
      return
    }

    try {
      if (onUpload) {
        await onUpload(file)
        setFileConfigs((prevFileConfigs) => uniqBy([{ ...fileConfig, status: 'success' }, ...prevFileConfigs], 'id'))
      } else {
        const document = await createDocument({ file, controller })

        setFileConfigs((prevFileConfigs) => uniqBy([{ ...fileConfig, document, status: 'success' }, ...prevFileConfigs], 'id'))
      }
    } catch (error: any) {
      if (typeof error === 'string' && error === DOCUMENT_UPLOAD_CANCEL_REASON) {
        setFileConfigs((prevFileConfigs) => filter(prevFileConfigs, (fileConfig) => id !== fileConfig.id))
      } else {
        setFileConfigs((prevFileConfigs) => uniqBy([{ ...fileConfig, status: 'error' }, ...prevFileConfigs], 'id'))
      }
    }
  }

  const handleCancel = (fileConfig: FileUploadConfig) => {
    const { id, status, controller } = fileConfig

    setFileConfigs((prevFileConfigs) => filter(prevFileConfigs, (fileConfig) => id !== fileConfig.id))

    if (status === 'saving' && controller) {
      return controller.abort(DOCUMENT_UPLOAD_CANCEL_REASON)
    }
  }

  return (
    <div className={classes}>
      <div className={dropzoneClasses} {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon icon="upload" variant="light" size={20} />
        <Text>Drag and drop a document, or</Text>
        <Tooltip_DEPRECATED tooltip="Only one document can be uploaded" width={200} isEnabled={isDropDisabled}>
          <Button className="mt-1" state="text" variant="link" isDisabled={isDropDisabled}>
            upload
          </Button>
        </Tooltip_DEPRECATED>
        <Text className="mt-1" variant="light" size="sm">
          Max {maxMb}MB
        </Text>
      </div>
      <div className="overflow-y-auto max-h-[200px]">
        {showUploadedFiles &&
          map(fileConfigs, (fileConfig) => (
            <DocumentUploadItem key={fileConfig.id} className="mt-2" {...fileConfig} onCancel={() => handleCancel(fileConfig)} />
          ))}
      </div>
      {map(rejectedFiles, (rejectedFile, index) => (
        <Text key={index} className="mt-2 break-words" variant="danger">
          {rejectedFile.errors[0].message}
        </Text>
      ))}
    </div>
  )
})
