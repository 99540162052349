import React from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { StepModel } from '@cotiss/common/components/steps.component'
import { Text } from '@cotiss/common/components/text.component'

type Props<T> = {
  step: StepModel<T>
  onClick?: (step: StepModel<T>) => void
  hasSeparator?: boolean
  isComplete?: boolean
  isActive?: boolean
}

export function StepItem<T>({ step, onClick, hasSeparator, isActive, isComplete }: Props<T>) {
  const textClasses = classNames('flex items-end grow text-center mb-2 mx-4', {
    'opacity-50': !isComplete && !isActive,
  })
  const indicatorClasses = classNames('flex items-center justify-center shrink-0 border-2 border-secondary-500 h-3.5 w-3.5 rounded-full', {
    'bg-white': isActive,
    'bg-secondary-500': !isActive,
    'opacity-20': !isActive && !isComplete,
  })
  const separatorClasses = classNames('absolute bottom-1.5 bg-secondary-500 h-0.5', {
    'opacity-20': !isComplete,
  })

  const renderContent = () => (
    <div className="relative flex flex-col items-center justify-between h-full">
      <Text className={textClasses} variant="secondary">
        {step.label}
      </Text>
      <div className={indicatorClasses} />
      {hasSeparator && <div className={separatorClasses} style={{ width: 'calc(100% - 14px)', left: `calc(50% + 7px)` }} />}
    </div>
  )

  if (onClick && isComplete) {
    return (
      <Button className="w-full" onClick={() => onClick(step)} state="raw">
        {renderContent()}
      </Button>
    )
  }

  return <div className="w-full">{renderContent()}</div>
}
