import React, { memo, useMemo, useState } from 'react'
import {
  GqlEvaluationCriteriaFieldsFragment,
  GqlEvaluationFieldsFragment,
  GqlEvaluationScoreFieldsFragment,
  GqlEvaluationUserFieldsFragment,
} from '@gql'
import { filter, find, includes, isNumber, map, some } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { EvaluationEventEvaluationStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-evaluation-status-badge.component'
import { EvaluationEventRatingScaleValueField } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-value-field.component'
import { EvaluationEventScoreComment } from '@cotiss/evaluation-event/components/evaluation-event-score-comment.component'
import { IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES } from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationScore } from '@cotiss/evaluation-event/hooks/use-evaluation-score.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { EvaluationEventCriteriaScoringDetailsModal } from '@cotiss/evaluation-event/modals/evaluation-event-criteria-scoring-details.modal'
import { userService } from '@cotiss/user/user.service'

type HandleScoreOverrideSubmitParam = {
  value: number
  evaluation?: GqlEvaluationFieldsFragment
  evaluationScore: GqlEvaluationScoreFieldsFragment
}

type Props = {
  className?: string
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  activeEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export const EvaluationEventModerationCriteriaReview = memo(({ className, parentEvaluationCriteriaItem, activeEvaluationCriteriaItem }: Props) => {
  const { openModal } = useCallout()
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(false)
  const { evaluationSubmission } = useEvaluationSubmission()
  const { evaluations, queryEvaluationList } = useEvaluation()
  const [evaluationScores, setEvaluationScores] = useState<GqlEvaluationScoreFieldsFragment[]>([])
  const { queryEvaluationScoreList, mutateUpdateEvaluationScore } = useEvaluationScore()
  const { evaluationEnvelope, evaluationEnvelopeOverview, queryEvaluationEnvelopeOverviewView } = useEvaluationEnvelope()

  const refreshScores = async () => {
    if (!evaluationEvent) {
      return
    }

    const { evaluationScores } = await queryEvaluationScoreList({
      filter: { evaluationEventId: evaluationEvent.id, evaluationCriteriaId: activeEvaluationCriteriaItem.id },
    })
    setEvaluationScores(evaluationScores)
  }

  useAsyncEffect(async () => {
    if (!evaluationEvent || !evaluationSubmission || !evaluationEnvelope) {
      return
    }

    setIsLoading(true)
    // TODO: We need to make sure we can paginate this data.
    await Promise.all([
      queryEvaluationList({
        filter: {
          evaluationEventId: evaluationEvent.id,
          evaluationEnvelopeId: evaluationEnvelope.id,
          evaluationSubmissionId: evaluationSubmission.id,
        },
      }),
      refreshScores(),
    ])
    setIsLoading(false)
  }, [evaluationEvent, evaluationSubmission, evaluationEnvelope, activeEvaluationCriteriaItem])

  const getEvaluationAndScore = (evaluationUser: GqlEvaluationUserFieldsFragment) => {
    const evaluation = find(evaluations, { evaluationUserId: evaluationUser.id })
    const evaluationScore = find(evaluationScores, { evaluationId: evaluation?.id })

    return { evaluation, evaluationScore }
  }

  const renderScoreDelta = (evaluationScore?: GqlEvaluationScoreFieldsFragment) => {
    if (
      !isNumber(evaluationScore?.moderationValue) ||
      !isNumber(evaluationScore?.value) ||
      evaluationScore?.moderationValue === evaluationScore?.value
    ) {
      return null
    }

    const delta = (evaluationScore?.moderationValue || 0) - (evaluationScore?.value || 0)

    return (
      <Text className="shrink-0" variant={delta > 0 ? 'success' : 'danger'}>
        <Icon className="mr-1" icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
        {Math.abs(delta).toFixed(0)}
      </Text>
    )
  }

  const handleScoreOverrideSubmit = async ({ value, evaluation, evaluationScore }: HandleScoreOverrideSubmitParam) => {
    if (!evaluationEvent || !evaluation || !activeEvaluationCriteriaItem || !evaluationEnvelope) {
      return
    }

    await mutateUpdateEvaluationScore({
      evaluationScoreId: evaluationScore.id,
      moderationValue: value,
    })

    const [{ evaluationScores }] = await Promise.all([
      queryEvaluationScoreList({ filter: { evaluationEventId: evaluationEvent.id, evaluationCriteriaId: activeEvaluationCriteriaItem.id } }),
      queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId: evaluationEnvelope.id }),
    ])

    setEvaluationScores(evaluationScores)
  }

  const { columns } = useMemo(() => {
    const submissionBreakdown = find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId: evaluationSubmission?.id })
    const evaluateEvaluationUsers = filter(evaluationUsers, ({ accessControls }) => {
      return some(accessControls, { resourceType: 'criteria', resourceId: parentEvaluationCriteriaItem?.id, access: 'score' })
    })

    const columns: TableColumn[] = [
      {
        heading: 'Evaluator',
        rows: map(evaluateEvaluationUsers, (evaluationUser) => ({
          content: () => (
            <Text className="truncate" title={userService.getFullName(evaluationUser.user)}>
              {userService.getFullName(evaluationUser.user)}
            </Text>
          ),
        })),
      },
      {
        heading: 'Score',
        thClassName: 'w-48',
        rows: map(evaluateEvaluationUsers, (evaluationUser) => {
          const { evaluation, evaluationScore } = getEvaluationAndScore(evaluationUser)

          if (evaluation?.status === 'abstained') {
            return {
              content: () => <EvaluationEventEvaluationStatusBadge status={evaluation.status} />,
            }
          }

          if (!evaluationScore) {
            return {
              content: () => <Text>--</Text>,
            }
          }

          return {
            content: () => (
              <div className="flex items-center justify-between">
                <EvaluationEventRatingScaleValueField
                  ratingScale={activeEvaluationCriteriaItem.ratingScale}
                  value={evaluationScore?.moderationValue || evaluationScore?.value || undefined}
                  onChange={(value) => handleScoreOverrideSubmit({ value, evaluation, evaluationScore })}
                  isDisabled={!evaluationEvent || evaluationEnvelope?.status !== 'moderate' || submissionBreakdown?.status === 'reviewed'}
                />
                {renderScoreDelta(evaluationScore)}
              </div>
            ),
          }
        }),
      },
      {
        heading: 'Evaluator comment',
        rows: map(evaluateEvaluationUsers, (evaluationUser) => {
          const { evaluationScore } = getEvaluationAndScore(evaluationUser)

          return {
            content: () => <Text size="sm">{evaluationScore?.comment || '--'}</Text>,
          }
        }),
      },
      {
        heading: 'Moderator comment',
        rows: map(evaluateEvaluationUsers, (evaluationUser) => {
          const { evaluation, evaluationScore } = getEvaluationAndScore(evaluationUser)

          if (evaluation?.status === 'abstained' || !evaluationScore) {
            return {
              content: () => <Text>--</Text>,
            }
          }

          return {
            content: () => (
              <EvaluationEventScoreComment
                evaluationCriteria={activeEvaluationCriteriaItem}
                evaluationScore={evaluationScore}
                evaluation={evaluation}
                onChange={() => refreshScores()}
                isEditable={Boolean(evaluationEvent && evaluationEnvelope?.status === 'moderate' && submissionBreakdown?.status !== 'reviewed')}
                isModeration
              />
            ),
          }
        }),
      },
    ]

    return { columns }
  }, [activeEvaluationCriteriaItem, evaluationUsers, evaluations, evaluationScores, evaluationEnvelopeOverview, evaluationSubmission])

  return (
    <div className={className}>
      <div className="bg-secondary-100 rounded p-3">
        {parentEvaluationCriteriaItem.id !== activeEvaluationCriteriaItem.id && (
          <Text className="mb-1" size="sm" variant="light">
            {parentEvaluationCriteriaItem.index}. {parentEvaluationCriteriaItem.content}
          </Text>
        )}
        <Text>
          {activeEvaluationCriteriaItem.parentEvaluationCriteriaId && `${parentEvaluationCriteriaItem.index}.`}
          {activeEvaluationCriteriaItem.index}. {activeEvaluationCriteriaItem.content}
        </Text>
      </div>
      <TableHeader className="flex items-center justify-between mt-4">
        <Text>Individual scores</Text>
        {(activeEvaluationCriteriaItem.supplementary ||
          includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, activeEvaluationCriteriaItem.ratingScale)) && (
          <Button
            onClick={() => openModal(<EvaluationEventCriteriaScoringDetailsModal evaluationCriteriaItem={activeEvaluationCriteriaItem} />)}
            state="text"
            variant="link"
            size="sm"
          >
            Scoring details
          </Button>
        )}
      </TableHeader>
      <Table columns={columns} isLoading={isLoading} />
    </div>
  )
})
