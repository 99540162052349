import React, { memo, useMemo, useState } from 'react'
import { find } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ContractDocumentAttachmentSignature } from '@cotiss/contract/contract.model'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractSignatureModal } from '@cotiss/contract/modals/contract-signature.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  signature: ContractDocumentAttachmentSignature
  contractShellId: string
}

export const ContractSignersEditDeleteCta = memo(({ signature, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateAttachmentSignaturesBulk } = useMutateContractShell()
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { existingSignatures, documentShell } = useMemo(() => {
    const documentShell = find(find(contractShell?.contracts, { status: 'DRAFTING' })?.documentShells, ({ type }) => type === 'CONTRACT')
    return { existingSignatures: documentShell?.attachments.length ? documentShell?.attachments[0].signatures : [], documentShell }
  }, [contractShell])

  const handleDelete = async (id: string) => {
    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const attachment = documentShell?.attachments.length ? documentShell?.attachments[0] : null

    if (!contractShell || !contract || !documentShell || !attachment) {
      return
    }

    const updatedSignatures = existingSignatures.filter((signature) => signature._id !== id)
    await updateAttachmentSignaturesBulk({
      contractShellId: contractShell._id,
      contractId: contract._id,
      documentShellId: documentShell._id,
      attachmentId: attachment?._id,
      body: { items: updatedSignatures },
    })
  }

  return (
    <>
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
        isDisabled={isLoading}
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="fixed top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(<ContractSignatureModal contractShellId={contractShellId} signature={signature} existingSignatures={existingSignatures} />)
          }
        >
          Edit
        </DropdownContent>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Delete"
                description="Are you sure you want to delete this signature?"
                onSubmit={() => handleDelete(signature._id)}
              />
            )
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </>
  )
})
