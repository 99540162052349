import { GqlCreateEvaluationEnvelopeSubmissionInput, GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput } from '@gql'
import { mutateCreateEvaluationEnvelopeSubmission } from '@cotiss/evaluation-event/graphql/evaluation-envelope-submission/mutate-create-evaluation-envelope-submission.graphql'
import { mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId } from '@cotiss/evaluation-event/graphql/evaluation-envelope-submission/mutate-delete-evaluation-envelope-submission-by-envelope-id-and-submission-id.graphql'

export const useEvaluationEnvelopeSubmission = () => {
  return {
    mutateCreateEvaluationEnvelopeSubmission: async (input: GqlCreateEvaluationEnvelopeSubmissionInput) => {
      const createdEvaluationEnvelopeSubmission = await mutateCreateEvaluationEnvelopeSubmission(input)

      return createdEvaluationEnvelopeSubmission
    },
    mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId: async (
      input: GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput
    ) => {
      await mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId(input)
    },
  }
}
