import React, { memo } from 'react'
import { Field } from '@cotiss/common/components/field.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { ConflictOfInterestTermsBackground } from '@cotiss/conflict-of-interest/components/conflict-of-interest-terms-background.component'
import { useGetConflictOfInterestTemplate } from '@cotiss/conflict-of-interest-template/resources/use-get-conflict-of-interest-template.resource'

type Props = {
  conflictOfInterestId?: string
  isReviewing?: boolean
}

export const ConflictOfInterestTerms = memo(({ conflictOfInterestId, isReviewing }: Props) => {
  const { conflictOfInterestTemplate, isLoading } = useGetConflictOfInterestTemplate()

  return (
    <>
      <Field className="mt-8" label="Background">
        <ConflictOfInterestTermsBackground conflictOfInterestId={conflictOfInterestId} />
      </Field>
      <Field
        className="mt-8"
        label="Terms"
        supplementary={
          isReviewing
            ? 'Read the following agreement terms carefully. Scroll to bottom to continue.'
            : 'Type or paste the conflict of interest terms here. When submitting a conflict of interest declaration, users will confirm they acknowledge these terms.'
        }
      >
        {!isLoading && <>{conflictOfInterestTemplate?.terms && <RichText value={conflictOfInterestTemplate.terms} />}</>}
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
      </Field>
    </>
  )
})
