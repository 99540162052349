import React, { memo, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { ComingSoonBanner } from '@cotiss/common/components/coming-soon-banner.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'
import { TENDER_TYPE_DESCRIPTION_MAP, TENDER_TYPE_NAME_MAP } from '@cotiss/tender/tender.constants'
import { TenderType } from '@cotiss/tender/tender.models'

type Props = {
  className?: string
  tenderType: TenderType
  onClick?: (tenderType: TenderType) => void
  isComingSoon?: boolean
  isDisabled?: boolean
}

export const TenderFlowCreateCard = memo(({ className, tenderType, onClick, isComingSoon, isDisabled }: Props) => {
  const classes = classNames(className, 'relative flex flex-col items-start justify-between bg-white rounded-lg shadow-lg p-4')
  const [isSaving, setIsSaving] = useState(false)

  const handleCLick = () => {
    if (!onClick) {
      return
    }

    setIsSaving(true)
    onClick(tenderType)
  }

  return (
    <div className={classes}>
      {isComingSoon && <ComingSoonBanner />}
      <div>
        <TenderTypeBadge tenderType={tenderType} />
        <Text className="font-semibold mt-4">{TENDER_TYPE_NAME_MAP[tenderType]}</Text>
        <Text className="mt-1" variant="light" size="sm">
          {TENDER_TYPE_DESCRIPTION_MAP[tenderType]}
        </Text>
      </div>
      {!isComingSoon && (
        <Button className="w-full mt-8" onClick={handleCLick} variant="secondary" size="sm" isLoading={isSaving} isDisabled={isDisabled && !isSaving}>
          Get started
          <Icon className="ml-1" icon="arrow-right" />
        </Button>
      )}
      {isComingSoon && (
        <Button className="w-full mt-8" variant="secondary" size="sm" isDisabled>
          Coming soon
        </Button>
      )}
    </div>
  )
})
