import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
}

export const TenderUpdateSkeleton = memo(({ className }: Props) => (
  <Skeleton className={classNames(className, 'bg-gray-200 p-4')} variant="none">
    <Skeleton className="bg-primary-200 h-1.5 w-1/6" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-1/2 mt-3" variant="none" />

    <Skeleton className="bg-primary-200 h-3 w-full mt-3" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-full mt-1" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-2/3 mt-1" variant="none" />

    <div className="flex items-center justify-between mt-2">
      <div className="flex">
        <Skeleton className="bg-primary-200 h-2 w-6 mr-2" variant="none" />
        <Skeleton className="bg-primary-200 h-2 w-6 mr-2" variant="none" />
        <Skeleton className="bg-primary-200 h-2 w-6 mr-2" variant="none" />
      </div>
      <Skeleton className="bg-primary-200 h-2 w-12" variant="none" />
    </div>
  </Skeleton>
))
