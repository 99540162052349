import React, { memo } from 'react'
import FourOhFourFolderSvg from '@assets/svg/404-folder.svg'
import { Badge } from '@cotiss/common/components/badge.component'
import { Page } from '@cotiss/common/components/page.component'
import { Section } from '@cotiss/common/components/section.component'
import { Text } from '@cotiss/common/components/text.component'

export const FourOhFourPage = memo(() => (
  <Page className="flex items-center justify-center">
    <Section className="text-center" isCentered>
      <FourOhFourFolderSvg className="mx-auto" />
      <Badge state="translucent" variant="secondary" shape="square">
        404 Error
      </Badge>
      <Text className="font-semibold text-center mt-6" size="h2" variant="heading">
        Sorry, there&apos;s nothing here
      </Text>
      <Text className="mt-2" variant="light">
        The page you are looking for is not available.
      </Text>
    </Section>
  </Page>
))
