import React, { memo, useMemo, useState } from 'react'
import { compact, find, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { EvaluationStatusBadge } from '@cotiss/evaluation/components/evaluation-status-badge.component'
import { EvaluationModel } from '@cotiss/evaluation/evaluation.models'
import { useListEvaluation } from '@cotiss/evaluation/resources/use-list-evaluation.resource'
import { TenderResponsePriceScoreConsensusStatusBadge } from '@cotiss/tender-response/components/tender-response-price-score-consensus-status-badge.component'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

type EvaluationViewSortKey = 'organisation' | 'status' | 'price'

type Props = {
  className?: string
  tenderId?: string
  onView?: (evaluation: EvaluationModel) => void
}

export const EvaluationList = memo(({ className, tenderId, onView }: Props) => {
  const { evaluations, isLoading: isEvaluationsLoading } = useListEvaluation({
    tenderId,
  })
  const { tenderResponseReports, isLoading: isTenderResponseReportsLoading } = useListTenderResponseReport(tenderId)
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId })
  const isLoading = isEvaluationsLoading || isTenderResponseReportsLoading || isTenderResponsesLoading
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<EvaluationViewSortKey>({ initialKey: 'organisation' })
  const listItems = useMemo(() => {
    const listItems = map(evaluations, (evaluation) => {
      const tenderResponse = find(tenderResponses, { _id: evaluation.tenderResponse })
      const tenderResponseReport = find(tenderResponseReports, { _id: evaluation.tenderResponse })
      const evaluationReport = find(tenderResponseReport?.evaluations, { _id: evaluation._id })

      if (!tenderResponse) {
        return null
      }

      return { evaluation, evaluationReport, tenderResponse, tenderResponseReport }
    })

    return compact(listItems)
  }, [evaluations, tenderResponses, tenderResponseReports])

  const { sortedListItems, pagination } = useMemo(() => {
    const result = listItems.sort((a, b) => {
      if (sortKey === 'organisation') {
        return sortService.sortString(a.tenderResponse.procurementResponse.supplier.name, b.tenderResponse.procurementResponse.supplier.name)
      } else if (sortKey === 'price') {
        return sortService.sortNumber(a.tenderResponse.totalPrice?.amount, b.tenderResponse.totalPrice?.amount)
      } else if (sortKey === 'status') {
        return sortService.sortString(a.evaluation.status, b.evaluation.status)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: sortedListItems, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { sortedListItems, pagination }
  }, [listItems, sortKey, sortDirection])

  const renderBadge = (evaluation: EvaluationModel, tenderResponse: TenderResponsePopulatedModel) => {
    if (tenderResponse?.priceScoreConsensusStatus) {
      return <TenderResponsePriceScoreConsensusStatusBadge status={tenderResponse.priceScoreConsensusStatus} />
    }

    if (evaluation) {
      return <EvaluationStatusBadge status={evaluation.status} />
    }
  }

  if (!isLoading && !sortedListItems.length) {
    return (
      <div className="flex items-center justify-center h-[calc(100%-64px)]">
        <NoDataPlaceholder label="Something has gone wrong, please contract support for assistance." />
      </div>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Name of organisation',
      onSort: () => onSort('organisation'),
      rows: map(sortedListItems, ({ tenderResponse, evaluation }) => ({
        content: () => <Text className="font-medium truncate">{tenderResponse.procurementResponse.supplier.name}</Text>,
        cta: (
          <Button onClick={onView ? () => onView(evaluation) : undefined} state="outline" variant="secondary" size="xs" isLink>
            View <Icon className="ml-1" icon="arrow-right" />
          </Button>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      onSort: () => onSort('status'),
      rows: map(sortedListItems, ({ tenderResponse, evaluation }) => ({
        content: () => <>{renderBadge(evaluation, tenderResponse)}</>,
      })),
    },
    {
      heading: 'Price',
      onSort: () => onSort('price'),
      rows: map(sortedListItems, ({ tenderResponse }) => ({
        content: () => <Text>{tenderResponse.priceScore?.toFixed(2) || '--'}</Text>,
      })),
    },
  ]

  return (
    <ScrollableTable
      className={className}
      fixedColumns={fixedColumns}
      columns={columns}
      isLoading={isLoading}
      pagination={pagination}
      onPageChange={setCurrentPage}
    />
  )
})
