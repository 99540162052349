import React, { FormEvent, memo, useMemo, useState } from 'react'
import { filter } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { UpdateContractPriceDurationBulkBody, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Input } from '@cotiss/common/components/input.component'

type FormData = {
  id: string
  length: number
  value: number
  variation: number
  index: number
  description: string
}

type Props = {
  milestoneId?: string
  contractShellId: string
}

const DESCRIPTION_MAX_LENGTH = 100

export const ContractWizardMilestoneModal = memo(({ milestoneId, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { updateContractMilestoneBulk } = useMutateContractShell()

  const { milestone, contract, isVariation } = useMemo(() => {
    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const milestone = milestoneId ? contract?.milestones.find((milestone) => milestone._id === milestoneId) : null
    return { milestone, contract, isVariation: Boolean(contract?.variationTypes.length) }
  }, [])

  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>(
    (() => {
      return {
        id: milestone?._id || crypto.randomUUID(),
        length: milestone?.length || 0,
        value: milestone?.value || 0,
        variation: milestone?.variation || 0,
        index: milestone?.index || 0,
        description: milestone?.description || '',
      }
    })()
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contract) {
      // TODO: something
      return
    }

    const existingMilestones = contract?.milestones.map((milestone) => {
      return {
        ...milestone,
        variation: milestone.variation || 0,
      }
    })

    const parsedMilestones: UpdateContractPriceDurationBulkBody['items'] = []

    // If we're adding a new milestone
    if (!existingMilestones?.find((pd) => pd._id === formData.id)) {
      parsedMilestones.push(...existingMilestones, {
        ...formData,
        index: existingMilestones.length,
        exercised: 0,
      })
    }

    const existingPriceDuration = existingMilestones?.find((rate) => rate._id === formData.id)

    // If we're updating an existing milestone
    if (existingPriceDuration) {
      parsedMilestones.push(...filter(existingMilestones, (pd) => pd._id !== formData.id), {
        ...formData,
        index: existingPriceDuration.index,
        exercised: existingPriceDuration.exercised ?? 0,
      })
    }

    try {
      setIsSaving(true)
      await updateContractMilestoneBulk(contractShellId, contract._id, { items: parsedMilestones })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${milestone ? 'Edit milestone' : 'Add new milestone'}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Length (months)</Label>
        <Input
          value={formData.length || ''}
          placeholder="Enter length"
          maxLength={5}
          onChange={({ target }) =>
            setFormData({
              ...formData,
              length: parseInt(target.value) || 0,
            })
          }
          isDisabled={isSaving}
        />
        <div className="grid grid-cols-2 gap-4">
          <div className="mt-6">
            <Label>{isVariation ? 'Initial value' : 'Value'}</Label>
            <Input
              value={formData.value ? formData.value.toLocaleString() : ''}
              placeholder="Enter value"
              onChange={({ target }) => setFormData({ ...formData, value: Number(target.value.replaceAll(',', '')) || 0 })}
              isDisabled={isSaving || isVariation}
            />
          </div>
          {isVariation && (
            <div className="mt-6">
              <Label>Variation</Label>
              <Input
                placeholder="Enter variation value"
                value={formData.variation.toLocaleString()}
                onChange={({ target }) => setFormData({ ...formData, variation: parseInt(target.value.replaceAll(',', '')) || 0 })}
                isDisabled={isSaving}
              />
            </div>
          )}
        </div>
        <div className="mt-6 mb-2">
          <Label className="flex items-baseline">
            Description <Text size="sm" variant="light" className="ml-1">{`(${formData.description.length}/${DESCRIPTION_MAX_LENGTH})`}</Text>
          </Label>
          <TextArea
            value={formData.description}
            rows={2}
            maxLength={DESCRIPTION_MAX_LENGTH}
            onChange={({ target }) => setFormData({ ...formData, description: target.value })}
            isDisabled={isSaving}
          />
          <Text className="mt-1" size="sm" variant="light">
            Max 100 characters
          </Text>
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
