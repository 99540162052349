import React, { FormEvent, memo, useState } from 'react'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'

export const EvaluationEventSubmissionUpdateModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationSubmission, mutateUpdateEvaluationSubmission } = useEvaluationSubmission()
  const [name, setName] = useState(evaluationSubmission?.name || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationSubmission) {
      return
    }

    track('evaluation_event_view_submissions_drawer_update_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationSubmission({ evaluationSubmissionId: evaluationSubmission.id, name })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update submission" isDisabled={isSaving} />
      <ModalContent>
        <Label>Submission name</Label>
        <Input value={name} onChange={({ target }) => setName(target.value)} placeholder="Enter submission name" isDisabled={isSaving} isRequired />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
