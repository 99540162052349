import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationViewHeader } from '@cotiss/evaluation/components/evaluation-view-header.component'
import { EvaluationView } from '@cotiss/evaluation/components/evaluation-view.component'
import { useGetEvaluation } from '@cotiss/evaluation/resources/use-get-evaluation.resource'

export const EvaluationViewPage = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { evaluation } = useGetEvaluation(id)

  const headerBreadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'evaluation' }),
      },
      {
        label: 'Individual evaluation',
      },
    ]
  }, [])

  const evaluationBreadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Supplier list',
        href: routerService.getHref('/evaluation/tender/:tenderId', { tenderId: evaluation?.tender._id || '' }),
        isLoading: !evaluation,
      },
    ]
  }, [evaluation])

  return (
    <Page>
      <EvaluationViewHeader tenderId={evaluation?.tender._id} breadcrumbs={headerBreadcrumbs} />
      <PageContent>
        <EvaluationView
          evaluationId={id}
          breadcrumbs={evaluationBreadcrumbs}
          isPriceVisible={Boolean(evaluation && !evaluation.tender.isTwoEnvelope)}
          isEditable
        />
      </PageContent>
    </Page>
  )
})
