import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ShortListPopulatedModel } from '@cotiss/short-list/short-list.models'

type Props = {
  recommendationId?: string
  limit?: number
  offset?: number
}

export const useListShortList = ({ recommendationId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ShortListPopulatedModel>>('/v2/short-lists', {
    params: { recommendationId, limit, offset },
    isEnabled: Boolean(recommendationId),
  })

  return { shortLists: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
