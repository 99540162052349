import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { AccountEmployeeNumberRange, AccountTenderExperience } from '@cotiss/account/account.models'

export const ACCOUNT_QUERY_KEYS = ['/v2/accounts', '/v2/users', '/v2/pending-users', '/v2/requested-users'] as const

export const ACCOUNT_EMPLOYEE_NUMBER_RANGE_OPTIONS: SelectOption_DEPRECATED<AccountEmployeeNumberRange>[] = [
  { value: '1-50', label: '1-50' },
  { value: '50-100', label: '50-100' },
  { value: '100-300', label: '100-300' },
  { value: '300+', label: '300+' },
]

export const ACCOUNT_TENDER_EXPERIENCE_OPTIONS: SelectOption_DEPRECATED<AccountTenderExperience>[] = [
  { value: 'never', label: 'Never' },
  { value: 'sometimes', label: 'Sometimes' },
  { value: 'often', label: 'Often' },
]

export const ACCOUNT_INDUSTRY_OPTIONS: SelectOption_DEPRECATED<string>[] = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Airlines/Aviation', label: 'Airlines/Aviation' },
  { value: 'Alternative Dispute Resolution', label: 'Alternative Dispute Resolution' },
  { value: 'Alternative Medicine', label: 'Alternative Medicine' },
  { value: 'Animation', label: 'Animation' },
  { value: 'Apparel & Fashion', label: 'Apparel & Fashion' },
  { value: 'Architecture & Planning', label: 'Architecture & Planning' },
  { value: 'Arts and Crafts', label: 'Arts and Crafts' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Aviation & Aerospace', label: 'Aviation & Aerospace' },
  { value: 'Banking', label: 'Banking' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Broadcast Media', label: 'Broadcast Media' },
  { value: 'Building Materials', label: 'Building Materials' },
  { value: 'Business Supplies and Equipment', label: 'Business Supplies and Equipment' },
  { value: 'Capital Markets', label: 'Capital Markets' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Civic & Social Organization', label: 'Civic & Social Organization' },
  { value: 'Civil Engineering', label: 'Civil Engineering' },
  { value: 'Commercial Real Estate', label: 'Commercial Real Estate' },
  { value: 'Computer & Network Security', label: 'Computer & Network Security' },
  { value: 'Computer Games', label: 'Computer Games' },
  { value: 'Computer Hardware', label: 'Computer Hardware' },
  { value: 'Computer Networking', label: 'Computer Networking' },
  { value: 'Computer Software', label: 'Computer Software' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Consumer Goods', label: 'Consumer Goods' },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Cosmetics', label: 'Cosmetics' },
  { value: 'Dairy', label: 'Dairy' },
  { value: 'Defense & Space', label: 'Defense & Space' },
  { value: 'Design', label: 'Design' },
  { value: 'Education Management', label: 'Education Management' },
  { value: 'E-Learning', label: 'E-Learning' },
  { value: 'Electrical/Electronic Manufacturing', label: 'Electrical/Electronic Manufacturing' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Events Services', label: 'Events Services' },
  { value: 'Executive Office', label: 'Executive Office' },
  { value: 'Facilities Services', label: 'Facilities Services' },
  { value: 'Farming', label: 'Farming' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Fine Art', label: 'Fine Art' },
  { value: 'Fishery', label: 'Fishery' },
  { value: 'Food & Beverages', label: 'Food & Beverages' },
  { value: 'Food Production', label: 'Food Production' },
  { value: 'Fund-Raising', label: 'Fund-Raising' },
  { value: 'Furniture', label: 'Furniture' },
  { value: 'Gambling & Casinos', label: 'Gambling & Casinos' },
  { value: 'Glass, Ceramics & Concrete', label: 'Glass, Ceramics & Concrete' },
  { value: 'Government Administration', label: 'Government Administration' },
  { value: 'Government Relations', label: 'Government Relations' },
  { value: 'Graphic Design', label: 'Graphic Design' },
  { value: 'Health, Wellness and Fitness', label: 'Health, Wellness and Fitness' },
  { value: 'Higher Education', label: 'Higher Education' },
  { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Import and Export', label: 'Import and Export' },
  { value: 'Individual & Family Services', label: 'Individual & Family Services' },
  { value: 'Industrial Automation', label: 'Industrial Automation' },
  { value: 'Information Services', label: 'Information Services' },
  { value: 'Information Technology and Services', label: 'Information Technology and Services' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'International Affairs', label: 'International Affairs' },
  { value: 'International Trade and Development', label: 'International Trade and Development' },
  { value: 'Investment Banking', label: 'Investment Banking' },
  { value: 'Investment Management', label: 'Investment Management' },
  { value: 'Judiciary', label: 'Judiciary' },
  { value: 'Law Enforcement', label: 'Law Enforcement' },
  { value: 'Law Practice', label: 'Law Practice' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Legislative Office', label: 'Legislative Office' },
  { value: 'Leisure, Travel & Tourism', label: 'Leisure, Travel & Tourism' },
  { value: 'Libraries', label: 'Libraries' },
  { value: 'Logistics and Supply Chain', label: 'Logistics and Supply Chain' },
  { value: 'Luxury Goods & Jewelry', label: 'Luxury Goods & Jewelry' },
  { value: 'Machinery', label: 'Machinery' },
  { value: 'Management Consulting', label: 'Management Consulting' },
  { value: 'Maritime', label: 'Maritime' },
  { value: 'Market Research', label: 'Market Research' },
  { value: 'Marketing and Advertising', label: 'Marketing and Advertising' },
  { value: 'Mechanical or Industrial Engineering', label: 'Mechanical or Industrial Engineering' },
  { value: 'Media Production', label: 'Media Production' },
  { value: 'Medical Devices', label: 'Medical Devices' },
  { value: 'Medical Practice', label: 'Medical Practice' },
  { value: 'Mental Health Care', label: 'Mental Health Care' },
  { value: 'Military', label: 'Military' },
  { value: 'Mining & Metals', label: 'Mining & Metals' },
  { value: 'Motion Pictures and Film', label: 'Motion Pictures and Film' },
  { value: 'Museums and Institutions', label: 'Museums and Institutions' },
  { value: 'Music', label: 'Music' },
  { value: 'Nanotechnology', label: 'Nanotechnology' },
  { value: 'Newspapers', label: 'Newspapers' },
  { value: 'Non-Profit Organization Management', label: 'Non-Profit Organization Management' },
  { value: 'Oil & Energy', label: 'Oil & Energy' },
  { value: 'Online Media', label: 'Online Media' },
  { value: 'Outsourcing/Offshoring', label: 'Outsourcing/Offshoring' },
  { value: 'Package/Freight Delivery', label: 'Package/Freight Delivery' },
  { value: 'Packaging and Containers', label: 'Packaging and Containers' },
  { value: 'Paper & Forest Products', label: 'Paper & Forest Products' },
  { value: 'Performing Arts', label: 'Performing Arts' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Philanthropy', label: 'Philanthropy' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Plastics', label: 'Plastics' },
  { value: 'Political Organization', label: 'Political Organization' },
  { value: 'Primary/Secondary Education', label: 'Primary/Secondary Education' },
  { value: 'Printing', label: 'Printing' },
  { value: 'Professional Training & Coaching', label: 'Professional Training & Coaching' },
  { value: 'Program Development', label: 'Program Development' },
  { value: 'Property', label: 'Property' },
  { value: 'Public Policy', label: 'Public Policy' },
  { value: 'Public Relations and Communications', label: 'Public Relations and Communications' },
  { value: 'Public Safety', label: 'Public Safety' },
  { value: 'Publishing', label: 'Publishing' },
  { value: 'Railroad Manufacture', label: 'Railroad Manufacture' },
  { value: 'Ranching', label: 'Ranching' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Recreational Facilities and Services', label: 'Recreational Facilities and Services' },
  { value: 'Religious Institutions', label: 'Religious Institutions' },
  { value: 'Renewables & Environment', label: 'Renewables & Environment' },
  { value: 'Research', label: 'Research' },
  { value: 'Restaurants', label: 'Restaurants' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Security and Investigations', label: 'Security and Investigations' },
  { value: 'Semiconductors', label: 'Semiconductors' },
  { value: 'Shipbuilding', label: 'Shipbuilding' },
  { value: 'Sporting Goods', label: 'Sporting Goods' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Staffing and Recruiting', label: 'Staffing and Recruiting' },
  { value: 'Supermarkets', label: 'Supermarkets' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Textiles', label: 'Textiles' },
  { value: 'Think Tanks', label: 'Think Tanks' },
  { value: 'Tobacco', label: 'Tobacco' },
  { value: 'Translation and Localization', label: 'Translation and Localization' },
  { value: 'Transportation/Trucking/Railroad', label: 'Transportation/Trucking/Railroad' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Venture Capital & Private Equity', label: 'Venture Capital & Private Equity' },
  { value: 'Veterinary', label: 'Veterinary' },
  { value: 'Warehousing', label: 'Warehousing' },
  { value: 'Wholesale', label: 'Wholesale' },
  { value: 'Wine and Spirits', label: 'Wine and Spirits' },
  { value: 'Wireless', label: 'Wireless' },
  { value: 'Writing and Editing', label: 'Writing and Editing' },
]
