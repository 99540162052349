import { gql } from '@apollo/client'
import { GqlEvaluationEnvelopeViewInput, GqlEvaluationEnvelopeViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-fragment.graphql'

export const queryEvaluationEnvelopeView = async (input: GqlEvaluationEnvelopeViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEnvelopeViewQuery>({
    query: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      query EvaluationEnvelopeView($input: EvaluationEnvelopeViewInput!) {
        evaluationEnvelopeView(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEnvelopeView
}
