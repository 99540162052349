import React, { memo, ReactNode } from 'react'
import classNames from 'classnames'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
  children?: ReactNode
  label: ReactNode
  supplementary?: ReactNode
  isFullWidth?: boolean
}

export const Field = memo(({ className, children, label, supplementary, isFullWidth }: Props) => {
  const classes = classNames(className, 'max-w-5xl flex items-start justify-between')

  return (
    <div className={classes}>
      <div className="shrink-0 w-1/3 mr-8">
        <Label>{label}</Label>
        {supplementary && (
          <Text className="mt-1" variant="light" size="sm">
            {supplementary}
          </Text>
        )}
      </div>
      <div className="w-2/3 min-w-0">
        <div className={classNames({ 'max-w-md': !isFullWidth })}>{children}</div>
      </div>
    </div>
  )
})
