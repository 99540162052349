import { gql } from '@apollo/client'
import { GqlPerformanceScorecardUserInSessionViewInput, GqlPerformanceScorecardUserInSessionViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardUserInSessionView = async (input: GqlPerformanceScorecardUserInSessionViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserInSessionViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserInSessionView($input: PerformanceScorecardUserInSessionViewInput!) {
        performanceScorecardUserInSessionView(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserInSessionView
}
