import { gql } from '@apollo/client'
import { GqlPerformanceScorecardUserViewInput, GqlPerformanceScorecardUserViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardUserView = async (input: GqlPerformanceScorecardUserViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserView($input: PerformanceScorecardUserViewInput!) {
        performanceScorecardUserView(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserView
}
