import React, { memo, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  hasPadding?: boolean
}

export const DrawerContent = memo(({ className, children, hasPadding = true }: Props) => {
  const classes = classNames(className, 'flex-1 overflow-y-auto', {
    'p-6': hasPadding,
  })

  return <div className={classes}>{children}</div>
})
