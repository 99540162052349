import React, { memo } from 'react'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria/tender-criteria.models'
import { TenderQuestionScoreGraph } from '@cotiss/tender-question/components/tender-question-score-graph.component'
import { useListTenderQuestionScore } from '@cotiss/tender-question/resources/use-list-tender-question-score.resource'
import { TenderQuestionReportModel } from '@cotiss/tender-question/tender-question.models'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  tenderQuestionId: string
  tenderResponseId: string
  tenderCriterionReport: TenderCriterionReportModel
  tenderQuestionReport: TenderQuestionReportModel
}

export const TenderQuestionReportQuestion = memo(({ tenderQuestionId, tenderResponseId, tenderCriterionReport, tenderQuestionReport }: Props) => {
  const { tenderQuestionScores } = useListTenderQuestionScore({ tenderQuestionId, tenderResponseId, evaluationStatus: 'completed' })

  return (
    <>
      <Text className="uppercase" variant="light" size="sm">
        Criteria {tenderCriterionReport.index + 1}.
      </Text>
      <Text className="font-semibold mt-1">{tenderCriterionReport.name}</Text>
      <Hr className="my-4" />
      <Text className="uppercase" variant="light" size="sm">
        Question {tenderCriterionReport.index + 1}.{tenderQuestionReport.index + 1}.
      </Text>
      <Text className="whitespace-pre-wrap mt-1" size="sm">
        {tenderQuestionReport.question}
      </Text>
      <TenderQuestionScoreGraph tenderQuestionScores={tenderQuestionScores} />
    </>
  )
})
