import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
}

export const KanbanCardSkeleton = memo(({ className }: Props) => (
  <Skeleton className={classNames(className, 'inline-block shrink-0 bg-gray-200 w-full p-2 mb-1 last:mb-0')} variant="none">
    <Skeleton className="bg-primary-200 h-4 w-1/2" variant="none" />
    <Skeleton className="bg-primary-200 h-3 w-2/3 mt-2" variant="none" />
  </Skeleton>
))
