import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardDocumentInput,
  GqlCreatePerformanceScorecardDocumentMutation,
  GqlCreatePerformanceScorecardDocumentMutationVariables,
  GqlPerformanceScorecardDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-document/performance-scorecard-document-fragment.graphql'

export const mutateCreatePerformanceScorecardDocument = async (input: GqlCreatePerformanceScorecardDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardDocumentMutation, GqlCreatePerformanceScorecardDocumentMutationVariables>({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_DOCUMENT_FRAGMENT}

      mutation CreatePerformanceScorecardDocument($input: CreatePerformanceScorecardDocumentInput!) {
        createPerformanceScorecardDocument(input: $input) {
          ...PerformanceScorecardDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardDocument as GqlPerformanceScorecardDocumentFieldsFragment
}
