import { gql } from '@apollo/client'
import { GqlUpdateEvaluationInput, GqlUpdateEvaluationMutation, GqlUpdateEvaluationMutationVariables, GqlEvaluationFieldsFragment } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation/evaluation-fragment.graphql'

export const mutateUpdateEvaluation = async (input: GqlUpdateEvaluationInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationMutation, GqlUpdateEvaluationMutationVariables>({
    mutation: gql`
      ${EVALUATION_FRAGMENT}

      mutation UpdateEvaluation($input: UpdateEvaluationInput!) {
        updateEvaluation(input: $input) {
          ...EvaluationFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluation as GqlEvaluationFieldsFragment
}
