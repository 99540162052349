import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationCriteriaInput,
  GqlCreateEvaluationCriteriaMutation,
  GqlCreateEvaluationCriteriaMutationVariables,
  GqlEvaluationCriteriaFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_CRITERIA_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-criteria/evaluation-criteria-fragment.graphql'

export const mutateCreateEvaluationCriteria = async (input: GqlCreateEvaluationCriteriaInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationCriteriaMutation, GqlCreateEvaluationCriteriaMutationVariables>({
    mutation: gql`
      ${EVALUATION_CRITERIA_FRAGMENT}

      mutation CreateEvaluationCriteria($input: CreateEvaluationCriteriaInput!) {
        createEvaluationCriteria(input: $input) {
          ...EvaluationCriteriaFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationCriteria as GqlEvaluationCriteriaFieldsFragment
}
