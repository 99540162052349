import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEnvelopeInput,
  GqlCreateEvaluationEnvelopeMutation,
  GqlCreateEvaluationEnvelopeMutationVariables,
  GqlEvaluationEnvelopeFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope/evaluation-envelope-fragment.graphql'

export const mutateCreateEvaluationEnvelope = async (input: GqlCreateEvaluationEnvelopeInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEnvelopeMutation, GqlCreateEvaluationEnvelopeMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_FRAGMENT}

      mutation CreateEvaluationEnvelope($input: CreateEvaluationEnvelopeInput!) {
        createEvaluationEnvelope(input: $input) {
          ...EvaluationEnvelopeFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEnvelope as GqlEvaluationEnvelopeFieldsFragment
}
