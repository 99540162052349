import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ProcurementResponsePopulatedModel } from '@cotiss/procurement-response/procurement-response.models'

type Props = {
  organisationId?: string
  procurementId?: string
  limit?: number
  offset?: number
}

export const useListProcurementResponse = ({ organisationId, procurementId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ProcurementResponsePopulatedModel>>('/v2/procurement-response', {
    route: `/v2/procurement-response`,
    params: { organisationId, procurementId, offset, limit },
    isEnabled: Boolean(organisationId) || Boolean(procurementId),
  })

  return { procurementResponses: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
