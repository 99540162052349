import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationAccessControlsInput,
  GqlCreateEvaluationAccessControlsMutation,
  GqlCreateEvaluationAccessControlsMutationVariables,
  GqlEvaluationAccessControlFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'

export const mutateCreateEvaluationAccessControls = async (input: GqlCreateEvaluationAccessControlsInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationAccessControlsMutation, GqlCreateEvaluationAccessControlsMutationVariables>({
    mutation: gql`
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation CreateEvaluationAccessControls($input: CreateEvaluationAccessControlsInput!) {
        createEvaluationAccessControls(input: $input) {
          ...EvaluationAccessControlFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationAccessControls as GqlEvaluationAccessControlFieldsFragment[]
}
