import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { TenderEvaluationMethodology, TenderRatingScale, TenderType, TenderTypeAbbreviated, TenderVisibility } from '@cotiss/tender/tender.models'

export const TENDER_QUERY_KEYS = ['/v2/tenders'] as const

export const TENDER_TYPES: TenderType[] = [
  'request_for_proposal',
  'request_for_quotation',
  'request_for_information',
  'expression_of_interest',
  'future_procurement_opportunity',
  'invitation_to_qualify',
]

export const TENDER_TYPE_NAME_MAP: Record<TenderType, string> = {
  request_for_proposal: 'Request for Proposal',
  request_for_quotation: 'Request for Quotation',
  request_for_information: 'Request for Information',
  expression_of_interest: 'Expression of Interest',
  future_procurement_opportunity: 'Future Procurement Opportunity',
  invitation_to_qualify: 'Invitation to Qualify',
}

export const TENDER_TYPE_ABBREVIATION_MAP: Record<TenderType, TenderTypeAbbreviated> = {
  request_for_proposal: 'RFP',
  request_for_quotation: 'RFQ',
  request_for_information: 'RFI',
  expression_of_interest: 'EOI',
  future_procurement_opportunity: 'FPO',
  invitation_to_qualify: 'ITQ',
}

export const TENDER_TYPE_DESCRIPTION_MAP: Record<TenderType, string> = {
  request_for_proposal: 'Request formal proposals from potential suppliers.',
  request_for_quotation: 'Request quotes for goods or services from potential suppliers.',
  request_for_information: 'Request information without formal proposals from potential suppliers.',
  expression_of_interest: 'Request expressions of interest without formal proposals from potential suppliers.',
  future_procurement_opportunity: 'Notify potential suppliers of a Future Procurement Opportunity.',
  invitation_to_qualify: 'Request submissions to qualify for panels or to qualify for future procurements from potential suppliers.',
}

export const EVALUATION_METHODOLOGY_NAME_MAP: Record<TenderEvaluationMethodology, string> = {
  simpleScore: 'Simple Score',
  lowestPrice: 'Lowest Price',
  weightedAttributePriceWeighted: 'Weighed Attribute',
  weightedAttributeNotWeighted: 'Weighted Attribute',
  targetPrice: 'Target Price',
  brooksLaw: 'Brooks Law',
  priceQuality: 'Price Quality',
}

type TenderEvaluationMethodologyOption = {
  value: TenderEvaluationMethodology
  name: string
  description: string
  isDisabled: boolean
}

export const EVALUATION_METHODOLOGY_OPTIONS: TenderEvaluationMethodologyOption[] = [
  {
    value: 'weightedAttributePriceWeighted',
    name: EVALUATION_METHODOLOGY_NAME_MAP['weightedAttributePriceWeighted'],
    description: `This is the most common methodology for tendering. Define your criteria and give each one it's own weighting.`,
    isDisabled: false,
  },
  {
    value: 'simpleScore',
    name: EVALUATION_METHODOLOGY_NAME_MAP['simpleScore'],
    description: '(Coming Soon)',
    isDisabled: true,
  },
  {
    value: 'lowestPrice',
    name: EVALUATION_METHODOLOGY_NAME_MAP['lowestPrice'],
    description: '(Coming Soon)',
    isDisabled: true,
  },
  {
    value: 'brooksLaw',
    name: EVALUATION_METHODOLOGY_NAME_MAP['brooksLaw'],
    description: '(Coming Soon)',
    isDisabled: true,
  },
  {
    value: 'priceQuality',
    name: EVALUATION_METHODOLOGY_NAME_MAP['priceQuality'],
    description: '(Coming Soon)',
    isDisabled: true,
  },
]

export const RATING_SCALE_NAME_MAP: Record<TenderRatingScale, string> = {
  zeroToFive: 'Zero to five (0, 1, 2, ... 5 points)',
  zeroToTen: 'Zero to ten (0, 1, 2, ... 10 points)',
}

export const RATING_SCALE_OPTIONS: SelectOption_DEPRECATED<TenderRatingScale>[] = [
  { value: 'zeroToFive', label: RATING_SCALE_NAME_MAP['zeroToFive'] },
  { value: 'zeroToTen', label: RATING_SCALE_NAME_MAP['zeroToTen'] },
]

export const RATING_SCALE_VALUE_MAP: Record<TenderRatingScale, { min: number; max: number }> = {
  zeroToFive: { min: 0, max: 5 },
  zeroToTen: { min: 0, max: 10 },
}

export const TENDER_VISIBILITY_NAME_MAP: Record<TenderVisibility, string> = {
  public: 'Public: Display on your portal',
  private: 'Private: Invite only',
}

export const TENDER_VISIBILITY_OPTIONS: SelectOption_DEPRECATED<TenderVisibility>[] = [
  { value: 'public', label: TENDER_VISIBILITY_NAME_MAP['public'] },
  { value: 'private', label: TENDER_VISIBILITY_NAME_MAP['private'] },
]
