import React, { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { ProcessedNonPriceCriterionWithQuestionsItem } from '@cotiss/tender/tender.service'
import { TenderCriterionModel } from '@cotiss/tender-criteria/tender-criteria.models'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

export type TenderQuestionFormData = {
  criterionId: string
  questionId?: string
  question: string
  weight: string
}

type Props = {
  className?: string
  tenderCriterion: TenderCriterionModel
  tenderQuestion: TenderQuestionFormData
  processedNonPriceCriteria: ProcessedNonPriceCriterionWithQuestionsItem
  index: number
  onQuestionUpdate: (indexToUpdate: number, question: TenderQuestionFormData) => void
  onQuestionRemove: (indexToRemove: number, criterionId: string) => void
  isEditing?: boolean
  isDisabled?: boolean
}

export const TenderQuestionAccordionItem = memo((props: Props) => {
  const { className, tenderCriterion, tenderQuestion, processedNonPriceCriteria, index, onQuestionUpdate, onQuestionRemove, isEditing, isDisabled } =
    props
  const isLast = index === processedNonPriceCriteria.questions.length - 1
  const tableCellClasses = classNames('align-top border-t border-gray-200 px-4 py-2', {
    'border-b': isLast,
  })

  // TODO: Swap this out with the AccordionToggle and AccordionContent components.

  return (
    <tr className={className}>
      <td />
      <td className={classNames(tableCellClasses, 'border-l')}>
        {!isEditing && (
          <div className="flex">
            <Text className="mr-3">
              {tenderCriterion.index + 1}.{index + 1}
            </Text>
            <Text className="whitespace-pre-wrap">{tenderQuestion.question}</Text>
          </div>
        )}
        {isEditing && (
          <TextArea
            className="text-sm"
            value={tenderQuestion.question}
            onChange={({ target }) => onQuestionUpdate(index, { ...tenderQuestion, question: target.value })}
            rows={2}
            isDisabled={isDisabled}
            isRequired
            resize="vertical"
          />
        )}
      </td>
      <td className={tableCellClasses}>
        {!isEditing && <Text>{tenderQuestion.weight}</Text>}
        {isEditing && (
          <Input
            className="text-sm w-16"
            value={tenderQuestion.weight}
            onChange={({ target }) => onQuestionUpdate(index, { ...tenderQuestion, weight: target.value })}
            size="sm"
            type="number"
            min={1}
            max={100}
            isDisabled={isDisabled}
            isRequired
          />
        )}
      </td>
      <td className={tableCellClasses}>
        <Text>{processedNonPriceCriteria.questions[index]?.percentage}</Text>
      </td>
      <td className={tableCellClasses}>
        <Text className="whitespace-nowrap" variant="link">
          {processedNonPriceCriteria.questions[index]?.overallPercentage}
        </Text>
        <Text className="whitespace-nowrap" size="sm" variant="light">
          ({utilService.formatAsPercentage(processedNonPriceCriteria.questions[index]?.weight * 100, 0) || 0} x{' '}
          {utilService.formatAsPercentage(processedNonPriceCriteria.weight * 100, 0) || 0})
        </Text>
      </td>
      <td className={classNames(tableCellClasses, 'border-r')}>
        {isEditing && (
          <Button className="justify-self-end" onClick={() => onQuestionRemove(index, tenderCriterion._id)} shape="square" size="sm" state="ghost">
            <Icon icon="x-close" variant="light" />
          </Button>
        )}
      </td>
    </tr>
  )
})
