import React, { memo, useState } from 'react'
import { Input } from '@cotiss/common/components/input.component'
import { MetafieldHyperlinkModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type Props = {
  className?: string
  metafield: MetafieldHyperlinkModel
  metafieldValue?: MetafieldValueModel
  onChange: (value: any) => void
}

export const MetafieldFormHyperlink = memo(({ className, metafield, metafieldValue, onChange }: Props) => {
  const [formData, setFormData] = useState(metafieldValue?.fieldValue?.value || '')

  const handleChange = (value: string) => {
    setFormData(value)
    onChange({ value })
  }

  return (
    <Input
      className={className}
      value={formData}
      onChange={({ target }) => handleChange(target.value)}
      placeholder={metafield.validation?.placeholder || metafield.fieldLabel}
      isRequired={metafield.isRequired}
      autoFocus
    />
  )
})
