import React from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'

type Props = {
  isDisabled?: boolean
  isLoading?: boolean
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onClear: () => void
  placeholder?: string
  className?: string
}

export const SearchInput = ({ isDisabled, value, onChange, placeholder, className, onClear }: Props) => {
  return (
    <div className={classNames('relative flex items-center', className)}>
      <Input
        className="pl-0 pr-0 flex-1 text-sm focus-within:ring-transparent"
        leftSlot={<Icon icon="search-sm" variant="neutral" size={16} />}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        state="ghost"
        isDisabled={isDisabled}
      />
      {value ? (
        <Button onClick={onClear} state="ghost" shape="square" size="sm" isDisabled={isDisabled}>
          <Icon icon="x-close" size={16} />
        </Button>
      ) : null}
    </div>
  )
}
