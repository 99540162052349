import React, { FormEvent, memo, useState } from 'react'
import { RemirrorJSON } from 'remirror'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { ConflictOfInterestTermsBackground } from '@cotiss/conflict-of-interest/components/conflict-of-interest-terms-background.component'
import { ConflictOfInterestTerms } from '@cotiss/conflict-of-interest/components/conflict-of-interest-terms.component'
import { useGetConflictOfInterestTemplate } from '@cotiss/conflict-of-interest-template/resources/use-get-conflict-of-interest-template.resource'
import { useMutateConflictOfInterestTemplate } from '@cotiss/conflict-of-interest-template/resources/use-mutate-conflict-of-interest-template.resource'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

type FormData = {
  terms: RemirrorJSON
}

export const SettingsConflictOfInterestTemplateTab = memo(() => {
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { conflictOfInterestTemplate } = useGetConflictOfInterestTemplate()
  const { updateConflictOfInterestTemplate } = useMutateConflictOfInterestTemplate()
  const [formData, setFormData] = useState<FormData>({
    terms: conflictOfInterestTemplate?.terms || richTextService.getEmptyRemirrorJson(),
  })

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({
      terms: conflictOfInterestTemplate?.terms || richTextService.getEmptyRemirrorJson(),
    })
    setIsEditing(isEditing)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateConflictOfInterestTemplate(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Field className="mt-8" label="Background">
            <ConflictOfInterestTermsBackground />
          </Field>
          <Field
            className="mt-8"
            label="Terms"
            supplementary="Type or paste the conflict of interest terms here. When submitting a conflict of interest declaration, users will confirm they acknowledge these terms."
          >
            <RichTextEditor value={formData.terms} onChange={(terms) => setFormData({ ...formData, terms })} isDisabled={isSaving} />
          </Field>
        </>
      )
    }

    return <ConflictOfInterestTerms />
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader
          heading="Conflict of interest template"
          onToggleEdit={handleToggleEdit}
          isEditable={permissions.isAdmin || permissions.hasLegalAccess}
          isEditing={isEditing}
          isSaving={isSaving}
        />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
