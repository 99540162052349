import React, { memo, useMemo } from 'react'
import { find, map, upperFirst } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { userService } from '@cotiss/user/user.service'

type Props = {
  contractId: string
  contractShellId: string
  isApproval?: boolean
}

export const ContractSummaryTermsAndMetadataTab = memo(({ contractId, contractShellId, isApproval }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { metafields } = useListMetafield({ entityType: 'CONTRACT' })
  const { metafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShell?.contracts, ({ _id }) => _id),
  })

  const { history, isVariation } = useMemo(() => {
    const contract = find(contractShell?.contracts, { _id: contractId })

    if (contract === undefined || contractShell === undefined) {
      return { history: [], isVariation: false }
    }

    const isVariation = contract.version > 1

    const history = contractService.getContractShellAuditHistory({
      contractShell,
      filterType: isApproval ? 'approval' : 'unapproved-compare',
      metafields,
      metafieldValues,
      contractId,
    })

    return { history, isVariation }
  }, [contractShell, metafieldValues])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Activity',
      rows: map(history, ({ type, value, fieldName }) => ({
        content: () => (
          <div className="flex items-center">
            <Icon className="mr-4" variant="secondary" icon={type === 'added' ? 'circle-add' : 'refresh-02'} />
            <div className="w-full truncate">
              <Text>{`${upperFirst(fieldName.replaceAll('-', ' '))} ${type}`}</Text>
              <Text variant="light" size="sm" className="w-full truncate" title={value}>
                {value}
              </Text>
            </div>
          </div>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Actioned by',
      rows: map(history, ({ actionedBy }) => ({
        content: () => (
          <Text className="truncate w-40" title={userService.getFullName(actionedBy)}>
            {userService.getFullName(actionedBy)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Timestamp',
      rows: map(history, ({ timestamp }) => ({
        content: () => <Text>{datetimeService.format(timestamp, 'd MMMM yyyy h:mm aaa')}</Text>,
      })),
    },
  ]

  if (isLoading) {
    return (
      <div className="p-6">
        <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
      </div>
    )
  }

  if (!history.length) {
    return <NoDataPlaceholder illustration="dot-list" label={isVariation ? 'No terms or metadata updated' : 'No terms or metadata added'} />
  }

  return <ScrollableTable fixedColumnsWidth={360} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
})
