import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricCycleDocumentInput,
  GqlCreatePerformanceScorecardMetricCycleDocumentMutation,
  GqlCreatePerformanceScorecardMetricCycleDocumentMutationVariables,
  GqlPerformanceScorecardMetricCycleDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/performance-scorecard-metric-cycle-document-fragment.graphql'

export const mutateCreatePerformanceScorecardMetricCycleDocument = async (input: GqlCreatePerformanceScorecardMetricCycleDocumentInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricCycleDocumentMutation,
    GqlCreatePerformanceScorecardMetricCycleDocumentMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT}

      mutation CreatePerformanceScorecardMetricCycleDocument($input: CreatePerformanceScorecardMetricCycleDocumentInput!) {
        createPerformanceScorecardMetricCycleDocument(input: $input) {
          ...PerformanceScorecardMetricCycleDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricCycleDocument as GqlPerformanceScorecardMetricCycleDocumentFieldsFragment
}
