import { gql } from '@apollo/client'
import { GqlApprovalTemplateViewInput, GqlApprovalTemplateViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template/graphql/approval-template-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryApprovalTemplateView = async (input: GqlApprovalTemplateViewInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}

      query ApprovalTemplateView($input: ApprovalTemplateViewInput!) {
        approvalTemplateView(input: $input) {
          ...ApprovalTemplateFields
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateView
}
