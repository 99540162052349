import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { isBefore } from 'date-fns'
import { filter, find, includes, isNumber, map, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PerformanceScorecardMetricCycleScoreStatusBadge } from '@cotiss/performance/components/performance-scorecard-metric-cycle-score-status-badge.component'
import { PerformanceScorecardMetricCycleStatusBadge } from '@cotiss/performance/components/performance-scorecard-metric-cycle-status-badge.component'
import { performanceService } from '@cotiss/performance/performance.service'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { usePerformanceScorecardAnalytics } from '@cotiss/performance/hooks/use-performance-scorecard-analytics.hook'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { usePerformanceScorecardMetricCycle } from '@cotiss/performance/hooks/use-performance-scorecard-metric-cycle.hook'
import { usePerformanceScorecardMetricCycleScore } from '@cotiss/performance/hooks/use-performance-scorecard-metric-cycle-score.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { Field } from '@cotiss/common/components/field.component'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { Header } from '@cotiss/common/components/header.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TableEmptyContainer } from '@cotiss/common/components/table-empty-container.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { PerformanceScorecardMetricCycleEvaluatorScoreDrawer } from '@cotiss/performance/drawers/performance-scorecard-metric-cycle-evaluator-score.drawer'
import { PerformanceScorecardMetricCycleOwnerScoreDrawer } from '@cotiss/performance/drawers/performance-scorecard-metric-cycle-owner-score.drawer'
import { userService } from '@cotiss/user/user.service'

export const PerformanceScorecardMetricCycleViewPage = memo(() => {
  const { openToast } = useToast()
  const { openNarrowDrawer } = useCallout()
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { track } = usePerformanceScorecardAnalytics()
  const [pagination, setPagination] = useState<GqlPagination>()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { performanceScorecard, queryPerformanceScorecardView } = usePerformanceScorecard()
  const { performanceScorecardMetric, queryPerformanceScorecardMetricView } = usePerformanceScorecardMetric()
  const { performanceScorecardMetricCycle, queryPerformanceScorecardMetricCycleView } = usePerformanceScorecardMetricCycle()
  const { performanceScorecardMetricCycleScores, queryPerformanceScorecardMetricCycleScoreList } = usePerformanceScorecardMetricCycleScore()
  const {
    performanceScorecardUsers,
    performanceScorecardUserInSession,
    queryPerformanceScorecardUserInSessionView,
    queryPerformanceScorecardUserList,
  } = usePerformanceScorecardUser()
  const { performanceScorecardId, performanceScorecardMetricId, performanceScorecardMetricCycleId } = useParams<{
    performanceScorecardId: string
    performanceScorecardMetricId: string
    performanceScorecardMetricCycleId: string
  }>()

  const { backHref, breadcrumbs, performanceScorecardMetricCycleLabel, isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    const performanceScorecardMetricCycleLabel = performanceScorecardMetricCycle
      ? performanceService.getPerformanceMetricCycleLabel(performanceScorecardMetricCycle)
      : ''

    const backHref = routerService.getHref('/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId', {
      performanceScorecardId,
      performanceScorecardMetricId,
    })
    const breadcrumbs: BreadcrumbModel[] = [
      {
        label: 'Performance',
        href: routerService.getHref('/performance/scorecard/list/:tab?'),
      },
      {
        label: performanceScorecard?.name || '',
        href: routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId }),
        isLoading,
      },
      {
        label: performanceScorecardMetric?.performanceMetric.name || '',
        href: backHref,
        isLoading,
      },
      {
        label: performanceScorecardMetricCycleLabel,
        isLoading,
      },
    ]

    return { backHref, breadcrumbs, performanceScorecardMetricCycleLabel, isOwner }
  }, [performanceScorecard, performanceScorecardMetric, performanceScorecardMetricCycle, performanceScorecardUsers, isLoading])

  useAsyncEffect(async () => {
    try {
      const [performanceScorecard, performanceScorecardMetric] = await Promise.all([
        queryPerformanceScorecardView({ performanceScorecardId }),
        queryPerformanceScorecardMetricView({ performanceScorecardMetricId }),
        queryPerformanceScorecardMetricCycleView({ performanceScorecardMetricCycleId }),
        queryPerformanceScorecardUserInSessionView({ performanceScorecardId }),
      ])

      if (performanceScorecard.status === 'draft') {
        openToast('You do not have permission to view this performance scorecard metric.', 'danger')
        replace(backHref)
        return
      }

      if (isBefore(new Date(), datetimeService.parse(performanceScorecardMetric.startDate))) {
        openToast("You cannot view a performance scorecard metric before it's start date.", 'danger')
        replace(backHref)
        return
      }

      track('performance_scorecard_metric_view')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!performanceScorecard) {
      return
    }

    if (performanceScorecard.isArchived) {
      openToast('You cannot access an archived performance scorecard.', 'danger')
      return push(routerService.getHref('/performance/scorecard/list/:tab?'))
    }
  }, [performanceScorecard])

  const refreshPerformanceMetricCycleList = async () => {
    try {
      setIsLoading(true)
      const { items, pagination } = await queryPerformanceScorecardUserList({
        filter: {
          performanceScorecardId,
          performanceScorecardMetricId,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })
      await queryPerformanceScorecardMetricCycleScoreList({
        filter: {
          performanceScorecardId,
          performanceScorecardMetricCycleId,
          performanceScorecardUserIds: map(items, 'id'),
        },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useAsyncEffect(async () => {
    await refreshPerformanceMetricCycleList()
  }, [currentPage])

  const { fixedColumns, columns } = useMemo(() => {
    const performanceScorecardUsersToRender = filter(performanceScorecardUsers, ({ id: performanceScorecardUserId, roles }) => {
      // We want to render evaluators and any users who have scored this cycle and who are no longer evaluators.
      return includes(roles, 'evaluator') || some(performanceScorecardMetricCycleScores, { performanceScorecardUserId })
    })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'User',
        rows: map(performanceScorecardUsersToRender, ({ id: performanceScorecardUserId, user, roles }) => {
          const fullName = userService.getFullName(user)
          const performanceScorecardMetricCycleScore = find(performanceScorecardMetricCycleScores, { performanceScorecardUserId })
          const isNoLongerEvaluator = !includes(roles, 'evaluator') && some(performanceScorecardMetricCycleScores, { performanceScorecardUserId })

          return {
            content: () => (
              <div>
                <div className="flex items-center truncate">
                  <Text className="truncate" title={fullName}>
                    {fullName}
                  </Text>
                  {performanceScorecardUserInSession?.id === performanceScorecardUserId && (
                    <Text className="ml-1" variant="light">
                      (you)
                    </Text>
                  )}
                </div>
                {isNoLongerEvaluator && <Text variant="light">(evaluator removed)</Text>}
              </div>
            ),
            cta: (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      View <Icon icon="arrow-right" />
                    </>
                  ),
                  onClick: () =>
                    openNarrowDrawer(
                      <PerformanceScorecardMetricCycleEvaluatorScoreDrawer
                        performanceScorecardMetricCycleScoreId={performanceScorecardMetricCycleScore?.id}
                        performanceScorecardMetricCycleId={performanceScorecardMetricCycleId}
                        performanceScorecardUserId={performanceScorecardUserId}
                        isEditable={!isNoLongerEvaluator}
                      />
                    ),
                }}
              />
            ),
          }
        }),
      },
    ]
    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Status',
        rows: map(performanceScorecardUsersToRender, ({ id: performanceScorecardUserId }) => {
          const performanceScorecardMetricCycleScore = find(performanceScorecardMetricCycleScores, { performanceScorecardUserId })

          return {
            content: () => (
              <PerformanceScorecardMetricCycleScoreStatusBadge performanceScorecardMetricCycleScore={performanceScorecardMetricCycleScore} />
            ),
          }
        }),
      },
      {
        heading: 'Value',
        rows: map(performanceScorecardUsersToRender, ({ id: performanceScorecardUserId }) => {
          const performanceScorecardMetricCycleScore = find(performanceScorecardMetricCycleScores, { performanceScorecardUserId })

          return {
            content: () => (
              <Text>
                {performanceScorecardMetric && isNumber(performanceScorecardMetricCycleScore?.value)
                  ? performanceService.formatPerformanceValue({
                      value: performanceScorecardMetricCycleScore.value,
                      performanceMetric: performanceScorecardMetric.performanceMetric,
                    })
                  : '--'}
              </Text>
            ),
          }
        }),
      },
      {
        heading: 'Comment',
        isWrapped: true,
        rows: map(performanceScorecardUsersToRender, ({ id: performanceScorecardUserId }) => {
          const performanceScorecardMetricCycleScore = find(performanceScorecardMetricCycleScores, { performanceScorecardUserId })

          return {
            content: () => <Text className="whitespace-pre-wrap">{performanceScorecardMetricCycleScore?.comment || '--'}</Text>,
          }
        }),
      },
      {
        heading: 'Date submitted',
        rows: map(performanceScorecardUsersToRender, ({ id: performanceScorecardUserId }) => {
          const performanceScorecardMetricCycleScore = find(performanceScorecardMetricCycleScores, { performanceScorecardUserId })

          return {
            content: () => (
              <Text>
                {performanceScorecardMetricCycleScore?.submittedAt
                  ? datetimeService.format(performanceScorecardMetricCycleScore.submittedAt, 'do MMM yyyy')
                  : '--'}
              </Text>
            ),
          }
        }),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardUsers, performanceScorecardMetricCycleScores, performanceScorecardMetric])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isPerformanceScorecardEnabled) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-start justify-between">
          <div>
            <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
            {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
            {!isLoading && performanceScorecardMetricCycle && performanceScorecardMetricCycleLabel && (
              <div className="flex items-center">
                <Text className="font-medium flex items-center" size="h7" variant="heading">
                  Cycle: {performanceScorecardMetricCycleLabel}
                </Text>
                <PerformanceScorecardMetricCycleStatusBadge className="ml-2" performanceScorecardMetricCycle={performanceScorecardMetricCycle} />
              </div>
            )}
          </div>
          {performanceScorecardMetricCycle && performanceScorecardMetric && performanceScorecardUserInSession && isOwner && (
            <Button
              className="ml-4"
              onClick={() => openNarrowDrawer(<PerformanceScorecardMetricCycleOwnerScoreDrawer />)}
              size="sm"
              variant="secondary"
            >
              Update score
            </Button>
          )}
        </div>
      </Header>
      <PageContent>
        <Card>
          <CardHeader>
            <div>
              {isLoading && (
                <>
                  <Skeleton className="h-2 w-16" variant="gray" />
                  <Skeleton className="h-4 w-32 mt-1" variant="gray" />
                </>
              )}
              {!isLoading && performanceScorecardMetric && (
                <>
                  <Text size="sm" variant="light">
                    {performanceScorecardMetric.performanceMetric.group}
                  </Text>
                  <Text className="font-medium mt-1" size="lg">
                    {performanceScorecardMetric.performanceMetric.name}
                  </Text>
                </>
              )}
            </div>
          </CardHeader>
          <Field label="Description" supplementary="Outlines this metric in more detail.">
            {!performanceScorecardMetric && (
              <>
                <Skeleton className="h-3 w-full" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-2/3 mt-1" variant="gray" />
              </>
            )}
            {performanceScorecardMetric && <Text>{performanceScorecardMetric.performanceMetric.description}</Text>}
          </Field>
          <Field className="mt-8" label="Methodology" supplementary="Outlines how to gather the value of this metric.">
            {!performanceScorecardMetric && (
              <>
                <Skeleton className="h-3 w-full" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-full mt-1" variant="gray" />
                <Skeleton className="h-3 w-2/3 mt-1" variant="gray" />
              </>
            )}
            {performanceScorecardMetric && <Text>{performanceScorecardMetric.performanceMetric.methodology}</Text>}
          </Field>
          <Field className="mt-8" label="Final value">
            {(!performanceScorecardMetricCycle || !performanceScorecardMetric) && <Skeleton className="h-3 w-full" variant="gray" />}
            {performanceScorecardMetricCycle && performanceScorecardMetric && (
              <Text>
                {isNumber(performanceScorecardMetricCycle.score)
                  ? performanceService.formatPerformanceValue({
                      value: performanceScorecardMetricCycle.score,
                      performanceMetric: performanceScorecardMetric.performanceMetric,
                    })
                  : '--'}
              </Text>
            )}
          </Field>
          <Field className="mt-8" label="Final comment">
            {!performanceScorecardMetricCycle && <Skeleton className="h-3 w-full" variant="gray" />}
            {performanceScorecardMetricCycle && <Text>{performanceScorecardMetricCycle.comment || '--'}</Text>}
          </Field>
          <Field className="mt-8" label="Date submitted">
            {!performanceScorecardMetricCycle && <Skeleton className="h-3 w-full" variant="gray" />}
            {performanceScorecardMetricCycle && (
              <Text>
                {performanceScorecardMetricCycle.submittedAt
                  ? datetimeService.format(performanceScorecardMetricCycle.submittedAt, 'do MMM yyyy')
                  : '--'}
              </Text>
            )}
          </Field>
          <Field className="mt-8" label="Updated by">
            {!performanceScorecardMetricCycle && <Skeleton className="h-3 w-full" variant="gray" />}
            {performanceScorecardMetricCycle && (
              <Text>
                {performanceScorecardMetricCycle.submittedByUser ? userService.getFullName(performanceScorecardMetricCycle.submittedByUser) : '--'}
              </Text>
            )}
          </Field>
          <TableHeader className="mt-8">
            <Text className="font-medium" size="lg">
              Evaluator values
            </Text>
            <Text className="mt-1" size="sm" variant="light">
              Suggested updates submitted by evaluators assigned to this metric.
            </Text>
          </TableHeader>
          {Boolean(isLoading || fixedColumns.length) && (
            <ScrollableTable
              fixedColumns={fixedColumns}
              columns={columns}
              pagination={pagination}
              onPageChange={setCurrentPage}
              isLoading={isLoading}
            />
          )}
          {!isLoading && !fixedColumns.length && (
            <TableEmptyContainer>
              <Text variant="light">This metric has no active evaluators assigned.</Text>
            </TableEmptyContainer>
          )}
        </Card>
      </PageContent>
    </Page>
  )
})
