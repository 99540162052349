import React, { memo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { RouterPath, routerService } from '@cotiss/common/services/router.service'
import { ConflictOfInterestOutcomeBadge } from '@cotiss/conflict-of-interest/components/conflict-of-interest-outcome-badge.component'
import { ConflictOfInterestStatusBadge } from '@cotiss/conflict-of-interest/components/conflict-of-interest-status-badge.component'
import { ConflictOfInterestViewCompletedDetails } from '@cotiss/conflict-of-interest/components/conflict-of-interest-view-completed-details.component'
import { ConflictOfInterestViewCompletedTerms } from '@cotiss/conflict-of-interest/components/conflict-of-interest-view-completed-terms.component'
import { conflictOfInterestService } from '@cotiss/conflict-of-interest/conflict-of-interest.service'
import { useGetConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-get-conflict-of-interest.resource'
import { useMutateConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-mutate-conflict-of-interest.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  conflictOfInterestId: string
}

type ConflictOfInterestedViewCompletedTab = 'details' | 'terms'
const CONFLICT_OF_INTEREST_VIEW_COMPLETED_TABS: TabModel<ConflictOfInterestedViewCompletedTab>[] = [
  { id: 'details', label: 'Details' },
  { id: 'terms', label: 'Terms' },
]

export const ConflictOfInterestViewCompleted = memo(({ conflictOfInterestId }: Props) => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { path } = useRouteMatch()
  const { user } = useGetLoggedInUser()
  const { step, transition, onTransition } = useTransition()
  const { createConflictOfInterest } = useMutateConflictOfInterest()
  const { conflictOfInterest, isLoading } = useGetConflictOfInterest(conflictOfInterestId)

  const handleSubmit = async (procurementId: string) => {
    const { _id: conflictOfInterestId } = await createConflictOfInterest({ procurementId })

    if ((path as RouterPath) === '/conflict-of-interest/me/view/:conflictOfInterestId') {
      push(routerService.getHref('/conflict-of-interest/me/view/:conflictOfInterestId', { conflictOfInterestId }))
    } else if ((path as RouterPath) === '/conflict-of-interest/view/:conflictOfInterestId') {
      push(routerService.getHref('/conflict-of-interest/view/:conflictOfInterestId', { conflictOfInterestId }))
    } else if ((path as RouterPath) === '/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId') {
      push(
        routerService.getHref('/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId', {
          procurementId,
          conflictOfInterestId,
        })
      )
    }
  }

  const handleTabChange = (tab: ConflictOfInterestedViewCompletedTab) => {
    const newStep = findIndex(CONFLICT_OF_INTEREST_VIEW_COMPLETED_TABS, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <div className="bg-white rounded-lg shadow-lg w-full px-8 py-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1">
          {isLoading && <Skeleton className="h-4 w-24" />}
          {!isLoading && conflictOfInterest && (
            <Text variant="heading" size="h4">
              {conflictOfInterest.procurement.title}
            </Text>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {isLoading && (
            <>
              <Skeleton className="h-4 w-20" isRounded />
              <Skeleton className="h-4 w-20" isRounded />
            </>
          )}
          {!isLoading && conflictOfInterest && (
            <>
              <ConflictOfInterestStatusBadge status={conflictOfInterestService.getConflictStatus(conflictOfInterest)} />
              <ConflictOfInterestOutcomeBadge outcome={conflictOfInterestService.getConflictOutcome(conflictOfInterest)} />
            </>
          )}
          {conflictOfInterest?.recipient._id === user?._id && (
            <Button
              onClick={() =>
                conflictOfInterest &&
                openModal(
                  <ConfirmModal
                    heading="Declare a new conflict of interest"
                    description="Are you sure you want to declare a new conflict of interest? This action cannot be undone."
                    onSubmit={() => handleSubmit(conflictOfInterest.procurement._id)}
                  />
                )
              }
              state="translucent"
              variant="secondary"
              size="sm"
            >
              Disclose new conflict
            </Button>
          )}
        </div>
      </div>
      <Hr className="my-4" />
      <Tabs<ConflictOfInterestedViewCompletedTab>
        className="border-b border-gray-300 w-full mb-8"
        tabs={CONFLICT_OF_INTEREST_VIEW_COMPLETED_TABS}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <ConflictOfInterestViewCompletedDetails isLoading={isLoading} conflictOfInterest={conflictOfInterest} />}
          {step === 2 && <ConflictOfInterestViewCompletedTerms conflictOfInterestId={conflictOfInterest?._id} />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
