import { gql } from '@apollo/client'
import { GqlPerformanceMetricGroupListInput, GqlPerformanceMetricGroupListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const queryPerformanceMetricGroupList = async (input: GqlPerformanceMetricGroupListInput) => {
  const { data } = await apolloService.query<GqlPerformanceMetricGroupListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}

      query PerformanceMetricGroupList($input: PerformanceMetricGroupListInput!) {
        performanceMetricGroupList(input: $input) {
          items
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceMetricGroupList
}
