import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { TenderInvitationModel } from '@cotiss/tender-invitation/tender-invitation.models'

export const useGetTenderInvitation = (id?: string) => {
  const { data: tenderInvitation, ...rest } = useFetch<TenderInvitationModel>('/v2/tender-invitations', {
    route: `/v2/tender-invitations/${id}`,
    isEnabled: Boolean(id),
  })

  return { tenderInvitation, ...rest }
}
