import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationSubmissionDocumentInput,
  GqlCreateEvaluationSubmissionDocumentMutation,
  GqlCreateEvaluationSubmissionDocumentMutationVariables,
  GqlEvaluationSubmissionDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-submission-document/evaluation-submission-document-fragment.graphql'

export const mutateCreateEvaluationSubmissionDocument = async (input: GqlCreateEvaluationSubmissionDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationSubmissionDocumentMutation, GqlCreateEvaluationSubmissionDocumentMutationVariables>({
    mutation: gql`
      ${EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationSubmissionDocument($input: CreateEvaluationSubmissionDocumentInput!) {
        createEvaluationSubmissionDocument(input: $input) {
          ...EvaluationSubmissionDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationSubmissionDocument as GqlEvaluationSubmissionDocumentFieldsFragment
}
