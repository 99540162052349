import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardInput,
  GqlCreatePerformanceScorecardMutation,
  GqlCreatePerformanceScorecardMutationVariables,
  GqlPerformanceScorecardFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { CONTACT_FRAGMENT } from '@cotiss/contact/graphql/contact-fragment.graphql'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract/graphql/contract-shell-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_SCORECARD_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard/performance-scorecard-fragment.graphql'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateCreatePerformanceScorecard = async (input: GqlCreatePerformanceScorecardInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardMutation, GqlCreatePerformanceScorecardMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${CONTACT_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      mutation CreatePerformanceScorecard($input: CreatePerformanceScorecardInput!) {
        createPerformanceScorecard(input: $input) {
          ...PerformanceScorecardFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecard as GqlPerformanceScorecardFieldsFragment
}
