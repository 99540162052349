import React, { FormEvent, memo, useState } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type FormData = {
  name: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventEnvelopeUpdateModal = memo(({ evaluationEnvelope }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { queryEvaluationEnvelopeList, mutateUpdateEvaluationEnvelope } = useEvaluationEnvelope()
  const [formData, setFormData] = useState<FormData>({
    name: evaluationEnvelope.name,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelopes_update_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelope.id, ...formData, isScored: true })

      // We need to refresh the evaluation event as updating the envelopes may update the steps of the wizard (E.g. if there is a price table.)
      await queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Update envelope" isDisabled={isSaving} />
      <ModalContent className="max-h-[500px]" isScrollable>
        <Label>Envelope name</Label>
        <Input
          value={formData.name}
          onChange={({ target }) => setFormData({ ...formData, name: target.value })}
          placeholder="Type what will be evaluated in this envelope"
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
