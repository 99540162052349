import React, { memo, useEffect, useMemo } from 'react'
import { isBefore, parseISO } from 'date-fns'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { ForumAddUpdateQuestionModal } from '@cotiss/forum/modals/forum-add-update-question.modal'
import { ProcurementViewTab } from '@cotiss/procurement/pages/procurement-view.page'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { ProcurementViewTenderDetailsTab } from '@cotiss/procurement/tabs/procurement-view-tender-details.tab'
import { ProcurementViewTenderDocumentsTab } from '@cotiss/procurement/tabs/procurement-view-tender-documents.tab'
import { ProcurementViewTenderForumTab } from '@cotiss/procurement/tabs/procurement-view-tender-forum.tab'
import { ProcurementViewTenderUpdatesTab } from '@cotiss/procurement/tabs/procurement-view-tender-updates.tab'
import { useGetProcurementResponse } from '@cotiss/procurement-response/resources/use-get-procurement-response.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderResponseStatusBadge } from '@cotiss/tender-response/components/tender-response-status-badge.component'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'
import { useListTenderUpdate } from '@cotiss/tender-update/resources/use-list-tender-update.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type ProcurementViewTenderTabs = 'details' | 'documents' | 'forum' | 'updates'

type Props = {
  tenderId?: string
  tenderResponse?: TenderResponsePopulatedModel
}

export const ProcurementViewTenderTab = memo(({ tenderId, tenderResponse }: Props) => {
  const { push, replace } = useHistory()
  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const { procurementId, tab, nestedTab } = useParams<{
    procurementId: string
    tab?: ProcurementViewTab
    nestedTab?: ProcurementViewTenderTabs
  }>()
  const { tender, isLoading: isLoadingTender } = useGetTender(tenderId)
  const { meta: tenderUpdatesMeta } = useListTenderUpdate({ tenderId })
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(procurementId)
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const { procurementResponse, isFetching: isFetchingProcurementResponse } = useGetProcurementResponse(tenderResponse?.procurementResponse._id)
  const isUserProcurementResponse = user?.account?.organisation?._id === procurementResponse?.procurement.procuringEntity._id
  const isForumClosed = tenderResponse?.tender.forumCloseDate?.endDate
    ? isBefore(parseISO(tenderResponse?.tender.forumCloseDate.endDate), new Date())
    : true
  const isUserProcuringEntity = loggedInUser?.account.organisation?._id === procurement?.procuringEntity
  const isLoading = isLoadingTender || isProcurementLoading || isLoggedInUserLoading || isFetchingProcurementResponse

  const isUserInProcurementResponse = useMemo(() => {
    if (!procurementResponse || !loggedInUser) {
      return false
    }

    return some(procurementResponse.procurementResponseUsers, ({ user }) => loggedInUser._id === user._id)
  }, [procurementResponse, loggedInUser])

  const isTenderViewDisabled = useMemo(() => {
    if (isUserProcuringEntity) {
      return false
    }

    if (!tenderResponse) {
      return 'To view this tab, you must subscribe to the listing.'
    }

    if (!isUserInProcurementResponse) {
      return 'To view this tab, you must join the team.'
    }

    return false
  }, [isUserProcuringEntity, tenderResponse, isUserInProcurementResponse])

  const tabs: TabModel<ProcurementViewTenderTabs>[] = useMemo(
    () => [
      { id: 'details', label: 'Details' },
      { id: 'documents', label: 'Documents & forms', isDisabled: isTenderViewDisabled },
      { id: 'forum', label: 'Questions & answers', isDisabled: isTenderViewDisabled },
      {
        id: 'updates',
        label: 'Updates',
        count: tenderUpdatesMeta?.unviewedUpdatesCount || undefined, // Since this is pending count we don't need to show it if it's 0
        isDisabled: isTenderViewDisabled,
      },
    ],
    [isTenderViewDisabled, tenderUpdatesMeta]
  )

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === nestedTab) + 1 })

  useEffect(() => {
    if (!nestedTab || !find(tabs, ({ id }) => id === nestedTab)) {
      replace(
        routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
          procurementId,
          tab,
          nestedTab: tabs[0].id,
        })
      )
    }

    const newStep = findIndex(tabs, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [nestedTab])

  const handleQuestionClick = () => {
    push(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId, tab, nestedTab: 'forum' }))

    openModal(<ForumAddUpdateQuestionModal tenderId={tenderResponse?.tender._id} />)
  }

  return (
    <div className="bg-white rounded-lg shadow w-full p-6">
      <div className="flex items-start justify-between mb-6">
        <div className="w-full">
          {isLoading && (
            <>
              <Skeleton className="h-3 w-1/3" />
              <Skeleton className="h-2 w-1/4 mt-1" />
            </>
          )}
          {!isLoading && (
            <div className="flex justify-between">
              <div>
                <div className="flex items-center">
                  <Text className="font-medium mr-2" size="h7">
                    {tender?.title}
                  </Text>
                  {!isUserProcurementResponse && tenderResponse?.status && <TenderResponseStatusBadge status={tenderResponse.status} />}
                </div>
                {tenderResponse?.tender.tenderPeriod?.endDate && (
                  <Text className="mt-1" size="sm" variant="light">
                    All submissions must be finalised by{' '}
                    {datetimeService.format(tenderResponse?.tender.tenderPeriod?.endDate, 'd MMMM yyyy h:mm aaa')}
                  </Text>
                )}
              </div>
              {!isUserProcuringEntity && (
                <Button className="ml-4" size="sm" state="translucent" variant="secondary" onClick={handleQuestionClick} isDisabled={isForumClosed}>
                  <Icon className="mr-2" icon="pencil-02" /> Ask a question
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <Tabs<ProcurementViewTenderTabs>
        className="border-b border-gray-300 w-full mb-8"
        tab={nestedTab}
        tabs={tabs}
        onChange={({ id: nestedTab }) =>
          push(
            routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
              procurementId,
              tab,
              nestedTab,
            })
          )
        }
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <ProcurementViewTenderDetailsTab
              tenderId={tenderId}
              onTabChange={(nestedTab) =>
                push(
                  routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
                    procurementId,
                    tab,
                    nestedTab,
                  })
                )
              }
              isDisabled={Boolean(isTenderViewDisabled)}
            />
          )}
          {step === 2 && !isTenderViewDisabled && <ProcurementViewTenderDocumentsTab tenderId={tenderId} />}
          {step === 3 && !isTenderViewDisabled && <ProcurementViewTenderForumTab tenderId={tenderId} />}
          {step === 4 && !isTenderViewDisabled && <ProcurementViewTenderUpdatesTab tenderId={tenderId} />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
