import { gql } from '@apollo/client'
import { GqlEvaluationSubmissionListInput, GqlEvaluationSubmissionListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-submission/evaluation-submission-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const queryEvaluationSubmissionList = async (input: GqlEvaluationSubmissionListInput) => {
  const { data } = await apolloService.query<GqlEvaluationSubmissionListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_FRAGMENT}

      query EvaluationSubmissionList($input: EvaluationSubmissionListInput!) {
        evaluationSubmissionList(input: $input) {
          items {
            ...EvaluationSubmissionFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationSubmissionList
}
