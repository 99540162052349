import React, { CSSProperties, ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children?: ReactNode
  style?: CSSProperties
}

export const Kanban = memo(({ className, children, style }: Props) => {
  const classes = classNames(className, 'flex overflow-x-auto w-full')

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
})
