import React, { memo, useMemo, useState } from 'react'
import { filter, find, map } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ContractSummaryScheduleOfRates } from '@cotiss/contract/components/contract-summary-schedule-of-rates.component'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { userService } from '@cotiss/user/user.service'
import { PriceDurationOrMilestonesHistoryTableData } from '@cotiss/contract/modals/contract-price-durations-or-milestones-history.modal'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'

type Props = {
  contractShellId: string
}

export const ContractScheduledRatesHistoryModal = memo(({ contractShellId }: Props) => {
  const [selectedContractId, setSelectedContractId] = useState('')
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { contractSelectOptions, selectedContract, tableData } = useMemo(() => {
    const contractSelectOptions = map(
      filter(
        // Get the original contract, as well as all contracts that have been approved and are a price_duration variation
        contractService.getAllApprovedContracts(contractShell),
        (contract) => contract.variationTypes.length === 0 || contract.variationTypes.includes('scheduled_rates')
      ),
      ({ createdAt, _id }) => ({
        label: datetimeService.format(createdAt, 'd MMMM yyyy h:mm aaa'),
        value: _id,
      })
    )
    const selectedContract = find(contractShell?.contracts, (contract) => contract._id === selectedContractId)

    const tableData: PriceDurationOrMilestonesHistoryTableData[] = [
      {
        activityLabel: 'Change made to scheduled rates',
        actionedBy: selectedContract?.lastModifiedBy,
        approvedBy: map(contractService.getApproval(selectedContract?.approvals, ['APPROVED'])?.approvers, ({ assigned }) => assigned) || [],
      },
    ]

    return { contractSelectOptions, selectedContract, tableData }
  }, [contractShell, selectedContractId])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Activity',
      rows: map(tableData, ({ activityLabel }) => ({
        content: () => (
          <Text size="sm" className="flex items-end">
            <Icon icon="edit" className="mr-1 stroke-2" variant="secondary" />
            {activityLabel}
          </Text>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Actioned by',
      rows: map(tableData, ({ actionedBy }) => ({
        content: () => (
          <Text size="sm" className="w-56 truncate" title={userService.getFullName(actionedBy)}>
            {userService.getFullName(actionedBy)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Approved by',
      rows: map(tableData, ({ approvedBy }) => ({
        content: () => (
          <>
            {approvedBy.length <= 1 && (
              <Text size="sm" className="w-56 truncate" title={userService.getFullName(approvedBy[0])}>
                {userService.getFullName(approvedBy[0])}
              </Text>
            )}
            {approvedBy.length > 1 && <UserAvatarGroup users={approvedBy || []} />}
          </>
        ),
      })),
    },
  ]

  return (
    <div className="w-[900px]">
      <ModalHeader heading="View history" />
      <ModalContent isScrollable>
        <Field label="Select variation time stamp" supplementary="Filter version history of the schedule of rates table">
          <Select_DEPRECATED<string>
            value={selectedContractId}
            options={contractSelectOptions}
            onChange={(id) => setSelectedContractId(id)}
            isDisabled={isLoading}
            placeholder
          />
        </Field>
        <Hr className="my-4" />
        {!selectedContract && (
          <div className="min-h-[200px] flex items-center justify-center">
            <Text className="italic">Select a contract period to view changes</Text>
          </div>
        )}
        {selectedContract && (
          <>
            <ScrollableTable fixedColumnsWidth={260} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
            <ContractSummaryScheduleOfRates contractId={selectedContract._id} contractShellId={contractShellId} />
          </>
        )}
      </ModalContent>
    </div>
  )
})
