import React from 'react'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useContractSearchFiltersContext } from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { ContractListPrimaryTabKeys } from '@cotiss/contract/contract.constants'

type Props = {
  tabs: TabModel<ContractListPrimaryTabKeys>[]
  tab: ContractListPrimaryTabKeys
}

export const ContractListTabs = ({ tabs, tab }: Props) => {
  const { track } = useAnalytics()
  const { queryStateDispatch } = useContractSearchFiltersContext()

  const handleTabChange = (_tab: ContractListPrimaryTabKeys) => {
    if (tab === 'active') {
      track('contract_list_active_view')
    }

    if (tab === 'archived') {
      track('contract_list_archived_view')
    }

    if (tab === 'mine') {
      track('contract_list_my_contracts_view')
    }
    queryStateDispatch({ type: 'UPDATE_PRIMARY_TAB', payload: _tab })
  }
  return <Tabs<ContractListPrimaryTabKeys> tabs={tabs} tab={tab} onChange={({ id }) => handleTabChange(id)} variant="raised" />
}
