import React, { memo } from 'react'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { TenderPriceTable } from '@cotiss/tender/components/tender-price-table.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderCriteriaTable } from '@cotiss/tender-criteria/components/tender-criteria-table.component'
import { useListTenderCriteria } from '@cotiss/tender-criteria/resources/use-list-tender-criteria.resource'
import { TenderMandatoryCriteriaTable } from '@cotiss/tender-mandatory-criteria/components/tender-mandatory-criteria-table.component'
import { useListTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria/resources/use-list-tender-mandatory-criteria.resource'

type Props = {
  tenderId?: string
}

// TODO: Add loading state.

export const ProcurementViewTenderDocumentsTab = memo(({ tenderId }: Props) => {
  const { tender } = useGetTender(tenderId)
  const { tenderMandatoryCriteria } = useListTenderMandatoryCriteria({ tenderId })
  const { tenderCriteria } = useListTenderCriteria({ tenderId })

  return (
    <>
      <Text className="font-semibold" variant="heading" size="h5">
        Documents
      </Text>
      <Text className="mt-1" variant="light" size="sm">
        Download and read the following documents. These do not need to be re-uploaded.
      </Text>
      {tender?.attachments && <DocumentList className="mt-3" documents={tender.attachments} />}

      <Hr className="my-4" />

      <Text className="font-semibold" variant="heading" size="h5">
        Response forms
      </Text>
      <Text className="mt-1" variant="light" size="sm">
        Download and complete the following forms. Submit completed forms via the response button.
      </Text>
      {tender?.nonPriceAttachments && <DocumentList className="mt-3" title="Non-price forms" documents={tender.nonPriceAttachments} />}
      {tender?.priceAttachments && <DocumentList className="mt-3" title="Price forms" documents={tender?.priceAttachments} />}

      <Hr className="my-4" />

      <Text className="font-semibold" variant="heading" size="h5">
        Criteria
      </Text>

      {tenderCriteria.length > 0 && (
        <>
          <Text className="font-semibold mt-2" variant="heading">
            Evaluation criteria
          </Text>
          <Text className="mt-1" variant="light" size="sm">
            These criteria will be used to assess your bid on submission.
          </Text>
          {tenderId && <TenderCriteriaTable className="mt-4" tenderId={tenderId} isSupplierView />}
        </>
      )}

      {Boolean(tenderMandatoryCriteria.length) && (
        <>
          <Text className="font-semibold mt-6" variant="heading">
            Mandatory criteria
          </Text>
          <Text className="mt-1" variant="light" size="sm">
            These questions will determine the mandatory conditions of a bid prior to evaluation.
          </Text>
          {tenderId && <TenderMandatoryCriteriaTable className="mt-6" tenderId={tenderId} />}
        </>
      )}

      <Hr className="my-4" />

      {tender?.priceTableEnabled && <TenderPriceTable className="mt-2" tenderId={tenderId} />}
    </>
  )
})
