import { gql } from '@apollo/client'
import { GqlEvaluationListInput, GqlEvaluationListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation/evaluation-fragment.graphql'

export const queryEvaluationList = async (input: GqlEvaluationListInput) => {
  const { data } = await apolloService.query<GqlEvaluationListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_FRAGMENT}

      query EvaluationList($input: EvaluationListInput!) {
        evaluationList(input: $input) {
          items {
            ...EvaluationFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationList
}
