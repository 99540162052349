import { gql } from '@apollo/client'
import {
  GqlCreatePlanEventDocumentsInput,
  GqlCreatePlanEventDocumentsMutation,
  GqlCreatePlanEventDocumentsMutationVariables,
  GqlPlanEventDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PLAN_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event-document/plan-event-document-fragment.graphql'

export const mutateCreatePlanEventDocuments = async (input: GqlCreatePlanEventDocumentsInput) => {
  const { data } = await apolloService.mutate<GqlCreatePlanEventDocumentsMutation, GqlCreatePlanEventDocumentsMutationVariables>({
    mutation: gql`
      ${PLAN_EVENT_DOCUMENT_FRAGMENT}

      mutation CreatePlanEventDocuments($input: CreatePlanEventDocumentsInput!) {
        createPlanEventDocuments(input: $input) {
          ...PlanEventDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPlanEventDocuments as GqlPlanEventDocumentFieldsFragment[]
}
