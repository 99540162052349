import { find, reduce, startsWith } from 'lodash'
import { BUYER_ROUTES, SERVICE_ROUTES, SUPPLIER_ROUTES } from '@cotiss/app/app.constants'
import { AppSideNavLinkType } from '@cotiss/app/components/app-side-nav.component'
import { UserAccessPermissions } from '@cotiss/user/hooks/use-user-access.hook'

type GetInitialSideNavTabParam = {
  pathname: string
  permissions: UserAccessPermissions
}

type AppGroupSideNavLinksResult = {
  enabled: AppSideNavLinkType[]
  disabled: AppSideNavLinkType[]
}

class AppService {
  getInitialSideNavTab = ({ pathname, permissions }: GetInitialSideNavTabParam) => {
    if (permissions.hasBuyerAccess && find(BUYER_ROUTES, (route) => startsWith(pathname, route))) {
      return 'buyer'
    }

    if (permissions.hasSupplierAccess && find(SUPPLIER_ROUTES, (route) => startsWith(pathname, route))) {
      return 'supplier'
    }

    if (permissions.hasServiceAccess && find(SERVICE_ROUTES, (route) => startsWith(pathname, route))) {
      return 'service'
    }

    if (permissions.hasBuyerAccess) {
      return 'buyer'
    }

    if (permissions.hasServiceAccess) {
      return 'service'
    }

    return permissions.isBuyer ? 'buyer' : permissions.isSupplier ? 'supplier' : 'service'
  }

  groupSideNavLinks = (sideNavLinks: AppSideNavLinkType[]) => {
    const accumulator: AppGroupSideNavLinksResult = { enabled: [], disabled: [] }

    return reduce(
      sideNavLinks,
      (group, link) => {
        group[link.isDisabled ? 'disabled' : 'enabled'].push(link)

        return group
      },
      accumulator
    )
  }
}

export const appService = new AppService()
