import { gql } from '@apollo/client'
import { GqlUpdatePlanEventInput, GqlUpdatePlanEventMutation, GqlUpdatePlanEventMutationVariables, GqlPlanEventFieldsFragment } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event/plan-event-fragment.graphql'

export const mutateUpdatePlanEvent = async (input: GqlUpdatePlanEventInput) => {
  const { data } = await apolloService.mutate<GqlUpdatePlanEventMutation, GqlUpdatePlanEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      mutation UpdatePlanEvent($input: UpdatePlanEventInput!) {
        updatePlanEvent(input: $input) {
          ...PlanEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePlanEvent as GqlPlanEventFieldsFragment
}
