import { gql } from '@apollo/client'
import { GqlPlanEventListInput, GqlPlanEventListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PLAN_EVENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event/plan-event-fragment.graphql'

export const queryPlanEventList = async (input: GqlPlanEventListInput) => {
  const { data } = await apolloService.query<GqlPlanEventListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PLAN_EVENT_FRAGMENT}

      query PlanEventList($input: PlanEventListInput!) {
        planEventList(input: $input) {
          items {
            ...PlanEventFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.planEventList
}
