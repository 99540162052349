import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'

export type UpdateTenderMandatoryCriteriaBulkItem = {
  tenderMandatoryCriteriaId?: string
  body: {
    index: number
    description: string
  }
}

type UpdateTenderMandatoryCriteriaBulkBody = {
  tenderId: string
  items: UpdateTenderMandatoryCriteriaBulkItem[]
}

type UpdateTenderMandatoryCriteriaScoreBody = {
  tenderMandatoryCriteriaId: string
  tenderResponseId: string
  pass: boolean
}

export const useMutateTenderMandatoryCriteria = () => {
  const { mutate } = useMutate()

  return {
    updateTenderMandatoryCriteriaBulk: async (body: UpdateTenderMandatoryCriteriaBulkBody) => {
      return await mutate({ route: `/v2/tender-mandatory-criteria/bulk`, body, invalidate: ['/v2/tender-mandatory-criteria', '/v2/procurements'] })
    },
    updateTenderMandatoryCriteriaScore: async (body: UpdateTenderMandatoryCriteriaScoreBody) => {
      return await mutate({ route: '/v2/tender-mandatory-criteria-score', body, invalidate: ['/v2/tender-mandatory-criteria'] })
    },
  }
}
