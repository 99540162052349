import React, { memo } from 'react'
import { upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { Text } from '@cotiss/common/components/text.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { ConflictOfInterestOutcome } from '@cotiss/conflict-of-interest/conflict-of-interest.constants'

const getVariant = (outcome: ConflictOfInterestOutcome): BaseVariant => {
  switch (outcome) {
    case 'excluded':
    default:
      return 'primary'
    case 'included':
      return 'success'
    case 'managed':
      return 'info'
    case 'pending':
      return 'danger'
  }
}

type Props = {
  className?: string
  outcome: ConflictOfInterestOutcome
  size?: BadgeSize
}

export const ConflictOfInterestOutcomeBadge = memo(({ className, outcome, size = 'sm' }: Props) =>
  outcome === '--' ? (
    <Text variant="light">--</Text>
  ) : (
    <Badge className={className} size={size} variant={getVariant(outcome)} state="outline">
      {upperFirst(outcome)}
    </Badge>
  )
)
