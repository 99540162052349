import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { ProjectListPage } from '@cotiss/project/pages/project-list.page'

export const ProjectLayout = memo(() => (
  <Switch>
    <Route path="/project/list/:tab?" exact>
      <ProjectListPage />
    </Route>
  </Switch>
))
