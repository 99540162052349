import { gql } from '@apollo/client'
import { GqlDeleteEvaluationSubmissionInput, GqlDeleteEvaluationSubmissionMutation, GqlDeleteEvaluationSubmissionMutationVariables } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationSubmission = async (input: GqlDeleteEvaluationSubmissionInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationSubmissionMutation, GqlDeleteEvaluationSubmissionMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationSubmission($input: DeleteEvaluationSubmissionInput!) {
        deleteEvaluationSubmission(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationSubmission as boolean
}
