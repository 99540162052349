import React, { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

type Props = {
  tenderId: string
}

export const TenderRequestDocumentUploadModal = memo(({ tenderId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { tender, isLoading } = useGetTender(tenderId)
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTender(tenderId, { attachments: [...map(attachments, ({ _id }) => _id), ...map(tender?.attachments, ({ _id }) => _id)] })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload specification documents" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={isLoading} isForm />
    </Form>
  )
})
