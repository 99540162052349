import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { SignUpSupplierFormData } from '@cotiss/auth/pages/sign-up-supplier.page'
import { ORGANISATION_ENTITY_TYPE_OPTIONS } from '@cotiss/organisation/organisation.constants'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
}

export const SignUpSupplierStepCompanyDetails = memo(({ formData, transition, setFormData, isDisabled }: Props) => {
  return (
    <TransitionContainer transition={transition}>
      <Label>Tax number</Label>
      <Input
        value={formData.taxNumber}
        onChange={({ target }) => setFormData({ ...formData, taxNumber: target.value })}
        name="Tax number"
        placeholder="Enter tax number"
        maxLength={100}
        isDisabled={isDisabled}
        isRequired
      />
      <Label className="mt-6">Entity type</Label>
      <Select_DEPRECATED
        value={formData.entityType}
        options={ORGANISATION_ENTITY_TYPE_OPTIONS}
        onChange={(entityType) => setFormData({ ...formData, entityType })}
        placeholder
        isRequired
      />
      <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
        Next
      </Button>
    </TransitionContainer>
  )
})
