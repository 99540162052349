import React, { useReducer, createContext, useContext, useMemo } from 'react'
import { Filter } from '@cotiss/common/models/filter.model'
import {
  PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB,
  PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS,
  PreferredSupplierPrimaryTabKeys,
} from '@cotiss/preferred-supplier/preferred-supplier.constants'

export type PreferredSupplierSearchFiltersContextState = {
  searchQuery: string
  currentPage: number
  advancedFilters: Filter[]
  primaryTab: PreferredSupplierPrimaryTabKeys
}

type UpdateSearchQueryAction = {
  type: 'UPDATE_SEARCH_QUERY'
  payload: string
}

type UpdateCurrentPageAction = {
  type: 'UPDATE_CURRENT_PAGE'
  payload: number
}

type UpdateAdvancedFiltersAction = {
  type: 'UPDATE_ADVANCED_FILTERS'
  payload: Filter[]
}

type UpdatePrimaryTabAction = {
  type: 'UPDATE_PRIMARY_TAB'
  payload: PreferredSupplierPrimaryTabKeys
}

type PreferredSupplierSearchFiltersContextAction =
  | UpdateSearchQueryAction
  | UpdateCurrentPageAction
  | UpdateAdvancedFiltersAction
  | UpdatePrimaryTabAction

const defaultInitialState: PreferredSupplierSearchFiltersContextState = {
  searchQuery: '',
  currentPage: 1,
  advancedFilters: [],
  primaryTab: PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB,
}

type PreferredSupplierSearchFiltersContextValue = {
  processedFilters: Filter[]
  isQuerying: boolean
  queryState: PreferredSupplierSearchFiltersContextState
  queryStateDispatch: React.Dispatch<PreferredSupplierSearchFiltersContextAction>
}

const PreferredSupplierSearchFiltersContext = createContext<PreferredSupplierSearchFiltersContextValue | null>(null)

function processFilters({ advancedFilters, primaryTab }: { advancedFilters: Filter[]; primaryTab: PreferredSupplierPrimaryTabKeys }): Filter[] {
  return [...advancedFilters, ...(PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS[primaryTab]?.filters || [])]
}

const searchFiltersReducer = (
  state: PreferredSupplierSearchFiltersContextState,
  action: PreferredSupplierSearchFiltersContextAction
): PreferredSupplierSearchFiltersContextState => {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        currentPage: 1,
        searchQuery: action.payload,
      }
    case 'UPDATE_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'UPDATE_ADVANCED_FILTERS':
      return {
        ...state,
        currentPage: 1,
        advancedFilters: action.payload,
      }
    case 'UPDATE_PRIMARY_TAB':
      return {
        ...state,
        currentPage: 1,
        primaryTab: action.payload,
      }

    default:
      return state
  }
}

export const usePreferredSupplierSearchFiltersContext = () => {
  const context = useContext(PreferredSupplierSearchFiltersContext)
  if (!context) {
    throw new Error('usePreferredSupplierSearchFiltersContext must be used within a PreferredSupplierSearchFiltersProvider')
  }
  return context
}

export const PreferredSupplierSearchFiltersContextProvider: React.FC<{
  children: React.ReactNode
  initialState?: Partial<PreferredSupplierSearchFiltersContextState>
}> = ({ children, initialState }) => {
  const [queryState, queryStateDispatch] = useReducer(searchFiltersReducer, { ...defaultInitialState, ...(initialState ?? {}) })

  const value = useMemo(
    () => ({
      queryState,
      isQuerying: Boolean(queryState.searchQuery?.length || queryState.advancedFilters?.length > 0),
      queryStateDispatch,
      processedFilters: processFilters({
        advancedFilters: queryState.advancedFilters,
        primaryTab: queryState.primaryTab,
      }),
    }),
    [queryState]
  )

  return <PreferredSupplierSearchFiltersContext.Provider value={value}>{children}</PreferredSupplierSearchFiltersContext.Provider>
}
