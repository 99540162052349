import React, { memo } from 'react'
import { map } from 'lodash'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { MetafieldForm } from '@cotiss/metafield/components/metafield-form.component'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'

type Props = {
  organisationId: string
  isEditable?: boolean
}

export const SupplierViewMetafieldsTab = memo(({ organisationId, isEditable }: Props) => {
  const { metafields } = useListMetafield({ entityType: 'SUPPLIER_PROFILE' })
  const { metafieldValues } = useListMetafieldValue({ resourceId: organisationId })

  return (
    <Card>
      <CardHeader className="mb-4">
        <Text className="font-semibold">Custom fields</Text>
      </CardHeader>

      {map(metafields, (metafield, index) => (
        <div key={metafield._id}>
          <MetafieldForm
            className={index ? 'mt-8' : ''}
            resourceId={organisationId}
            metafield={metafield}
            metafieldValues={metafieldValues}
            isEditable={isEditable}
          />
        </div>
      ))}
    </Card>
  )
})
