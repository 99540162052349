import { gql } from '@apollo/client'
import {
  GqlUpdateEvaluationUserAccessControlsInput,
  GqlUpdateEvaluationUserAccessControlsMutation,
  GqlUpdateEvaluationUserAccessControlsMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateUpdateEvaluationUserAccessControls = async (input: GqlUpdateEvaluationUserAccessControlsInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationUserAccessControlsMutation, GqlUpdateEvaluationUserAccessControlsMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation UpdateEvaluationUserAccessControls($input: UpdateEvaluationUserAccessControlsInput!) {
        updateEvaluationUserAccessControls(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationUserAccessControls as GqlEvaluationUserFieldsFragment
}
