import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderMandatoryCriterionModel } from '@cotiss/tender-mandatory-criteria/tender-mandatory-criteria.models'

type Props = {
  tenderId?: string
  limit?: number
  offset?: number
}

export const useListTenderMandatoryCriteria = ({ tenderId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderMandatoryCriterionModel>>('/v2/tender-mandatory-criteria', {
    route: `/v2/tender-mandatory-criteria`,
    params: { tenderId, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { tenderMandatoryCriteria: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
