import { gql } from '@apollo/client'
import {
  GqlDuplicateEvaluationEventInput,
  GqlDuplicateEvaluationEventMutation,
  GqlDuplicateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const mutateDuplicateEvaluationEvent = async (input: GqlDuplicateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlDuplicateEvaluationEventMutation, GqlDuplicateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation DuplicateEvaluationEvent($input: DuplicateEvaluationEventInput!) {
        duplicateEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.duplicateEvaluationEvent as GqlEvaluationEventFieldsFragment
}
