import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderResponsePopulatedModel, TenderResponseReviewStatus, TenderResponseStatus } from '@cotiss/tender-response/tender-response.models'

type Props = {
  tenderId?: string
  status?: TenderResponseStatus
  reviewStatus?: TenderResponseReviewStatus
  limit?: number
  offset?: number
}

export const useListTenderResponse = ({ tenderId, status, reviewStatus, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderResponsePopulatedModel>>('/v2/tender-response', {
    route: `/v2/tender-response`,
    params: { tenderId, status, reviewStatus, offset, limit },
    isEnabled: Boolean(tenderId),
  })

  return { tenderResponses: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
