import { gql } from '@apollo/client'
import { GqlEvaluationEventListInput, GqlEvaluationEventListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const queryEvaluationEventList = async (input: GqlEvaluationEventListInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      query EvaluationEventList($input: EvaluationEventListInput!) {
        evaluationEventList(input: $input) {
          items {
            ...EvaluationEventFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEventList
}
