import { differenceInMonths } from 'date-fns'
import { compact, filter, find, first, flatMap, forEach, includes, isEqual, join, map, orderBy } from 'lodash'
import { regionService } from '@cotiss/common/services/region.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { ContractWizardMilestoneTableItem } from '@cotiss/contract/components/contract-wizard-milestone-table.component'
import { ContractWizardPriceDurationTableItem } from '@cotiss/contract/components/contract-wizard-price-duration-table.component'
import {
  ContractApprovalModel,
  ContractApprovalStatus,
  ContractDocumentShellType,
  ContractMilestoneModel,
  ContractModel,
  ContractPriceDurationModel,
  ContractShellAuditHistoryField,
  ContractShellAuditHistoryModel,
  ContractShellAuditHistoryType,
  ContractShellPopulatedModel,
  ContractStatus,
} from '@cotiss/contract/contract.model'
import { MetafieldModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'
import { metafieldValueService } from '@cotiss/metafield-value/metafield-value.service'
import { userService } from '@cotiss/user/user.service'

export type ContractDocumentShellHierarchy = 'master' | 'variation'

type GetContractDocumentShellHierarchyParam = {
  contractShell?: ContractShellPopulatedModel
  documentShellName?: string
  type?: ContractDocumentShellType
}

type GetAllPublishedContractDocumentShellsParam = {
  contracts?: ContractModel[]
  type?: ContractDocumentShellType
}

type ValidateContractPriceDurationVariationParam = {
  approvedValues: ContractWizardPriceDurationTableItem[] | ContractWizardMilestoneTableItem[]
  newValues: ContractWizardPriceDurationTableItem[] | ContractWizardMilestoneTableItem[]
}

type HasContractPropertyChangedParam = {
  // These need to be string representations of each property, each parsed in the same way
  // This is so we can more easily compare the two values to see if they have changed
  activeProperty?: string
  previousProperty?: string
}

/** AuditHistoryFilter - dictates which contracts are compared in order to generate a history
 * all - compare all contracts including draft
 * approved - compare all approved contracts
 * approval - compare a contract to the version before it (requires `contractId`)
 * unapproved-compare - compare the current unapproved (drafting/pending approval) contract with the last approved contract
 */
export type AuditHistoryFilter = 'all' | 'approved' | 'approval' | 'unapproved-compare'

type GetContractShellAuditHistoryParam = {
  contractShell: ContractShellPopulatedModel
  field?: ContractShellAuditHistoryField
  filterType?: AuditHistoryFilter
  metafields?: MetafieldModel[]
  metafieldValues?: MetafieldValueModel[]
  /** Required when using `filterType: 'approval'` */
  contractId?: string
}

class ContractService {
  // If applicable, get the default contract from the contract shell
  getContract = (contractShell: ContractShellPopulatedModel, statusList?: ContractStatus[]) => {
    // Find the first contract that meets any given status
    if (statusList?.length) {
      return find(contractShell.contracts, (contract) => statusList.includes(contract.status))
    }

    /** TODO: this logic is WIP - as we add new features it will likely change
     *
     * Current logic for which contract is the default:
     *     - If we only have one contract, just return that one
     *     - Else if we have a published contract, return that one as the default
     *     - If we have more than one contract but none in 'published', that is an invalid state (should never be possible)
     * and will be handled outside of this function
     */

    if (contractShell.contracts.length === 1) {
      return contractShell.contracts[0]
    }

    const ceasedContract = find(contractShell.contracts, (contract) => contract.status === 'CEASED')

    if (ceasedContract) {
      return ceasedContract
    }

    const publishedContract = find(contractShell.contracts, (contract) => contract.status === 'PUBLISHED')

    if (publishedContract) {
      return publishedContract
    }

    return null
  }

  // Get the default approval if applicable
  // Centralise this logic to make it easier to keep up-to-date
  getApproval = (approvals?: ContractApprovalModel[], statusList?: ContractApprovalStatus[]) => {
    if (!approvals?.length) {
      return null
    }

    const _approvals = orderBy(approvals, 'createdAt', 'desc')

    // Find the first approval that meets any given status (or undefined, if not applicable)
    if (statusList) {
      return find(_approvals, ({ status }) => includes(statusList, status))
    }

    if (_approvals.length === 1) {
      return first(_approvals)
    }

    // REVOKED = rejected for now
    const rejectedApproval = find(_approvals, { status: 'REVOKED' })

    if (rejectedApproval) {
      return rejectedApproval
    }

    const pendingApproval = find(_approvals, { status: 'PENDING_APPROVAL' })

    if (pendingApproval) {
      return pendingApproval
    }

    // Else the user should be looking for a specific approval or status
    return null
  }

  // TODO: in the future 'changes requested' will be it's own contract state - remove this method when new state is added
  getAreChangesRequested = (approvals?: ContractApprovalModel[]) => {
    if (!approvals) {
      return false
    }

    // If there is both an approver that has requested changes, as well as an approval in drafting
    return (
      find(
        flatMap(approvals, ({ approvers }) => approvers),
        { status: 'REQUESTED_CHANGES' }
      ) && find(approvals, { status: 'DRAFTING' })
    )
  }

  getAllApprovedContracts = (contractShell?: ContractShellPopulatedModel) =>
    orderBy(
      filter(contractShell?.contracts || [], ({ status }) => includes(['PUBLISHED', 'UNPUBLISHED'], status)),
      'createdAt',
      'desc'
    )

  getAllApprovedContractDocumentShellsOfType = ({ contracts, type = 'CONTRACT' }: GetAllPublishedContractDocumentShellsParam) => {
    return flatMap(
      filter(contracts, ({ status }) => includes(['PUBLISHED', 'UNPUBLISHED'], status)),
      (contract) => filter(contract.documentShells, { type })
    )
  }

  getContractDocumentShellHierarchy = ({
    contractShell,
    documentShellName,
    type,
  }: GetContractDocumentShellHierarchyParam): ContractDocumentShellHierarchy => {
    const allDocumentShellNames = map(this.getAllApprovedContractDocumentShellsOfType({ contracts: contractShell?.contracts, type }), (ds) => ds.name)

    if (!documentShellName) {
      return 'variation'
    }

    return includes(allDocumentShellNames, documentShellName) ? 'variation' : 'master'
  }

  parseContractPriceDurations = (priceDurations: ContractPriceDurationModel[]): ContractWizardPriceDurationTableItem[] => {
    return (
      priceDurations
        .sort((a, b) => sortService.sortNumber(a.index, b.index))
        .map((pd) => {
          const { _id, startDate, endDate, value, index, referenceId, description, length } = pd
          return {
            _id,
            label: index ? `Renewal ${index}` : 'Initial term',
            startDate: startDate ? new Date(startDate) : undefined,
            endDate: endDate ? new Date(endDate) : undefined,
            length: !length ? (startDate && endDate ? Math.abs(differenceInMonths(new Date(startDate), new Date(endDate))) : 0) : length,
            value,
            referenceId,
            description,
            exercised: pd.exercised || 0,
            variation: pd.variation || 0,
            index,
          }
        }) || []
    )
  }

  parseContractMilestones = (milestones: ContractMilestoneModel[]): ContractWizardMilestoneTableItem[] => {
    return (
      milestones
        .sort((a, b) => sortService.sortNumber(a.index, b.index))
        .map((pd) => {
          const { _id, value, index, referenceId, description, length } = pd
          return {
            _id,
            label: `Milestone ${index + 1}`,
            length: length || 0,
            value,
            referenceId,
            description,
            exercised: pd.exercised || 0,
            variation: pd.variation || 0,
            index,
          }
        }) || []
    )
  }

  getContractPriceDurationTotals = (priceDurations: ContractWizardPriceDurationTableItem[]) => {
    if (!priceDurations.length) {
      return {
        length: 0,
        variation: 0,
        value: 0,
        exercised: 0,
      }
    }
    return priceDurations.reduce(
      (accumulator, priceDuration) => {
        return {
          ...priceDuration,
          length: accumulator.length + priceDuration.length,
          variation: accumulator.variation + (priceDuration.variation || 0),
          value: accumulator.value + priceDuration.value,
          exercised: accumulator.exercised + priceDuration.exercised,
        }
      },
      {
        length: 0,
        variation: 0,
        value: 0,
        exercised: 0,
      }
    )
  }

  validateContractPriceDurationOrMilestoneVariation = ({ approvedValues, newValues }: ValidateContractPriceDurationVariationParam) => {
    const { originalTotal, originalLength } = (() => {
      const { value, variation, length } = this.getContractPriceDurationTotals(approvedValues)
      return { originalTotal: value + variation, originalLength: length }
    })()

    const { newTotal, newLength } = (() => {
      const { value, variation, length } = this.getContractPriceDurationTotals(newValues)
      return { newTotal: value + variation, newLength: length }
    })()

    return {
      // If the new total value is less than the original total value, return the amount that needs to be made up
      // by the user
      differenceInMonths: newLength < originalLength ? originalLength - newLength : 0,
      differenceInTotalValues: newTotal < originalTotal ? originalTotal - newTotal : 0,
    }
  }

  getContractShellAuditHistory = ({
    contractShell,
    field,
    filterType = 'approved',
    metafields = [],
    metafieldValues = [],
    contractId,
  }: GetContractShellAuditHistoryParam) => {
    const unapprovedContractStatus: ContractStatus[] = ['DRAFTING', 'PENDING_APPROVAL', 'PENDING_SIGNATURES']
    const contractAuditHistory: ContractShellAuditHistoryModel[] = []
    const unapprovedContract = this.getContract(contractShell, unapprovedContractStatus)
    const publishedContract = this.getContract(contractShell, ['PUBLISHED'])
    const unpublishedContracts = filter(contractShell.contracts, ({ status }) => includes(['UNPUBLISHED'], status))

    let contracts: ContractModel[] = []

    if (filterType === 'all') {
      contracts = orderBy(compact([publishedContract, ...unpublishedContracts, unapprovedContract]), 'createdAt', 'asc')
    }

    if (filterType === 'unapproved-compare' && unapprovedContract) {
      contracts = orderBy(compact([publishedContract, unapprovedContract]), 'createdAt', 'asc')
    }

    if (filterType === 'approved') {
      contracts = orderBy(compact([publishedContract, ...unpublishedContracts]), 'createdAt', 'asc')
    }

    if (filterType === 'approval') {
      const approvalContract = find(contractShell.contracts, { _id: contractId })

      const previousContract =
        approvalContract && approvalContract.version > 1 ? find(contractShell.contracts, { version: approvalContract.version - 1 }) : undefined

      contracts = compact([previousContract, approvalContract])
    }

    for (let i = 0; i < contracts.length; i++) {
      const activeContract = contracts[i]
      if (
        (filterType === 'approval' && contracts.length > 1 && i === 0) ||
        (filterType === 'unapproved-compare' && !unapprovedContractStatus.includes(activeContract.status))
      ) {
        // If filterType is 'approval' we only want to compare the approval contract to it's previous contract
        // We don't want the logs from the previous contract.
        // Therefore if there is a previous contract we should skip the first iteration of the loop so that
        // the approval contract becomes the 'activeContract' and the previous contract is the 'previousContract'

        // If filterType is 'unapproved-compare'
        // We only want to *compare* the published (if there is one) contract to the unapproved contract,
        // we don't actually want to display the logs from the published contract
        continue
      }

      const previousContract = i ? contracts[i - 1] : undefined
      const approvedBy = map(this.getApproval(activeContract.approvals, ['APPROVED'])?.approvers, ({ assigned }) => assigned)
      const timestamp = activeContract.metadata.updatedAt
      const actionedBy = activeContract.metadata.lastModifiedBy

      const metafieldsAuditHistory = metafields.map((metafield) => {
        const activeMetaFieldValue = find(metafieldValues, (value) => value.resourceId === activeContract._id && metafield._id === value.metafield)
        const previousMetaFieldValue = find(
          metafieldValues,
          (value) => value.resourceId === previousContract?._id && metafield._id === value.metafield
        )

        return {
          field: metafield,
          value: metafieldValueService.renderFieldValue({ metafield, metafieldValue: activeMetaFieldValue, isEditable: false }) || '--',
          type: this.getContractShellAuditHistoryType({
            activeProperty: activeMetaFieldValue?.fieldValue,
            previousProperty: previousMetaFieldValue?.fieldValue,
          }),
        }
      })

      const regionsAuditType = this.getContractShellAuditHistoryType({
        activeProperty: join(activeContract.metadata.regions, ', '),
        previousProperty: join(previousContract?.metadata.regions, ', '),
      })

      const categoriesAuditType = this.getContractShellAuditHistoryType({
        activeProperty: join(
          map(activeContract.metadata.categories, (category) => category.description),
          ', '
        ),
        previousProperty: join(
          map(previousContract?.metadata.categories, (category) => category.description),
          ', '
        ),
      })

      const externalReferenceAuditType = this.getContractShellAuditHistoryType({
        activeProperty: activeContract.metadata.externalReference,
        previousProperty: previousContract?.metadata.externalReference,
      })

      const currencyAuditType = this.getContractShellAuditHistoryType({
        activeProperty: activeContract.metadata.currency,
        previousProperty: previousContract?.metadata.currency,
      })

      const ownerAuditHistory = this.getContractShellAuditHistoryArray({
        key: 'email',
        active: activeContract.metadata.owners,
        previous: previousContract?.metadata.owners || [],
      })

      forEach(ownerAuditHistory.added, (addedOwner) => {
        contractAuditHistory.push({
          fieldName: 'contract-owner',
          type: 'added',
          value: userService.getFullName(addedOwner),
          timestamp,
          approvedBy,
          actionedBy,
        })
      })

      forEach(ownerAuditHistory.removed, (removedOwner) => {
        contractAuditHistory.push({
          fieldName: 'contract-owner',
          type: 'removed',
          value: userService.getFullName(removedOwner),
          timestamp,
          approvedBy,
          actionedBy,
        })
      })

      regionsAuditType &&
        contractAuditHistory.push({
          fieldName: 'region',
          type: regionsAuditType,
          value: join(map(regionService.processRegions(activeContract.metadata.regions), 'label'), ', '),
          timestamp,
          approvedBy,
          actionedBy,
        })

      categoriesAuditType &&
        contractAuditHistory.push({
          fieldName: 'contract-category',
          type: categoriesAuditType,
          value: join(
            map(activeContract.metadata.categories, (category) => category.description),
            ', '
          ),
          timestamp,
          approvedBy,
          actionedBy,
        })

      externalReferenceAuditType &&
        contractAuditHistory.push({
          fieldName: 'internal-reference',
          type: externalReferenceAuditType,
          value: activeContract.metadata.externalReference,
          timestamp,
          approvedBy,
          actionedBy,
        })

      currencyAuditType &&
        contractAuditHistory.push({
          fieldName: 'currency',
          type: currencyAuditType,
          value: activeContract.metadata.currency,
          timestamp,
          approvedBy,
          actionedBy,
        })

      forEach(metafieldsAuditHistory, (history) => {
        if (history.type) {
          contractAuditHistory.push({
            fieldName: history.field.fieldLabel,
            type: history.type,
            value: history.value,
            timestamp,
            approvedBy,
            actionedBy,
          })
        }
      })
    }
    return orderBy(field ? filter(contractAuditHistory, ({ fieldName }) => fieldName === field) : contractAuditHistory, 'timestamp', 'desc')
  }

  getContractShellAuditHistoryArray = <T, K extends keyof T>({ key, active, previous }: { key: K; active: T[]; previous?: T[] }) => {
    const added: T[] = []
    const removed: T[] = []

    forEach(active, (item) => {
      if (!find(previous, (previousItem) => previousItem[key] === item[key])) {
        added.push(item)
      }
    })

    forEach(previous, (item) => {
      if (!find(active, (activeItem) => activeItem[key] === item[key])) {
        removed.push(item)
      }
    })

    return { added, removed }
  }

  getContractShellAuditHistoryType = (param: HasContractPropertyChangedParam): ContractShellAuditHistoryType | undefined => {
    const { activeProperty, previousProperty } = param

    if (!previousProperty && !activeProperty) {
      return
    }

    if (!previousProperty && activeProperty) {
      return 'added'
    }

    if (previousProperty && !activeProperty) {
      return 'removed'
    }

    if (activeProperty && previousProperty && !isEqual(activeProperty, previousProperty)) {
      return 'changed'
    }
  }

  getNextExpirationDate = (priceDurations: ContractPriceDurationModel[]) => {
    // Find the future most expiry date for an exercised duration
    const nextExpiry = map(
      filter(priceDurations, (priceDuration) => priceDuration.endDate !== undefined && priceDuration.exercised > 0),
      (priceDuration) => priceDuration.endDate as string
    ).sort((a, b) => sortService.sortDate(b, a))[0]

    return nextExpiry
  }
}

export const contractService = new ContractService()
