import { gql } from '@apollo/client'
import { GqlApprovalTemplateGroupViewInput, GqlApprovalTemplateGroupViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template/graphql/approval-template-fragment.graphql'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group/graphql/approval-template-group-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryApprovalTemplateGroupView = async (input: GqlApprovalTemplateGroupViewInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateGroupViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      query ApprovalTemplateGroupView($input: ApprovalTemplateGroupViewInput!) {
        approvalTemplateGroupView(input: $input) {
          ...ApprovalTemplateGroupFields
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateGroupView
}
