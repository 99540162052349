import { gql } from '@apollo/client'
import { GqlEvaluationEventViewInput, GqlEvaluationEventViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const queryEvaluationEventView = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventViewQuery>({
    query: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      query EvaluationEventView($input: EvaluationEventViewInput!) {
        evaluationEventView(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationEventView
}
