import React, { FormEvent, memo, useMemo, useState } from 'react'
import { GqlApprovalTemplateFieldsFragment, GqlApprovalTemplateGroupFieldsFragment, GqlApprovalTemplateModuleType } from '@gql'
import { compact, filter, includes, map, some, sortBy, uniq } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { useApprovalTemplate } from '@cotiss/approval-template/hooks/use-approval-template.hook'
import { UserMultiSelect } from '@cotiss/user/components/user-multi-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group/hooks/use-approval-template-group.hook'
import { mutateCreateApprovalTemplateUsers } from '@cotiss/approval-template-user/graphql/mutate-create-approval-template-users.graphql'
import { mutateDeleteApprovalTemplateUsers } from '@cotiss/approval-template-user/graphql/mutate-delete-approval-template-users.graphql'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Select } from '@cotiss/common/components/select.component'

const DEFAULT_APPROVAL_TYPES = ['DFA', 'Legal', 'Financial', 'Commercial'] as const

type FormData = {
  name: string
  userIds: string[]
  isOptional: boolean
}

type Props = {
  approvalTemplateGroupId?: string
  approvalTemplate?: GqlApprovalTemplateFieldsFragment
  module: GqlApprovalTemplateModuleType
  onUpdate?: () => void | Promise<unknown>
}

export const SettingsModulesApprovalTemplateCreateUpdateModal = memo(({ approvalTemplateGroupId, approvalTemplate, module, onUpdate }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { users } = useListUser({ permissions: ['APPROVER'] })
  const { queryApprovalTemplateGroupView } = useApprovalTemplateGroup()
  const [approvalTemplates, setApprovalTemplates] = useState<GqlApprovalTemplateFieldsFragment[]>([])
  const [approvalTemplateGroup, setApprovalTemplateGroup] = useState<GqlApprovalTemplateGroupFieldsFragment>()
  const { queryApprovalTemplateList, mutateCreateApprovalTemplate, mutateUpdateApprovalTemplate } = useApprovalTemplate()
  const [formData, setFormData] = useState<FormData>({
    name: approvalTemplate?.name || '',
    userIds: approvalTemplate ? map(approvalTemplate.approvalTemplateUsers, ({ user }) => user.id) : [],
    isOptional: approvalTemplate?.isOptional || false,
  })

  const approvalTypeOptions = useMemo(() => {
    const approvalTemplateNames = compact(map(approvalTemplates, 'name'))
    const approvalTypeOptions = map(sortBy(uniq([...DEFAULT_APPROVAL_TYPES, ...approvalTemplateNames])), (name) => ({ value: name, label: name }))

    if (formData.name && !some(approvalTypeOptions, { value: formData.name })) {
      approvalTypeOptions.push({
        value: formData.name,
        label: formData.name,
      })
    }

    return approvalTypeOptions
  }, [formData, approvalTemplates])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)

      const { approvalTemplates } = await queryApprovalTemplateList({ filter: { module } })
      setApprovalTemplates(approvalTemplates)

      approvalTemplateGroupId && setApprovalTemplateGroup(await queryApprovalTemplateGroupView({ approvalTemplateGroupId }))
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsLoading(false)
    }
  }, [approvalTemplate])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      if (approvalTemplate) {
        // Update approval template users
        const approvalTemplateUserIdsToCreate = filter(
          formData.userIds,
          (userId) => !some(approvalTemplate.approvalTemplateUsers, ({ user }) => user.id === userId)
        )

        const approvalTemplateUserIdsToDelete = map(
          filter(approvalTemplate.approvalTemplateUsers, ({ user }) => !includes(formData.userIds, user.id)),
          (approvalTemplateUser) => approvalTemplateUser.id
        )

        // Run queries in parallel
        await Promise.all([
          // Create new approval template users
          mutateCreateApprovalTemplateUsers({ approvalTemplateId: approvalTemplate.id, userIds: approvalTemplateUserIdsToCreate }),

          // Delete approval template users
          mutateDeleteApprovalTemplateUsers({ approvalTemplateUserIds: approvalTemplateUserIdsToDelete }),

          // Update the approval template
          mutateUpdateApprovalTemplate({ approvalTemplateId: approvalTemplate.id, name: formData.name, isOptional: formData.isOptional }),
        ])
      } else {
        // Create approval template
        const createdApprovalTemplate = await mutateCreateApprovalTemplate({
          approvalTemplateGroupId,
          module: 'contract',
          name: formData.name,
          order: approvalTemplateGroup ? approvalTemplateGroup.approvalTemplates.length + 1 : 1,
          isOptional: formData.isOptional,
        })

        // Create approval template users, can't be done in parallel with the approval template create since we need the approval template id first
        await mutateCreateApprovalTemplateUsers({
          approvalTemplateId: createdApprovalTemplate.id,
          userIds: formData.userIds,
        })
      }

      await Promise.all([approvalTemplateGroupId && queryApprovalTemplateGroupView({ approvalTemplateGroupId }), onUpdate && onUpdate()])

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[550px] max-w-[550px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${approvalTemplate ? 'Edit' : 'Add'} sequence`} isDisabled={isSaving} />
      <ModalContent isScrollable={formData.userIds.length > 3}>
        <Label>Select or create approval type</Label>
        <Select
          value={formData.name}
          options={approvalTypeOptions}
          onChange={(name) => setFormData({ ...formData, name })}
          onCreate={(name) => setFormData({ ...formData, name })}
          isDisabled={isLoading || isSaving}
          isRequired
        />
        <Label className="mt-4">Assign users to this approval</Label>
        <UserMultiSelect
          value={formData.userIds}
          options={users}
          onChange={(userIds) => setFormData({ ...formData, userIds })}
          isDisabled={isLoading || isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter className="flex items-center justify-between" isSaving={isSaving}>
        <label className="flex items-center" htmlFor="approval-type-optional-checkbox">
          <Checkbox
            id="approval-type-optional-checkbox"
            className="mr-1"
            onChange={() => setFormData({ ...formData, isOptional: !formData.isOptional })}
            isChecked={formData.isOptional}
            isDisabled={isLoading || isSaving}
          />
          <Text>This approval type is optional</Text>
        </label>
        <div>
          <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </Form>
  )
})
