import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { Form } from '@cotiss/common/components/form.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { ContractStepCardSkeletonLoading } from '@cotiss/contract/components/contract-step-card-skeleton-loading.component'
import { ContractWizardMilestoneTableItem } from '@cotiss/contract/components/contract-wizard-milestone-table.component'
import { ContractWizardPriceDurationTableItem } from '@cotiss/contract/components/contract-wizard-price-duration-table.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'

type Props = {
  onNext: () => void
  onBack: (() => void) | null
  contractShellId: string
}

export const ContractWizardExerciseStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const { updateContractPriceDurationBulk, updateContractMilestoneBulk } = useMutateContractShell()
  const { contractPeriods, contract, exercisedContractPeriodIds, approvedExercisedPriceDurationReferenceIds } = useMemo(() => {
    if (!contractShell) {
      return { contractPeriods: [], contractId: null }
    }
    const contract = contractService.getContract(contractShell, ['DRAFTING'])
    const priceDurations = contractService.parseContractPriceDurations(contract?.priceDurations || [])
    const milestones = contractService.parseContractMilestones(contract?.milestones || [])
    const exercisedPriceDurations = filter(contract?.priceDurations, (priceDuration) => priceDuration.exercised > 0)
    const exercisedMilestones = filter(contract?.milestones, (milestone) => milestone.exercised > 0)

    // The ids of the exercised price durations from the last approved contract/variation
    const latestApprovedContract = contractShell ? contractService.getContract(contractShell, ['PUBLISHED']) : null
    const approvedExercisedPriceDurationReferenceIds = map(
      filter(latestApprovedContract?.priceDurations, (pd) => Boolean(pd.exercised)),
      (pd) => pd.referenceId
    )

    const contractPeriods: (ContractWizardPriceDurationTableItem | ContractWizardMilestoneTableItem)[] =
      contractShell.type === 'MILESTONE' ? milestones : priceDurations

    const exercisedContractPeriodIds =
      contractShell.type === 'MILESTONE' ? map(exercisedMilestones, (pd) => pd._id) : map(exercisedPriceDurations, (pd) => pd._id)

    return {
      contractPeriods,
      contract,
      exercisedContractPeriodIds,
      approvedExercisedPriceDurationReferenceIds,
    }
  }, [contractShell])

  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<string[]>(exercisedContractPeriodIds || [])

  useEffect(() => {
    track('contract_wizard_exercise_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('contract_wizard_exercise_update_submit')

    if (!contractShell || !contract) {
      sentryService.captureException({
        exception: 'Tried exercising price duration but missing either contract shell or contract id',
        extras: { contractShellId: contractShell?._id },
      })
      openToast('Whoops, something went wrong exercising contract period', 'danger')
      return
    }

    try {
      setIsSaving(true)
      if (contractShell.type === 'MILESTONE') {
        await updateContractMilestoneBulk(contractShell._id, contract._id, {
          items: contract.milestones.map((milestone) => {
            const { _id, value, variation = 0 } = milestone
            return {
              ...milestone,
              variation,
              exercised: formData.includes(_id) ? value + variation : 0,
            }
          }),
        })
      }

      if (!contractShell.type || contractShell.type === 'PRICE_DURATION') {
        await updateContractPriceDurationBulk(contractShell._id, contract._id, {
          items: contract.priceDurations.map((priceDuration) => {
            const { _id, value, variation = 0, startDate, endDate } = priceDuration
            return {
              ...priceDuration,
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
              variation,
              exercised: formData.includes(_id) ? value + variation : 0,
            }
          }),
        })
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    onNext()
  }

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="p-6">
          <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
          <Skeleton className="bg-primary-200 h-16 w-full" />
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Term',
      rows: map(contractPeriods, ({ _id, referenceId = '', label }) => ({
        content: () => (
          <label className="flex items-center cursor-pointer" htmlFor={`contract-exercise-term-${_id}`}>
            <Checkbox
              id={`contract-exercise-term-${_id}`}
              className="mr-2"
              isDisabled={approvedExercisedPriceDurationReferenceIds?.includes(referenceId)}
              isChecked={formData.includes(_id)}
              onChange={() =>
                formData.includes(_id) ? setFormData([...formData].filter((value) => value !== _id)) : setFormData([...formData, _id])
              }
            />
            <div className="flex items-end">
              {!approvedExercisedPriceDurationReferenceIds?.includes(referenceId) && (
                <Text className="font-semibold truncate" variant="none">
                  {label}
                </Text>
              )}
              {approvedExercisedPriceDurationReferenceIds?.includes(referenceId) && (
                <Tooltip_DEPRECATED tooltip="This period had been exercised and cannot be edited">
                  <Text className="font-semibold truncate" variant="light">
                    {label}
                  </Text>
                </Tooltip_DEPRECATED>
              )}
            </div>
          </label>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    ...(!contractShell.type || contractShell.type === 'PRICE_DURATION'
      ? [
          {
            heading: 'Dates',
            rows: map(contractPeriods as ContractWizardPriceDurationTableItem[], ({ startDate, endDate, referenceId = '' }) => ({
              content: () => (
                <Text variant={approvedExercisedPriceDurationReferenceIds?.includes(referenceId) ? 'light' : 'none'}>
                  {startDate && endDate
                    ? `${datetimeService.format(startDate, 'do MMM yyyy')} - ${datetimeService.format(endDate, 'do MMM yyyy')}`
                    : '--'}
                </Text>
              ),
            })),
          },
        ]
      : []),
    {
      heading: 'Duration',
      rows: map(contractPeriods, ({ length, referenceId = '' }) => ({
        content: () => (
          <Text variant={approvedExercisedPriceDurationReferenceIds?.includes(referenceId) ? 'light' : 'none'}>{`${length} months`}</Text>
        ),
      })),
    },
    {
      heading: 'Total',
      rows: map(contractPeriods, ({ value, referenceId = '', variation }) => ({
        content: () => (
          <Text variant={approvedExercisedPriceDurationReferenceIds?.includes(referenceId) ? 'light' : 'none'}>
            {utilService.formatAsCurrency(value + variation, contract?.metadata.currency)}
          </Text>
        ),
      })),
    },
  ]

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5">
              Exercise period
            </Text>
          </div>
          <div className="ml-4">
            {onBack && (
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
                Back
              </Button>
            )}
            <Button type="submit" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
              Continue
            </Button>
          </div>
        </CardHeader>
        <div className="p-6">
          {!contractPeriods.length && <NoDataPlaceholder label="No contract periods to exercise" />}
          {Boolean(contractPeriods.length) && (
            <>
              <Text className="font-medium mb-10">Select the contract periods that you would like to exercise immediately</Text>
              <ScrollableTable fixedColumnsWidth={350} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
            </>
          )}
        </div>
      </Card>
    </Form>
  )
})
