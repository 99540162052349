import { useState } from 'react'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useDeepMemo } from '@cotiss/common/hooks/use-deep-memo.hook'
import { Filter } from '@cotiss/common/models/filter.model'
import {
  ContractSearchFiltersContextState,
  useContractSearchFiltersContext,
} from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { CONTRACT_LIST_PRIMARY_TAB_FILTERS } from '@cotiss/contract/contract.constants'
import { ContractShellFilterTotals, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
// This is a helper function for getting the base filters for the filter totals. They don't include the filters set by clicking the status card (as this would affect the totals shown in the card).
export function getContractBaseFilters({
  queryState,
  primaryTabFilters,
}: {
  queryState: ContractSearchFiltersContextState
  primaryTabFilters: Record<string, { filters: Filter[] }>
}) {
  return {
    filters: [...queryState.advancedFilters, ...primaryTabFilters[queryState.primaryTab].filters],
    searchQuery: queryState.searchQuery,
  }
}

export const useContractListFilterTotals = ({ totalFilters }: { totalFilters: Record<string, { filters: Filter[] }> }) => {
  const [totals, setTotals] = useState<Record<string, ContractShellFilterTotals>>({})
  const [isLoading, setIsLoading] = useState(false)
  const { queryState, processedFilters } = useContractSearchFiltersContext()
  const { countContractShellFilterTotals } = useMutateContractShell()

  const filterTotalsParams = useDeepMemo(
    () => ({
      totalFilters,
      baseFilters: getContractBaseFilters({ queryState, primaryTabFilters: CONTRACT_LIST_PRIMARY_TAB_FILTERS }),
    }),
    [totalFilters, processedFilters, queryState.searchQuery]
  )

  useAsyncEffect(async () => {
    setIsLoading(true)
    try {
      const { totals } = await countContractShellFilterTotals(filterTotalsParams)
      setTotals(totals)
    } finally {
      setIsLoading(false)
    }
  }, [filterTotalsParams])

  return { totals, isLoading }
}
