import { gql } from '@apollo/client'
import {
  GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdInput,
  GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdMutation,
  GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdMutationVariables,
  GqlEvaluationUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId = async (
  input: GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdInput
) => {
  const { data } = await apolloService.mutate<
    GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdMutation,
    GqlDeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdMutationVariables
  >({
    mutation: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      mutation DeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId(
        $input: DeleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeIdInput!
      ) {
        deleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationUserEvaluateAccessControlsByEvaluationEnvelopeId as GqlEvaluationUserFieldsFragment
}
