import { gql } from '@apollo/client'
import { GqlPerformanceMetricViewInput, GqlPerformanceMetricViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-metric/performance-metric-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceMetricView = async (input: GqlPerformanceMetricViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceMetricViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      query PerformanceMetricView($input: PerformanceMetricViewInput!) {
        performanceMetricView(input: $input) {
          ...PerformanceMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceMetricView
}
