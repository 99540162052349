import { gql } from '@apollo/client'
import {
  GqlUpdatePerformanceMetricInput,
  GqlUpdatePerformanceMetricMutation,
  GqlUpdatePerformanceMetricMutationVariables,
  GqlPerformanceMetricFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-metric/performance-metric-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateUpdatePerformanceMetric = async (input: GqlUpdatePerformanceMetricInput) => {
  const { data } = await apolloService.mutate<GqlUpdatePerformanceMetricMutation, GqlUpdatePerformanceMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      mutation UpdatePerformanceMetric($input: UpdatePerformanceMetricInput!) {
        updatePerformanceMetric(input: $input) {
          ...PerformanceMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceMetric as GqlPerformanceMetricFieldsFragment
}
