import React, { memo } from 'react'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventPanelList } from '@cotiss/evaluation-event/components/evaluation-event-panel-list.component'

export const EvaluationEventSummaryPanel = memo(() => (
  <>
    <TableHeader>
      <Text className="font-semibold truncate" variant="heading">
        Your evaluation panel
      </Text>
    </TableHeader>
    <EvaluationEventPanelList />
  </>
))
