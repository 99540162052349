import { useMemo } from 'react'
import { filter, sortBy } from 'lodash'
import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { EvaluationStatus } from '@cotiss/evaluation/evaluation.models'
import { TenderQuestionScoreModel } from '@cotiss/tender-question/tender-question.models'

type Props = {
  evaluationId?: string
  evaluationStatus?: EvaluationStatus
  tenderQuestionId?: string
  tenderResponseId?: string
  limit?: number
  offset?: number
}

export const useListTenderQuestionScore = ({
  evaluationId,
  evaluationStatus,
  tenderQuestionId,
  tenderResponseId,
  limit = -1,
  offset = 0,
}: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderQuestionScoreModel>>('/v2/tender-question-scores', {
    route: `/v2/tender-question-scores`,
    params: { evaluationId, tenderQuestionId, offset, limit },
    isEnabled: Boolean(evaluationId) || Boolean(tenderQuestionId),
  })

  const tenderQuestionScores = useMemo(() => {
    let tenderQuestionScores = data?.items || []

    if (tenderResponseId) {
      tenderQuestionScores = filter(tenderQuestionScores, ({ evaluation }) => evaluation.tenderResponse === tenderResponseId)
    }

    if (evaluationStatus) {
      tenderQuestionScores = filter(tenderQuestionScores, ({ evaluation }) => evaluation.status === evaluationStatus)
    }

    tenderQuestionScores = sortBy(tenderQuestionScores, ['evaluation.user.firstname', 'evaluation.user.surname'])

    return tenderQuestionScores
  }, [data, evaluationStatus, tenderResponseId])

  return { tenderQuestionScores, count: data?.count || 0, meta: data?.meta, ...rest }
}
