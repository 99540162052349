import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEnvelopeDocumentsInput,
  GqlCreateEvaluationEnvelopeDocumentsMutation,
  GqlCreateEvaluationEnvelopeDocumentsMutationVariables,
  GqlEvaluationEnvelopeDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope-document/evaluation-envelope-document-fragment.graphql'

export const mutateCreateEvaluationEnvelopeDocuments = async (input: GqlCreateEvaluationEnvelopeDocumentsInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEnvelopeDocumentsMutation, GqlCreateEvaluationEnvelopeDocumentsMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationEnvelopeDocuments($input: CreateEvaluationEnvelopeDocumentsInput!) {
        createEvaluationEnvelopeDocuments(input: $input) {
          ...EvaluationEnvelopeDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEnvelopeDocuments as GqlEvaluationEnvelopeDocumentFieldsFragment[]
}
