import React, { memo, useEffect, useMemo, useState } from 'react'
import { compact, filter, find, includes, map, uniq } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import {
  PreferredSupplierSearchAndFiltersProvider,
  usePreferredSupplierSearchAndFilters,
} from '@cotiss/preferred-supplier/components/preferred-supplier-search-and-filters'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'
import { PreferredSupplierListSearchInput } from '@cotiss/preferred-supplier/components/preferred-supplier-list-search-input.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useHistory } from 'react-router-dom'
import { routerService } from '@cotiss/common/services/router.service'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import HighlightedText from '@cotiss/common/components/highlighted-text.component'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractEditContactsDrawerInternal = memo(({ contractShellId, contractId }: Props) => {
  const { openToast } = useToast()
  const { push } = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useAnalytics()
  const { closeDrawer } = useCallout()
  const { updateContractMetadata } = useMutateContractShell()
  const { contractShell } = useGetContractShell(contractShellId)
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([])
  const contract = useMemo(() => find(contractShell?.contracts, ({ _id }) => _id === contractId), [contractShell, contractId])

  const { isQuerying, queryState, preferredSuppliers, pagination, isLoadingPreferredSuppliers, handlePageChange, onSort } =
    usePreferredSupplierSearchAndFilters()

  useEffect(() => {
    if (!contract) {
      return
    }

    setSelectedContactIds(map(contract.metadata.suppliers, '_id'))
  }, [contract])

  const handleSubmit = async () => {
    if (!contractShell || !contract || !selectedContactIds.length) {
      return
    }

    setIsSaving(true)
    try {
      await updateContractMetadata(contractShell._id, contract._id, contract.metadata._id, {
        suppliers: selectedContactIds,
      })

      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  const handleAddContact = () => {
    closeDrawer()
    push(routerService.getHref('/preferred-supplier/list/:tab?'))
  }

  const handlePreferredSupplierChange = (preferredSupplier: PreferredSupplierPopulatedModel) => {
    if (!preferredSupplier.supplierOrganisation?._id) {
      return
    }

    if (includes(selectedContactIds, preferredSupplier.supplierOrganisation._id)) {
      setSelectedContactIds(filter(selectedContactIds, (selectedContactId) => selectedContactId !== preferredSupplier.supplierOrganisation?._id))
    } else {
      setSelectedContactIds(uniq(compact([...selectedContactIds, preferredSupplier.supplierOrganisation._id])))
    }
  }

  const { fixedColumns, columns } = useMemo(() => {
    const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
      if (!preferredSupplier.tags?.length) {
        return <Text size="sm">--</Text>
      }

      const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

      return (
        <>
          <Badge className="mr-1" state="outline" variant="secondary">
            {firstTag}
          </Badge>
          {Boolean(rest.length) && (
            <Tooltip_DEPRECATED tooltip={rest.join(', ')}>
              <Text className="font-medium" variant="link" size="sm">
                +{rest.length}
              </Text>
            </Tooltip_DEPRECATED>
          )}
        </>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Checkbox
              value={preferredSupplier._id}
              name="evaluation-event-submission-contact"
              onChange={() => handlePreferredSupplierChange(preferredSupplier)}
              isChecked={includes(selectedContactIds, preferredSupplier.supplierOrganisation?._id)}
              isDisabled={isSaving}
            />
          ),
        })),
      },
      {
        heading: 'Organisation name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" title={preferredSupplier.supplierOrganisation?.name || '--'}>
              <HighlightedText text={preferredSupplier.supplierOrganisation?.name || '--'} highlightText={queryState.searchQuery} />
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [preferredSuppliers, selectedContactIds, isSaving])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Associate counterparties
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button variant="secondary" type="submit" isLoading={isSaving} isDisabled={!selectedContactIds.length || isLoadingPreferredSuppliers}>
        Submit
      </Button>
      <Text className="ml-2">{selectedContactIds.length} selected</Text>
    </div>
  )

  const renderContent = () => {
    const emptyState = isQuerying ? (
      <Text size="md" variant="light">
        No results
      </Text>
    ) : (
      <NoDataPlaceholder
        illustration="dot-list"
        variant="transparent"
        label="You have not added any contacts yet."
        ctaLabel="+ Add contact"
        onCtaClick={handleAddContact}
      />
    )

    return (
      <>
        <TableHeader className="flex items-center">
          <Text className="font-medium" size="lg">
            Select contact
          </Text>
        </TableHeader>
        <div className="w-full h-13 px-4 border-t flex items-center justify-between gap-4 border-l border-r">
          <PreferredSupplierListSearchInput className="flex-1" />
          <VerticalDivider className="h-7" />
          <AdvancedFiltersDropdownButton title="Filter contacts" onOpen={() => track('preferred_supplier_list_filters_dropdown_open')} />
        </div>
        {queryState.advancedFilters?.length ? (
          <div className="w-full px-4 py-3 border-x border-t">
            <AdvancedFiltersPillList />
          </div>
        ) : null}
        {!isLoadingPreferredSuppliers && !preferredSuppliers.length ? (
          <div className="flex items-center justify-center h-60 bg-white border">{emptyState}</div>
        ) : (
          <ScrollableTable
            fixedColumns={fixedColumns}
            columns={columns}
            pagination={pagination}
            onPageChange={handlePageChange}
            isLoading={isLoadingPreferredSuppliers}
          />
        )}
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      {renderContent()}
    </Drawer>
  )
})

export const ContractEditContactsDrawer = ({ contractShellId, contractId }: Props) => {
  return (
    <PreferredSupplierSearchAndFiltersProvider>
      <ContractEditContactsDrawerInternal contractShellId={contractShellId} contractId={contractId} />
    </PreferredSupplierSearchAndFiltersProvider>
  )
}
