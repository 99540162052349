import React, { ReactNode, memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  className?: string
  children: ReactNode
  maxHeight?: number
}

export const ViewMoreContainer = memo(({ className, children, maxHeight = 40 }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [isOverflowVisible, setIsOverflowVisible] = useState(true)
  const contentClasses = classNames({
    'line-clamp-2': !isOverflowVisible,
  })

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) > maxHeight) {
      setIsOverflowVisible(false)
      setHasOverflow(true)
    }
  }, [contentRef])

  return (
    <div className={className}>
      <div className={contentClasses} ref={contentRef}>
        {children}
      </div>
      {hasOverflow && (
        <Button onClick={() => setIsOverflowVisible(!isOverflowVisible)} state="text" variant="link">
          View {isOverflowVisible ? 'less' : 'more'}
        </Button>
      )}
    </div>
  )
})
