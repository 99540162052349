import { gql } from '@apollo/client'
import { GqlPerformanceScorecardUserListInput, GqlPerformanceScorecardUserListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardUserList = async (input: GqlPerformanceScorecardUserListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardUserList($input: PerformanceScorecardUserListInput!) {
        performanceScorecardUserList(input: $input) {
          items {
            ...PerformanceScorecardUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardUserList
}
