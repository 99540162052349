import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'

type Props = {
  tenderId: string
}

export const TaskListEvaluationCta = memo(({ tenderId }: Props) => {
  return (
    <Button href={routerService.getHref('/evaluation/tender/:tenderId', { tenderId })} state="outline" variant="secondary" size="xs" isLink>
      View <Icon className="ml-1" icon="arrow-right" />
    </Button>
  )
})
