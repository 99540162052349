import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Text } from '@cotiss/common/components/text.component'

type Props<T> = {
  className?: string
  children?: ReactNode
  name?: string
  value?: T
  onChange?: (value: T) => void
  isChecked?: boolean
  isDisabled?: boolean
  isRequired?: boolean
}

export function Radio<T>({ className, children, name, value, onChange, isChecked, isDisabled, isRequired }: Props<T>) {
  const classes = classNames(className, 'inline-flex', {
    'items-center': children,
    'pointer-events-none': isDisabled,
    'cursor-pointer': !isDisabled,
  })
  const radioClasses = classNames('inline-flex items-center justify-center border border-gray-300 rounded-full w-4 h-4', {
    'mr-2': children,
    'bg-gray-200': isDisabled && !isChecked,
    'border-gray-500 bg-gray-500': isChecked && isDisabled,
  })

  if (!onChange) {
    return (
      <div className={classes}>
        <div className={radioClasses}>{isChecked && <div className="bg-secondary-500 rounded-full h-2 w-2" />}</div>
        {children && <Text>{children}</Text>}
      </div>
    )
  }

  return (
    <label className={classes}>
      <input
        className="sr-only"
        onChange={() => onChange && value !== undefined && onChange(value)}
        type="radio"
        name={name}
        checked={isChecked || false}
        required={isRequired}
        disabled={isDisabled}
      />
      <div className={radioClasses}>{<div className={classNames('bg-secondary-500 rounded-full h-2 w-2', { invisible: !isChecked })} />}</div>
      {children && <Text>{children}</Text>}
    </label>
  )
}
