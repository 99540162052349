import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { SsoConfigurationModel, SsoProviderName } from '@cotiss/auth/auth.models'

type RegisterTwoFactorBody = {
  secret: string
  token: string
}

type UnregisterTwoFactorBody = {
  password: string
}

type ResetPasswordBody = {
  token: string
  newPassword: string
}

type UpdateSsoBody = {
  providerName?: SsoProviderName
  url?: string
  metadataUrl?: string
  x509Certificate?: string
  enabled?: boolean
}

export const useMutateAuth = () => {
  const { mutate } = useMutate()

  return {
    registerTwoFactor: async (body: RegisterTwoFactorBody) => {
      return await mutate({ route: '/v2/auth/register-two-factor', body, invalidate: ['/v2/users/me'] })
    },
    unregisterTwoFactor: async (body: UnregisterTwoFactorBody) => {
      return await mutate({ route: '/v2/auth/unregister-two-factor', body, invalidate: ['/v2/users/me'] })
    },
    resetPassword: async (body: ResetPasswordBody) => {
      return await mutate({ route: '/v3/auth/reset-password', body, invalidate: ['/v2/users/me'] })
    },
    createSsoConfiguration: async (): Promise<SsoConfigurationModel> => {
      return await mutate({ route: '/v3/sso/saml/configuration', invalidate: ['/v3/sso'] })
    },
    updateSsoConfiguration: async (id: string, body: UpdateSsoBody) => {
      return await mutate({ route: `/v3/sso/saml/configuration/${id}`, body, method: 'PUT', invalidate: ['/v3/sso'] })
    },
  }
}
