import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Form } from '@cotiss/common/components/form.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventSummaryCriteria } from '@cotiss/evaluation-event/components/evaluation-event-summary-criteria.component'
import { EvaluationEventSummaryEnvelopes } from '@cotiss/evaluation-event/components/evaluation-event-summary-envelopes.component'
import { EvaluationEventSummaryMethodology } from '@cotiss/evaluation-event/components/evaluation-event-summary-methodology.component'
import { EvaluationEventSummaryPanel } from '@cotiss/evaluation-event/components/evaluation-event-summary-panel.component'
import { EvaluationEventSummaryScoring } from '@cotiss/evaluation-event/components/evaluation-event-summary-scoring.component'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  onBack: () => void
}

type EvaluationSummaryTab = 'envelopes' | 'methodology' | 'criteria' | 'panel' | 'scoring'
const TABS: TabModel<EvaluationSummaryTab>[] = [
  { id: 'envelopes', label: 'Envelopes' },
  { id: 'methodology', label: 'Methodology' },
  { id: 'criteria', label: 'Criteria & sub-criteria' },
  { id: 'panel', label: 'Panel' },
  { id: 'scoring', label: 'Scoring' },
]

export const EvaluationEventWizardSummaryStep = memo(({ onBack }: Props) => {
  const { openToast } = useToast()
  const { closeFullModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const { evaluationEvent, mutateUpdateEvaluationEvent } = useEvaluationEvent()
  const activeTabId = useMemo(() => TABS[step - 1].id, [step])

  useEffect(() => {
    track('evaluation_event_wizard_summary_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_summary_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEvent({ evaluationEventId: evaluationEvent.id, isSetupComplete: true })
      closeFullModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleTabChange = (tab: EvaluationSummaryTab) => {
    const newStep = findIndex(TABS, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <Text className="font-semibold" variant="heading" size="h5">
            Evaluation summary
          </Text>
          <div className="ml-4">
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isSaving}>
              Back
            </Button>
            <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
              Submit
            </Button>
          </div>
        </CardHeader>

        <Tabs<EvaluationSummaryTab>
          className="border-b border-gray-300 w-full mb-6"
          tab={activeTabId}
          tabs={TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {activeTabId === 'envelopes' && <EvaluationEventSummaryEnvelopes hasParallelRecommendation isSortable />}
            {activeTabId === 'methodology' && <EvaluationEventSummaryMethodology />}
            {activeTabId === 'criteria' && <EvaluationEventSummaryCriteria />}
            {activeTabId === 'panel' && <EvaluationEventSummaryPanel />}
            {activeTabId === 'scoring' && <EvaluationEventSummaryScoring />}
          </TransitionContainer>
        </AnimatePresence>
      </Card>
    </Form>
  )
})
