import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'

export const useGetTender = (id?: string) => {
  const { data: tender, ...rest } = useFetch<TenderPopulatedModel>('/v2/tenders', {
    route: `/v2/tenders/${id}`,
    isEnabled: Boolean(id),
  })

  return { tender, ...rest }
}
