import React, { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { ORGANISATION_CERTIFICATION_OPTIONS } from '@cotiss/organisation/organisation.constants'
import { OrganisationCertificationModel, OrganisationCertificationType, OrganisationModel } from '@cotiss/organisation/organisation.models'
import { useMutateOrganisation } from '@cotiss/organisation/resources/use-mutate-organisation.resource'

type FormData = {
  title: string
  type?: OrganisationCertificationType
  description: string
  link: string
  attachments: DocumentModel[]
}

type Props = {
  organisation: OrganisationModel
  certification?: OrganisationCertificationModel
}

export const SupplierAddUpdateCertificationModal = memo(({ organisation, certification }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createOrganisationCertification, updateOrganisationCertification } = useMutateOrganisation()
  const [formData, setFormData] = useState<FormData>({
    title: certification?.title || '',
    type: certification?.type,
    description: certification?.description || '',
    link: certification?.link || '',
    attachments: certification?.attachments || [],
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.type) {
      return
    }

    try {
      setIsSaving(true)
      const formDataToPost = { ...formData, type: formData.type, attachments: map(formData.attachments, ({ _id }) => _id) }

      if (certification) {
        await updateOrganisationCertification(organisation._id, certification._id, formDataToPost)
      } else {
        await createOrganisationCertification(organisation._id, formDataToPost)
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${certification ? 'Update' : 'Add'} certification`} isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Enter certification title"
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-4">What type of certification is this?</Label>
        <Select_DEPRECATED<OrganisationCertificationType>
          value={formData.type}
          options={ORGANISATION_CERTIFICATION_OPTIONS}
          onChange={(type) => setFormData({ ...formData, type })}
          isDisabled={isSaving}
          placeholder
          isRequired
        />

        <Label className="mt-4">Description</Label>
        <TextArea
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Enter certification description (Max 500 characters)"
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-4">External link (optional)</Label>
        <Input
          value={formData.link}
          onChange={({ target }) => setFormData({ ...formData, link: target.value })}
          placeholder="Add an external link if relevant"
          isDisabled={isSaving}
        />

        <Label className="mt-4">Attachments</Label>
        <DocumentUpload documents={formData.attachments} onChange={(attachments) => setFormData({ ...formData, attachments })} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
