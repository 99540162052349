import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { ProcurementListPopulatedModel } from '@cotiss/procurement/procurement.models'

export type ListProcurementSortKey = 'title' | 'createdAt' | 'opexBudget' | 'capexBudget'

type Props = {
  currentPage?: number
  pageSize: number
  sort?: ListProcurementSortKey
  order?: 'asc' | 'desc'
  isArchived?: boolean
  projectId?: string
}

export const useListProcurement = ({ currentPage = 1, pageSize = 20, sort = 'createdAt', order = 'asc', isArchived, projectId }: Props) => {
  const { limit, offset } = paginationService.convertPageSizeToOffset({ currentPage, pageSize })

  const { data, ...rest } = useFetch<PaginatedModel<ProcurementListPopulatedModel>>('/v2/procurements', {
    params: {
      limit,
      offset,
      sort,
      order: order === 'asc' ? 1 : -1,
      isArchived,
      projectId,
    },
  })

  const procurements = data?.items || []
  const count = data?.count || 0

  return {
    procurements,
    pagination: {
      currentPage,
      pageSize,
      pageCount: Math.ceil(count / pageSize),
      itemCount: procurements.length,
      totalCount: count,
    },
    meta: data?.meta,
    ...rest,
  }
}
