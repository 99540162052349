import React, { FormEvent, memo, useState } from 'react'
import { parseISO } from 'date-fns'
import { filter, map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import { Badge } from '@cotiss/common/components/badge.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { RegionsModal } from '@cotiss/common/modals/regions.modal'
import { UnspscCategoriesModal } from '@cotiss/common/modals/unspsc-categories.modal'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { regionService } from '@cotiss/common/services/region.service'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { supplierService } from '@cotiss/supplier/supplier.service'
import { TenderHistoryModal } from '@cotiss/tender/modals/tender-history.modal'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TENDER_TYPES, TENDER_TYPE_NAME_MAP, TENDER_VISIBILITY_NAME_MAP, TENDER_VISIBILITY_OPTIONS } from '@cotiss/tender/tender.constants'
import { TenderType, TenderVisibility } from '@cotiss/tender/tender.models'
import { TenderMandatoryCriteriaTable } from '@cotiss/tender-mandatory-criteria/components/tender-mandatory-criteria-table.component'
import { useListTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria/resources/use-list-tender-mandatory-criteria.resource'
import { useMutateTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria/resources/use-mutate-tender-mandatory-criteria.resource'

type FormData = {
  title: string
  endDate: Date | null
  gracePeriod: string
  briefDescription: string
  description: RemirrorJSON
  visibility?: TenderVisibility
  criteria: {
    _id?: string
    description: string
  }[]
  tenderType?: TenderType
  canViewResponsesBeforeClose?: boolean
  regions: string[]
  categories: string[]
  forumCloseDate: Date | null
}

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestDetailsTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { openModal, closeModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { updateTenderMandatoryCriteriaBulk } = useMutateTenderMandatoryCriteria()
  const { tenderMandatoryCriteria, isLoading: isTenderMandatoryCriteriaLoading } = useListTenderMandatoryCriteria({ tenderId })
  const isLoading = isTenderLoading || isTenderMandatoryCriteriaLoading
  const [formData, setFormData] = useState<FormData>({
    title: tender?.title || '',
    endDate: tender?.tenderPeriod?.endDate ? parseISO(tender?.tenderPeriod?.endDate) : null,
    gracePeriod: tender?.gracePeriod?.toString() || '',
    briefDescription: tender?.briefDescription || '',
    description: tender?.description || richTextService.getEmptyRemirrorJson(),
    visibility: tender?.visibility,
    criteria: tenderMandatoryCriteria.length ? map(tenderMandatoryCriteria, ({ _id, description }) => ({ _id, description })) : [],
    tenderType: tender?.tenderType,
    canViewResponsesBeforeClose: tender?.canViewResponsesBeforeClose,
    regions: tender?.regions || [],
    categories: map(tender?.categories, ({ _id }) => _id) || [],
    forumCloseDate: tender?.forumCloseDate?.endDate ? parseISO(tender.forumCloseDate.endDate) : null,
  })
  const processedCategories = supplierService.processCategoryCodes(formData.categories)

  const handleUnspscCategoryChange = (categories: string[]) => {
    setFormData({ ...formData, categories })
    closeModal()
  }

  const handleOpenCategoriesModal = () => {
    openModal(<UnspscCategoriesModal selectedCategoryCodes={formData.categories} onChange={handleUnspscCategoryChange} />)
  }

  const handleOpenRegionsModal = () =>
    openModal(
      <RegionsModal
        selectedRegions={formData.regions}
        onChange={(regions) => {
          setFormData({
            ...formData,
            regions,
          })
        }}
      />
    )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      const { endDate, gracePeriod, criteria, canViewResponsesBeforeClose, tenderType, ...tenderFormData } = formData

      await updateTenderMandatoryCriteriaBulk({
        tenderId: tenderId,
        items: map(criteria, ({ _id, description }, index) => ({ tenderMandatoryCriteriaId: _id, body: { index, description } })),
      })

      await updateTender(tenderId, {
        ...tenderFormData,
        canViewResponsesBeforeClose,
        tenderType,
        tenderPeriod: { endDate: endDate?.toISOString() },
        gracePeriod: Number(gracePeriod),
        forumCloseDate: { endDate: formData.forumCloseDate?.toISOString() },
      })
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({
      title: tender?.title || '',
      endDate: tender?.tenderPeriod?.endDate ? parseISO(tender.tenderPeriod?.endDate) : null,
      gracePeriod: tender?.gracePeriod?.toString() || '',
      briefDescription: tender?.briefDescription || '',
      description: tender?.description || richTextService.getEmptyRemirrorJson(),
      visibility: tender?.visibility,
      criteria: tenderMandatoryCriteria.length ? map(tenderMandatoryCriteria, ({ _id, description }) => ({ _id, description })) : [],
      canViewResponsesBeforeClose: tender?.canViewResponsesBeforeClose,
      tenderType: tender?.tenderType,
      regions: tender?.regions || [],
      categories: map(tender?.categories, ({ _id }) => _id) || [],
      forumCloseDate: tender?.forumCloseDate?.endDate ? parseISO(tender.forumCloseDate.endDate) : null,
    })

    setIsEditing(isEditing)
  }

  const handleUpdateCriteria = (indexToUpdate: number, description: string) => {
    const criteria = map(formData.criteria, (criteria, index) => (index === indexToUpdate ? { ...criteria, description } : criteria))
    setFormData({ ...formData, criteria })
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderGracePeriod = () => {
    if (!formData.gracePeriod) {
      return 'Submissions will not be allowed after close date'
    }

    return `Submissions will be allowed ${utilService.pluralizeCount(parseInt(formData.gracePeriod), 'hour')} after close`
  }

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Field label="Title">
            <Input value={formData.title} onChange={({ target }) => setFormData({ ...formData, title: target.value })} isDisabled={isSaving} />
          </Field>
          <Field className="mt-8" label="Type">
            <Select_DEPRECATED
              className="w-2/3"
              value={formData.tenderType}
              options={TENDER_TYPES.map((key) => ({ value: key, label: TENDER_TYPE_NAME_MAP[key] }))}
              onChange={(tenderType) => setFormData({ ...formData, tenderType })}
              isDisabled={isSaving}
              placeholder
              isRequired
            />
          </Field>
          <Field className="mt-8" label="Regions">
            {!formData.regions.length && <NoDataPlaceholder label="No regions selected" ctaLabel="+ Add" onCtaClick={handleOpenRegionsModal} />}
            {Boolean(formData.regions.length) && (
              <>
                <div className="bg-primary-50 p-2 pb-3">
                  {map(regionService.processRegions(formData.regions), ({ value, label }) => (
                    <Pill
                      className="mr-1 mt-1"
                      key={value}
                      onRemove={() => setFormData({ ...formData, regions: filter(formData.regions, (c) => c !== value) })}
                      isDisabled={isSaving}
                    >
                      {label}
                    </Pill>
                  ))}
                </div>
                <Button className="mt-2" onClick={handleOpenRegionsModal} size="xs" state="translucent" variant="secondary" isDisabled={isSaving}>
                  + Add
                </Button>
              </>
            )}
          </Field>
          <Field className="mt-8" label="Categories">
            {!formData.categories.length && (
              <NoDataPlaceholder label="No categories selected" ctaLabel="+ Add" onCtaClick={handleOpenCategoriesModal} />
            )}
            {Boolean(formData.categories.length) && (
              <>
                <div className="bg-primary-50 p-2 pb-3">
                  {map(processedCategories, ({ code, name }) => (
                    <Pill
                      className="mr-1 mt-1"
                      key={code}
                      onRemove={() => setFormData({ ...formData, categories: filter(formData.categories, (c) => c !== code) })}
                      isDisabled={isSaving}
                    >
                      {name}
                    </Pill>
                  ))}
                </div>
                <Button className="mt-2" onClick={handleOpenCategoriesModal} size="xs" state="translucent" variant="secondary" isDisabled={isSaving}>
                  + Add
                </Button>
              </>
            )}
          </Field>
          <Field className="mt-8" label="Close date">
            <DatetimeInput isFuture value={formData.endDate} onChange={(endDate) => setFormData({ ...formData, endDate })} isDisabled={isSaving} />
          </Field>
          <Field className="mt-8" label="Forum close date">
            <DatetimeInput
              isFuture
              value={formData.forumCloseDate}
              onChange={(forumCloseDate) => setFormData({ ...formData, forumCloseDate })}
              isDisabled={isSaving}
            />
          </Field>
          <Field className="mt-8" label="Grace period" supplementary="Add a grace period for submissions (hours, max 24)">
            <Input
              value={formData.gracePeriod}
              onChange={({ target }) => setFormData({ ...formData, gracePeriod: target.value })}
              type="number"
              min={0}
              max={24}
              isDisabled={isSaving}
            />
            <Text className="mt-1" size="sm" variant="light">
              {renderGracePeriod()}
            </Text>
          </Field>
          <Field className="mt-8" label="Brief description">
            <TextArea
              value={formData.briefDescription}
              onChange={({ target }) => setFormData({ ...formData, briefDescription: target.value })}
              rows={2}
              isDisabled={isSaving}
            />
          </Field>
          <Field className="mt-8" label="Summary">
            <RichTextEditor
              value={formData.description}
              onChange={(description) => setFormData({ ...formData, description })}
              isDisabled={isSaving}
            />
          </Field>
          <Field
            className="mt-8"
            label="Visibility"
            supplementary="Select whether you want your tender to be open to the market (visible on your portal) or invite only."
          >
            <Select_DEPRECATED<TenderVisibility>
              value={formData.visibility}
              options={TENDER_VISIBILITY_OPTIONS}
              onChange={(visibility) => setFormData({ ...formData, visibility })}
              isDisabled={isSaving}
              placeholder
            />
          </Field>

          <Field
            className="mt-8"
            label="Response visibility"
            supplementary="Do you want to be able to view submitted responses while the listing is still active? (prior to the close date)"
          >
            <div className="flex items-center mt-4">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(canViewResponsesBeforeClose) => setFormData({ ...formData, canViewResponsesBeforeClose })}
                name="can-view-response-before-close"
                isChecked={formData.canViewResponsesBeforeClose}
                isDisabled={isSaving}
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(canViewResponsesBeforeClose) => setFormData({ ...formData, canViewResponsesBeforeClose })}
                name="can-view-response-before-close"
                isChecked={formData.canViewResponsesBeforeClose === false}
                isDisabled={isSaving}
              >
                No
              </Radio>
            </div>
          </Field>
          <Hr className="mt-8 mb-4" />
          <Field
            className="mt-8"
            label="Mandatory conditions (optional)"
            supplementary="Checklist of conditions for reviewing supplier responses. These will be visible on the listing to suppliers."
          >
            {map(formData.criteria, (criteria, index) => (
              <div key={index} className="mb-4">
                <div className="flex items-center justify-between">
                  <TextArea
                    className="mr-4"
                    value={criteria.description}
                    onChange={({ target }) => handleUpdateCriteria(index, target.value)}
                    rows={2}
                    placeholder="Description of the mandatory condition"
                    isDisabled={isSaving}
                    isRequired
                  />
                  <Button
                    className="justify-self-end"
                    onClick={() => setFormData({ ...formData, criteria: filter(formData.criteria, (_, indexToRemove) => index !== indexToRemove) })}
                    shape="square"
                    size="sm"
                    state="ghost"
                    isDisabled={isSaving}
                  >
                    <Icon icon="x-close" variant="light" />
                  </Button>
                </div>
              </div>
            ))}
            <Button
              onClick={() => setFormData({ ...formData, criteria: [...formData.criteria, { description: '' }] })}
              size="sm"
              state="translucent"
              variant="secondary"
              isDisabled={isSaving}
            >
              + Add
            </Button>
          </Field>
        </>
      )
    }

    return (
      <>
        <Field label="Title">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.title && isEditable && renderToggleLink('+ Add title')}
              {tender?.title && <Text className="font-semibold">{tender.title}</Text>}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Type">
          {isLoading && <Skeleton className="h-3 w-12" />}
          {!isLoading && (
            <>
              {!formData?.tenderType && isEditable && renderToggleLink('+ Add tender type')}
              {formData?.tenderType && <Text>{TENDER_TYPE_NAME_MAP[formData.tenderType]}</Text>}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Regions">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.regions?.length && isEditable && renderToggleLink('+ Add regions')}
              {!!tender?.regions.length &&
                map(regionService.processRegions(tender.regions), (region) => (
                  <Badge key={region.value} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                    {region.label}
                  </Badge>
                ))}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Categories">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.categories.length && isEditable && renderToggleLink('+ Add categories')}
              {Boolean(tender?.categories.length) &&
                map(tender?.categories, (classification) => (
                  <Badge key={classification._id} className="truncate max-w-[140px] mr-2" state="translucent" variant="secondary" size="sm">
                    {classification.description}
                  </Badge>
                ))}
            </>
          )}
        </Field>
        <Field
          className="mt-8"
          label="Close date"
          supplementary={
            <Button
              variant="secondary"
              state="text"
              size="sm"
              onClick={() => openModal(<TenderHistoryModal tenderId={tenderId} field="tenderPeriod" />)}
            >
              View history
            </Button>
          }
        >
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.tenderPeriod?.endDate && isEditable && renderToggleLink('+ Add close date')}
              {tender?.tenderPeriod?.endDate && <Text>{datetimeService.format(tender.tenderPeriod.endDate, 'd MMMM yyyy h:mm aaa')}</Text>}
            </>
          )}
        </Field>
        <Field
          className="mt-8"
          label="Forum close date"
          supplementary={
            <Button
              variant="secondary"
              state="text"
              size="sm"
              onClick={() => openModal(<TenderHistoryModal tenderId={tenderId} field="forumCloseDate" />)}
            >
              View history
            </Button>
          }
        >
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.forumCloseDate?.endDate && isEditable && renderToggleLink('+ Add forum close date')}
              {tender?.forumCloseDate?.endDate && <Text>{datetimeService.format(tender.forumCloseDate.endDate, 'd MMMM yyyy h:mm aaa')}</Text>}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Grace period" supplementary="Add a grace period for submissions (hours, max 24)">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && <Text>{renderGracePeriod()}</Text>}
        </Field>
        <Field className="mt-8" label="Brief description">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.briefDescription && isEditable && renderToggleLink('+ Add brief description')}
              {tender?.briefDescription && <Text>{tender.briefDescription}</Text>}
            </>
          )}
        </Field>
        <Field className="mt-8" label="Summary">
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.description && isEditable && renderToggleLink('+ Add summary')}
              {tender?.description && <RichText value={tender.description} />}
            </>
          )}
        </Field>
        <Field
          className="mt-8"
          label="Visibility"
          supplementary="Select weather you want your tender to be open to the market (visible on your portal) or invite only."
        >
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tender?.visibility && isEditable && renderToggleLink('+ Add visibility')}
              {tender?.visibility && <Text>{TENDER_VISIBILITY_NAME_MAP[tender.visibility]}</Text>}
            </>
          )}
        </Field>

        <Field
          className="mt-8"
          label="Response visibility"
          supplementary="Do you want to be able to view submitted responses while the listing is still active? (prior to the close date)"
        >
          <Text>
            {isLoading && <Skeleton className="h-3 w-12" />}
            {!isLoading && (
              <>
                {tender?.canViewResponsesBeforeClose === undefined && isEditable && renderToggleLink('+ Add response visibility')}
                {tender?.canViewResponsesBeforeClose === undefined && !isEditable && 'No'}
                {tender?.canViewResponsesBeforeClose !== undefined && <Text>{tender.canViewResponsesBeforeClose ? 'Yes' : 'No'}</Text>}
              </>
            )}
          </Text>
        </Field>
        <Field
          className="mt-8"
          label="Mandatory conditions"
          supplementary="Checklist of conditions for reviewing supplier responses. These will be visible on the listing to suppliers."
        >
          {isLoading && <Skeleton className="h-3 w-20" />}
          {!isLoading && (
            <>
              {!tenderMandatoryCriteria.length && isEditable && renderToggleLink('+ Add mandatory conditions')}
              {Boolean(tenderMandatoryCriteria.length) && <TenderMandatoryCriteriaTable tenderId={tenderId} />}
            </>
          )}
        </Field>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader
          heading="Live listing details"
          onToggleEdit={handleToggleEdit}
          isEditable={isEditable}
          isEditing={isEditing}
          isSaving={isSaving}
          isLoading={isLoading}
          hasSeparator={false}
        />
        <Text className="mb-4" variant="light">
          This information should provide an overview of the request to interested parties
        </Text>
        <Hr className="my-4" />
        <Banner className="mb-4" icon="visible" iconVariant="secondary" variant="light">
          <div>
            <Text className="font-semibold" variant="heading">
              The below information will be displayed on the live listing
            </Text>
            <Text size="sm">External or invited parties will have access to this information.</Text>
          </div>
        </Banner>
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
