import React, { memo, useMemo, useState } from 'react'
import { find, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { TenderUpdateExpanded } from '@cotiss/tender-update/components/tender-update-expanded.component'
import { TenderUpdateSkeleton } from '@cotiss/tender-update/components/tender-update-skeleton.component'
import { TenderUpdate } from '@cotiss/tender-update/components/tender-update.component'
import { TenderUpdateCreateUpdateModal } from '@cotiss/tender-update/modals/tender-update-create-update.modal'
import { useListTenderUpdate } from '@cotiss/tender-update/resources/use-list-tender-update.resource'

type Props = {
  className?: string
  tenderId: string
}

export const TenderResponseForumUpdates = memo(({ className, tenderId }: Props) => {
  const { openModal } = useCallout()
  const [expandedTenderUpdateId, setExpandedTenderUpdateId] = useState<null | string>(null)
  const { tenderUpdates, isLoading: isTenderUpdatesLoading } = useListTenderUpdate({ tenderId })
  const { count: tenderResponsesCount, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId, limit: 1 })
  const isLoading = isTenderUpdatesLoading || isTenderResponsesLoading

  const expandedTenderUpdate = useMemo(() => {
    if (!expandedTenderUpdateId) {
      return
    }

    return find(tenderUpdates, (update) => update._id === expandedTenderUpdateId)
  }, [expandedTenderUpdateId, tenderUpdates])

  const openTenderCreateUpdateModal = () => openModal(<TenderUpdateCreateUpdateModal tenderId={tenderId} />)

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {map(Array(3), (_, index) => (
            <TenderUpdateSkeleton key={index} className="mt-4" />
          ))}
        </>
      )
    }

    return (
      <>
        {!tenderUpdates.length && (
          <div className="bg-gray-200 rounded-lg p-4">
            <Text className="font-medium text-center">No updates have been posted yet</Text>
            <Text className="text-center mt-1" variant="light">
              Updates you post here will be visible to suppliers
            </Text>
          </div>
        )}
        {!expandedTenderUpdate && Boolean(tenderUpdates.length) && (
          <>
            {map(tenderUpdates, (tenderUpdate) => (
              <TenderUpdate
                key={tenderUpdate._id}
                className="bg-white mt-4"
                tenderUpdate={tenderUpdate}
                onReadMore={setExpandedTenderUpdateId}
                isEditable
                tenderResponseCount={tenderResponsesCount}
              />
            ))}
          </>
        )}
        {expandedTenderUpdate && (
          <TenderUpdateExpanded
            className="bg-white"
            tenderUpdate={expandedTenderUpdate}
            onBack={() => setExpandedTenderUpdateId(null)}
            isEditable
            tenderResponseCount={tenderResponsesCount}
          />
        )}
      </>
    )
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between min-h-[2rem] mb-4">
        <Text className="uppercase" variant="light">
          Updates ({tenderUpdates.length})
        </Text>
        <Button className="ml-2" onClick={openTenderCreateUpdateModal} state="translucent" variant="secondary" size="sm" isDisabled={isLoading}>
          + New update
        </Button>
      </div>
      {renderContent()}
    </div>
  )
})
