import React, { ReactNode } from 'react'
import classNames from 'classnames'

type Props<T> = {
  className?: string
  children: ReactNode
  onChange: (value: T) => void
  name: string
  value: T
  isSelected?: boolean
  isDisabled?: boolean
  isRequired?: boolean
}

export function SupplierOnboardingRadioButton<T>(props: Props<T>) {
  const { children, name, className, onChange, value, isSelected, isDisabled, isRequired } = props
  const classes = classNames(className, 'items-center border rounded cursor-pointer p-2', {
    'text-secondary-400 border-secondary-400': !isSelected,
    'text-secondary-500 border-secondary-500': isSelected,
    'pointer-events-none opacity-50': isDisabled,
  })

  return (
    <label className={classes}>
      <input
        className="sr-only"
        onChange={() => onChange(value)}
        type="radio"
        name={name}
        checked={isSelected}
        required={isRequired}
        disabled={isDisabled}
      />
      <div>{children}</div>
    </label>
  )
}
