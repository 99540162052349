import React, { memo, useState } from 'react'
import classNames from 'classnames'
import { includes } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TenderUpdateButtons } from '@cotiss/tender-update/components/tender-update-buttons.component'
import { useListTenderUpdateComment } from '@cotiss/tender-update/resources/use-list-tender-update-comment.resource'
import { ActionTenderUpdateParam, useMutateTenderUpdate } from '@cotiss/tender-update/resources/use-mutate-tender-update.resource'
import { TenderUpdatePopulatedModel } from '@cotiss/tender-update/tender-update.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  className?: string
  tenderUpdate: TenderUpdatePopulatedModel
  onReadMore?: (tenderUpdateId: string) => void
  isEditable?: boolean
  tenderResponseCount?: number
  isRead?: boolean
}

export const TenderUpdate = memo(({ className, tenderUpdate, onReadMore, isEditable, tenderResponseCount, isRead }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { actionTenderUpdate } = useMutateTenderUpdate()
  const { tenderUpdateComments } = useListTenderUpdateComment({ tenderUpdateId: tenderUpdate._id })
  const isLikedByUser = includes(tenderUpdate.likes, user?._id)
  const classes = classNames(className, 'rounded-lg p-4')
  const { track } = useAnalytics()

  const handleAction = async (action: ActionTenderUpdateParam) => {
    try {
      setIsSaving(true)
      if (action === 'like') {
        track('forum_update_like_create_submit')
      }
      await actionTenderUpdate(tenderUpdate._id, action)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <div key={tenderUpdate._id} className={classes}>
      <TenderUpdateButtons tenderUpdate={tenderUpdate} isEditable={isEditable} tenderResponseCount={tenderResponseCount} isRead={isRead} />

      <div>
        {tenderUpdate.pinned && (
          <Text className="inline-flex items-center mr-2" size="xs" isInline>
            <Icon className="mr-1" icon="push-pin" size={12} />
            Pinned
          </Text>
        )}
        {tenderUpdate.hidden && (
          <Text className="inline-flex items-center" size="xs" isInline>
            <Icon className="mr-1" icon="hidden" size={12} />
            Hidden
          </Text>
        )}
      </div>

      <Text className="font-medium" variant="light" size="sm">
        {datetimeService.format(tenderUpdate.createdAt, 'd MMMM yyyy h:mm aaa')}
      </Text>

      <Text className="font-semibold mt-4">{tenderUpdate.title}</Text>

      <RichText className="mt-2" value={tenderUpdate.content} />
      <div className="flex items-center justify-between mt-2">
        <div className="flex items-center">
          <Button onClick={() => handleAction(isLikedByUser ? 'unlike' : 'like')} state="ghost" size="xs" shape="square" isDisabled={isSaving}>
            <Icon icon="like" variant={isLikedByUser ? 'secondary' : 'dark'} />
            <Text className="ml-1" size="sm" variant={isLikedByUser ? 'secondary' : 'dark'}>
              {tenderUpdate.likes.length}
            </Text>
          </Button>
          <Button
            onClick={() => onReadMore && onReadMore(tenderUpdate._id)}
            className="ml-2"
            state="ghost"
            size="xs"
            shape="square"
            isDisabled={isSaving}
          >
            <Icon icon="comment" variant="dark" />
            <Text className="ml-1" size="sm">
              {tenderUpdateComments.length}
            </Text>
          </Button>
          <Button className="ml-2" state="ghost" size="xs" shape="square" isDisabled={isSaving}>
            <Icon icon="attachment" size={12} variant="dark" />
            <Text className="ml-1" size="sm">
              {tenderUpdate.attachments.length}
            </Text>
          </Button>
        </div>
        {onReadMore && (
          <Button onClick={() => onReadMore(tenderUpdate._id)} state="text" variant="link">
            Read more
          </Button>
        )}
      </div>
    </div>
  )
})
