import React, { memo } from 'react'
import { GqlEvaluationCriteriaRatingScale } from '@gql'
import classNames from 'classnames'
import { clamp } from 'lodash'
import { Input } from '@cotiss/common/components/input.component'
import { Select } from '@cotiss/common/components/select.component'
import { Text } from '@cotiss/common/components/text.component'
import {
  EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP,
  EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS,
} from '@cotiss/evaluation-event/evaluation-event.constants'

type Props = {
  className?: string
  ratingScale: GqlEvaluationCriteriaRatingScale
  value?: number
  onChange: (value: number) => void
  isDisabled?: boolean
}

export const EvaluationEventRatingScaleValueField = memo(({ className, ratingScale, value, onChange, isDisabled }: Props) => {
  const classes = classNames(className, 'flex items-center w-full')

  const handleChange = (value: string) => {
    onChange(Number(value))
  }

  const stringValue = value?.toString()

  const renderContent = () => {
    if (ratingScale === 'percentage') {
      return (
        <Input
          className="w-full"
          value={stringValue}
          onChange={({ target }) => handleChange(target.value)}
          placeholder="--"
          isDisabled={isDisabled}
        />
      )
    }

    if (ratingScale === 'zeroToOneHundredWithStaggeredDefinitions') {
      return (
        <Input
          className="w-full"
          value={stringValue}
          onChange={({ target }) => handleChange((clamp(Number(target.value), 0, 100) || 0).toString())}
          placeholder="--"
          min={0}
          max={100}
          step={1}
          isDisabled={isDisabled}
        />
      )
    }

    return (
      <Select
        className="w-full"
        value={stringValue}
        isRequired
        onChange={(value) => handleChange(value)}
        options={EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS[ratingScale]}
        placeholder="--"
        isDisabled={isDisabled}
      />
    )
  }

  return (
    <div className={classes}>
      {renderContent()}
      <Text className="flex items-center shrink-0 w-10 ml-2">
        {ratingScale === 'percentage' ? '%' : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[ratingScale]}`}
      </Text>
    </div>
  )
})
