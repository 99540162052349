import React, { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Page } from '@cotiss/common/components/page.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { BuyerViewOpenListingsTab } from '@cotiss/buyer/tabs/buyer-view-open-listings.tab'
import { BuyerViewClosedListingsTab } from '@cotiss/buyer/tabs/buyer-view-closed-listings.tab'
import { BuyerViewResourcesTab } from '@cotiss/buyer/tabs/buyer-view-resources.tab'
import { BuyerViewSupportTab } from '@cotiss/buyer/tabs/buyer-view-support.tab'
import { BuyerViewDetails } from '@cotiss/buyer/components/buyer-view-details.component'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'
import { SettingsOrganisationUploadLogoModal } from '@cotiss/settings/modals/settings-organisation-upload-logo.modal'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type BuyerViewTab = 'open-listings' | 'closed-listings' | 'resources' | 'support'
const BUYER_VIEW_TABS: TabModel<BuyerViewTab>[] = [
  { id: 'open-listings', label: 'Active listings' },
  { id: 'closed-listings', label: 'Closed listings' },
  { id: 'resources', label: 'Resources' },
  { id: 'support', label: 'Support' },
]

export const BuyerViewPage = memo(() => {
  const { user } = useGetLoggedInUser()
  const { replace } = useHistory()
  const { openModal } = useCallout()
  const { id, tab } = useParams<{ id: string; tab?: BuyerViewTab }>()
  const { organisation } = useGetOrganisation(id)
  const isUserProfile = useMemo(() => user?.account.organisation?._id === id, [user, id])
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(BUYER_VIEW_TABS, ({ id }) => id === tab) + 1 })

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/buyer/view/:id/:tab?', { id, tab: 'open-listings' }))
    }
  }, [])

  const handleTabChange = (tab: BuyerViewTab) => {
    const newStep = findIndex(BUYER_VIEW_TABS, ({ id }) => id === tab) + 1

    replace(routerService.getHref('/buyer/view/:id/:tab?', { id, tab }))
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <Page>
      <div className="bg-primary-500 h-24 w-full" />
      <div className="relative -top-8 flex items-start justify-between px-10">
        <div className="w-2/3 mr-8">
          <OrganisationLogo
            organisation={organisation}
            onUpdate={isUserProfile ? () => openModal(<SettingsOrganisationUploadLogoModal />) : undefined}
          />

          <Text className="font-medium mt-4" size="h4">
            {organisation?.name || '–'}
            {isUserProfile && (
              <Badge className="ml-2" state="translucent" variant="secondary">
                Admin view
              </Badge>
            )}
          </Text>

          <Tabs<BuyerViewTab>
            className="border-b border-gray-300 w-full mt-2 mb-4"
            tab={tab}
            tabs={BUYER_VIEW_TABS}
            onChange={({ id }) => handleTabChange(id)}
            variant="underline"
          />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && <BuyerViewOpenListingsTab />}
              {step === 2 && <BuyerViewClosedListingsTab />}
              {step === 3 && <BuyerViewResourcesTab isEditable={isUserProfile} />}
              {step === 4 && <BuyerViewSupportTab />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
        <BuyerViewDetails isEditable={isUserProfile} />
      </div>
    </Page>
  )
})
