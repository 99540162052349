import React, { memo, useEffect, useState } from 'react'
import { every, filter, flatMap, map, some } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventScoringAccessList } from '@cotiss/evaluation-event/components/evaluation-event-scoring-access-list.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardScoringStep = memo(({ onNext, onBack }: Props) => {
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationUsers } = useEvaluationUser()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()

  useEffect(() => {
    track('evaluation_event_wizard_scoring_access_view')
  }, [])

  const handleClickContinue = () => {
    const allAccessControls = flatMap(evaluationUsers, ({ accessControls }) => filter(accessControls, { resourceType: 'criteria' }))
    const parentCriteria = filter(evaluationCriteria, ({ evaluationEnvelopeId, parentEvaluationCriteriaId }) => {
      return parentEvaluationCriteriaId === null && some(evaluationEnvelopes, { id: evaluationEnvelopeId })
    })

    if (!every(parentCriteria, ({ id: criteriaId }) => some(allAccessControls, { resourceId: criteriaId }))) {
      setErrorText('There must be at least one scorer for each criteria within each envelope.')
      return
    }

    onNext()
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Scoring access
        </Text>

        <div className="flex flex-col items-end ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      <Text className="font-medium">Review evaluators access to score criteria & edit as required.</Text>
      {map(evaluationEnvelopes, (evaluationEnvelope) => (
        <EvaluationEventScoringAccessList key={evaluationEnvelope.id} className="mt-4" evaluationEnvelope={evaluationEnvelope} isEditable />
      ))}
    </Card>
  )
})
