import React, { memo } from 'react'
import classNames from 'classnames'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
}

export const ComingSoonBanner = memo(({ className }: Props) => {
  const classes = classNames(className, 'absolute -right-2 -top-2 overflow-hidden w-[110px] h-[82px]')

  return (
    <div className={classes}>
      <div className="relative -left-6 top-6 bg-primary-200 w-[190px] py-1 rotate-[35deg] z-1">
        <Text className="font-medium whitespace-nowrap pl-12" variant="light" size="sm">
          Coming Soon
        </Text>
      </div>
      <div className="absolute top-0 left-0 h-2 w-5 bg-gray-300" />
      <div className="absolute bottom-0 right-0 h-5 w-2 bg-gray-300" />
    </div>
  )
})
