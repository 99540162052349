import { gql } from '@apollo/client'
import {
  GqlUpdateEvaluationEventInput,
  GqlUpdateEvaluationEventMutation,
  GqlUpdateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const mutateUpdateEvaluationEvent = async (input: GqlUpdateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationEventMutation, GqlUpdateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation UpdateEvaluationEvent($input: UpdateEvaluationEventInput!) {
        updateEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationEvent as GqlEvaluationEventFieldsFragment
}
