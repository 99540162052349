import React, { memo, useMemo, useState } from 'react'
import {
  GqlEvaluationEnvelopeOverviewCriteriaBreakdownFieldsFragment,
  GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment,
  GqlPagination,
} from '@gql'
import { compact, find, forEach, map, some, sortBy, uniq } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { EvaluationEventEnvelopeSubmissionStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-envelope-submission-status-badge.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { utilService } from '@cotiss/common/services/util.service'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

export const EvaluationEventModerateEnvelopePage = memo(() => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [isInitialised, setIsInitialised] = useState(false)
  const { evaluations, queryEvaluationList } = useEvaluation()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const { evaluationCriteria, queryEvaluationCriteriaList } = useEvaluationCriteria()
  const { evaluationUserInSession, queryEvaluationUserInSessionView } = useEvaluationUser()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { evaluationEventId, evaluationEnvelopeId } = useParams<{ evaluationEventId: string; evaluationEnvelopeId: string }>()
  const {
    evaluationEnvelope,
    evaluationEnvelopeOverview,
    queryEvaluationEnvelopeView,
    queryEvaluationEnvelopeDownloadCsv,
    queryEvaluationEnvelopeOverviewView,
    mutateProgressEvaluationEnvelope,
  } = useEvaluationEnvelope()

  const backHref = routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: routerService.getHref('/evaluation-event/list/:tab?'),
    },
    {
      label: evaluationEvent?.name || '',
      href: backHref,
      isLoading,
    },
    {
      label: evaluationEnvelope ? `Envelope ${evaluationEnvelope.order}. ${evaluationEnvelope.name}` : '',
      isLoading,
    },
  ]

  useAsyncEffect(async () => {
    try {
      track('evaluation_event_envelope_moderate_view')

      const [evaluationEnvelope, evaluationUserInSession] = await Promise.all([
        queryEvaluationEnvelopeView({ evaluationEnvelopeId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId, evaluationEnvelopeId, parentEvaluationCriteriaId: null } }),
      ])

      // If the user navigates directly to this page, and the envelope is not ready to be moderated, redirect them back to the envelope view page.
      if (evaluationEnvelope.status === 'evaluate') {
        replace(backHref)
        return
      }

      if (evaluationEvent?.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        replace(backHref)
        return
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsInitialised(true)
  }, [])

  useAsyncEffect(async () => {
    if (!isInitialised) {
      return
    }

    try {
      setIsLoading(true)
      const { evaluationSubmissions, pagination } = await queryEvaluationSubmissionList({
        filter: { evaluationEventId },
        pagination: { page: currentPage, pageSize: 20 },
      })

      const evaluationSubmissionIds = compact(uniq(map(evaluationSubmissions, 'id')))
      await queryEvaluationList({ filter: { evaluationEventId, evaluationEnvelopeId, evaluationSubmissionIds } })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [isInitialised, currentPage])

  const renderCriteriaBreakdownDelta = (criteriaBreakdown?: GqlEvaluationEnvelopeOverviewCriteriaBreakdownFieldsFragment) => {
    if (
      !criteriaBreakdown ||
      criteriaBreakdown.moderatedScoreSummary.averageWeightedPercentageScore === criteriaBreakdown.rawScoreSummary.averageWeightedPercentageScore
    ) {
      return null
    }

    const delta =
      criteriaBreakdown.moderatedScoreSummary.averageWeightedPercentageScore - criteriaBreakdown.rawScoreSummary.averageWeightedPercentageScore

    return (
      <Text className="ml-1" variant={delta > 0 ? 'success' : 'danger'}>
        <Icon className="mr-1" icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
        {utilService.formatAsPercentage(delta * 100)}
      </Text>
    )
  }

  const renderSubmissionBreakdownDelta = (submissionBreakdown?: GqlEvaluationEnvelopeOverviewSubmissionBreakdownFieldsFragment) => {
    if (
      !submissionBreakdown ||
      submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore ===
        submissionBreakdown.rawScoreSummary.averageEnvelopeWeightedPercentageScore
    ) {
      return null
    }

    const delta =
      submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore -
      submissionBreakdown.rawScoreSummary.averageEnvelopeWeightedPercentageScore

    return (
      <Text className="ml-1" variant={delta > 0 ? 'success' : 'danger'}>
        <Icon className="mr-1" icon={delta > 0 ? 'arrow-up' : 'arrow-down'} variant={delta > 0 ? 'success' : 'danger'} />
        {utilService.formatAsPercentage(delta * 100)}
      </Text>
    )
  }

  const { fixedColumns, columns } = useMemo(() => {
    const sortedCriteria = sortBy(evaluationCriteria, 'index')
    const weightById = evaluationEventService.getWeightById({ items: evaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const weightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Criteria',
        colSpan: 3,
        rows: [
          {
            colSpan: 3,
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Weight
              </Text>
            ),
          },
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Submission
              </Text>
            ),
          },
          ...map(evaluationSubmissions, (evaluationSubmission) => ({
            content: () => (
              <Text className="font-medium truncate" title={evaluationSubmission.organisation?.name || evaluationSubmission.name}>
                {evaluationSubmission.name || evaluationSubmission.organisation?.name}
              </Text>
            ),
            cta: (
              <TableRowCta
                cta={{
                  label: (
                    <>
                      {evaluationEnvelope?.status === 'moderate' ? 'Moderate' : 'View'} <Icon className="ml-2" icon="arrow-right" />
                    </>
                  ),
                  href: routerService.getHref(
                    '/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?',
                    {
                      evaluationEventId,
                      evaluationEnvelopeId,
                      evaluationSubmissionId: evaluationSubmission.id,
                    }
                  ),
                }}
              />
            ),
          })),
        ],
      },
      {
        rows: [
          {}, // Empty cell to get colSpan of 3 above working as expected.
          {
            variant: 'primary',
            tdClassName: 'w-24',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Status
              </Text>
            ),
          },
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => ({
            content: () => (
              <EvaluationEventEnvelopeSubmissionStatusBadge
                status={
                  // If the envelope is complete, then we mark all submissions as reviewed. This is mainly to account for any moderation's that were
                  // done before reviewing moderation submissions was a thing.
                  evaluationEnvelope?.status !== 'complete'
                    ? find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId })?.status
                    : 'reviewed'
                }
              />
            ),
          })),
        ],
      },
      {
        rows: [
          // Empty cells to get colSpan of 3 above working as expected.
          {},
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Weighted total
              </Text>
            ),
          },
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
            const submissionBreakdown = find(evaluationEnvelopeOverview?.submissionBreakdown, { evaluationSubmissionId })

            return {
              content: () => (
                <div className="flex items-center">
                  <Text>
                    {submissionBreakdown !== undefined
                      ? utilService.formatAsPercentage(Number(submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore) * 100)
                      : '--'}
                  </Text>
                  {renderSubmissionBreakdownDelta(submissionBreakdown)}
                </div>
              ),
            }
          }),
        ],
      },
    ]

    const columns: ScrollableTableColumn[] = []

    forEach(sortedCriteria, ({ id: evaluationCriteriaId, content, index }, i) => {
      columns.push({
        heading: (
          <>
            {isLoading && <Skeleton className="h-2 w-full" />}
            {!isLoading && (
              <Text className="normal-case">
                {index}. {content}
              </Text>
            )}
          </>
        ),
        rows: [
          {
            hasHover: false,
            content: () => (
              <Text size="sm" variant="light">
                {utilService.formatAsPercentage(Number(weightPercentageById[evaluationCriteriaId]) * 100)}
              </Text>
            ),
          },
          !i
            ? {
                variant: 'primary',
                hasHover: false,
                colSpan: evaluationCriteria.length,
                content: () => (
                  <Text className="uppercase" size="xs" variant="light">
                    Weighted score
                  </Text>
                ),
              }
            : {},
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
            const criteriaBreakdown = find(evaluationEnvelopeOverview?.criteriaBreakdown, { evaluationCriteriaId, evaluationSubmissionId })

            return {
              content: () => (
                <div className="flex items-center">
                  <Text>
                    {criteriaBreakdown !== undefined
                      ? `AVG. ${utilService.formatAsPercentage(criteriaBreakdown.moderatedScoreSummary.averageWeightedPercentageScore * 100)}`
                      : '--'}
                  </Text>
                  {renderCriteriaBreakdownDelta(criteriaBreakdown)}
                </div>
              ),
            }
          }),
        ],
      })
    })

    return { fixedColumns, columns }
  }, [evaluationEvent, evaluationEnvelope, evaluationCriteria, evaluationSubmissions, evaluationEnvelopeOverview, evaluations, isLoading])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  const handleProgressEnvelope = async () => {
    await mutateProgressEvaluationEnvelope({ evaluationEnvelopeId })

    await Promise.all([
      queryEvaluationEventView({ evaluationEventId }),
      queryEvaluationEnvelopeView({ evaluationEnvelopeId }),
      queryEvaluationEnvelopeOverviewView({ evaluationEnvelopeId }),
    ])

    push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab: 'tracking' }))
  }

  const handleDownloadCsv = async () => {
    if (!evaluationEnvelope) {
      return
    }

    try {
      setIsSaving(true)
      track('evaluation_event_envelope_moderate_download_csv_submit')

      const csv = await queryEvaluationEnvelopeDownloadCsv({ evaluationEnvelopeId: evaluationEnvelope.id })

      utilService.downloadCsv({ csv, filename: `${evaluationEnvelope.name}.csv` })

      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderEnvelopeCta = () => {
    const hasProgressPermission =
      evaluationUserInSession?.role === 'owner' ||
      some(evaluationUserInSession?.accessControls, ({ resourceType, resourceId, access }) => {
        return resourceType === 'envelope' && resourceId === evaluationEnvelope?.id && access === 'moderate'
      })

    if (hasProgressPermission) {
      return (
        <Button
          variant="primary"
          size="sm"
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Submit reviewed scores"
                description="Are you sure you want to submit the reviewed scores of this envelope? This action cannot be undone."
                onSubmit={async () => await handleProgressEnvelope()}
              />
            )
          }
          isDisabled={!evaluationEnvelopeOverview?.nextStatus}
        >
          Submit reviewed scores
        </Button>
      )
    }
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start truncate">
            <div className="truncate">
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationEnvelope && (
                <div className="flex items-center truncate">
                  <Text className="font-medium" size="h7" variant="heading">
                    Envelope {evaluationEnvelope.order}. {evaluationEnvelope.name}:
                  </Text>
                  <Text className="font-medium truncate ml-1" size="h7" variant="light">
                    All submissions
                  </Text>
                </div>
              )}
            </div>
          </div>
          {renderEnvelopeCta()}
        </div>
      </Header>
      <PageContent>
        <TableHeader className="flex items-center justify-between" variant="white">
          {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
          {!isLoading && evaluationEnvelope && (
            <>
              <Text variant="heading" size="h7">
                Envelope {evaluationEnvelope.order}. {evaluationEnvelope.name}
              </Text>
              <Button size="xs" state="translucent" variant="secondary" onClick={handleDownloadCsv} isLoading={isSaving}>
                <Icon className="mr-2" icon="download-01" /> Download CSV
              </Button>
            </>
          )}
        </TableHeader>
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          pagination={pagination}
          onPageChange={setCurrentPage}
          fixedColumnsWidth={600}
          isLoading={isLoading}
        />
      </PageContent>
    </Page>
  )
})
