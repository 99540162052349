import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderUpdateViewModel } from '@cotiss/tender-update/tender-update.models'

type Props = {
  tenderUpdateId?: string
  limit?: number
  offset?: number
}

export const useListTenderUpdateView = ({ tenderUpdateId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderUpdateViewModel>>('/v2/tender-update-view', {
    route: `/v2/tender-update-view`,
    params: { tenderUpdateId, offset, limit },
    isEnabled: Boolean(tenderUpdateId),
  })

  return { tenderUpdateViews: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
