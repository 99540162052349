import React, { ReactNode, memo, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { compact, findIndex, map } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { AppSideNavLink } from '@cotiss/app/components/app-side-nav-link.component'
import { APP_SIDE_NAV_TABS, AppSideNavTab, AppSideNavTabs } from '@cotiss/app/components/app-side-nav-tabs.component'
import { AppSideNavOrganisation } from '@cotiss/app/components/app-side-nav-organisation.component'
import { appService } from '@cotiss/app/app.service'
import { AppSideNavNotifications } from '@cotiss/app/components/app-side-nav-notifications.component'
import { AppSideNavProfile } from '@cotiss/app/components/app-side-nav-profile.component'
import { AppSideNavWrapper } from '@cotiss/app/components/app-side-nav-wrapper.component'
import { SupplierOnboardingFullModal } from '@cotiss/supplier/modals/supplier-onboarding.full-modal'
import { useGetSupplierProfilesOnboarding } from '@cotiss/supplier/resources/use-get-supplier-profiles-onboarding.resource'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type AppSideNavLinkType = {
  label: string
  href: string
  icon: ReactNode
  isDisabled?: boolean
}

export const AppSideNav = memo(() => {
  const { pathname } = useLocation()
  const { openFullModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const { permissions } = useUserAccess()
  const [isExpanded, setIsExpanded] = useState(false)
  const initialTab = appService.getInitialSideNavTab({ pathname, permissions })
  const [activeTab, setActiveTab] = useState<AppSideNavTab>(initialTab)
  const { supplierProfilesOnboarding, isLoading: isSupplierProfilesOnboardingLoading } = useGetSupplierProfilesOnboarding()
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(APP_SIDE_NAV_TABS, (tab) => tab === initialTab) + 1 })
  const classes = classNames('h-full flex flex-col px-4 pt-4', {
    'min-w-[220px] max-w-[220px]': isExpanded,
  })

  const navSectionClasses = classNames({ 'py-4': isExpanded, 'py-2 flex items-center justify-center': !isExpanded })

  const buyerLinks: AppSideNavLinkType[] = compact([
    {
      label: 'Contacts',
      href: routerService.getHref('/preferred-supplier/list/:tab?'),
      icon: <Icon icon="list" />,
      isDisabled: !permissions.isBuyer,
    },
    {
      label: 'Organisation',
      href: routerService.getHref('/buyer/view/:id/:tab?', { id: user?.account?.organisation?._id || '' }),
      icon: <Icon icon="home-line" />,
      isDisabled: !permissions.isBuyer,
    },
    permissions.hasProcurementAccess
      ? {
          label: 'Procurements',
          href: routerService.getHref('/project/list/:tab?'),
          icon: <Icon icon="file-06" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasEvaluationAccess
      ? {
          label: 'Evaluate',
          href: routerService.getHref('/evaluation-event/list/:tab?'),
          icon: <Icon icon="file-search-02" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasContractAccess
      ? {
          label: 'Contracts',
          href: routerService.getHref('/contract/list'),
          icon: <Icon icon="file-check-02" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasLegalAccess
      ? {
          label: 'Legal',
          href: routerService.getHref('/conflict-of-interest/list'),
          icon: <Icon icon="shield-tick" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasPlanningAccess
      ? {
          label: 'Plan',
          href: routerService.getHref('/plan-event/list/:tab?'),
          icon: <Icon icon="file-check-02" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasPerformanceAccess
      ? {
          label: 'Performance',
          href: routerService.getHref('/performance/scorecard/list/:tab?'),
          icon: <Icon icon="line-chart-up-01" />,
          isDisabled: !permissions.isBuyer,
          isBeta: true,
        }
      : undefined,
  ])

  const supplierLinks: AppSideNavLinkType[] = [
    {
      label: 'Responses',
      href: routerService.getHref('/procurement-response/list'),
      icon: <Icon icon="mail-02" />,
      isDisabled: !permissions.isSupplier,
    },
    {
      label: 'Profile',
      icon: <Icon icon="user-circle" />,
      href: routerService.getHref('/supplier/view/:id/:tab?', { id: user?.account?.organisation?._id || '' }),
      isDisabled: !permissions.isSupplier,
    },
    {
      label: 'Buyers',
      icon: <Icon icon="menu-01" />,
      href: routerService.getHref('/preferred-supplier/buyer/list'),
      isDisabled: !permissions.isSupplier,
    },
  ]

  const serviceLinks: AppSideNavLinkType[] = compact([
    // Tasks hidden if no task-viewing roles assigned (keep consistent with roles in task-list.page)
    permissions.hasApproverBase || permissions.hasOldEvaluationAccess
      ? {
          label: 'Tasks',
          href: routerService.getHref('/task/list'),
          icon: <Icon icon="check-square-broken" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    {
      label: 'Conflicts',
      href: routerService.getHref('/conflict-of-interest/me/list'),
      icon: <Icon icon="message-question-circle" />,
      isDisabled: !permissions.isBuyer,
    },
    permissions.hasEvaluationAccess
      ? {
          label: 'Evaluate',
          href: routerService.getHref('/evaluation-event/list/:tab?'),
          icon: <Icon icon="file-search-02" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
    permissions.hasPerformanceAccess
      ? {
          label: 'Performance',
          href: routerService.getHref('/performance/scorecard/list/:tab?'),
          icon: <Icon icon="file-check-02" />,
          isDisabled: !permissions.isBuyer,
        }
      : undefined,
  ])

  const handleSetActiveTab = (activeTab: AppSideNavTab) => {
    const newStep = findIndex(APP_SIDE_NAV_TABS, (tab) => tab === activeTab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    setActiveTab(activeTab)
  }

  const renderLinks = (label: string, links: AppSideNavLinkType[]) => {
    const groupedLinks = appService.groupSideNavLinks(links)

    return (
      <>
        {isExpanded && (
          <div className={classNames('flex items-center px-2', { 'justify-center': !isExpanded })}>
            {!groupedLinks.enabled.length && <Icon className="text-primary-400 mr-1.5" icon="lock" size={18} />}
            <Text className="uppercase" size="sm" variant="light">
              {label}
            </Text>
          </div>
        )}
        {map(groupedLinks.enabled, (link) => (
          <AppSideNavLink key={link.href} className="mt-2" {...link} state={isExpanded ? 'full' : 'simple'} label={link.label} />
        ))}
        {Boolean(groupedLinks.disabled.length && groupedLinks.enabled.length) && (
          <div className="flex items-center px-2 mt-8">
            <Icon className="text-primary-400 mr-1.5" icon="lock" size={18} />
            <Text className="uppercase" size="sm" variant="light">
              Upgraded plan
            </Text>
          </div>
        )}
        {map(groupedLinks.disabled, (link) => (
          <AppSideNavLink key={link.href} className="mt-2" {...link} state={isExpanded ? 'full' : 'simple'} label={link.label} />
        ))}
      </>
    )
  }

  const renderUpgradePlan = () => (
    <div className="bg-secondary-100 rounded p-4 mt-8">
      <div className="flex items-center">
        <Icon className="mr-2" icon="star-06" variant="link" />
        <Text variant="link">Set up profile</Text>
      </div>
      <div className="mt-2">
        <Text>Complete your profile to stand out in the market.</Text>
      </div>
      <Button className="w-full mt-4" onClick={() => openFullModal(<SupplierOnboardingFullModal />)} variant="secondary" size="sm">
        Let&apos;s go
      </Button>
    </div>
  )

  return (
    <AppSideNavWrapper isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
      <AnimatePresence>
        <motion.nav
          animate={{
            width: isExpanded ? 220 : 70,
          }}
          transition={{ type: 'tween' }}
          className={classes}
        >
          <div
            className={classNames('flex relative border-b border-secondary-100', {
              'pb-4 justify-between': isExpanded,
              'pb-2 flex-col items-center justify-center gap-1': !isExpanded,
            })}
          >
            <AppSideNavOrganisation state={isExpanded ? 'full' : 'simple'} />
            <AppSideNavNotifications />
          </div>
          {isExpanded && <AppSideNavTabs activeTab={activeTab} setActiveTab={handleSetActiveTab} />}
          <div className={navSectionClasses}>
            <AnimatePresence mode="wait" initial={false}>
              <TransitionContainer key={step} transition={transition}>
                {step === 1 && <>{renderLinks('Buyer', buyerLinks)}</>}
                {step === 2 && (
                  <>
                    {renderLinks('Supplier', supplierLinks)}
                    {!isSupplierProfilesOnboardingLoading && !supplierProfilesOnboarding?.completed && isExpanded && renderUpgradePlan()}
                  </>
                )}
                {step === 3 && <>{renderLinks('Service', serviceLinks)}</>}
              </TransitionContainer>
            </AnimatePresence>
          </div>
          <div className={`${navSectionClasses} mt-auto`}>
            <AppSideNavLink
              href="/settings"
              icon={<Icon icon="settings-01" />}
              variant="secondary"
              state={isExpanded ? 'full' : 'simple'}
              label="Settings"
            />
          </div>
          <div className={`${navSectionClasses} relative border-t border-secondary-100`}>
            <AppSideNavProfile state={isExpanded ? 'full' : 'simple'} />
          </div>
        </motion.nav>
      </AnimatePresence>
    </AppSideNavWrapper>
  )
})
