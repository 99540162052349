import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricListInput, GqlPerformanceScorecardMetricListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-metric/performance-metric-fragment.graphql'
import { PERFORMANCE_SCORECARD_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric/performance-scorecard-metric-fragment.graphql'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle/performance-scorecard-metric-cycle-fragment.graphql'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardMetricList = async (input: GqlPerformanceScorecardMetricListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      query PerformanceScorecardMetricList($input: PerformanceScorecardMetricListInput!) {
        performanceScorecardMetricList(input: $input) {
          items {
            ...PerformanceScorecardMetricFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricList
}
