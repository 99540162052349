import React, { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { PerformanceScorecardCreateContactStep } from '@cotiss/performance/steps/performance-scorecard-create-contact.step'
import { PerformanceScorecardCreateContractShellStep } from '@cotiss/performance/steps/performance-scorecard-create-contract-shell.step'
import { PerformanceScorecardCreateInitialStep } from '@cotiss/performance/steps/performance-scorecard-create-initial.step'

export type PerformanceScorecardCreateFormData = {
  name: string
  contactId: string
  supplierId: string
  contractShellId: string
  withContract: boolean
}

export const PerformanceScorecardCreateDrawer = memo(() => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const { mutateCreatePerformanceScorecard } = usePerformanceScorecard()
  const [formData, setFormData] = useState<PerformanceScorecardCreateFormData>({
    name: '',
    contactId: '',
    supplierId: '',
    contractShellId: '',
    withContract: false,
  })

  const handleSubmit = async () => {
    if (step === 1) {
      return onTransition({ step: 2 })
    } else if (step === 2 && formData.withContract) {
      return onTransition({ step: 3 })
    }

    setIsSaving(true)

    try {
      const performanceScorecard = await mutateCreatePerformanceScorecard({
        name: formData.name,
        contactId: formData.contactId,
        contractShellId: formData.contractShellId || null,
      })
      push(
        routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
          performanceScorecardId: performanceScorecard.id,
        })
      )
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Create scorecard
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence initial={false} mode="wait">
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <Button type="submit" variant="secondary">
            Continue <Icon className="ml-1" icon="arrow-right" />
          </Button>
        )}
        {step === 2 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!formData.contactId}>
              {formData.withContract ? (
                <>
                  Continue <Icon className="ml-1" icon="arrow-right" />
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Text className="ml-2">{formData.contactId ? '1' : '0'} selected</Text>
          </div>
        )}
        {step === 3 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!formData.contractShellId}>
              Save
            </Button>
            <Text className="ml-2">{formData.contractShellId ? '1' : '0'} selected</Text>
          </div>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <PerformanceScorecardCreateInitialStep formData={formData} setFormData={setFormData} />}
          {step === 2 && (
            <PerformanceScorecardCreateContactStep
              formData={formData}
              setFormData={setFormData}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
            />
          )}
          {step === 3 && (
            <PerformanceScorecardCreateContractShellStep
              formData={formData}
              setFormData={setFormData}
              onBack={() => onTransition({ step: 2, transition: 'left' })}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
