import { gql } from '@apollo/client'
import {
  GqlApprovalTemplateGroupFieldsFragment,
  GqlCreateApprovalTemplateGroupInput,
  GqlCreateApprovalTemplateGroupMutation,
  GqlCreateApprovalTemplateGroupMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template/graphql/approval-template-fragment.graphql'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group/graphql/approval-template-group-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateCreateApprovalTemplateGroup = async (input: GqlCreateApprovalTemplateGroupInput) => {
  const { data } = await apolloService.mutate<GqlCreateApprovalTemplateGroupMutation, GqlCreateApprovalTemplateGroupMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      mutation CreateApprovalTemplateGroup($input: CreateApprovalTemplateGroupInput!) {
        createApprovalTemplateGroup(input: $input) {
          ...ApprovalTemplateGroupFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createApprovalTemplateGroup as GqlApprovalTemplateGroupFieldsFragment
}
