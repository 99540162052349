import React, { useState } from 'react'
import AssociateContractPlaceholder from '@assets/images/associated-contract-placeholder.png'
import { upperFirst } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { routerService } from '@cotiss/common/services/router.service'
import { AssociatedContractShellDetails, ContractShellHierarchy } from '@cotiss/contract/contract.model'

// If we ever need to re-use this, pull entire badge into component
const HIERARCHY_BADGE_MAPPING: Record<ContractShellHierarchy, BaseVariant> = {
  child: 'info',
  parent: 'danger',
  sibling: 'warning',
}

type Props = {
  associatedContractShellDetails: AssociatedContractShellDetails
  onRemoveAssociatedContractShell: (associatedContractShellDetails: AssociatedContractShellDetails) => void
  isEditable?: boolean
}

export const ContractAssociationCard = ({ associatedContractShellDetails, onRemoveAssociatedContractShell, isEditable }: Props) => {
  const { push } = useHistory()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div
      key={`${associatedContractShellDetails._id}-${associatedContractShellDetails.type}`}
      className="border hover:border-secondary-400 w-48 h-48 rounded-lg"
    >
      <div
        className="h-2/3 hover:cursor-pointer bg-primary-200 overflow-clip rounded-t-lg p-2"
        onClick={() => push(routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId: associatedContractShellDetails._id }))}
      >
        <img src={AssociateContractPlaceholder} className="w-full" alt="Generic contract placeholder" />
      </div>
      <div className="h-1/3 flex flex-col justify-center bg-white px-4 rounded-b-lg">
        <Text className="w-full truncate font-medium" size="sm">
          {associatedContractShellDetails.title}
        </Text>
        <div className="flex justify-between w-full mt-1 relative">
          <Badge shape="square" state="translucent" variant={HIERARCHY_BADGE_MAPPING[associatedContractShellDetails.type]}>
            {upperFirst(associatedContractShellDetails.type)}
          </Badge>
          {isEditable && (
            <>
              <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost" size="xs">
                <Icon icon="dots" variant="light" size={20} />
              </Button>
              <Dropdown className="top-4 right-2" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                <DropdownContent className="flex items-center" onClick={() => onRemoveAssociatedContractShell(associatedContractShellDetails)}>
                  Remove
                </DropdownContent>
              </Dropdown>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
