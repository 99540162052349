import React, { ChangeEvent, forwardRef, FocusEvent } from 'react'
import classNames from 'classnames'

export type InputProps = {
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  type?: 'email' | 'number' | 'password' | 'text'
  min?: number
  max?: number
  autoComplete?: 'current-password' | 'email' | 'username' | 'given-name' | 'family-name' | 'new-password' | 'off'
  size?: 'sm' | 'md'
  state?: 'default' | 'ghost'
  placeholder?: string
  name?: string
  minLength?: number
  maxLength?: number
  value?: string | number
  pattern?: string
  title?: string
  autoFocus?: boolean
  step?: string | number
  ariaLabel?: string
  defaultValue?: string | number
  isReadOnly?: boolean
  isFull?: boolean
  isError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  leftSlot?: React.ReactNode
  rightSlot?: React.ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    size = 'md',
    state = 'default',
    type = 'text',
    ariaLabel,
    isError,
    isRequired,
    isDisabled,
    isReadOnly,
    leftSlot,
    rightSlot,
    ...rest
  } = props

  let containerClasses = classNames(
    className,
    'flex items-center bg-white text-primary-500 placeholder-gray-400 rounded-lg gap-2 focus-within:shadow-none focus-within:outline-none focus-within:ring-2',
    {
      'opacity-50': isDisabled,
      'h-11 px-3': size === 'md',
      'text-sm h-7 px-2': size === 'sm',
    }
  )

  if (state === 'default') {
    containerClasses = classNames(containerClasses, 'border border-gray-300', {
      'focus-within:border-secondary-300 focus-within:ring-secondary-200': !isError,
      'border-red-400 focus-within:border-red-300 focus-within:ring-red-200': isError,
    })
  }

  const inputClasses = classNames('flex-1 w-full h-full focus:outline-none focus:ring-0 bg-transparent', {
    'cotiss-input--password': type === 'password',
    'pointer-events-none': isDisabled,
  })

  return (
    <div className={containerClasses}>
      {leftSlot ? <div className="flex-0 inline-flex items-center h-full">{leftSlot}</div> : null}
      <input
        {...rest}
        readOnly={isReadOnly}
        className={inputClasses}
        type={type}
        ref={ref}
        disabled={isDisabled}
        required={isRequired}
        aria-label={ariaLabel}
      />
      {rightSlot ? <div className="flex-0 inline-flex items-center h-full">{rightSlot}</div> : null}
    </div>
  )
})
