import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  className?: string
  children?: ReactNode
  onClick?: () => void
  href?: string
  isLink?: boolean
  isOpenNewTab?: boolean
  isDisabled?: boolean
}

export const KanbanCard = memo(({ className, children, href, onClick, isLink, isOpenNewTab, isDisabled }: Props) => {
  const classes = classNames(className, 'inline-block shrink-0 bg-white rounded w-full p-2 mb-1 last:mb-0', {
    'cursor-pointer': onClick || href,
  })

  if (onClick || href) {
    return (
      <Button className={classes} onClick={onClick} href={href} state="raw" isLink={isLink} isOpenNewTab={isOpenNewTab} isDisabled={isDisabled}>
        {children}
      </Button>
    )
  }

  return <div className={classes}>{children}</div>
})
