import { GqlPerformanceMetricUnit, GqlPerformanceScorecardMetricFrequency } from '@gql'
import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'

export const PERFORMANCE_ROUTES = [
  '/performance',
  '/performance/scorecard',
  '/performance/scorecard/list/:tab?',
  '/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?',
  '/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId',
  '/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId/cycle/:performanceScorecardMetricCycleId',
] as const

export const PERFORMANCE_METRIC_UNIT_OPTIONS: SelectOption_DEPRECATED<GqlPerformanceMetricUnit>[] = [
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Decimal',
    value: 'decimal',
  },
  {
    label: 'Percentage',
    value: 'percentage',
  },
  {
    label: 'Currency',
    value: 'currency',
  },
]

export const PERFORMANCE_METRIC_FREQUENCY_OPTIONS: SelectOption_DEPRECATED<GqlPerformanceScorecardMetricFrequency>[] = [
  {
    label: 'Annually',
    value: 'annually',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
]

export const PERFORMANCE_METRIC_FREQUENCY_MONTH_REPEAT_OPTIONS: SelectOption_DEPRECATED[] = [
  {
    label: '1 month',
    value: '1',
  },
  {
    label: '2 months',
    value: '2',
  },
  {
    label: '3 months',
    value: '3',
  },
  {
    label: '4 months',
    value: '4',
  },
  {
    label: '5 months',
    value: '5',
  },
  {
    label: '6 months',
    value: '6',
  },
]
