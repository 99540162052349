import React, { memo, useMemo } from 'react'
import { Badge } from '@cotiss/common/components/badge.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { SelectOption_DEPRECATED, Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Field } from '@cotiss/common/components/field.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractShellFilterPopulatedModel, ContractShellHierarchy } from '@cotiss/contract/contract.model'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

const CONTRACT_SHELL_HIERARCHY_OPTIONS: SelectOption_DEPRECATED<ContractShellHierarchy>[] = [
  { label: 'Parent', value: 'parent' },
  { label: 'Child', value: 'child' },
  { label: 'Sibling', value: 'sibling' },
]

type Props = {
  hierarchy: ContractShellHierarchy
  selectedContractShell: ContractShellFilterPopulatedModel
  errorMessage: string
  onHierarchyChange: (hierarchy: ContractShellHierarchy) => void
  onBack: () => void
  isDisabled: boolean
}

export const ContractLinkTypeStep = memo(({ hierarchy, selectedContractShell, errorMessage, onHierarchyChange, onBack, isDisabled }: Props) => {
  const { contractShell } = useGetContractShell(selectedContractShell._id)
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Select contract',
      onClick: onBack,
    },
    {
      label: selectedContractShell.title,
    },
  ]

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Select the hierarchy for this contract
          </Text>
          <Text size="sm">Select one option below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of 2
        </Badge>
      </Banner>

      <div className="flex items-center justify-between border-b border-grey-200 pb-4 mt-8">
        <div>
          <div className="flex items-center">
            <Text className="font-semibold" size="h4">
              {selectedContractShell.title}
            </Text>
            <ContractStatusBadge className="ml-2" status={selectedContractShell.contracts?.status} />
          </div>
          <Text className="mt-1" variant="light">
            ID: {contract?.metadata?.internalReference}
          </Text>
        </div>
        <Button
          href={routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId: selectedContractShell._id })}
          shape="square"
          variant="secondary"
          state="translucent"
          size="xs"
          isLink
          isOpenNewTab
          isRounded
        >
          <Icon icon="link-external-01" />
        </Button>
      </div>

      <Field className="mt-8" label="Contract type" supplementary="Select the hierarchy of this contract">
        <Select_DEPRECATED<ContractShellHierarchy>
          value={hierarchy}
          options={CONTRACT_SHELL_HIERARCHY_OPTIONS}
          placeholder
          onChange={onHierarchyChange}
          isDisabled={isDisabled}
          isRequired
        />
        {errorMessage && (
          <Text className="mt-1" variant="danger">
            {errorMessage}
          </Text>
        )}
      </Field>
    </>
  )
})
