import React, { FormEvent, memo, useMemo, useState } from 'react'
import classNames from 'classnames'
import { filter, find, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'
import { UserMultiSelect } from '@cotiss/user/components/user-multi-select.component'
import { UserSelect } from '@cotiss/user/components/user-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { useListAccessControl } from '@cotiss/access-control/resources/use-list-access-control.resource'
import { useMutateAccessControl } from '@cotiss/access-control/resources/use-mutate-access-control.resource'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { accessControlService } from '@cotiss/access-control/access-control.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderEvaluationPanelType } from '@cotiss/tender/tender.models'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type FormData = {
  chair: string
  evaluators: string[]
  evaluationPanelType?: TenderEvaluationPanelType
}

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderPlanEvaluationTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { users } = useListUser({ permissions: ['EVALUATOR'] })
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { accessControls, isLoading: isAccessControlsLoading } = useListAccessControl({ resourceId: tenderId })
  const { createAccessControlBulk, removeAccessControlBulk } = useMutateAccessControl()
  const isLoading = isTenderLoading || isAccessControlsLoading
  const [formData, setFormData] = useState<FormData>({
    chair: '',
    evaluators: [],
    evaluationPanelType: tender?.evaluationPanelType,
  })
  const { chair, evaluators } = useMemo(() => {
    const chair = find(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:chair'))
    const evaluators = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:evaluator'))

    return { chair, evaluators }
  }, [accessControls])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTender(tenderId, { evaluationPanelType: formData.evaluationPanelType })
      const evaluatorsToCreate = accessControlService.getCreateAccessControlBulkBody({
        userIdsToAdd: formData.evaluators,
        existingAccessControls: evaluators,
        roles: ['tender:evaluator'],
      })
      const evaluatorsToRemove = accessControlService.getRemoveAccessControlBulkBody({
        userIdsToAdd: formData.evaluators,
        existingAccessControls: evaluators,
        role: 'tender:evaluator',
      })
      evaluatorsToCreate && (await createAccessControlBulk(tenderId, 'TENDER', evaluatorsToCreate))
      evaluatorsToRemove && (await removeAccessControlBulk(tenderId, 'TENDER', evaluatorsToRemove))

      if (chair && formData.chair && chair.user._id !== formData.chair) {
        await removeAccessControlBulk(tenderId, 'TENDER', { accessControls: [{ accessControlId: chair._id, role: 'tender:chair' }] })
        await createAccessControlBulk(tenderId, 'TENDER', { users: [{ userId: formData.chair, roles: ['tender:chair'] }] })
      } else if (!chair && formData.chair) {
        await createAccessControlBulk(tenderId, 'TENDER', { users: [{ userId: formData.chair, roles: ['tender:chair'] }] })
      } else if (!formData.chair && chair) {
        await removeAccessControlBulk(tenderId, 'TENDER', { accessControls: [{ accessControlId: chair._id, role: 'tender:chair' }] })
      }

      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    const chair = find(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:chair'))
    const evaluators = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:evaluator'))

    setFormData({
      ...formData,
      chair: chair?.user._id || '',
      evaluators: map(evaluators, ({ user }) => user._id) || [],
      evaluationPanelType: tender?.evaluationPanelType,
    })
    setIsEditing(isEditing)
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderPanelEvaluationDescription = () => (
    <Text className="mt-2" variant="light" size="sm">
      <ul className="list-disc list-outside pl-4">
        <li>A chair person is assigned to oversee evaluation of this tender.</li>
        <li>Panel member/s are tasked with evaluating this tender.</li>
      </ul>
    </Text>
  )

  const renderSelfEvaluationDescription = () => (
    <Text className="mt-2" variant="light" size="sm">
      <ul className="list-disc list-outside pl-4">
        <li>Conduct your own price and non-price evaluations.</li>
      </ul>
    </Text>
  )

  const renderNoEvaluationDescription = () => (
    <Text className="mt-2" variant="light" size="sm">
      <ul className="list-disc list-outside pl-4">
        <li>Do not conduct an evaluation for this tender.</li>
      </ul>
    </Text>
  )

  const renderChairLabel = () => (
    <>
      Chair (one only)
      <Text className="mt-2" variant="light" size="sm">
        <ul className="list-disc list-outside pl-4">
          <li>This person will be responsible for managing the evaluation process.</li>
          <li>This person will not be a Voting Member.</li>
          <li>This person will compile the final recommendation report.</li>
          <li>
            Adding new users to your account must be done in{' '}
            <Button
              size="sm"
              state="text"
              variant="link"
              href={routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' })}
              isLink
            >
              Settings.
            </Button>
          </li>
        </ul>
      </Text>
    </>
  )

  const renderEvaluatorsSupplementaryLabel = () => (
    <ul className="list-disc list-outside pl-4">
      <li>
        If an evaluator is also an owner or collaborator,
        <br /> they will be able to view pricing documents prior to non-price evaluation.{' '}
      </li>
      <li>
        Adding new users to your account must be done in{' '}
        <Button
          size="sm"
          state="text"
          variant="link"
          href={routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' })}
          isLink
        >
          Settings.
        </Button>
      </li>
    </ul>
  )

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Text className="font-medium" variant="heading">
            Select evaluation type
          </Text>
          <Text className="w-1/3 mt-1" variant="light" size="sm">
            Choose a evaluation type that best fits with this specific procurement or your organisational policies.
          </Text>
          <div className="flex justify-between space-x-2 mt-4">
            <RadioCard
              className="flex-1 border border-gray-100 p-3.5"
              value="panel"
              name="evaluation-type"
              onChange={() => setFormData({ ...formData, evaluationPanelType: 'panel' })}
              isSelected={formData.evaluationPanelType === 'panel'}
              isDisabled={isSaving}
              isRequired
            >
              <div className="flex justify-between">
                <Badge shape="square" variant="secondary" state="translucent">
                  <Icon icon="users-01" size={16} />
                </Badge>
                <Radio className="ml-2" isChecked={formData.evaluationPanelType === 'panel'} />
              </div>
              <Text className="font-medium mt-2">Evaluation panel</Text>
              {renderPanelEvaluationDescription()}
            </RadioCard>
            <RadioCard
              className="flex-1 border border-gray-100 p-3.5"
              value="self"
              name="evaluation-type"
              onChange={() => setFormData({ ...formData, evaluationPanelType: 'self' })}
              isSelected={formData.evaluationPanelType === 'self'}
              isDisabled={isSaving}
              isRequired
            >
              <div className="flex justify-between">
                <Badge shape="square" variant="info" state="translucent">
                  <Icon icon="user-01" size={16} />
                </Badge>
                <Radio className="ml-2" isChecked={formData.evaluationPanelType === 'self'} />
              </div>
              <Text className="font-medium mt-2">Self evaluation</Text>
              {renderSelfEvaluationDescription()}
            </RadioCard>
            <RadioCard
              className="flex-1 border border-gray-100 p-3.5"
              value="none"
              name="evaluation-type"
              onChange={() => setFormData({ ...formData, evaluationPanelType: 'none' })}
              isSelected={formData.evaluationPanelType === 'none'}
              isDisabled={isSaving}
              isRequired
            >
              <div className="flex justify-between">
                <Badge shape="square" variant="neutral" state="translucent">
                  <Icon icon="x-square" size={16} />
                </Badge>
                <Radio className="ml-2" isChecked={formData.evaluationPanelType === 'none'} />
              </div>
              <Text className="font-medium mt-2">No evaluation</Text>
              {renderNoEvaluationDescription()}
            </RadioCard>
          </div>

          {formData.evaluationPanelType === 'panel' && (
            <>
              <Hr className="my-4" />
              <Field label={renderChairLabel()}>
                <UserSelect value={formData.chair} options={users} onChange={(chair) => setFormData({ ...formData, chair })} />
              </Field>
              <Hr className="my-4" />
              <Field className="mt-8" label="Evaluators (multi)" supplementary={renderEvaluatorsSupplementaryLabel()}>
                <UserMultiSelect value={formData.evaluators} options={users} onChange={(evaluators) => setFormData({ ...formData, evaluators })} />
              </Field>
            </>
          )}
        </>
      )
    }

    return (
      <>
        <Field
          label="Evaluation type"
          supplementary="Choose a evaluation type that best fits with this specific procurement or your organisational policies."
        >
          {!formData.evaluationPanelType && isEditable && renderToggleLink('+ Add evaluation type')}
          {formData.evaluationPanelType && (
            <div className="flex items-start">
              <Badge
                shape="square"
                variant={formData.evaluationPanelType === 'panel' ? 'secondary' : formData.evaluationPanelType === 'self' ? 'info' : 'neutral'}
                state="translucent"
              >
                <Icon
                  icon={formData.evaluationPanelType === 'panel' ? 'users-01' : formData.evaluationPanelType === 'self' ? 'user-01' : 'x-square'}
                  size={16}
                />
              </Badge>
              <div className="ml-4">
                {formData.evaluationPanelType === 'panel' && (
                  <>
                    <Text className="font-medium">Evaluation panel</Text>
                    {renderPanelEvaluationDescription()}
                  </>
                )}
                {formData.evaluationPanelType === 'self' && (
                  <>
                    <Text className="font-medium">Self evaluation</Text>
                    {renderSelfEvaluationDescription()}
                  </>
                )}
                {formData.evaluationPanelType === 'none' && (
                  <>
                    <Text className="font-medium">No evaluation</Text>
                    {renderNoEvaluationDescription()}
                  </>
                )}
              </div>
            </div>
          )}
        </Field>

        {formData.evaluationPanelType === 'panel' && (
          <>
            <Hr className="my-4" />
            <Field label="Chair">
              {!chair && isEditable && renderToggleLink('+ Add chair')}
              {chair && <UserLineItem {...chair.user} />}
            </Field>
            <Hr className="my-4" />
            <Field label="Evaluators">
              {!evaluators.length && isEditable && renderToggleLink('+ Add evaluators')}
              {map(evaluators, ({ _id, user }, index) => (
                <UserLineItem key={_id} className={classNames({ 'mt-4': index })} {...user} />
              ))}
            </Field>
          </>
        )}
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader
          heading="Evaluation"
          onToggleEdit={handleToggleEdit}
          isEditable={isEditable}
          isEditing={isEditing}
          isSaving={isSaving}
          isLoading={isLoading}
        />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
