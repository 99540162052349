import React from 'react'
import { GqlPerformanceMetricUnit } from '@gql'
import { NumberInput, NumberInputFormat } from '@cotiss/common/components/number-input.component'
import { Text } from '@cotiss/common/components/text.component'
import { NumericFormatProps } from 'react-number-format'

type Props = {
  metricUnit: GqlPerformanceMetricUnit
  metricUnitType?: string | null
  onValueChange: NumericFormatProps['onValueChange']
  value: string
  isDisabled?: boolean
  isRequired?: boolean
  placeholder?: string
}

const numberFormatFor: Record<GqlPerformanceMetricUnit, NumberInputFormat | undefined> = {
  integer: 'integer',
  currency: 'currency',
  decimal: undefined,
  percentage: undefined,
}

export const PerformanceScorecardMetricNumberInput = ({
  metricUnit,
  metricUnitType,
  onValueChange,
  value,
  isDisabled,
  isRequired,
  placeholder,
}: Props) => {
  const rightSlotContent = () => {
    if (metricUnit === 'percentage') return <Text>%</Text>
    if (metricUnit === 'currency') return <Text>{metricUnitType}</Text>
    return null
  }

  return (
    <NumberInput
      format={numberFormatFor[metricUnit as GqlPerformanceMetricUnit]}
      value={value}
      onValueChange={onValueChange}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isRequired={isRequired}
      rightSlot={rightSlotContent()}
    />
  )
}
