import { ExtractRouteParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import { APPROVAL_ROUTES } from '@cotiss/approval/approval.constants'
import { AUTH_ROUTES } from '@cotiss/auth/auth.constants'
import { BUYER_ROUTES } from '@cotiss/buyer/buyer.constants'
import { CONFLICT_OF_INTEREST_ROUTES } from '@cotiss/conflict-of-interest/conflict-of-interest.constants'
import { CONTRACT_ROUTES } from '@cotiss/contract/contract.constants'
import { DOCUMENT_ROUTES } from '@cotiss/document/document.constants'
import { EVALUATION_ROUTES } from '@cotiss/evaluation/evaluation.constants'
import { PLAN_EVENT_ROUTES } from '@cotiss/plan-event/plan-event.constants'
import { PROJECT_ROUTES } from '@cotiss/project/project.constants'
import { TASK_ROUTES } from '@cotiss/task/task.constants'
import { SETTINGS_ROUTES } from '@cotiss/settings/settings.constants'
import { SUPPLIER_ROUTES } from '@cotiss/supplier/supplier.constants'
import { PROCUREMENT_ROUTES } from '@cotiss/procurement/procurement.constants'
import { TENDER_FLOW_ROUTES } from '@cotiss/tender-flow/tender-flow.constants'
import { PERFORMANCE_ROUTES } from '@cotiss/performance/performance.constants'
import { EVALUATION_EVENT_ROUTES } from '@cotiss/evaluation-event/evaluation-event.constants'
import { TENDER_INVITATION_ROUTES } from '@cotiss/tender-invitation/tender-invitation.constants'
import { PREFERRED_SUPPLIER_ROUTES } from '@cotiss/preferred-supplier/preferred-supplier.constants'
import { PROCUREMENT_RESPONSE_ROUTES } from '@cotiss/procurement-response/procurement-response.constants'

export type RouterPath = '/' | '/error' | (typeof ROUTES)[number]
export const ROUTES = [
  ...APPROVAL_ROUTES,
  ...AUTH_ROUTES,
  ...BUYER_ROUTES,
  ...CONFLICT_OF_INTEREST_ROUTES,
  ...CONTRACT_ROUTES,
  ...DOCUMENT_ROUTES,
  ...EVALUATION_EVENT_ROUTES,
  ...EVALUATION_ROUTES,
  ...PERFORMANCE_ROUTES,
  ...PLAN_EVENT_ROUTES,
  ...PREFERRED_SUPPLIER_ROUTES,
  ...PROCUREMENT_RESPONSE_ROUTES,
  ...PROCUREMENT_ROUTES,
  ...PROJECT_ROUTES,
  ...SETTINGS_ROUTES,
  ...SUPPLIER_ROUTES,
  ...TASK_ROUTES,
  ...TENDER_FLOW_ROUTES,
  ...TENDER_INVITATION_ROUTES,
] as const

class RouterService {
  getHref<S extends RouterPath>(path: S, params?: ExtractRouteParams<S>) {
    // Wrapping this in a try catch, because generatePath will throw an error if the params don't contain values to generate the path from. If this
    // error occurs, we will just default to the root route.
    try {
      return generatePath<S>(path, params)
    } catch {
      return '/'
    }
  }
}

export const routerService = new RouterService()
