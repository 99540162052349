import { gql } from '@apollo/client'
import { GqlPerformanceMetricListInput, GqlPerformanceMetricListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-metric/performance-metric-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceMetricList = async (input: GqlPerformanceMetricListInput) => {
  const { data } = await apolloService.query<GqlPerformanceMetricListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}

      query PerformanceMetricList($input: PerformanceMetricListInput!) {
        performanceMetricList(input: $input) {
          items {
            ...PerformanceMetricFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceMetricList
}
