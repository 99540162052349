import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { TenderModel, TenderPopulatedModel, TenderType } from '@cotiss/tender/tender.models'

type CreateTenderBody = {
  procurementId: string
  tenderType: TenderType
}

export type UpdateTenderBody = BaseUpdateBody<TenderModel, 'procurement'>

type ResendTenderInvitationBody = {
  emailType: 'chair-invitation'
}

export const useMutateTender = () => {
  const { mutate } = useMutate()

  return {
    createTender: async (body: CreateTenderBody) => {
      return await mutate<TenderPopulatedModel>({ route: '/v2/tenders', body, invalidate: ['/v2/tenders', '/v2/procurements', '/v2/access-control'] })
    },
    updateTender: async (id: string, body: UpdateTenderBody) => {
      return await mutate<TenderPopulatedModel>({
        route: `/v2/tenders/${id}`,
        body,
        invalidate: ['/v2/tenders', '/v2/procurements', '/v2/access-control'],
      })
    },
    generateTenderInvitationToken: async (id: string) => {
      return await mutate<TenderPopulatedModel>({
        route: `/v2/tenders/${id}/generate-invitation-token`,
        invalidate: ['/v2/tenders', '/v2/procurements', '/v2/access-control'],
      })
    },
    progressTender: async (id: string) => {
      return await mutate<TenderPopulatedModel>({ route: `/v2/tenders/${id}/progress`, invalidate: ['/v2/tenders'] })
    },
    regressTender: async (id: string) => {
      return await mutate<TenderPopulatedModel>({ route: `/v2/tenders/${id}/regress`, invalidate: ['/v2/tenders'] })
    },
    resendTenderInvitation: async (id: string, body: ResendTenderInvitationBody) => {
      return await mutate({ route: `/v2/tenders/${id}/resend`, body })
    },
    deleteTender: async (id: string) => {
      return await mutate({ route: `/v2/tenders/${id}`, method: 'DELETE', invalidate: ['/v2/tenders', '/v2/procurements', '/v2/access-control'] })
    },
  }
}
