import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricUsersInput,
  GqlCreatePerformanceScorecardMetricUsersMutation,
  GqlCreatePerformanceScorecardMetricUsersMutationVariables,
  GqlPerformanceScorecardMetricUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-user/performance-scorecard-metric-user-fragment.graphql'

export const mutateCreatePerformanceScorecardMetricUsers = async (input: GqlCreatePerformanceScorecardMetricUsersInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricUsersMutation,
    GqlCreatePerformanceScorecardMetricUsersMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      mutation CreatePerformanceScorecardMetricUsers($input: CreatePerformanceScorecardMetricUsersInput!) {
        createPerformanceScorecardMetricUsers(input: $input) {
          ...PerformanceScorecardMetricUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricUsers as GqlPerformanceScorecardMetricUserFieldsFragment[]
}
