import { GqlCurrencyCode } from '@gql'
import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'

export const CURRENCY_OPTIONS: SelectOption_DEPRECATED<GqlCurrencyCode>[] = [
  { value: 'NZD', label: 'NZD' },
  { value: 'AUD', label: 'AUD' },
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
]

export const CURRENCY_DISPLAY_NAME_MAP: Record<GqlCurrencyCode, string> = {
  NZD: 'New Zealand Dollar',
  AUD: 'Australian Dollar',
  USD: 'United States Dollar',
  GBP: 'British Pound Sterling',
  EUR: 'Euro',
}
