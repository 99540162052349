import React, { memo } from 'react'
import { filter, find, first, map, uniq } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationIndividualListItemCta } from '@cotiss/evaluation/components/evaluation-individual-list-item-cta.component'
import { EvaluationStatusBadge } from '@cotiss/evaluation/components/evaluation-status-badge.component'
import { EvaluationStatus } from '@cotiss/evaluation/evaluation.models'
import { useListEvaluation } from '@cotiss/evaluation/resources/use-list-evaluation.resource'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { userService } from '@cotiss/user/user.service'

type Props = {
  onView: (userId: string) => void
  tenderId: string
}

export const EvaluationAllIndividualList = memo(({ onView, tenderId }: Props) => {
  const { evaluations, isLoading: isLoadingEvaluations } = useListEvaluation({ tenderId })
  const { tenderResponses, isLoading: isLoadingTenderResponses } = useListTenderResponse({ tenderId })

  const isLoading = isLoadingEvaluations || isLoadingTenderResponses

  const evaluators = uniq(map(evaluations, (evaluation) => evaluation.user._id))

  const formattedEvaluationData = tenderResponses
    .filter((tenderResponse) => tenderResponse.status === 'submitted' && tenderResponse.reviewStatus === 'included')
    .map((tenderResponse) => {
      const filteredEvaluations = filter(evaluations, { tenderResponse: tenderResponse._id })
      return {
        supplier: tenderResponse.procurementResponse.supplier.name,
        items: map(evaluators, (evaluatorId) => {
          const evaluation = find(filteredEvaluations, { user: { _id: evaluatorId } })
          return {
            name: userService.getFullName(evaluation?.user) || '',
            status: evaluation?.status || 'notStarted',
            evaluation: evaluation,
          }
        }),
      }
    })

  const renderTable = () => {
    if (!formattedEvaluationData.length && !isLoading)
      return (
        <div className="flex items-center justify-center h-[calc(100%-64px)]">
          <NoDataPlaceholder label="Something has gone wrong, please contract support for assistance." />
        </div>
      )

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Evaluator',
        rows: map(evaluators, (evaluatorId) => {
          const user = first(filter(evaluations, { user: { _id: evaluatorId } }))?.user
          return {
            content: () => (
              <Text className="truncate" title={userService.getFullName(user)}>
                {userService.getFullName(user)}
              </Text>
            ),
            cta: user && <EvaluationIndividualListItemCta onView={() => onView(user._id)} tenderId={tenderId} userId={user._id} />,
          }
        }),
      },
    ]

    const columns: ScrollableTableColumn[] = map(formattedEvaluationData, (item) => {
      return {
        heading: item.supplier,
        rows: map(item.items, (item) => {
          return {
            content: () => <EvaluationStatusBadge status={item.status as EvaluationStatus} />,
          }
        }),
      }
    })

    return <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
  }

  return renderTable()
})
