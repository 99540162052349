import React, { FormEvent, memo, useState } from 'react'
import { find } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { COUNTRY_REGION_OPTIONS, GROUPED_REGION_OPTIONS } from '@cotiss/common/constants/region.constants'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useMutateAccount } from '@cotiss/account/resources/use-mutate-account.resource'
import { useMutateOrganisation } from '@cotiss/organisation/resources/use-mutate-organisation.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type OrganisationFormData = {
  name: string
  website: string
  linkedin: string
  otherLink: string
}

type LocationFormData = {
  country?: string
  address: string
  city: string
  region?: string
  postcode: string
}

export const SupplierUpdateProfileModal = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateAccount } = useMutateAccount()
  const [isSaving, setIsSaving] = useState(false)
  const { updateOrganisation } = useMutateOrganisation()
  const [organisationFormData, setOrganisationFormData] = useState<OrganisationFormData>({
    name: user?.account?.organisation?.name || '',
    website: user?.account?.organisation?.website || '',
    linkedin: user?.account?.organisation?.linkedin || '',
    otherLink: user?.account?.organisation?.otherLink || '',
  })
  const [locationFormData, setLocationFormData] = useState<LocationFormData>({
    country: user?.account?.location?.country,
    address: user?.account?.location?.address || '',
    city: user?.account?.location?.city || '',
    region: user?.account?.location?.region,
    postcode: user?.account?.location?.postcode || '',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!user?.account?.organisation) {
      return
    }

    try {
      setIsSaving(true)
      await Promise.all([
        updateOrganisation(user.account.organisation._id, organisationFormData),
        updateAccount(user.account._id, { location: locationFormData }),
      ])
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="h-full min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Edit profile" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Title</Label>
        <Input
          value={organisationFormData.name}
          onChange={({ target }) => setOrganisationFormData({ ...organisationFormData, name: target.value })}
          placeholder="Title"
          isDisabled={isSaving}
          isRequired
        />
        <Label className="mt-4">Country</Label>
        <Select_DEPRECATED
          value={locationFormData.country}
          options={COUNTRY_REGION_OPTIONS}
          onChange={(country) => setLocationFormData({ ...locationFormData, country })}
          isDisabled={isSaving}
          placeholder
          isRequired
        />
        <Label className="mt-4">Address</Label>
        <Input
          value={locationFormData.address}
          onChange={({ target }) => setLocationFormData({ ...locationFormData, address: target.value })}
          placeholder="Address"
          isDisabled={isSaving}
          isRequired
        />
        <Label className="mt-4">City</Label>
        <Input
          value={locationFormData.city}
          onChange={({ target }) => setLocationFormData({ ...locationFormData, city: target.value })}
          placeholder="Address"
          isDisabled={isSaving}
          isRequired
        />
        <div className="flex items-center justify-between mt-4">
          <div className="w-1/2 mr-4">
            <Label>Region</Label>
            <Select_DEPRECATED
              value={locationFormData.region}
              options={find(GROUPED_REGION_OPTIONS, { label: locationFormData.country })?.options || []}
              onChange={(region) => setLocationFormData({ ...locationFormData, region })}
              placeholder="- Select region -"
              isDisabled={isSaving}
              isRequired
            />
          </div>
          <div className="w-1/2">
            <Label>Zip/Postcode</Label>
            <Input
              value={locationFormData.postcode}
              onChange={({ target }) => setLocationFormData({ ...locationFormData, postcode: target.value })}
              placeholder="Zip/Postcode"
              isDisabled={isSaving}
              isRequired
            />
          </div>
        </div>
        <Label className="mt-4">Website (optional)</Label>
        <Input
          value={organisationFormData.website}
          onChange={({ target }) => setOrganisationFormData({ ...organisationFormData, website: target.value })}
          placeholder="https://www.cotiss.com"
          isDisabled={isSaving}
        />
        <Label className="mt-4">LinkedIn (optional)</Label>
        <Input
          value={organisationFormData.linkedin}
          onChange={({ target }) => setOrganisationFormData({ ...organisationFormData, linkedin: target.value })}
          placeholder="https://www.linkedin.com/cotiss"
          isDisabled={isSaving}
        />
        <Label className="mt-4">Other (optional)</Label>
        <Input
          value={organisationFormData.otherLink}
          onChange={({ target }) => setOrganisationFormData({ ...organisationFormData, otherLink: target.value })}
          placeholder="https://www.twitter.com/cotiss"
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
