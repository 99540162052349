import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricInput,
  GqlDeletePerformanceScorecardMetricInput,
  GqlPerformanceScorecardMetricFieldsFragment,
  GqlPerformanceScorecardMetricListInput,
  GqlPerformanceScorecardMetricViewInput,
  GqlUpdatePerformanceScorecardMetricInput,
} from '@gql'
import { filter, uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecardMetric } from '@cotiss/performance/graphql/performance-scorecard-metric/mutate-create-performance-scorecard-metric.graphql'
import { mutateDeletePerformanceScorecardMetric } from '@cotiss/performance/graphql/performance-scorecard-metric/mutate-delete-performance-scorecard-metric.graphql'
import { mutateUpdatePerformanceScorecardMetric } from '@cotiss/performance/graphql/performance-scorecard-metric/mutate-update-performance-scorecard-metric.graphql'
import { queryPerformanceScorecardMetricList } from '@cotiss/performance/graphql/performance-scorecard-metric/query-performance-scorecard-metric-list.graphql'
import { queryPerformanceScorecardMetricView } from '@cotiss/performance/graphql/performance-scorecard-metric/query-performance-scorecard-metric-view.graphql'

const performanceScorecardMetricVar = makeVar<GqlPerformanceScorecardMetricFieldsFragment | null>(null)
const performanceScorecardMetricsVar = makeVar<GqlPerformanceScorecardMetricFieldsFragment[]>([])

export const usePerformanceScorecardMetric = () => {
  const performanceScorecardMetric = useReactiveVar(performanceScorecardMetricVar)
  const performanceScorecardMetrics = useReactiveVar(performanceScorecardMetricsVar)

  return {
    performanceScorecardMetric,
    performanceScorecardMetrics,
    setPerformanceScorecardMetric: performanceScorecardMetricVar,
    setPerformanceScorecardMetrics: performanceScorecardMetricsVar,
    queryPerformanceScorecardMetricList: async (input: GqlPerformanceScorecardMetricListInput) => {
      const { items, pagination } = await queryPerformanceScorecardMetricList(input)

      performanceScorecardMetricsVar(items)

      return { items, pagination }
    },
    queryPerformanceScorecardMetricView: async (input: GqlPerformanceScorecardMetricViewInput) => {
      const performanceScorecardMetric = await queryPerformanceScorecardMetricView(input)

      performanceScorecardMetricVar(performanceScorecardMetric)
      performanceScorecardMetricsVar(uniqBy([performanceScorecardMetric, ...performanceScorecardMetrics], 'id'))

      return performanceScorecardMetric
    },
    mutateCreatePerformanceScorecardMetric: async (input: GqlCreatePerformanceScorecardMetricInput) => {
      const createdPerformanceScorecardMetric = await mutateCreatePerformanceScorecardMetric(input)

      performanceScorecardMetricVar(createdPerformanceScorecardMetric)
      performanceScorecardMetricsVar(uniqBy([createdPerformanceScorecardMetric, ...performanceScorecardMetrics], 'id'))

      return createdPerformanceScorecardMetric
    },
    mutateUpdatePerformanceScorecardMetric: async (input: GqlUpdatePerformanceScorecardMetricInput) => {
      const updatedPerformanceScorecardMetric = await mutateUpdatePerformanceScorecardMetric(input)

      performanceScorecardMetricVar(updatedPerformanceScorecardMetric)
      performanceScorecardMetricsVar(uniqBy([updatedPerformanceScorecardMetric, ...performanceScorecardMetrics], 'id'))

      return updatedPerformanceScorecardMetric
    },
    mutateDeletePerformanceScorecardMetric: async (input: GqlDeletePerformanceScorecardMetricInput) => {
      await mutateDeletePerformanceScorecardMetric(input)

      performanceScorecardMetricsVar(filter(performanceScorecardMetrics, ({ id }) => id !== input.performanceScorecardMetricId))
    },
  }
}

export const clearReactivePerformanceScorecardMetric = async () => {
  performanceScorecardMetricVar(null)
  performanceScorecardMetricsVar([])
}
