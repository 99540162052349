import { compact, map } from 'lodash'
import REGIONS_MAP from '@cotiss/common/data/regions-map.data.json'

export type SingularRegion = {
  label: string
  value: string
}

export type GroupedRegion = {
  label: string
  value: string
  options?: SingularRegion[]
}

type SingularRegionWithParent = SingularRegion & {
  parent?: string
}

type ProcessedRegionMap = Record<string, SingularRegionWithParent | undefined>

class RegionService {
  processRegions = (regions: string[]) => {
    return compact(map(regions, (region) => (REGIONS_MAP as ProcessedRegionMap)[region]))
  }
}

export const regionService = new RegionService()
