import React, { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex, includes } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { routerService } from '@cotiss/common/services/router.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { PerformanceScorecardList } from '@cotiss/performance/components/performance-scorecard-list.component'
import { PerformanceScorecardCreateDrawer } from '@cotiss/performance/drawers/performance-scorecard-create.drawer'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type PerformanceScorecardListTab = 'archived' | 'active'

const PERFORMANCE_SCORECARD_LIST_TABS: TabModel<PerformanceScorecardListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const PerformanceScorecardListPage = memo(() => {
  const { track } = useAnalytics()
  const { openDrawer } = useCallout()
  const { user } = useGetLoggedInUser()
  const { tab } = useParams<{ tab?: PerformanceScorecardListTab }>()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { step, transition, onTransition } = useTransition({
    initialStep: tab ? findIndex(PERFORMANCE_SCORECARD_LIST_TABS, ({ id }) => id === tab) + 1 : 0,
  })
  const { replace, push } = useHistory()

  useEffect(() => {
    track('performance_scorecard_list_view')

    if (!tab) {
      replace(routerService.getHref('/performance/scorecard/list/:tab?', { tab: 'active' }))
    }
  }, [])

  const canCreatePerformanceScorecard = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])

  const handleTabChange = (newTab: PerformanceScorecardListTab) => {
    const newStep = findIndex(PERFORMANCE_SCORECARD_LIST_TABS, ({ id }) => id === newTab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    push(routerService.getHref('/performance/scorecard/list/:tab?', { tab: newTab }))
  }

  if (!isPerformanceScorecardEnabled) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Performance
        </Text>
        {canCreatePerformanceScorecard && (
          <Button onClick={() => openDrawer(<PerformanceScorecardCreateDrawer />)} variant="primary" size="md">
            + New scorecard
          </Button>
        )}
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <Tabs<PerformanceScorecardListTab>
          tab={tab}
          tabs={PERFORMANCE_SCORECARD_LIST_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="raised"
        />
      </div>
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <PerformanceScorecardList />}
            {step === 2 && <PerformanceScorecardList isArchived />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
