import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Badge } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

type Props = {
  className?: string
  children?: ReactNode
  variant: BaseVariant
  count?: number
}

export const KanbanHeaderCard = memo(({ className, children, variant, count = 0 }: Props) => {
  const classes = classNames(className, 'sticky top-0 flex shrink-0 bg-white border-b-[3px] rounded shadow w-full p-2 mb-2', {
    'border-gray-400': variant === 'primary',
    'border-secondary-500': variant === 'secondary',
    'border-pink-400': variant === 'danger',
    'border-blue-400': variant === 'info',
    'border-orange-400': variant === 'warning',
    'border-green-400': variant === 'success',
    'border-gray-50': variant === 'neutral',
  })

  return (
    <div className={classes}>
      {children}
      <Badge className="ml-2" state="translucent" variant={variant}>
        {count}
      </Badge>
    </div>
  )
})
