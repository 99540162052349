import React, { memo } from 'react'
import documentsPng from '@assets/images/documents-01.png'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
  onCreateRequest: () => void
}

export const TenderFlowRequestDraftCard = memo(({ className, onCreateRequest }: Props) => {
  const classes = classNames(className, 'shrink-0 border border-dashed border-primary-200 rounded-lg w-60 p-4')

  return (
    <div className={classes}>
      <img className="mx-auto" src={documentsPng} alt="Documents" />
      <Text className="font-semibold mt-4">Start drafting a request</Text>
      <Text className="mt-1" variant="light" size="sm">
        Create a request to open this procurement up to new submissions.
      </Text>
      <Button className="w-full mt-8" onClick={onCreateRequest} size="sm" variant="secondary">
        + Create request
      </Button>
    </div>
  )
})
