import React, { memo, useMemo, useState } from 'react'
import { GqlPagination, GqlPerformanceScorecardMetricFieldsFragment } from '@gql'
import { filter, includes, map, some } from 'lodash'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { usePerformanceScorecardMetricUser } from '@cotiss/performance/hooks/use-performance-scorecard-metric-user.hook'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { userService } from '@cotiss/user/user.service'

type Props = {
  performanceScorecardId: string
  performanceScorecardUserId: string
  performanceScorecardMetricIds: string[]
  setPerformanceScorecardMetricIds: (performanceScorecardMetricIds: string[]) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardEvaluatorUpdateMetricsStep = memo((props: Props) => {
  const { performanceScorecardId, performanceScorecardUserId, performanceScorecardMetricIds, setPerformanceScorecardMetricIds, onBack, isDisabled } =
    props
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardUser } = usePerformanceScorecardUser()
  const { performanceScorecardMetricUsers } = usePerformanceScorecardMetricUser()
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricList({
        filter: { performanceScorecardId },
        pagination: { page: currentPage, pageSize: 20 },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  const handleChange = (performanceScorecardMetric: GqlPerformanceScorecardMetricFieldsFragment) => {
    if (includes(performanceScorecardMetricIds, performanceScorecardMetric.id)) {
      setPerformanceScorecardMetricIds(
        filter(performanceScorecardMetricIds, (performanceMetricId) => performanceMetricId !== performanceScorecardMetric.id)
      )
    } else {
      setPerformanceScorecardMetricIds([...performanceScorecardMetricIds, performanceScorecardMetric.id])
    }
  }

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(performanceScorecardMetrics, (performanceScorecardMetric) => {
          const isAdded = some(performanceScorecardMetricUsers, {
            performanceScorecardMetricId: performanceScorecardMetric.id,
            performanceScorecardUserId,
          })

          return {
            content: () => (
              <Checkbox
                value={performanceScorecardMetric.id}
                onChange={() => handleChange(performanceScorecardMetric)}
                isChecked={isAdded || includes(performanceScorecardMetricIds, performanceScorecardMetric.id)}
                isDisabled={isAdded || isDisabled}
              />
            ),
          }
        }),
      },
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => (performanceMetric.createdByUser ? <Avatar>{userService.getInitials(performanceMetric.createdByUser)}</Avatar> : '--'),
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceScorecardMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardMetrics, performanceScorecardMetricIds, isDisabled])

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: userService.getFullName(performanceScorecardUser?.user) || '',
      onClick: onBack,
    },
    {
      label: 'Add metric',
      isLoading,
    },
  ]

  if (isError) {
    return (
      <>
        <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
        <ErrorPanel />
      </>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      {performanceScorecardUser && (
        <Text className="font-medium" size="lg">
          {userService.getFullName(performanceScorecardUser.user)}
        </Text>
      )}
      {performanceScorecardUser && (
        <Text className="mt-2" size="sm" variant="light">
          {performanceScorecardUser.user.email}
        </Text>
      )}
      <Hr className="my-4" />
      <TableHeader>
        <Text className="font-medium" variant="heading">
          Search and select metric
        </Text>
        <Text className="mt-1" size="sm" variant="light">
          Select options below to continue
        </Text>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} pagination={pagination} onPageChange={setCurrentPage} />
    </>
  )
})
