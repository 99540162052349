import React, { memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Field } from '@cotiss/common/components/field.component'
import { Input } from '@cotiss/common/components/input.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventSubmissionUpdateDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-submission-update.drawer'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'

export const EvaluationEventSubmissionImportManualDrawer = memo(() => {
  const { openToast } = useToast()
  const [name, setName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { openDrawer, closeNarrowDrawer } = useCallout()
  const { setEvaluationSubmission, mutateCreateEvaluationSubmission } = useEvaluationSubmission()

  const handleSubmit = async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      setIsSaving(true)
      closeNarrowDrawer()
      const evaluationSubmission = await mutateCreateEvaluationSubmission({
        evaluationEventId: evaluationEvent.id,
        name,
      })
      setEvaluationSubmission(evaluationSubmission)
      openDrawer(<EvaluationEventSubmissionUpdateDrawer />)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Import manual
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button variant="secondary" type="submit" isLoading={isSaving}>
        Submit
      </Button>
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <Field label="Submission name">
        <Input value={name} onChange={({ target }) => setName(target.value)} placeholder="Enter submission name" isDisabled={isSaving} isRequired />
      </Field>
    </Drawer>
  )
})
