import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { PreferredSupplierBuyerPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'

type Props = {
  limit?: number
  offset?: number
}

export const useListPreferredSupplierBuyer = ({ limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<PreferredSupplierBuyerPopulatedModel>>('/v2/preferred-suppliers/buyer', {
    route: `/v2/preferred-suppliers/buyer`,
    params: { limit, offset },
  })

  return { preferredSupplierBuyers: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
