import React, { useMemo, useState } from 'react'
import { Badge } from '@cotiss/common/components/badge.component'
import { useGetSsoConfiguration } from '@cotiss/auth/resources/use-get-sso-configuration.resource'
import { useMutateAuth } from '@cotiss/auth/resources/use-mutate-auth.resource'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Header } from '@cotiss/common/components/header.component'
import { Text } from '@cotiss/common/components/text.component'
import { Switch } from '@cotiss/common/components/switch.component'
import { Field } from '@cotiss/common/components/field.component'
import { EditableField } from '@cotiss/common/components/editable-field.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Page } from '@cotiss/common/components/page.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { PROVIDER_NAME_MAPPING, SsoConfigurationModel } from '@cotiss/auth/auth.models'
import { SsoCertificateEditDrawer } from '@cotiss/auth/drawers/sso-certificate-edit.drawer'
import { SsoConfigurationEditModal } from '@cotiss/auth/modals/sso-configuration-edit.modal'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { USER_PERMISSION_MAP } from '@cotiss/user/user.constants'

export const SsoConfigurationPage = () => {
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const { permissions } = useUserAccess()
  const [isSaving, setIsSaving] = useState(false)
  const { openModal, openNarrowDrawer } = useCallout()
  const { configurations, isLoading } = useGetSsoConfiguration()
  const isSsoConfigurationEnabled = useFeature('sso-configuration')
  const { createSsoConfiguration, updateSsoConfiguration } = useMutateAuth()
  const [configuration, setConfiguration] = useState<SsoConfigurationModel | null>(null)

  useAsyncEffect(async () => {
    if (isLoading) {
      return
    }

    // Currently there should only ever be one configuration
    let configuration = configurations?.length ? configurations[0] : null

    if (!configuration) {
      track('sso_configuration_create_submit')
      try {
        configuration = await createSsoConfiguration()
      } catch (e: any) {
        sentryService.captureException({ exception: e.message })
        openToast('Whoops, something went wrong loading configuration.')
      }
    }

    setConfiguration(configuration)
  }, [configurations, isLoading])

  const handleClickActivateSso = async () => {
    if (!configuration) {
      return
    }

    const isEnabledUpdated = !configuration?.enabled
    if (isEnabledUpdated) {
      track('sso_configuration_activate_submit')
    }
    if (!isEnabledUpdated) {
      track('sso_configuration_deactivate_submit')
    }
    try {
      setIsSaving(true)
      await updateSsoConfiguration(configuration.id, { enabled: isEnabledUpdated })
      openToast(`SSO ${isEnabledUpdated ? 'activated' : 'deactivated'}`)
      setIsSaving(false)
    } catch (e: any) {
      setIsSaving(false)
      sentryService.captureException({ exception: e.message })
      openToast("Couldn't update configuration. Please try again.")
    }
  }
  const decodedCert = useMemo(
    () => (configuration?.x509Certificate ? window.atob(configuration.x509Certificate) : ''),
    [configuration?.x509Certificate]
  )

  const renderClickToCopyButton = (textToCopy: string) => {
    return (
      <Button
        isDisabled={isSaving}
        state="text"
        onClick={() => {
          navigator.clipboard.writeText(textToCopy)
          openToast('Copied to clipboard')
        }}
        className="w-full rounded-md group hover:bg-primary-50 p-3"
      >
        <div className="w-full flex items-start justify-between group-hover:text-secondary-500">
          <Text className="w-full max-w-md flex group-hover:text-secondary-500 whitespace-pre-wrap">{textToCopy}</Text>
          <Icon icon="copy-07" className="text-gray-500 group-hover:text-secondary-500" />
        </div>
      </Button>
    )
  }

  if (!isSsoConfigurationEnabled || !permissions.isAdmin) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center">
          <Text className="font-semibold mr-4" size="h5" variant="heading">
            SSO Integration
          </Text>
          <Badge shape="rounded" variant={configuration?.enabled ? 'success' : 'neutral'} state="outline">
            {configuration?.enabled ? 'Active' : 'Inactive'}
          </Badge>
        </div>
      </Header>

      <Card className="m-4 max-w-7xl">
        <CardHeader>
          <div>
            <Text size="lg" className="font-medium">
              Integration form
            </Text>
            <Text variant="light">Complete this form to activate your SSO integration</Text>
          </div>
          <div className="flex items-center">
            <Switch isOn={configuration?.enabled} className="mr-1" onClick={() => handleClickActivateSso()} isDisabled={isSaving} />
            <Text className="ml-2">Activate SSO integration</Text>
          </div>
        </CardHeader>
        <div className="flex flex-col gap-6">
          <EditableField
            isDisabled={isSaving}
            label="IDP Provider"
            supplementary="Choose identity provider"
            textValue={configuration?.providerName ? PROVIDER_NAME_MAPPING[configuration.providerName] : ''}
            onClick={() => openModal(<SsoConfigurationEditModal label="Provider name" field="providerName" />)}
          />
          <Field label="ACS URL" supplementary="Assertion consumer service URL" isFullWidth>
            {configuration?.assertionConsumerServiceUrl ? renderClickToCopyButton(configuration.assertionConsumerServiceUrl) : '--'}
          </Field>
          <Banner icon="star-06" variant="light" iconVariant="secondary">
            <div>
              <Text className="font-semibold" variant="heading">
                Enter data into the following inputs
              </Text>
              <Text size="sm">Configure and review the following settings in order to complete or update your SSO integration</Text>
            </div>
          </Banner>
          <Field label="Entity ID" isFullWidth>
            {configuration?.entityId ? renderClickToCopyButton(configuration.entityId) : '--'}
          </Field>
          <EditableField
            isDisabled={isSaving}
            label="Metadata XML URL"
            textValue={configuration?.metadataUrl}
            onClick={() => openModal(<SsoConfigurationEditModal label="Metadata XML" field="metadataUrl" />)}
          />
          <EditableField
            isDisabled={isSaving}
            label="Login URL"
            onClick={() => openModal(<SsoConfigurationEditModal label="Login URL" field="loginUrl" />)}
            textValue={configuration?.url}
          />
          <EditableField isDisabled={isSaving} label="Certificate" onClick={() => openNarrowDrawer(<SsoCertificateEditDrawer />)}>
            {decodedCert && <Text className="truncate">{decodedCert}</Text>}
          </EditableField>
          <EditableField
            isDisabled={isSaving}
            label="Default user role"
            supplementary="Default role assigned to new users created when created during SSO process. This can be updated by an admin in the user settings."
            textValue={configuration?.defaultUserPermission ? USER_PERMISSION_MAP[configuration?.defaultUserPermission] : 'Select a role'}
            onClick={() => openModal(<SsoConfigurationEditModal label="Default user role" field="defaultUserPermission" />)}
          />
        </div>
      </Card>
    </Page>
  )
}
