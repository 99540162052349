import React, { memo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'
import { SupplierViewCertificationItem } from '@cotiss/supplier/components/supplier-view-certification-item.component'
import { SupplierAddUpdateCertificationModal } from '@cotiss/supplier/modals/supplier-add-update-certification.modal'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewCertificationTab = memo(({ organisationId, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { organisation } = useGetOrganisation(organisationId)

  if (!organisation) {
    return null
  }

  const renderContent = () => {
    if (!organisation?.certifications?.length) {
      return (
        <div className="flex items-center justify-center">
          <Text variant="light">No certifications added yet</Text>
        </div>
      )
    }

    return (
      <>
        {map(organisation?.certifications, (certification, index) => (
          <SupplierViewCertificationItem
            key={certification._id}
            className={classNames({ 'pt-4 mt-4': index, 'border-t border-gray-200': index })}
            organisation={organisation}
            certification={certification}
            isEditable={isEditable}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {!isEditable && (
        <Banner className="mb-4" icon="info-circle" iconVariant="secondary" variant="neutral">
          <div>
            <Text className="font-semibold" variant="heading">
              This tab is for the contact to complete
            </Text>
            <Text size="sm">Contact profiles will be updated by the organisation</Text>
          </div>
        </Banner>
      )}
      <Card>
        <CardHeader>
          <Text className="font-semibold">Certifications</Text>
          {isEditable && (
            <Button
              onClick={() => openModal(<SupplierAddUpdateCertificationModal organisation={organisation} />)}
              state="translucent"
              variant="secondary"
              size="sm"
            >
              <Icon icon="plus-01" />
              Add
            </Button>
          )}
        </CardHeader>
        {renderContent()}
      </Card>
    </>
  )
})
