import React, { memo, useMemo } from 'react'
import { map } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderFieldWithHistory } from '@cotiss/tender/tender.models'
import { tenderService } from '@cotiss/tender/tender.service'
import { userService } from '@cotiss/user/user.service'

type Props = {
  tenderId?: string
  field: TenderFieldWithHistory
}

export const TenderHistoryModal = memo(({ tenderId, field }: Props) => {
  const { tender, isLoading } = useGetTender(tenderId)

  const history = useMemo(() => (tender ? tenderService.getPeriodEndDateHistory({ tender, field }) : []), [tender])

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
          <Skeleton className="bg-primary-200 h-16 w-full" />
        </>
      )
    }

    if (!history.length) {
      return <NoDataPlaceholder label="No changes made" />
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Activity',
        rows: map(history, ({ type, value }) => ({
          content: () => (
            <div className="flex items-center">
              <Icon className="mr-4" variant="secondary" icon={type === 'added' ? 'circle-add' : 'refresh-02'} />
              <div className="w-full truncate">
                <Text>
                  {field === 'tenderPeriod' ? 'Tender' : 'Forum'} close date {type}
                </Text>
                <Text variant="light" size="sm" className="w-full truncate" title={value}>
                  {value ? datetimeService.format(value, 'd MMMM yyyy h:mm aaa') : '--'}
                </Text>
              </div>
            </div>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Actioned by',
        rows: map(history, ({ actionedBy }) => ({
          content: () => (
            <Text className="truncate w-48" title={userService.getFullName(actionedBy)}>
              {actionedBy ? userService.getFullName(actionedBy) : '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Timestamp',
        rows: map(history, ({ timestamp }) => ({
          content: () => <Text>{timestamp ? datetimeService.format(timestamp, 'd MMMM yyyy h:mm aaa') : '--'}</Text>,
        })),
      },
    ]

    return <ScrollableTable fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
  }

  return (
    <>
      <ModalHeader heading="View history" />
      <ModalContent>{renderContent()}</ModalContent>
    </>
  )
})
