import React, { FormEvent, memo, useState } from 'react'
import { format, parse, parseISO } from 'date-fns'
import { map } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { MONTH_OPTIONS } from '@cotiss/common/constants/datetime.constants'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { OrganisationJobModel, OrganisationModel } from '@cotiss/organisation/organisation.models'
import { useMutateOrganisation } from '@cotiss/organisation/resources/use-mutate-organisation.resource'

type FormData = {
  title: string
  description: string
  startMonth?: string
  startYear: string
  endMonth?: string
  endYear: string
  link: string
  attachments: DocumentModel[]
}

type Props = {
  organisation: OrganisationModel
  job?: OrganisationJobModel
}

export const SupplierAddUpdateJobModal = memo(({ organisation, job }: Props) => {
  const today = new Date()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createOrganisationJob, updateOrganisationJob } = useMutateOrganisation()
  const [formData, setFormData] = useState<FormData>({
    title: job?.title || '',
    description: job?.description || '',
    startMonth: job?.startDate ? format(parseISO(job.startDate), 'MMMM') : undefined,
    startYear: job?.startDate ? format(parseISO(job.startDate), 'yyyy') : today.getFullYear().toString(),
    endMonth: job?.endDate ? format(parseISO(job.endDate), 'MMMM') : undefined,
    endYear: job?.endDate ? format(parseISO(job.endDate), 'yyyy') : today.getFullYear().toString(),
    link: job?.link || '',
    attachments: job?.attachments || [],
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const { startMonth, startYear, endMonth, endYear, attachments, ...rest } = formData

      const formDataToPost = {
        startDate: parse(`${startMonth} ${startYear}`, 'MMMM yyyy', new Date()),
        endDate: parse(`${endMonth} ${endYear}`, 'MMMM yyyy', new Date()),
        attachments: map(attachments, ({ _id }) => _id),
        ...rest,
      }

      if (job) {
        await updateOrganisationJob(organisation._id, job._id, formDataToPost)
      } else {
        await createOrganisationJob(organisation._id, formDataToPost)
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${job ? 'Update' : 'Add'} past work`} isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Enter job title"
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-4">Description</Label>
        <TextArea
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Enter job description (Max 500 characters)"
          isDisabled={isSaving}
          isRequired
        />

        <div className="flex items-center justify-between mt-4">
          <div className="w-full mr-4">
            <Label>Start month</Label>
            <Select_DEPRECATED
              value={formData.startMonth}
              options={MONTH_OPTIONS}
              onChange={(startMonth) => setFormData({ ...formData, startMonth })}
              isDisabled={isSaving}
              isRequired
              placeholder="- Select month -"
            />
          </div>
          <div className="w-full">
            <Label>Start year</Label>
            <Input
              value={formData.startYear}
              onChange={({ target }) => setFormData({ ...formData, startYear: target.value })}
              placeholder="Enter start year"
              type="number"
              min={1900}
              max={2100}
              isDisabled={isSaving}
              isRequired
            />
          </div>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="w-full mr-4">
            <Label>End month (Or expected)</Label>
            <Select_DEPRECATED
              value={formData.endMonth}
              options={MONTH_OPTIONS}
              onChange={(endMonth) => setFormData({ ...formData, endMonth })}
              isDisabled={isSaving}
              isRequired
              placeholder="- Select month -"
            />
          </div>
          <div className="w-full">
            <Label>End year (or expected)</Label>
            <Input
              value={formData.endYear}
              onChange={({ target }) => setFormData({ ...formData, endYear: target.value })}
              placeholder="Enter end year"
              type="number"
              min={1900}
              max={2100}
              isDisabled={isSaving}
              isRequired
            />
          </div>
        </div>

        <Label className="mt-4">External link (optional)</Label>
        <Input
          value={formData.link}
          onChange={({ target }) => setFormData({ ...formData, link: target.value })}
          placeholder="Add an external link if relevant"
          isDisabled={isSaving}
        />

        <Label className="mt-4">Attachments</Label>
        <DocumentUpload documents={formData.attachments} onChange={(attachments) => setFormData({ ...formData, attachments })} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
