import { differenceInDays } from 'date-fns'
import { forEach } from 'lodash'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { PriceItemResponsePopulatedModel } from '@cotiss/price-item-response/price-item-response.models'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'

type GetTotalNpvParam = {
  npv: number
  priceItemResponses: PriceItemResponsePopulatedModel[]
}

const DAYS_IN_YEAR = 365.25

class PriceItemResponseService {
  // The method of calculating the total NPV can be found here: https://www.investopedia.com/terms/n/npv.asp.
  getTotalNpv = ({ npv, priceItemResponses }: GetTotalNpvParam) => {
    let totalNpv = 0
    const discountRate = npv / 100
    const dailyDiscountRate = discountRate / DAYS_IN_YEAR

    forEach(priceItemResponses, ({ priceItem, quantity, paymentDate, unitPrice }) => {
      // We have to prioritise the quantity off the price item response, as this would have been overridden by the supplier.
      const quantityToUse = quantity || priceItem?.quantity

      if (!paymentDate || unitPrice === undefined || quantityToUse === undefined) {
        return
      }

      // ! The values of the table can either come from the `PriceItemResponse.priceItem.{...}`, or directly off the `PriceItemResponse.{...}`. This
      // ! is because the buyer sets their price items on the price item, and if the supplier has permission to add their own line items via
      // ! `priceTableAllowAdditionalItems` being set on the tender, they create their own line items, but their properties are set directly on
      // ! `PriceItemResponse`. This is not an intuitive model, and we should consider refining this in the future.
      const paymentDateToUse = datetimeService.parse(paymentDate)
      const numberOfDaysTillPaymentDate = differenceInDays(paymentDateToUse, new Date())

      const totalItemPrice = unitPrice * quantityToUse
      totalNpv += totalItemPrice / Math.pow(1 + dailyDiscountRate, numberOfDaysTillPaymentDate)
    })

    return totalNpv
  }

  isComplete = ({ priceItemResponse, tender }: { priceItemResponse: PriceItemResponsePopulatedModel; tender: TenderPopulatedModel }) => {
    // Buyer line item, only need to check unit price and payment date if required.
    if (priceItemResponse.priceItem) {
      return priceItemResponse.unitPrice !== undefined && (tender.priceTableRequirePaymentDate ? priceItemResponse.paymentDate !== undefined : true)
    }

    // Supplier line item, need to check all fields.
    return (
      priceItemResponse.category !== undefined &&
      priceItemResponse.description !== undefined &&
      priceItemResponse.quantity !== undefined &&
      priceItemResponse.unit !== undefined &&
      priceItemResponse.unitPrice !== undefined &&
      (tender.priceTableRequirePaymentDate ? priceItemResponse.paymentDate !== undefined : true)
    )
  }
}

export const priceItemResponseService = new PriceItemResponseService()
