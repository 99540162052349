import { isBefore, parseISO } from 'date-fns'
import { filter, includes, indexOf } from 'lodash'
import { featureService } from '@cotiss/common/services/feature.service'
import { TenderModel, TenderPopulatedModel } from '@cotiss/tender/tender.models'
import { TENDER_FLOW_STEPS, TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'

class TenderFlowService {
  getTenderFlowSteps = (tender?: TenderModel | TenderPopulatedModel): TenderFlowStep[] => {
    const newSteps = [...filter(TENDER_FLOW_STEPS, (step) => includes(['planning', 'active', 'review', 'shortlist'], step))]
    const oldSteps = [...filter(TENDER_FLOW_STEPS, (step) => step !== 'shortlist')]

    if (!tender) {
      return newSteps
    }

    if (!this.isOldEvaluationsEnabled(tender)) {
      return newSteps
    }

    return tender.evaluationPanelType === 'none' ? filter(oldSteps, (step) => step !== 'evaluate') : oldSteps
  }

  getActiveStep = (tender?: TenderModel | TenderPopulatedModel): TenderFlowStep => {
    if (!tender) {
      return 'planning'
    }

    const isOldEvaluationsEnabled = this.isOldEvaluationsEnabled(tender)

    switch (tender.status) {
      case 'planning':
      default:
        return 'planning'
      case 'planned-approval':
      case 'active':
        return 'active'
      case 'review':
        return 'review'
      case 'evaluate':
        return isOldEvaluationsEnabled ? 'evaluate' : 'shortlist'
      case 'consensus':
      case 'price':
        return 'evaluate'
      case 'selection':
        return 'selection'
      case 'selection-approval':
      case 'report':
        return 'report'
    }
  }

  getUnlockedRequestStepIndex = (tender: TenderModel | TenderPopulatedModel) => {
    const steps = this.getTenderFlowSteps(tender)
    const activeStep = this.getActiveStep(tender)

    return indexOf(steps, activeStep) + 1
  }

  isOldEvaluationsEnabled = (tender?: TenderModel | TenderPopulatedModel): boolean => {
    if (!tender) {
      return false
    }

    // Need to have access to the feature flag
    // As well as having to advanced the tender past the planning stage before the date below
    // The 18th of December 2023 is the cut off date for old evaluations. Any tenders created after this date will not be able to use the old evaluations
    // This is to ensure that even with feature flag access, all tenders created after this date will use the new evaluations
    return featureService.variation('old-evaluations') && tender.status !== 'planning' && isBefore(parseISO(tender.createdAt), new Date(2023, 11, 18))
  }
}

export const tenderFlowService = new TenderFlowService()
