import React, { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { indexOf, upperFirst } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { ProcurementSettings } from '@cotiss/procurement/components/procurement-settings.component'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderFlowHeader } from '@cotiss/tender-flow/components/tender-flow-header.component'
import { TenderFlowActiveStep } from '@cotiss/tender-flow/steps/tender-flow-active.step'
import { TenderFlowEvaluateStep } from '@cotiss/tender-flow/steps/tender-flow-evaluate.step'
import { TenderFlowReportStep } from '@cotiss/tender-flow/steps/tender-flow-report.step'
import { TenderFlowRequestStep } from '@cotiss/tender-flow/steps/tender-flow-request.step'
import { TenderFlowReviewStep } from '@cotiss/tender-flow/steps/tender-flow-review.step'
import { TenderFlowSelectionStep } from '@cotiss/tender-flow/steps/tender-flow-selection.step'
import { TenderFlowShortlistStep } from '@cotiss/tender-flow/steps/tender-flow-shortlist.step'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

export const TenderFlowPage = memo(() => {
  const { replace } = useHistory()
  const { tenderId, step: procurementRequestStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab: string }>()
  const { tender } = useGetTender(tenderId)
  const { procurement } = useGetProcurement(tender?.procurement)
  const activeStep = tenderFlowService.getActiveStep(tender)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)
  const procurementRequestStepsWithSettings = [...tenderFlowService.getTenderFlowSteps(tender), 'settings']

  const { step, transition, onTransition } = useTransition({ initialStep: indexOf(procurementRequestStepsWithSettings, procurementRequestStep) + 1 })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: upperFirst(activeStep),
      onClick: () => replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: activeStep })),
      isLoading: !procurement,
    },
  ]

  useEffect(() => {
    if (!procurementRequestStep) {
      replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'planning' }))
    }
  }, [])

  useEffect(() => {
    if (procurementRequestStep === 'planning' && tab === undefined) {
      replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: procurementRequestStep, tab: 'details' }))
    }
  }, [procurementRequestStep])

  useEffect(() => {
    const newStep = indexOf(procurementRequestStepsWithSettings, procurementRequestStep) + 1

    onTransition({ step: newStep || 1, transition: newStep > step ? 'right' : 'left' })
  }, [procurementRequestStep])

  return (
    <Page>
      <TenderFlowHeader />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <TenderFlowRequestStep />}
            {step === 2 && <TenderFlowActiveStep />}
            {step === 3 && <TenderFlowReviewStep />}
            {step === 4 && !isOldEvaluationsEnabled && <TenderFlowShortlistStep />}
            {step === 4 && isOldEvaluationsEnabled && <TenderFlowEvaluateStep />}
            {step === 5 && isOldEvaluationsEnabled && <TenderFlowSelectionStep />}
            {step === 6 && isOldEvaluationsEnabled && <TenderFlowReportStep />}
            {step === procurementRequestStepsWithSettings.length && (
              <ProcurementSettings procurementId={tender?.procurement} breadcrumbs={breadcrumbs} />
            )}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
