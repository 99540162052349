import React, { memo, useState } from 'react'
import { map, filter, includes } from 'lodash'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { TenderResponsePriceTable } from '@cotiss/tender-response/components/tender-response-price-table.component'
import { TenderResponseNonPriceClarificationUploadModal } from '@cotiss/tender-response/modals/tender-response-non-price-clarification-upload.modal'
import { TenderResponsePriceClarificationUploadModal } from '@cotiss/tender-response/modals/tender-response-price-clarification-upload.modal'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'

type Props = {
  tenderResponseId: string
  isEditable?: boolean
}

export const TenderResponseReviewForms = memo(({ tenderResponseId, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { tenderResponse, isLoading } = useGetTenderResponse(tenderResponseId)

  const isUploadEnabled = useFeature('enable-editing-response-clarification-documents')

  const isAbleToUploadClarifications = isEditable || isUploadEnabled

  const handleBulkRemovePriceClarificationDocuments = async (documentIdsToRemove: string[]) => {
    if (!tenderResponse) {
      return
    }

    try {
      setIsSaving(true)
      const priceAttachmentsClarification = map(
        filter(tenderResponse.priceAttachmentsClarification, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateTenderResponse(tenderResponse._id, { priceAttachmentsClarification })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleBulkRemoveNonPriceClarificationDocuments = async (documentIdsToRemove: string[]) => {
    if (!tenderResponse) {
      return
    }

    try {
      setIsSaving(true)
      const nonPriceAttachmentsClarification = map(
        filter(tenderResponse.nonPriceAttachmentsClarification, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateTenderResponse(tenderResponse._id, { nonPriceAttachmentsClarification })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      <Text className="font-semibold">Response forms</Text>
      <Text className="mt-1" variant="light" size="sm">
        View and download response forms.
      </Text>
      <DocumentList className="mt-4" title="Price documents" documents={tenderResponse?.priceAttachments} />
      <DocumentList className="mt-4" title="Non-price documents" documents={tenderResponse?.nonPriceAttachments} />

      <Hr className="my-4" />

      <Text className="font-semibold">Clarifications (optional)</Text>
      <Text className="mt-1" variant="light" size="sm">
        Please upload any additional documents relevant to this response.
      </Text>
      <DocumentList
        className="mt-4"
        title="Price clarification documents"
        documents={tenderResponse?.priceAttachmentsClarification}
        onUpload={
          isAbleToUploadClarifications
            ? () => openModal(<TenderResponsePriceClarificationUploadModal tenderResponseId={tenderResponseId} />)
            : undefined
        }
        onBulkRemove={isAbleToUploadClarifications ? handleBulkRemovePriceClarificationDocuments : undefined}
        isDisabled={isSaving || isLoading}
      />
      <DocumentList
        className="mt-4"
        title="Non-price clarification documents"
        documents={tenderResponse?.nonPriceAttachmentsClarification}
        onUpload={
          isAbleToUploadClarifications
            ? () => openModal(<TenderResponseNonPriceClarificationUploadModal tenderResponseId={tenderResponseId} />)
            : undefined
        }
        onBulkRemove={isAbleToUploadClarifications ? handleBulkRemoveNonPriceClarificationDocuments : undefined}
        isDisabled={isSaving || isLoading}
      />
      {tenderResponse?.tender.priceTableEnabled && (
        <>
          <Hr className="my-4" />
          <Text className="font-semibold">Line items</Text>
          <Text className="mt-1" variant="light" size="sm">
            View pricing table submission.
          </Text>
          <TenderResponsePriceTable
            className="mt-4"
            tenderResponseId={tenderResponseId}
            isNpvVisible={tenderResponse?.tender.priceTableRequirePaymentDate}
          />
        </>
      )}
    </>
  )
})
