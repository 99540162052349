import React from 'react'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Input } from '@cotiss/common/components/input.component'
import { MultiSelect } from '@cotiss/common/components/multi-select.component'
import { NumberInput } from '@cotiss/common/components/number-input.component'
import { useDebouncedChangeHandler } from '@cotiss/common/hooks/use-debounced-change-handler.hook'
import { FilterValueType } from '@cotiss/common/models/filter.model'
import { datetimeService } from '@cotiss/common/services/datetime.service'

interface Props {
  valueType?: FilterValueType
  valueOptions?: { value: string; label: string }[]
  setValue: (value?: string | string[]) => void
  value: any
  isDisabled?: boolean
}

export const AdvancedFiltersValueField = ({ valueType, valueOptions, setValue, value, isDisabled }: Props) => {
  const { tempValue, handleDebouncedChange } = useDebouncedChangeHandler(value, setValue)

  if (valueType === 'array') {
    return (
      <MultiSelect
        renderInPortal
        values={value ?? []}
        options={valueOptions ?? []}
        onChange={(newValue) => setValue(newValue)}
        isDisabled={isDisabled}
        placeholder="Select value"
      />
    )
  }

  if (valueType === 'string') {
    return (
      <Input
        value={tempValue ?? ''}
        onChange={({ target }) => handleDebouncedChange(target.value)}
        placeholder="Type input"
        isDisabled={isDisabled}
      />
    )
  }

  if (valueType === 'date') {
    return (
      <DatetimeInput
        isTimeVisible={false}
        value={value ? datetimeService.parse(value) : null}
        placeholder="Select date"
        onChange={(newValue) => setValue(newValue?.toISOString())}
        isDisabled={isDisabled}
      />
    )
  }

  if (valueType === 'number') {
    return (
      <NumberInput
        value={tempValue ?? ''}
        onValueChange={({ value }) => handleDebouncedChange(value)}
        placeholder="Type input"
        isDisabled={isDisabled}
      />
    )
  }

  return <Input value="" placeholder="" isDisabled />
}
