import { gql } from '@apollo/client'
import { GqlEvaluationUserInSessionViewInput, GqlEvaluationUserInSessionViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryEvaluationUserInSessionView = async (input: GqlEvaluationUserInSessionViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserInSessionViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      query EvaluationUserInSessionView($input: EvaluationUserInSessionViewInput!) {
        evaluationUserInSessionView(input: $input) {
          ...EvaluationUserFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserInSessionView
}
