import { gql } from '@apollo/client'
import { GqlEvaluationUserListInput, GqlEvaluationUserListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ACCESS_CONTROL_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-access-control/evaluation-access-control-fragment.graphql'
import { EVALUATION_USER_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryEvaluationUserList = async (input: GqlEvaluationUserListInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_USER_FRAGMENT}
      ${EVALUATION_ACCESS_CONTROL_FRAGMENT}

      query EvaluationUserList($input: EvaluationUserListInput!) {
        evaluationUserList(input: $input) {
          items {
            ...EvaluationUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserList
}
