import React, { FormEvent, memo, useState } from 'react'
import classNames from 'classnames'
import { includes, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { ActionTenderUpdateParam, useMutateTenderUpdate } from '@cotiss/tender-update/resources/use-mutate-tender-update.resource'
import { TenderUpdateCommentModel } from '@cotiss/tender-update/tender-update.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { userService } from '@cotiss/user/user.service'

type Props = {
  className?: string
  tenderUpdateComment: TenderUpdateCommentModel
  tenderUpdateCommentParentId?: string
  procurementId?: string
}

export const TenderUpdateComment = memo(({ className, tenderUpdateComment, tenderUpdateCommentParentId, procurementId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const classes = classNames(className)
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isReplying, setIsReplying] = useState(false)
  const { procurement } = useGetProcurement(procurementId)
  const isLikedByUser = includes(tenderUpdateComment.likes, user?._id)
  const isUserComment = tenderUpdateComment.createdBy.account.organisation._id === user?.account?.organisation?._id
  const isProcurementOwnerComment = tenderUpdateComment.createdBy.account.organisation._id === procurement?.procuringEntity
  const isProcurementOwner = procurement?.procuringEntity === user?.account?.organisation?._id
  const { replyToTenderUpdateComment, actionTenderUpdateComment } = useMutateTenderUpdate()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await replyToTenderUpdateComment(tenderUpdateCommentParentId ? tenderUpdateCommentParentId : tenderUpdateComment._id, { content: comment })
      setComment('')
      setIsReplying(false)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleAction = async (action: ActionTenderUpdateParam) => {
    try {
      setIsSaving(true)
      await actionTenderUpdateComment(tenderUpdateComment._id, action)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <div className={classes}>
      <div className="border-l-2 border-gray-200 pl-4 ml-4">
        <Text size="sm" variant="secondary">
          {isProcurementOwner || isUserComment || isProcurementOwnerComment ? userService.getFullName(tenderUpdateComment.createdBy) : 'Anonymous'}
        </Text>
        <Text size="xs" variant="light">
          {datetimeService.format(tenderUpdateComment.createdAt, 'd MMMM yyyy h:mm aaa')}
        </Text>
        <Text className="mt-2 whitespace-pre-wrap">{tenderUpdateComment.content}</Text>
      </div>
      <div className="flex items-center pl-4 mt-2">
        <Button onClick={() => handleAction(isLikedByUser ? 'unlike' : 'like')} state="ghost" size="xs" isDisabled={isSaving}>
          <Icon icon="like" variant={isLikedByUser ? 'secondary' : 'dark'} />
          <Text className="ml-1" size="sm" variant={isLikedByUser ? 'secondary' : 'dark'}>
            {tenderUpdateComment.likes.length}
          </Text>
        </Button>
        <Button state="ghost" size="xs" isDisabled={isSaving}>
          <Icon icon="comment" variant="dark" />
          <Text className="ml-1" size="sm">
            {tenderUpdateComment.comments.length}
          </Text>
        </Button>
        <Button onClick={() => setIsReplying(true)} state="ghost" size="xs" isDisabled={isSaving}>
          <Icon icon="reply" variant="dark" />
          <Text className="ml-1" size="sm">
            Reply
          </Text>
        </Button>
      </div>
      {isReplying && (
        <Form className="pl-8 mt-2" onSubmit={handleSubmit}>
          <TextArea
            className="text-sm"
            value={comment}
            onChange={({ target }) => setComment(target.value)}
            placeholder="Reply to comment"
            isDisabled={isSaving}
            isRequired
          />
          <div className="flex items-center justify-end mt-2">
            <Button onClick={() => setIsReplying(false)} state="ghost" variant="link" size="xs" isDisabled={isSaving}>
              Close
            </Button>
            <Button variant="secondary" size="xs" type="submit" isLoading={isSaving}>
              Post
            </Button>
          </div>
        </Form>
      )}
      {Boolean(tenderUpdateComment.comments.length) && (
        <>
          {map(tenderUpdateComment.comments, (tenderUpdateCommentChild) => (
            <TenderUpdateComment
              key={tenderUpdateCommentChild._id}
              className="pl-4 mt-2"
              tenderUpdateComment={tenderUpdateCommentChild}
              tenderUpdateCommentParentId={tenderUpdateComment._id} // Don't allow replies more than 2 levels deep
              procurementId={procurement?._id}
            />
          ))}
        </>
      )}
    </div>
  )
})
