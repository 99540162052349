import React, { useRef } from 'react'
import classNames from 'classnames'
import { findIndex, map } from 'lodash'
import { StepItem } from '@cotiss/common/components/step-item.component'

export type StepModel<T> = {
  id: T
  label: string
}

type Props<T> = {
  className?: string
  step: StepModel<T>
  steps: StepModel<T>[]
  onChange?: (step: StepModel<T>) => void
}

export function Steps<T>({ className, step: activeStep, steps, onChange }: Props<T>) {
  const ref = useRef<HTMLDivElement>(null)
  const classes = classNames(className, 'relative flex items-stretch justify-stretch w-full')

  return (
    <div className={classes} ref={ref}>
      {map(steps, (step, index) => (
        <StepItem<T>
          key={index}
          step={step}
          onClick={onChange}
          hasSeparator={index !== steps.length - 1}
          isComplete={findIndex(steps, ({ id }) => id === activeStep.id) > findIndex(steps, ({ id }) => id === step.id)}
          isActive={step.id === activeStep.id}
        />
      ))}
    </div>
  )
}
