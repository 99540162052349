import React, { memo, useMemo } from 'react'
import { filter, find, forEach, map, orderBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { sortService } from '@cotiss/common/services/sort.service'
import { ContractDocumentItem, ContractDocumentTable } from '@cotiss/contract/components/contract-document-table.component'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

type ContractDocumentGroup = {
  name: string
  documentItems: ContractDocumentItem[]
  createdAt?: string
}

export const ContractViewContractTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => (contractShell ? contractService.getContract(contractShell) : null), [])

  const documentGroups: ContractDocumentGroup[] = useMemo(() => {
    const _documentGroups: ContractDocumentGroup[] = []
    forEach(contractService.getAllApprovedContracts(contractShell), (contract) => {
      forEach(filter(contract.documentShells, { type: 'CONTRACT' }), (documentShell) => {
        const documentShellName = documentShell.name
        let documentGroup: ContractDocumentGroup | undefined = find(_documentGroups, ({ name }) => name === documentShellName)

        // If a document group doesn't already exist, create it, and add it to the document groups array.
        if (!documentGroup) {
          documentGroup = {
            name: documentShellName,
            documentItems: [],
          }

          _documentGroups.push(documentGroup)
        }

        // TODO: not ideal to set values for hierarchy ad index here, need to fix this
        forEach(documentShell.attachments, ({ document, signedDocument, status }) => {
          documentGroup?.documentItems.push({
            contractId: contract._id,
            contractShellId,
            shellTitle: documentShellName,
            hierarchy: 'variation', // We will re-calculate this after we sort this list
            document,
            signedDocument,
            status,
            index: '', // We will re-calculate this after we sort this list
            createdAt: document.createdAt,
          })
        })

        // The date the group was created is the earliest createdAt date from the document items
        // We need to calculate this so we can order the groups from oldest to newest
        documentGroup.createdAt = documentGroup.documentItems
          .map(({ createdAt }) => createdAt)
          .sort((a, b) => {
            return sortService.sortDate(a, b)
          })[0]
      })
    })

    return map(orderBy(_documentGroups, 'createdAt'), (group, count) => {
      const groupCount = count
      return {
        ...group,
        documentItems: map(orderBy(group.documentItems, 'createdAt', 'asc'), (item, count) => {
          return { ...item, index: `${groupCount + 1}.${count}`, hierarchy: count === 0 ? 'master' : 'variation' }
        }),
      }
    })
  }, [contractShell])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Contract
            </Text>
          </div>
        </CardHeader>
        <Skeleton className="h-64" />
      </Card>
    )
  }

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5">
            Contract
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>
      {!documentGroups.length && <NoDataPlaceholder label="No contract added" />}
      {map(documentGroups, (documentGroup) => (
        <ContractDocumentTable className="mt-6" key={documentGroup.name} title={documentGroup.name} documentItems={documentGroup.documentItems} />
      ))}
    </Card>
  )
})
