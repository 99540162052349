import React, { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { TenderResponseReviewStatus } from '@cotiss/tender-response/tender-response.models'

const getVariant = (type: TenderResponseReviewStatus): BaseVariant => {
  switch (type) {
    case 'included':
      return 'success'
    case 'excluded':
      return 'danger'
    case 'pending':
      return 'warning'
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: TenderResponseReviewStatus
  size?: BadgeSize
}

export const TenderResponseReviewStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status))}
  </Badge>
))
