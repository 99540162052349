import { every, includes } from 'lodash'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useGetUserRoles } from '@cotiss/user/resources/use-get-user-roles.resource'
import { UserRole } from '@cotiss/user/user.models'

export type UserAccessPermissions = {
  isAdmin: boolean
  isBuyer: boolean
  isSupplier: boolean
  hasBidBase: boolean
  hasPortalBase: boolean
  hasContractBase: boolean
  hasProcurementBase: boolean
  hasApproverBase: boolean
  hasLegalBase: boolean
  hasEvaluationBase: boolean
  hasSupplierAccess: boolean
  hasServiceAccess: boolean
  hasLegalAccess: boolean
  hasContractAccess: boolean
  hasProcurementAccess: boolean
  hasBuyerAccess: boolean
  hasEvaluationAccess: boolean
  hasOldEvaluationAccess: boolean
  hasPlanningAccess: boolean
  hasPerformanceAccess: boolean
}

export const useUserAccess = () => {
  const { roles } = useGetUserRoles()
  const { user } = useGetLoggedInUser()
  const isPlanningEnabled = useFeature('planning')
  const isProcurementsEnabled = useFeature('procurements')
  const isOldEvaluationsEnabled = useFeature('old-evaluations')
  const isContractsEnabled = useFeature('contract-management-v1')
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')

  // Enabling MBIE feature for a single user to unblock high value customer.
  const isEvaluationEventEnabled = useFeature('evaluation-event') || user?._id === '66ecafee399dba5c98fad356'

  const hasUserRole = (role: UserRole) => {
    return includes(roles, role)
  }

  const hasUserRoles = (rolesToCheck: UserRole[]) => {
    return every(rolesToCheck, hasUserRole)
  }

  const isAdmin = hasUserRole('account:admin')
  const isBuyer = hasUserRole('buyer:paid')
  const isSupplier = hasUserRole('supplier:base')
  const hasApproverBase = hasUserRole('approver:base')
  const hasBidBase = hasUserRole('bid:base')
  const hasPortalBase = hasUserRole('portal:base')
  const hasContractBase = hasUserRole('contract:base')
  const hasProcurementBase = hasUserRole('procurement:base')
  const hasPerformanceBase = hasUserRole('performance:base')
  const hasLegalBase = hasUserRole('legal:base')
  const hasEvaluationBase = hasUserRole('evaluator:base')

  const permissions: UserAccessPermissions = {
    isAdmin,
    isBuyer,
    isSupplier,
    hasBidBase,
    hasPortalBase,
    hasContractBase,
    hasProcurementBase,
    hasApproverBase,
    hasLegalBase,
    hasEvaluationBase,
    hasSupplierAccess: hasBidBase && isSupplier,
    hasServiceAccess: hasPortalBase && isBuyer,
    hasLegalAccess: hasLegalBase && isBuyer,
    hasContractAccess: hasContractBase && isBuyer && isContractsEnabled,
    hasProcurementAccess: hasProcurementBase && isBuyer && isProcurementsEnabled,
    hasBuyerAccess: (hasContractBase || hasProcurementBase || hasLegalBase) && isBuyer,
    hasEvaluationAccess: (hasEvaluationBase || hasProcurementBase) && isEvaluationEventEnabled && isBuyer,
    hasOldEvaluationAccess: hasEvaluationBase && isOldEvaluationsEnabled && isBuyer,
    hasPlanningAccess: isPlanningEnabled && isBuyer,
    hasPerformanceAccess: (hasEvaluationBase || hasPerformanceBase) && isPerformanceScorecardEnabled && isBuyer,
  }

  return {
    roles,
    permissions,
    hasUserRole,
    hasUserRoles,
  }
}
