import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { ApprovalStatus } from '@cotiss/approval/approval.models'

type UpdateApprovalBody = {
  status: ApprovalStatus
  feedback?: string
}

export const useMutateApproval = () => {
  const { mutate } = useMutate()

  return {
    updateApproval: async (id: string, body: UpdateApprovalBody) => {
      return await mutate({ route: `/v2/approvals/${id}`, body, invalidate: ['/v2/approvals', '/v2/procurements'] })
    },
    resendApproval: async (id: string) => {
      return await mutate({ route: `/v2/approvals/${id}/resend` })
    },
  }
}
