import React, { memo, useState } from 'react'
import { map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import { Button } from '@cotiss/common/components/button.component'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { Text } from '@cotiss/common/components/text.component'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { CorrespondenceModel } from '@cotiss/correspondence/correspondence.model'
import { useMutateCorrespondence } from '@cotiss/correspondence/resources/use-mutate-correspondence.resource'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'

type Props = {
  contractShellId: string
  correspondence?: CorrespondenceModel
}

type FormData = {
  title: string
  date: Date | null
  description: RemirrorJSON
  documents: DocumentModel[]
}

// TODO: when we support correspondence on other models, move this to be a generic component
export const ContractAddEditCorrespondenceDrawer = memo(({ contractShellId, correspondence }: Props) => {
  const { closeNarrowDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createCorrespondence, updateCorrespondence } = useMutateCorrespondence()
  const [formData, setFormData] = useState<FormData>({
    title: correspondence?.title || '',
    date: correspondence?.date ? new Date(correspondence?.date) : new Date(),
    description: correspondence?.description || richTextService.getEmptyRemirrorJson(),
    documents: correspondence?.attachments || [],
  })

  const handleSubmit = async () => {
    if (!formData.title) {
      return
    }

    try {
      setIsSaving(true)
      if (correspondence) {
        await updateCorrespondence(correspondence._id, {
          ...formData,
          date: formData.date || undefined,
          attachments: map(formData.documents, ({ _id }) => _id),
        })
      }
      if (!correspondence) {
        await createCorrespondence({
          ...formData,
          date: formData.date || undefined,
          contractShellId,
          attachments: map(formData.documents, ({ _id }) => _id),
        })
      }
      closeNarrowDrawer()
    } catch (error: any) {
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      {correspondence ? 'Edit note' : 'Add note'}
    </Text>
  )

  const renderFooter = () => (
    <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
      Save
    </Button>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <Label>Title</Label>
      <Input
        value={formData.title}
        onChange={({ target }) => setFormData({ ...formData, title: target.value })}
        maxLength={100}
        placeholder="Add a title"
        isDisabled={isSaving}
        isRequired
      />
      <Label className="mt-6">Date and time</Label>
      <DatetimeInput
        value={formData.date}
        placeholder="Select date and time"
        onChange={(date) => setFormData({ ...formData, date })}
        isDisabled={isSaving}
        isRequired
      />
      <Label className="mt-6">Description</Label>
      <RichTextEditor value={formData.description} onChange={(description) => setFormData({ ...formData, description })} isDisabled={isSaving} />
      <Label className="mt-6">Attachments</Label>
      <DocumentUpload className="mt-2" documents={correspondence?.attachments} onChange={(documents) => setFormData({ ...formData, documents })} />
    </Drawer>
  )
})
