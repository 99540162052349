import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricCycleDocumentsInput,
  GqlCreatePerformanceScorecardMetricCycleDocumentsMutation,
  GqlCreatePerformanceScorecardMetricCycleDocumentsMutationVariables,
  GqlPerformanceScorecardMetricCycleDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/performance-scorecard-metric-cycle-document-fragment.graphql'

export const mutateCreatePerformanceScorecardMetricCycleDocuments = async (input: GqlCreatePerformanceScorecardMetricCycleDocumentsInput) => {
  const { data } = await apolloService.mutate<
    GqlCreatePerformanceScorecardMetricCycleDocumentsMutation,
    GqlCreatePerformanceScorecardMetricCycleDocumentsMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_DOCUMENT_FRAGMENT}

      mutation CreatePerformanceScorecardMetricCycleDocuments($input: CreatePerformanceScorecardMetricCycleDocumentsInput!) {
        createPerformanceScorecardMetricCycleDocuments(input: $input) {
          ...PerformanceScorecardMetricCycleDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetricCycleDocuments as GqlPerformanceScorecardMetricCycleDocumentFieldsFragment[]
}
