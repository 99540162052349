import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardUsersInput,
  GqlCreatePerformanceScorecardUsersMutation,
  GqlCreatePerformanceScorecardUsersMutationVariables,
  GqlPerformanceScorecardUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateCreatePerformanceScorecardUsers = async (input: GqlCreatePerformanceScorecardUsersInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardUsersMutation, GqlCreatePerformanceScorecardUsersMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      mutation CreatePerformanceScorecardUsers($input: CreatePerformanceScorecardUsersInput!) {
        createPerformanceScorecardUsers(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardUsers as GqlPerformanceScorecardUserFieldsFragment[]
}
