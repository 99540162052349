import React, { FormEvent, memo, useMemo, useState } from 'react'
import { filter, some } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetProcurementResponse } from '@cotiss/procurement-response/resources/use-get-procurement-response.resource'
import { useMutateProcurementResponseUser } from '@cotiss/procurement-response-user/resources/use-mutate-procurement-response-user.resource'
import { UserSelect } from '@cotiss/user/components/user-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'

type FormData = {
  userId: string
}

type Props = {
  procurementResponseId: string
}

export const ProcurementResponseUserAddModal = memo(({ procurementResponseId }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { closeModal } = useCallout()
  const [formData, setFormData] = useState<FormData>({ userId: '' })
  const { users, isLoading: isLoadingUsers } = useListUser({ permissions: ['ADMIN', 'BID_MANAGER'] })
  const { createProcurementResponseUser } = useMutateProcurementResponseUser()
  const { procurementResponse, isLoading: isProcurementResponseLoading } = useGetProcurementResponse(procurementResponseId)
  const isLoading = isLoadingUsers || isProcurementResponseLoading

  const userOptions = useMemo(
    () => filter(users, ({ _id }) => !some(procurementResponse?.procurementResponseUsers, ({ user }) => _id === user._id)),
    [procurementResponse, users]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!procurementResponse) {
      return
    }

    setIsSaving(true)
    try {
      await createProcurementResponseUser({ procurementResponseId: procurementResponse._id, userId: formData.userId })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add team member" />
      <ModalContent>
        <UserSelect
          value={formData.userId}
          options={userOptions}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving || isLoading}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
