import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  isCentered?: boolean
  isFullHeight?: boolean
}

export const Section = memo(({ children, className, isCentered, isFullHeight }: Props) => {
  const classes = classNames(className, 'relative px-8 w-full', { 'h-full': isFullHeight })
  const contentClasses = classNames('max-w-screen-2xl', {
    'mx-auto': isCentered,
    'h-full': isFullHeight,
  })

  return (
    <div className={classes}>
      <div className={contentClasses}>{children}</div>
    </div>
  )
})
