import { gql } from '@apollo/client'
import { GqlDeletePlanEventDocumentInput, GqlDeletePlanEventDocumentMutation, GqlDeletePlanEventDocumentMutationVariables } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeletePlanEventDocument = async (input: GqlDeletePlanEventDocumentInput) => {
  const { data } = await apolloService.mutate<GqlDeletePlanEventDocumentMutation, GqlDeletePlanEventDocumentMutationVariables>({
    mutation: gql`
      mutation DeletePlanEventDocument($input: DeletePlanEventDocumentInput!) {
        deletePlanEventDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePlanEventDocument as boolean
}
