import React, { memo, useEffect } from 'react'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { usePlanEventAnalytics } from '@cotiss/plan-event/hooks/use-plan-event-analytics.hook'

export const PlanEventViewDetailsTab = memo(() => {
  const { track } = usePlanEventAnalytics()

  useEffect(() => {
    track('plan_event_view_details_tab_view')
  }, [])

  return (
    <>
      <NoDataPlaceholder label="You have not published any plans yet. Once you do, it will display here." />
    </>
  )
})
