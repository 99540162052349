import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricUserListInput, GqlPerformanceScorecardMetricUserListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-user/performance-scorecard-metric-user-fragment.graphql'

export const queryPerformanceScorecardMetricUserList = async (input: GqlPerformanceScorecardMetricUserListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricUserListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_USER_FRAGMENT}

      query PerformanceScorecardMetricUserList($input: PerformanceScorecardMetricUserListInput!) {
        performanceScorecardMetricUserList(input: $input) {
          items {
            ...PerformanceScorecardMetricUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricUserList
}
