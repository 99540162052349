import { gql } from '@apollo/client'
import { GqlCreateEvaluationInput, GqlCreateEvaluationMutation, GqlCreateEvaluationMutationVariables, GqlEvaluationFieldsFragment } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation/evaluation-fragment.graphql'

export const mutateCreateEvaluation = async (input: GqlCreateEvaluationInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationMutation, GqlCreateEvaluationMutationVariables>({
    mutation: gql`
      ${EVALUATION_FRAGMENT}

      mutation CreateEvaluation($input: CreateEvaluationInput!) {
        createEvaluation(input: $input) {
          ...EvaluationFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluation as GqlEvaluationFieldsFragment
}
