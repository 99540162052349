import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderFlowEvaluateStepPanel } from '@cotiss/tender-flow/components/tender-flow-evaluate-step-panel.component'
import { TenderFlowEvaluateStepSelf } from '@cotiss/tender-flow/components/tender-flow-evaluate-step-self.component'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

export const TenderFlowEvaluateStep = memo(() => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  return tender?.evaluationPanelType === 'self' ? <TenderFlowEvaluateStepSelf /> : <TenderFlowEvaluateStepPanel />
})
