import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricCycleDocumentInput,
  GqlCreatePerformanceScorecardMetricCycleDocumentsInput,
  GqlDeletePerformanceScorecardMetricCycleDocumentInput,
  GqlPerformanceScorecardMetricCycleDocumentFieldsFragment,
  GqlPerformanceScorecardMetricCycleDocumentListInput,
} from '@gql'
import { filter, uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecardMetricCycleDocument } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/mutate-create-performance-scorecard-metric-cycle-document.graphql'
import { mutateCreatePerformanceScorecardMetricCycleDocuments } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/mutate-create-performance-scorecard-metric-cycle-documents.graphql'
import { mutateDeletePerformanceScorecardMetricCycleDocument } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/mutate-delete-performance-scorecard-metric-cycle-document.graphql'
import { queryPerformanceScorecardMetricCycleDocumentList } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-document/performance-scorecard-metric-cycle-document-list.graphql'

const performanceScorecardMetricCycleDocumentVar = makeVar<GqlPerformanceScorecardMetricCycleDocumentFieldsFragment | null>(null)
const performanceScorecardMetricCycleDocumentsVar = makeVar<GqlPerformanceScorecardMetricCycleDocumentFieldsFragment[]>([])

export const usePerformanceScorecardMetricCycleDocument = () => {
  const performanceScorecardMetricCycleDocument = useReactiveVar(performanceScorecardMetricCycleDocumentVar)
  const performanceScorecardMetricCycleDocuments = useReactiveVar(performanceScorecardMetricCycleDocumentsVar)

  return {
    performanceScorecardMetricCycleDocument,
    performanceScorecardMetricCycleDocuments,
    setPerformanceScorecardMetricCycleDocument: performanceScorecardMetricCycleDocumentVar,
    setPerformanceScorecardMetricCycleDocuments: performanceScorecardMetricCycleDocumentsVar,
    queryPerformanceScorecardMetricCycleDocumentList: async (input: GqlPerformanceScorecardMetricCycleDocumentListInput) => {
      const { items: performanceScorecardMetricCycleDocuments, pagination } = await queryPerformanceScorecardMetricCycleDocumentList(input)

      performanceScorecardMetricCycleDocumentsVar(performanceScorecardMetricCycleDocuments)

      return { performanceScorecardMetricCycleDocuments, pagination }
    },
    mutateCreatePerformanceScorecardMetricCycleDocument: async (input: GqlCreatePerformanceScorecardMetricCycleDocumentInput) => {
      const createdPerformanceScorecardMetricCycleDocument = await mutateCreatePerformanceScorecardMetricCycleDocument(input)

      performanceScorecardMetricCycleDocumentsVar(
        uniqBy([createdPerformanceScorecardMetricCycleDocument, ...performanceScorecardMetricCycleDocuments], 'id')
      )

      return createdPerformanceScorecardMetricCycleDocument
    },
    mutateCreatePerformanceScorecardMetricCycleDocuments: async (input: GqlCreatePerformanceScorecardMetricCycleDocumentsInput) => {
      const createdPerformanceScorecardMetricCycleDocuments = await mutateCreatePerformanceScorecardMetricCycleDocuments(input)

      performanceScorecardMetricCycleDocumentsVar(
        uniqBy([...createdPerformanceScorecardMetricCycleDocuments, ...performanceScorecardMetricCycleDocuments], 'id')
      )

      return createdPerformanceScorecardMetricCycleDocuments
    },
    mutateDeletePerformanceScorecardMetricCycleDocument: async (input: GqlDeletePerformanceScorecardMetricCycleDocumentInput) => {
      await mutateDeletePerformanceScorecardMetricCycleDocument(input)

      performanceScorecardMetricCycleDocumentsVar(
        filter(performanceScorecardMetricCycleDocuments, ({ id }) => id !== input.performanceScorecardMetricCycleDocumentId)
      )
    },
  }
}

export const clearReactivePerformanceScorecardMetricCycleDocument = async () => {
  performanceScorecardMetricCycleDocumentVar(null)
  performanceScorecardMetricCycleDocumentsVar([])
}
