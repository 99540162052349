import React from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { AdvancedFiltersDropdownContent } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-content.component'
import { AdvancedFiltersDropdown } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown.component'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { AdvancedFiltersDropdownTrigger } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-trigger.component'

type Props = {
  title: string
  onOpen: () => void
}

export const AdvancedFiltersDropdownButton = ({ title, onOpen }: Props) => {
  const { validAdvancedFilters, isInitialized } = useAdvancedFiltersContext()

  return (
    <AdvancedFiltersDropdown onOpen={onOpen}>
      <AdvancedFiltersDropdownTrigger>
        <Button size="xs" variant="secondary" state="translucent" className="gap-1" isDisabled={!isInitialized}>
          <Icon icon="filter-funnel-01" />
          <span>Filters ({validAdvancedFilters?.length})</span>
        </Button>
      </AdvancedFiltersDropdownTrigger>
      <AdvancedFiltersDropdownContent title={title} />
    </AdvancedFiltersDropdown>
  )
}
