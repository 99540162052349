import React, { useState, memo, ReactNode } from 'react'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { envService } from '@cotiss/common/services/env.service'
import { featureService } from '@cotiss/common/services/feature.service'
import { hubSpotService } from '@cotiss/common/services/hub-spot.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'

type Props = {
  children: ReactNode
}

export const AppProvider = memo(({ children }: Props) => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useAsyncEffect(async () => {
    try {
      if (envService.isProduction()) {
        hubSpotService.init()
      }

      await Promise.all([sentryService.init(), featureService.init()])
      setIsLoading(false)
    } catch (error: any) {
      setIsError(true)
      setIsLoading(false)
      sentryService.captureException({ exception: error })
    }
  }, [])

  // TODO: Show a Cotiss loading page while initialising?
  if (isLoading) {
    return null
  }

  if (isError) {
    return <AppErrorPage />
  }

  return <>{children}</>
})
