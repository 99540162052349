import { gql } from '@apollo/client'
import {
  GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput,
  GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdMutation,
  GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId = async (
  input: GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput
) => {
  const { data } = await apolloService.mutate<
    GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdMutation,
    GqlDeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdMutationVariables
  >({
    mutation: gql`
      mutation DeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId(
        $input: DeleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionIdInput!
      ) {
        deleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationEnvelopeSubmissionByEnvelopeIdAndSubmissionId as boolean
}
