import React, { memo } from 'react'
import { find } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Td_DEPRECATED } from '@cotiss/common/components/deprecated/td.component'
import { Tr_DEPRECATED } from '@cotiss/common/components/deprecated/tr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { ShortListStatusBadge } from '@cotiss/short-list/components/short-list-status-badge.component'
import { ShortListPopulatedModel } from '@cotiss/short-list/short-list.models'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  shortList: ShortListPopulatedModel
  tenderResponseReports: TenderResponseReportModel[]
  onView?: (shortList: ShortListPopulatedModel) => void
  isEditable?: boolean
  isScoreVisible?: boolean
}

export const ShortListDueDiligenceListItem = memo(({ shortList, tenderResponseReports, onView, isEditable, isScoreVisible }: Props) => (
  <Tr_DEPRECATED variant="white">
    {isScoreVisible && (
      <Td_DEPRECATED>
        <Text>{find(tenderResponseReports, { _id: shortList.tenderResponse._id })?.totalScore?.toFixed(2) || '--'}</Text>
      </Td_DEPRECATED>
    )}
    <Td_DEPRECATED>
      <Text className="truncate">{shortList.tenderResponse.procurementResponse.supplier.name}</Text>
    </Td_DEPRECATED>
    <Td_DEPRECATED>
      <ShortListStatusBadge status={shortList.status} />
    </Td_DEPRECATED>
    <Td_DEPRECATED className="text-right">
      {onView && (
        <Button onClick={() => onView(shortList)} state="translucent" variant="secondary" size="sm">
          {isEditable ? 'Conduct due diligence' : 'View'}
          <Icon className="ml-1" icon="arrow-right" size={20} />
        </Button>
      )}
    </Td_DEPRECATED>
  </Tr_DEPRECATED>
))
