import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricInput,
  GqlCreatePerformanceScorecardMetricMutation,
  GqlCreatePerformanceScorecardMetricMutationVariables,
  GqlPerformanceScorecardMetricFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-metric/performance-metric-fragment.graphql'
import { PERFORMANCE_SCORECARD_METRIC_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric/performance-scorecard-metric-fragment.graphql'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle/performance-scorecard-metric-cycle-fragment.graphql'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateCreatePerformanceScorecardMetric = async (input: GqlCreatePerformanceScorecardMetricInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardMetricMutation, GqlCreatePerformanceScorecardMetricMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${PERFORMANCE_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      mutation CreatePerformanceScorecardMetric($input: CreatePerformanceScorecardMetricInput!) {
        createPerformanceScorecardMetric(input: $input) {
          ...PerformanceScorecardMetricFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardMetric as GqlPerformanceScorecardMetricFieldsFragment
}
