import React, { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useMutateUser } from '@cotiss/user/resources/use-mutate-user.resource'
import { userService } from '@cotiss/user/user.service'

export const SettingsGeneralFormName = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { updateUserMe } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState({ firstname: user?.firstname || '', surname: user?.surname || '' })

  const handleReset = () => {
    setFormData({ firstname: user?.firstname || '', surname: user?.surname || '' })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateUserMe(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>First name</Label>
        <Input
          className="mb-4"
          value={formData.firstname}
          onChange={({ target }) => setFormData({ ...formData, firstname: target.value })}
          placeholder="Enter first name"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />
        <Label>Last name</Label>
        <Input
          className="mb-4"
          value={formData.surname}
          onChange={({ target }) => setFormData({ ...formData, surname: target.value })}
          placeholder="Enter last name"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Name</Text>
        <Text>{userService.getFullName(user) || '–'}</Text>
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
        Update
      </Button>
    </div>
  )
})
