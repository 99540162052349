import React, { memo } from 'react'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useGetConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-get-conflict-of-interest.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  conflictOfInterestId?: string
}

export const ConflictOfInterestTermsBackground = memo(({ conflictOfInterestId }: Props) => {
  const { conflictOfInterest, isLoading: isLoadingConflictOfInterest } = useGetConflictOfInterest(conflictOfInterestId)
  const { user, isLoading: isLoadingUser } = useGetLoggedInUser()

  const isLoading = (isLoadingConflictOfInterest && conflictOfInterestId) || isLoadingUser

  return (
    <>
      {isLoading && (
        <>
          <Skeleton className="h-3 w-full mt-1" />
          <Skeleton className="h-3 w-full mt-1" />
          <Skeleton className="h-3 w-2/3 mt-1" />
        </>
      )}
      {!isLoading && user && (
        <Text>
          As a result of the Recipient’s role in the process for the selection of{' '}
          <i>
            {conflictOfInterest
              ? `${conflictOfInterest.procurement.title} (${conflictOfInterest.procurement.internalIdentifier})`
              : '<insert tender number>'}
          </i>{' '}
          and on behalf of <i>{user.account.organisation?.name}</i> (the “Organisation”) you are required to make a number of disclosures,
          acknowledgements, covenants and warranties relating to confidential information and any actual, perceived or potential conflicts of interest
        </Text>
      )}
    </>
  )
})
