import React, { memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
}

export const Hr = memo(({ className }: Props) => {
  const classes = classNames(className, 'bg-gray-200 h-px w-full')

  return <div className={classes} />
})
