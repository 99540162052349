import { IconType } from '@cotiss/common/components/icon.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

export const CONFLICT_OF_INTEREST_QUERY_KEYS = ['/v2/conflict-of-interest'] as const

export const CONFLICT_OF_INTEREST_ROUTES = [
  '/conflict-of-interest',
  '/conflict-of-interest/view/:conflictOfInterestId',
  '/conflict-of-interest/list',
  '/conflict-of-interest/me/list',
  '/conflict-of-interest/me/view/:conflictOfInterestId',
] as const

export type ConflictOfInterestOutcome = 'included' | 'excluded' | 'managed' | 'pending' | '--'
export type ConflictOfInterestStatus = 'conflict' | 'no-conflict' | '--'

export const CONFLICT_OF_INTEREST_BANNER_HEADER_MAP: Record<ConflictOfInterestOutcome, string> = {
  '--': 'Conflict is incomplete',
  excluded: 'Excluded due to conflict',
  included: 'No conflict of interest',
  managed: 'Conflict managed',
  pending: 'Conflict is pending review',
}

export const CONFLICT_OF_INTEREST_BANNER_SUBTEXT_MAP: Record<ConflictOfInterestOutcome, string> = {
  '--': 'The conflict of interest declaration is yet to be completed.',
  excluded: 'Due to the nature of the conflict this person should not have access to this procurement.',
  included: 'No conflict of interest has been declared.',
  managed: 'Due to the nature of the conflict this user can be included without risking the integrity of the procurement.',
  pending: 'A conflict of interest has been declared but is yet to be reviewed.',
}

export const CONFLICT_OF_INTEREST_BANNER_ICON_MAP: Record<ConflictOfInterestOutcome, IconType> = {
  '--': 'alert-circle-deprecated',
  excluded: 'alert-circle-deprecated',
  included: 'check-circle',
  managed: 'star-06',
  pending: 'alert-circle-deprecated',
}

export const CONFLICT_OF_INTEREST_BANNER_VARIANT_MAP: Record<ConflictOfInterestOutcome, BaseVariant> = {
  '--': 'primary',
  pending: 'danger',
  excluded: 'danger',
  included: 'success',
  managed: 'primary',
}
