import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEnvelopeSubmissionInput,
  GqlCreateEvaluationEnvelopeSubmissionMutation,
  GqlCreateEvaluationEnvelopeSubmissionMutationVariables,
  GqlEvaluationEnvelopeSubmissionFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_ENVELOPE_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-envelope-submission/evaluation-envelope-submission-fragment.graphql'

export const mutateCreateEvaluationEnvelopeSubmission = async (input: GqlCreateEvaluationEnvelopeSubmissionInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEnvelopeSubmissionMutation, GqlCreateEvaluationEnvelopeSubmissionMutationVariables>({
    mutation: gql`
      ${EVALUATION_ENVELOPE_SUBMISSION_FRAGMENT}

      mutation CreateEvaluationEnvelopeSubmission($input: CreateEvaluationEnvelopeSubmissionInput!) {
        createEvaluationEnvelopeSubmission(input: $input) {
          ...EvaluationEnvelopeSubmissionFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEnvelopeSubmission as GqlEvaluationEnvelopeSubmissionFieldsFragment
}
