import { gql } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardUserInput,
  GqlCreatePerformanceScorecardUserMutation,
  GqlCreatePerformanceScorecardUserMutationVariables,
  GqlPerformanceScorecardUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateCreatePerformanceScorecardUser = async (input: GqlCreatePerformanceScorecardUserInput) => {
  const { data } = await apolloService.mutate<GqlCreatePerformanceScorecardUserMutation, GqlCreatePerformanceScorecardUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      mutation CreatePerformanceScorecardUser($input: CreatePerformanceScorecardUserInput!) {
        createPerformanceScorecardUser(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPerformanceScorecardUser as GqlPerformanceScorecardUserFieldsFragment
}
