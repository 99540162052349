import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { UserModel } from '@cotiss/user/user.models'

type Props = {
  organisationId?: string
}

export const useListOrganisationUser = ({ organisationId }: Props = {}) => {
  const { data: organisationUsers = [], ...rest } = useFetch<UserModel[]>('/v2/organisations', {
    route: `/v2/organisations/${organisationId}/users`,
    isEnabled: Boolean(organisationId),
  })

  return { organisationUsers, ...rest }
}
