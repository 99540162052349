import React, { FormEvent, memo, useState } from 'react'
import { find, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Label } from '@cotiss/common/components/label.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TenderEvaluationMethodologyFormWeighedAttribute } from '@cotiss/tender/components/tender-evaluation-methodology-form-weighted-attribute.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { EVALUATION_METHODOLOGY_OPTIONS } from '@cotiss/tender/tender.constants'
import { TenderEvaluationMethodology, TenderRatingScale } from '@cotiss/tender/tender.models'
import { tenderService } from '@cotiss/tender/tender.service'
import { useListTenderCriteria } from '@cotiss/tender-criteria/resources/use-list-tender-criteria.resource'
import { useMutateTenderCriteria } from '@cotiss/tender-criteria/resources/use-mutate-tender-criteria.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

export type TenderPlanMethodologyFormData = {
  evaluationMethodology?: TenderEvaluationMethodology
  ratingScale?: TenderRatingScale
  envelopeWeight: string
  priceEnvelopeWeight: string
  criteriaWeightingsVisible?: boolean

  isTwoEnvelope?: boolean
  isPriceWeighted?: boolean
  criteria: {
    _id?: string
    name: string
    weight: string
  }[]
}

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderPlanMethodologyTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updateTenderCriteriaBulk } = useMutateTenderCriteria()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { tenderCriteria, isLoading: isTenderCriteriaLoading } = useListTenderCriteria({ tenderId: tender?._id })
  const isLoading = isTenderLoading || isTenderCriteriaLoading
  const [formData, setFormData] = useState<TenderPlanMethodologyFormData>({
    evaluationMethodology: tender?.evaluationMethodology || 'weightedAttributePriceWeighted',
    ratingScale: tender?.ratingScale,
    envelopeWeight: tender?.envelope.weight?.toString() || '',
    priceEnvelopeWeight: tender?.priceEnvelope.weight?.toString() || '',
    criteriaWeightingsVisible: tender?.criteriaWeightingsVisible,

    isTwoEnvelope: tender?.isTwoEnvelope,
    isPriceWeighted: tender?.isPriceWeighted,
    criteria: [],
  })
  const selectedTenderEvaluationMethodology = find(EVALUATION_METHODOLOGY_OPTIONS, { value: tender?.evaluationMethodology })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const {
        criteria,
        evaluationMethodology,
        ratingScale,
        envelopeWeight,
        priceEnvelopeWeight,
        criteriaWeightingsVisible,

        isTwoEnvelope,
        isPriceWeighted,
      } = formData

      const tenderItemsToUpdate = map(criteria, ({ _id, name, weight }, index) => {
        if (_id) {
          return { tenderCriteriaId: _id, body: { name, weight: Number(weight), index } }
        } else {
          return { body: { name, weight: Number(weight), index } }
        }
      })

      if (tenderService.hasEvaluation(tender)) {
        await updateTenderCriteriaBulk({ tenderId, items: tenderItemsToUpdate })
        await updateTender(tenderId, {
          evaluationMethodology,
          ratingScale,
          envelope: Number(envelopeWeight) ? { weight: Number(envelopeWeight) } : undefined,
          priceEnvelope: Number(priceEnvelopeWeight) ? { weight: Number(priceEnvelopeWeight) } : undefined,

          isTwoEnvelope,
          isPriceWeighted,
          criteriaWeightingsVisible,
        })
      }

      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    const criteria = map(tenderCriteria, ({ _id, name, weight }) => ({ _id, name, weight: weight?.toString() || '' }))

    setFormData({
      evaluationMethodology: tender?.evaluationMethodology || 'weightedAttributePriceWeighted',
      ratingScale: tender?.ratingScale,
      envelopeWeight: tender?.envelope.weight?.toString() || '',
      priceEnvelopeWeight: tender?.priceEnvelope.weight?.toString() || '',
      criteriaWeightingsVisible: tender?.criteriaWeightingsVisible,

      isTwoEnvelope: tender?.isTwoEnvelope,
      isPriceWeighted: tender?.isPriceWeighted,
      criteria: criteria.length ? criteria : [{ name: '', weight: '' }],
    })

    setIsEditing(isEditing)
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          {tenderService.hasEvaluation(tender) && (
            <>
              <div className="flex items-start justify-between">
                <div className="shrink-0 w-1/3 mr-8">
                  <Label>Evaluation methodology</Label>
                  <Text className="mt-1" variant="light" size="sm">
                    Choose a methodology that best fits with this specific procurement or your organisational policies.
                  </Text>
                </div>
                <div className="grid grid-cols-2 grid-rows-1 gap-4 w-2/3">
                  {map(EVALUATION_METHODOLOGY_OPTIONS, ({ value, name, description, isDisabled }) => (
                    <RadioCard
                      key={value}
                      className="min-h-[80px] border border-gray-100 p-3.5"
                      value={value}
                      name="evaluation-methodology"
                      onChange={() => setFormData({ ...formData, evaluationMethodology: value })}
                      isDisabled={isDisabled}
                      isSelected={formData.evaluationMethodology === value}
                      isRequired
                    >
                      <div className="flex justify-between">
                        <Text className="font-medium">{name}</Text>
                        <Radio className="ml-2" isChecked={formData.evaluationMethodology === value} />
                      </div>
                      <Text className="mt-1" size="sm" variant="light">
                        {description}
                      </Text>
                    </RadioCard>
                  ))}
                </div>
              </div>
              {formData.evaluationMethodology && (
                <>
                  <Hr className="my-8" />
                  <TenderEvaluationMethodologyFormWeighedAttribute
                    tenderId={tenderId}
                    formData={formData}
                    setFormData={setFormData}
                    setIsEditing={handleToggleEdit}
                    isEditable={isEditable}
                    isEditing={isEditing}
                    isDisabled={isSaving}
                  />
                </>
              )}
            </>
          )}
        </>
      )
    }

    return (
      <>
        {tenderService.hasEvaluation(tender) && (
          <>
            <Field
              label="Evaluation methodology"
              supplementary="Choose a methodology that best fits with this specific procurement or your organisational policies."
            >
              {!selectedTenderEvaluationMethodology && isEditable && renderToggleLink('+ Add methodology')}
              {selectedTenderEvaluationMethodology && (
                <RadioCard className="min-h-[80px] border border-gray-100 w-1/2 p-3.5" isReadOnly isSelected>
                  <div className="flex justify-between">
                    <Text className="font-medium">{selectedTenderEvaluationMethodology.name}</Text>
                    <Radio className="ml-2" isChecked />
                  </div>
                  <Text className="mt-1" size="sm" variant="light">
                    {selectedTenderEvaluationMethodology.description}
                  </Text>
                </RadioCard>
              )}
            </Field>
            {formData.evaluationMethodology && (
              <>
                <Hr className="my-8" />
                <TenderEvaluationMethodologyFormWeighedAttribute
                  formData={formData}
                  tenderId={tenderId}
                  setFormData={setFormData}
                  setIsEditing={handleToggleEdit}
                  isEditable={isEditable}
                  isEditing={isEditing}
                  isDisabled={isSaving}
                />
              </>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader
          heading="Methodology"
          onToggleEdit={handleToggleEdit}
          isEditable={isEditable}
          isEditing={isEditing}
          isSaving={isSaving}
          isLoading={isLoading}
        />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
