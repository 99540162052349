import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { SignUpSupplierFormData } from '@cotiss/auth/pages/sign-up-supplier.page'
import {
  ORGANISATION_CANADA_SUBDIVISION_OPTIONS,
  ORGANISATION_COUNTRY_OPTIONS,
  ORGANISATION_US_SUBDIVISION_OPTIONS,
} from '@cotiss/organisation/organisation.constants'
import { organisationService } from '@cotiss/organisation/organisation.service'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
  willProceedWithoutValidation?: boolean
}

export const SignUpSupplierStepCompanyDetailsExtended = memo(
  ({ formData, transition, setFormData, isDisabled, willProceedWithoutValidation }: Props) => {
    const processedCountryCode =
      formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

    return (
      <TransitionContainer transition={transition}>
        <Label>Country</Label>
        <Select_DEPRECATED
          value={formData.countryCode}
          options={ORGANISATION_COUNTRY_OPTIONS}
          onChange={(countryCode) => setFormData({ ...formData, countryCode, countryCodeSubdivision: undefined })}
          placeholder
          isRequired
        />
        {(formData.countryCode === 'CA' || formData.countryCode === 'US') && (
          <>
            <Label className="mt-6">Region</Label>
            <Select_DEPRECATED
              value={formData.countryCodeSubdivision}
              options={formData.countryCode === 'CA' ? ORGANISATION_CANADA_SUBDIVISION_OPTIONS : ORGANISATION_US_SUBDIVISION_OPTIONS}
              onChange={(countryCodeSubdivision) => setFormData({ ...formData, countryCodeSubdivision })}
              placeholder
              isRequired
            />
          </>
        )}
        {processedCountryCode && (
          <>
            <Label className="mt-6">
              {organisationService.getRegistryNumberType(processedCountryCode)}
              {formData.entityType === 'Sole trader' ? ' (optional)' : ''}
            </Label>
            <Input
              value={formData.businessNumber}
              onChange={({ target }) => setFormData({ ...formData, businessNumber: target.value })}
              name={organisationService.getRegistryNumberType(processedCountryCode)}
              placeholder={`Enter ${organisationService.getRegistryNumberType(processedCountryCode)}`}
              maxLength={100}
              isDisabled={isDisabled}
              isRequired={formData.entityType !== 'Sole trader'}
            />
          </>
        )}
        <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
          {willProceedWithoutValidation ? 'My details are correct, proceed' : 'Next'}
        </Button>
      </TransitionContainer>
    )
  }
)
