import React, { memo, useState } from 'react'
import { GqlPerformanceScorecardUserFieldsFragment, GqlPerformanceScorecardUserRole } from '@gql'
import { AnimatePresence } from 'framer-motion'
import { uniq } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { usePerformanceScorecardMetricUser } from '@cotiss/performance/hooks/use-performance-scorecard-metric-user.hook'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { PerformanceScorecardEvaluatorAddMetricStep } from '@cotiss/performance/steps/performance-scorecard-evaluator-add-metric.step'
import { PerformanceScorecardEvaluatorAddUserStep } from '@cotiss/performance/steps/performance-scorecard-evaluator-add-user.step'
import { UserModel } from '@cotiss/user/user.models'

export type PerformanceScorecardEvaluatorAddFormData = {
  evaluatorUser: UserModel | null
  performanceScorecardMetricIds: string[]
}

type Props = {
  performanceScorecardId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardEvaluatorAddDrawer = memo(({ performanceScorecardId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { step, transition, onTransition } = useTransition()
  const [hasScorecardMetrics, setHasScorecardMetrics] = useState(false)
  const { queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()
  const { mutateCreatePerformanceScorecardMetricUsers } = usePerformanceScorecardMetricUser()
  const { queryPerformanceScorecardUserList, mutateCreatePerformanceScorecardUser, mutateUpdatePerformanceScorecardUser } =
    usePerformanceScorecardUser()
  const [formData, setFormData] = useState<PerformanceScorecardEvaluatorAddFormData>({
    evaluatorUser: null,
    performanceScorecardMetricIds: [],
  })

  useAsyncEffect(async () => {
    try {
      const { items } = await queryPerformanceScorecardMetricList({ filter: { performanceScorecardId } })
      setHasScorecardMetrics(Boolean(items.length))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }

    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    if (!formData.evaluatorUser) {
      return
    }

    if (step === 1 && hasScorecardMetrics) {
      return onTransition({ step: 2 })
    }

    setIsSaving(true)

    try {
      const { items } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, userId: formData.evaluatorUser._id },
      })

      let performanceScorecardUser: GqlPerformanceScorecardUserFieldsFragment | null = null
      if (items[0] && items[0].userId === formData.evaluatorUser._id) {
        performanceScorecardUser = await mutateUpdatePerformanceScorecardUser({
          performanceScorecardUserId: items[0].id,
          // Merge the users current roles with `evaluator`
          roles: uniq([...(items[0]?.roles || []), 'evaluator']) as GqlPerformanceScorecardUserRole[],
        })
      } else {
        performanceScorecardUser = await mutateCreatePerformanceScorecardUser({
          performanceScorecardId,
          userId: formData.evaluatorUser._id,
          roles: ['evaluator'],
        })
      }

      if (performanceScorecardUser && formData.performanceScorecardMetricIds.length) {
        await mutateCreatePerformanceScorecardMetricUsers({
          performanceScorecardId,
          performanceScorecardUserIds: [performanceScorecardUser.id],
          performanceScorecardMetricIds: formData.performanceScorecardMetricIds,
        })
      }
      await onSubmit()
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Add evaluator
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence initial={false} mode="wait">
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <div className="flex items-center">
            {/* If there are scorecard metrics already setup for this scorecard, then we can add an additional optional step where the user can assign
                those scorecard to the selected user in a single flow. However if not, then we will just save the user with no assigned metrics. */}
            <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={isLoading || !formData.evaluatorUser}>
              {hasScorecardMetrics ? (
                <>
                  Continue <Icon className="ml-1" icon="arrow-right" />
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Text className="ml-2">{formData.evaluatorUser ? '1' : '0'} selected</Text>
          </div>
        )}
        {step === 2 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!formData.performanceScorecardMetricIds.length}>
              Save
            </Button>
            <Text className="ml-2">{formData.performanceScorecardMetricIds.length} selected</Text>
          </div>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <PerformanceScorecardEvaluatorAddUserStep performanceScorecardId={performanceScorecardId} formData={formData} setFormData={setFormData} />
          )}
          {step === 2 && (
            <PerformanceScorecardEvaluatorAddMetricStep
              performanceScorecardId={performanceScorecardId}
              formData={formData}
              setFormData={setFormData}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
              isDisabled={isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
