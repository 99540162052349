import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { EvaluationEventAnalyticsEvent } from '@cotiss/evaluation-event/evaluation-event.events'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

export const useEvaluationEventAnalytics = () => {
  const { track } = useAnalytics()
  const { evaluationUserInSession } = useEvaluationUser()

  return {
    track: async (event: EvaluationEventAnalyticsEvent, additionalProperties: Record<string, any> = {}) => {
      const properties = { ...additionalProperties }

      if (evaluationUserInSession) {
        properties.evaluationUserId = evaluationUserInSession.id
        properties.evaluationEventRole = evaluationUserInSession.role
      }

      return await track(event, properties)
    },
  }
}
