import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { DrawerContainer } from '@cotiss/common/containers/callout/drawer/drawer-container.component'
import { NarrowDrawerContainer } from '@cotiss/common/containers/callout/drawer/narrow-drawer-container.component'
import { FullModalContainer } from '@cotiss/common/containers/callout/modal/full-modal-container.component'
import { ModalContainer } from '@cotiss/common/containers/callout/modal/modal-container.component'

export type DrawerConfig = {
  willCloseOnClickOutside?: boolean
}

export type CalloutContextType = {
  modal: ReactNode
  openModal: (modal: ReactNode) => void
  closeModal: () => void

  fullModal: ReactNode
  openFullModal: (fullModal: ReactNode) => void
  closeFullModal: () => void

  drawer: ReactNode
  drawerConfig?: DrawerConfig
  openDrawer: (drawer: ReactNode, drawerConfig?: DrawerConfig) => void
  closeDrawer: () => void

  narrowDrawer: ReactNode
  narrowDrawerConfig?: DrawerConfig
  openNarrowDrawer: (narrowDrawer: ReactNode, narrowDrawerConfig?: DrawerConfig) => void
  closeNarrowDrawer: () => void
}

const CalloutContext = createContext<CalloutContextType | null>(null)

type Props = {
  children: ReactNode
}

export const CalloutProvider = ({ children }: Props) => {
  const [modal, setModal] = useState<ReactNode>(null)
  const [drawer, setDrawer] = useState<ReactNode>(null)
  const [fullModal, setFullModal] = useState<ReactNode>(null)
  const [drawerConfig, setDrawerConfig] = useState<DrawerConfig>()
  const [narrowDrawer, setNarrowDrawer] = useState<ReactNode>(null)
  const [narrowDrawerConfig, setNarrowDrawerConfig] = useState<DrawerConfig>()

  const value = useMemo(
    () => ({
      modal,
      openModal: setModal,
      closeModal: () => setModal(null),

      fullModal,
      openFullModal: setFullModal,
      closeFullModal: () => setFullModal(null),

      drawer,
      drawerConfig,
      openDrawer: (drawer: ReactNode, drawerConfig?: DrawerConfig) => {
        setDrawer(drawer)
        setDrawerConfig(drawerConfig)
      },
      closeDrawer: () => setDrawer(null),

      narrowDrawer,
      narrowDrawerConfig,
      openNarrowDrawer: (narrowDrawer: ReactNode, narrowDrawerConfig?: DrawerConfig) => {
        setNarrowDrawer(narrowDrawer)
        setNarrowDrawerConfig(narrowDrawerConfig)
      },
      closeNarrowDrawer: () => setNarrowDrawer(null),
    }),
    [drawer, drawerConfig, narrowDrawer, narrowDrawerConfig, modal, fullModal]
  )

  return (
    <CalloutContext.Provider value={value}>
      <FullModalContainer />
      <ModalContainer />
      <NarrowDrawerContainer />
      <DrawerContainer />
      {children}
    </CalloutContext.Provider>
  )
}

export const useCallout = () => {
  const context = useContext(CalloutContext)

  if (!context) {
    throw new Error('This component must be used within a <CalloutProvider> component.')
  }

  return context
}
