import React, { memo } from 'react'
import { Banner } from '@cotiss/common/components/banner.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { SupplierViewGeneralFormAbout } from '@cotiss/supplier/components/supplier-view-general-form-about.component'
import { SupplierViewGeneralFormRegions } from '@cotiss/supplier/components/supplier-view-general-form-regions.component'
import { SupplierViewGeneralFormUnspscCategories } from '@cotiss/supplier/components/supplier-view-general-form-unspsc-categories.component'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewGeneralTab = memo(({ organisationId, isEditable }: Props) => (
  <>
    {!isEditable && (
      <Banner className="mb-4" icon="info-circle" iconVariant="secondary" variant="neutral">
        <div>
          <Text className="font-semibold" variant="heading">
            This tab is for the contact to complete
          </Text>
          <Text size="sm">Contact profiles will be updated by the organisation</Text>
        </div>
      </Banner>
    )}
    <Card>
      <SupplierViewGeneralFormAbout organisationId={organisationId} isEditable={isEditable} />
    </Card>
    <Card className="mt-8">
      <SupplierViewGeneralFormUnspscCategories organisationId={organisationId} isEditable={isEditable} />
    </Card>
    <Card className="mt-8">
      <SupplierViewGeneralFormRegions organisationId={organisationId} isEditable={isEditable} />
    </Card>
  </>
))
