import React, { memo, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { usePlanEventAnalytics } from '@cotiss/plan-event/hooks/use-plan-event-analytics.hook'
import { usePlanUser } from '@cotiss/plan-event/hooks/use-plan-user.hook'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { Header } from '@cotiss/common/components/header.component'
import { Page } from '@cotiss/common/components/page.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { usePlanEvent } from '@cotiss/plan-event/hooks/use-plan-event.hook'
import { PlanEventViewDetailsTab } from '@cotiss/plan-event/tabs/plan-event-view-details.tab'
import { PlanEventViewResourcesTab } from '@cotiss/plan-event/tabs/plan-event-view-resources.tab'
import { PlanEventViewTeamTab } from '@cotiss/plan-event/tabs/plan-event-view-team.tab'

export type PlanEventViewTab = 'details' | 'team' | 'resources'
const TABS: TabModel<PlanEventViewTab>[] = [
  { id: 'details', label: 'Details' },
  { id: 'team', label: 'Team' },
  { id: 'resources', label: 'Resources' },
]

export const PlanEventViewPage = memo(() => {
  const { openToast } = useToast()
  const { push, replace } = useHistory()
  const { track } = usePlanEventAnalytics()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { queryPlanUserInSessionView } = usePlanUser()
  const { planEvent, queryPlanEventView } = usePlanEvent()
  const { step, transition, onTransition } = useTransition({ initialStep: 2 })
  const { planEventId, tab } = useParams<{ planEventId: string; tab?: PlanEventViewTab }>()

  const backHref = routerService.getHref('/plan-event/list/:tab?')
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Plan',
      href: backHref,
    },
    {
      label: planEvent?.name || '',
      isLoading,
    },
  ]

  useAsyncEffect(async () => {
    try {
      await Promise.all([queryPlanEventView({ planEventId }), queryPlanUserInSessionView({ planEventId })])

      track('plan_event_view')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!planEvent) {
      return
    }

    if (planEvent.isArchived) {
      openToast('You cannot access an archived plan event.', 'danger')
      return push(routerService.getHref('/plan-event/list/:tab?'))
    }
  }, [planEvent])

  useEffect(() => {
    if (!tab) {
      replace(
        routerService.getHref('/plan-event/view/:planEventId/:tab?/:nestedTab?', {
          planEventId,
          tab: 'details',
        })
      )
    }

    if (isLoading) {
      return
    }

    const newStep = findIndex(TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [isLoading, tab])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !planEvent) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && planEvent && (
                <Text className="font-medium flex items-center" size="h7" variant="heading">
                  {planEvent.name}
                </Text>
              )}
            </div>
          </div>
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm overflow-x-auto px-10 z-10">
        {!isLoading && (
          <Tabs<PlanEventViewTab>
            className="w-full"
            tab={tab}
            tabs={TABS}
            onChange={({ id: tab }) => push(routerService.getHref('/plan-event/view/:planEventId/:tab?/:nestedTab?', { planEventId, tab }))}
            variant="underline"
          />
        )}
      </div>
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <PlanEventViewDetailsTab />}
            {step === 2 && <PlanEventViewTeamTab />}
            {step === 3 && <PlanEventViewResourcesTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
