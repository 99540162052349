import React, { FormEvent, memo, useState } from 'react'
import { GqlApprovalTemplateModuleType } from '@gql'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group/hooks/use-approval-template-group.hook'
import { SettingsModulesApprovalTemplateGroupUpdateDrawer } from '@cotiss/settings/drawers/settings-modules-approval-template-group-update.drawer'

type Props = {
  module: GqlApprovalTemplateModuleType
}

export const SettingsModulesApprovalTemplateGroupCreateModal = memo(({ module }: Props) => {
  const { openToast } = useToast()
  const { openDrawer, closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateCreateApprovalTemplateGroup } = useApprovalTemplateGroup()
  const [name, setName] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const approvalTemplateGroup = await mutateCreateApprovalTemplateGroup({ name })

      openDrawer(
        <SettingsModulesApprovalTemplateGroupUpdateDrawer approvalTemplateGroupId={approvalTemplateGroup.id} module={module} isEditable isNew />
      )

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add sequence" isDisabled={isSaving} />
      <ModalContent>
        <Label>Name</Label>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Enter name"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
