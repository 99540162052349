import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { OcdsValue } from '@cotiss/common/models/ocds.model'
import { ProcurementModel } from '@cotiss/procurement/procurement.models'

type CreateProcurementBody = {
  projectId: string
  title: string
  summary?: RemirrorJSON
  regions?: string[]
  topLevelClassification?: string[]
}

type UpdateProcurementBody = {
  title?: string
  summary?: RemirrorJSON
  marketAnalysis?: RemirrorJSON
  regions?: string[]
  manualIdentifier?: string
  topLevelClassification?: string[]
  opexBudget?: OcdsValue
  capexBudget?: OcdsValue
  totalBudget?: OcdsValue
  project?: string
  attachments?: string[]
  isArchived?: boolean
}

type ExportProcurementCsvBody = {
  isArchived?: boolean
  projectId?: string
  timeZone?: string
}

export const useMutateProcurement = () => {
  const { mutate } = useMutate()

  return {
    createProcurement: async (body: CreateProcurementBody) => {
      return await mutate<ProcurementModel>({ route: '/v2/procurements', body })
    },
    updateProcurement: async (id: string, body: UpdateProcurementBody) => {
      return await mutate<ProcurementModel>({ route: `/v2/procurements/${id}`, body, invalidate: ['/v2/procurements', '/v2/tenders'] })
    },
    exportCsv: async (body: ExportProcurementCsvBody) => {
      return await mutate<{ csv: string }>({
        method: 'POST',
        route: '/v2/procurements/export-csv',
        body,
      })
    },
  }
}
