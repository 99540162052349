import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { TenderInvitationModel } from '@cotiss/tender-invitation/tender-invitation.models'

type Props = {
  tenderId?: string
  procurementId?: string
  limit?: number
  offset?: number
}

export const useListTenderInvitation = ({ tenderId, procurementId, limit = -1, offset = 0 }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<TenderInvitationModel>>('/v2/tender-invitations', {
    route: `/v2/tender-invitations`,
    params: { tenderId, procurementId, offset, limit },
    isEnabled: Boolean(tenderId || procurementId),
  })

  return { tenderInvitations: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
