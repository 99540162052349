import React, { FormEvent, memo, useMemo, useState } from 'react'
import { addMonths, differenceInMonths } from 'date-fns'
import { filter } from 'lodash'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { UpdateContractPriceDurationBulkBody, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { Label } from '@cotiss/common/components/label.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { Input } from '@cotiss/common/components/input.component'

type FormData = {
  id: string
  startDate: Date | null
  endDate: Date | null
  length: number
  value: string
  variation: string
  index: number
  description: string
}

type Props = {
  priceDurationId?: string
  startDate?: string
  contractShellId: string
}

const DESCRIPTION_MAX_LENGTH = 100

export const ContractWizardPriceDurationModal = memo(({ priceDurationId, startDate, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { updateContractPriceDurationBulk } = useMutateContractShell()
  const { track } = useAnalytics()

  const { priceDuration, contract, isVariation } = useMemo(() => {
    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const priceDuration = priceDurationId ? contract?.priceDurations.find((pd) => pd._id === priceDurationId) : null
    return { priceDuration, contract, isVariation: Boolean(contract?.variationTypes.length) }
  }, [])

  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>(
    (() => {
      const _startDate = startDate ? new Date(startDate) : priceDuration?.startDate ? new Date(priceDuration?.startDate) : null

      const _endDate = priceDuration?.endDate ? new Date(priceDuration?.endDate) : null

      return {
        id: priceDuration?._id || crypto.randomUUID(),
        startDate: _startDate,
        endDate: _endDate,
        length: !priceDuration?.length ? (_startDate && _endDate ? Math.abs(differenceInMonths(_startDate, _endDate)) : 0) : priceDuration?.length,
        value: priceDuration?.value?.toString() || '',
        variation: priceDuration?.variation?.toString() || '',
        index: priceDuration?.index || 0,
        description: priceDuration?.description || '',
      }
    })()
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contract) {
      // TODO: something
      return
    }

    const existingPriceDurations = contract?.priceDurations.map((priceDuration) => {
      return {
        ...priceDuration,
        startDate: priceDuration.startDate ? new Date(priceDuration.startDate) : undefined,
        endDate: priceDuration.endDate ? new Date(priceDuration.endDate) : undefined,
        variation: priceDuration.variation || 0,
      }
    })

    const { startDate, endDate, value, variation } = formData

    const parsedPriceDurations: UpdateContractPriceDurationBulkBody['items'] = []

    // If we're adding a new price duration
    if (!existingPriceDurations?.find((pd) => pd._id === formData.id)) {
      track('contract_wizard_price_duration_create_submit')
      parsedPriceDurations.push(...existingPriceDurations, {
        ...formData,
        value: Number(value),
        variation: Number(variation),
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        index: existingPriceDurations.length,
        exercised: 0,
      })
    }

    const existingPriceDuration = existingPriceDurations?.find((rate) => rate._id === formData.id)

    // If we're updating an existing price duration
    if (existingPriceDuration) {
      track('contract_wizard_price_duration_update_submit')
      parsedPriceDurations.push(...filter(existingPriceDurations, (pd) => pd._id !== formData.id), {
        ...formData,
        value: Number(value),
        variation: Number(variation),
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        index: existingPriceDuration.index,
        exercised: existingPriceDuration.exercised ?? 0,
      })
    }

    try {
      setIsSaving(true)
      await updateContractPriceDurationBulk(contractShellId, contract._id, { items: parsedPriceDurations })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${priceDuration ? 'Edit contract period' : 'Add new contract period'}`} isDisabled={isSaving} />
      <ModalContent>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>Start date</Label>
            <div className="relative">
              <Icon className="absolute top-1/2 -translate-y-1/2 ml-3 z-1" icon="calendar" variant="light" />
              <DatetimeInput
                className="pl-10"
                dateFormat="do MMM yyyy"
                value={formData.startDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    startDate: value,
                    endDate: value && formData.length && formData.startDate ? addMonths(value, formData.length || 0) : formData.endDate,
                  })
                }
                isTimeVisible={false}
                isDisabled={isSaving}
                placeholder="Select date"
              />
            </div>
          </div>
          <div>
            <Label>End date</Label>
            <div className="relative">
              <Icon className="absolute top-1/2 -translate-y-1/2 ml-3 z-1" icon="calendar" variant="light" />
              <DatetimeInput
                className="pl-10"
                dateFormat="do MMM yyyy"
                value={formData.endDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    endDate: value,
                    length: value && formData.startDate ? Math.abs(differenceInMonths(formData.startDate, value)) : formData.length,
                  })
                }
                isTimeVisible={false}
                isDisabled={isSaving}
                placeholder="Select date"
                minDate={formData.startDate || undefined}
              />
            </div>
          </div>
          <div>
            <Label>Length (months)</Label>
            <Input
              value={formData.length || ''}
              placeholder="Enter length"
              maxLength={5}
              onChange={({ target }) =>
                setFormData({
                  ...formData,
                  length: parseInt(target.value) || 0,
                  endDate: formData.startDate ? addMonths(formData.startDate, parseInt(target.value) || 0) : null,
                })
              }
              isDisabled={isSaving}
            />
          </div>
        </div>
        <Hr className="mt-8 mb-4" />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>{isVariation ? 'Initial value' : 'Value'}</Label>
            <Input
              value={formData.value ? formData.value.toLocaleString() : ''}
              onChange={({ target }) => setFormData({ ...formData, value: target.value })}
              placeholder="Enter value"
              isDisabled={isSaving || isVariation}
            />
            {isVariation && (
              <Text className=" mt-1 font-medium" size="sm" variant="light">
                Initial value cannot be edited
              </Text>
            )}
          </div>
          {isVariation && (
            <div>
              <Label>Variation</Label>
              <Input
                value={formData.variation.toLocaleString()}
                onChange={({ target }) => setFormData({ ...formData, variation: target.value })}
                placeholder="Enter variation value"
                isDisabled={isSaving}
              />
            </div>
          )}
        </div>
        <Hr className="mt-8 mb-4" />
        <Label className="flex items-baseline">
          Description <Text size="sm" variant="light" className="ml-1">{`(${formData.description.length}/${DESCRIPTION_MAX_LENGTH})`}</Text>
        </Label>
        <TextArea
          value={formData.description}
          rows={2}
          maxLength={DESCRIPTION_MAX_LENGTH}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
