import React, { memo, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  isScrollable?: boolean
}

export const ModalContent = memo(({ className, children, isScrollable }: Props) => {
  const classes = classNames(className, 'py-6 px-10', {
    'overflow-y-auto max-h-[500px]': isScrollable,
  })

  return <div className={classes}>{children}</div>
})
