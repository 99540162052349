import { toLower } from 'lodash'
import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { SsoEnabledModel } from '@cotiss/auth/auth.models'

export const useGetIsEmailSso = ({ email }: { email: string }) => {
  const { data, ...rest } = useFetch<SsoEnabledModel>('/v3/auth', {
    route: `/v3/auth/login/check`,
    params: { email: toLower(email) }, // This check is case-sensitive
  })

  return { isSsoEnabled: data?.enabled, ...rest }
}
