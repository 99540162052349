import React, { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { AnimatePresence } from 'framer-motion'
import { findIndex, includes } from 'lodash'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { EvaluationEventRatingScaleDefinitionList } from '@cotiss/evaluation-event/components/evaluation-event-rating-scale-definition-list.component'
import { IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES } from '@cotiss/evaluation-event/evaluation-event.constants'

type Tab = 'rating-scale-definitions' | 'supplementary'

type Props = {
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export const EvaluationEventCriteriaScoringDetailsModal = memo(({ evaluationCriteriaItem }: Props) => {
  const { isRatingScaleVisible, isSupplementaryVisible, tabs } = useMemo(() => {
    const isRatingScaleVisible = includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, evaluationCriteriaItem.ratingScale)
    const isSupplementaryVisible = Boolean(evaluationCriteriaItem.supplementary)
    const tabs: TabModel<Tab>[] = [
      { id: 'rating-scale-definitions', label: 'Rating scale definitions', isHidden: !isRatingScaleVisible },
      { id: 'supplementary', label: 'Scoring requirements', isHidden: !isSupplementaryVisible },
    ]

    return { isRatingScaleVisible, isSupplementaryVisible, tabs }
  }, [evaluationCriteriaItem, IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES])

  // Set the tab to rating scale definitions if the rating scale is in the list of visible rating scales, otherwise set it to supplementary. We know
  // there will be at least one of these tabs visible, so we don't need to worry about the case where both are not visible.
  const [tab, setTab] = useState<Tab>(isRatingScaleVisible ? 'rating-scale-definitions' : 'supplementary')
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  const handleTabChange = (tab: Tab) => {
    setTab(tab)

    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <div className="min-w-[850px] max-w-[850px]">
      <ModalHeader heading="Scoring details" />
      <ModalContent className="min-h-[500px]" isScrollable>
        {isRatingScaleVisible && isSupplementaryVisible && (
          <Tabs<Tab>
            className="border-b border-gray-300 w-full mb-4"
            tab={tab}
            tabs={tabs}
            onChange={({ id }) => handleTabChange(id)}
            variant="underline"
          />
        )}
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <EvaluationEventRatingScaleDefinitionList ratingScale={evaluationCriteriaItem.ratingScale} />}
            {step === 2 && (
              <div className="border border-gray-100 rounded-lg whitespace-pre-wrap p-4">
                <Text>{evaluationCriteriaItem.supplementary}</Text>
              </div>
            )}
          </TransitionContainer>
        </AnimatePresence>
      </ModalContent>
    </div>
  )
})
