import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ContractViewCorrespondenceDrawer } from '@cotiss/contract/drawers/contract-view-correspondence.drawer'
import { CorrespondenceUpdateDeleteCta } from '@cotiss/correspondence/components/correspondence-update-delete-cta.component'
import { CorrespondenceModel } from '@cotiss/correspondence/correspondence.model'
import { userService } from '@cotiss/user/user.service'

type Props = {
  contractShellId: string
  correspondence: CorrespondenceModel
}

// TODO: when we support correspondence on other models, move this to be a generic component
export const ContractCorrespondenceListItem = memo(({ contractShellId, correspondence }: Props) => {
  const { openNarrowDrawer } = useCallout()

  return (
    <div className="relative flex justify-between w-full p-4 border rounded-md group hover:bg-secondary-50 mb-6 last:mb-0" key={correspondence._id}>
      <div className="w-3/4">
        <Text className="font-medium">{correspondence.title}</Text>
        <Text size="sm" variant="light" className="flex whitespace-nowrap">
          <span className="mt-1 mr-2 truncate" title={userService.getFullName(correspondence.createdBy)}>
            {userService.getFullName(correspondence.createdBy)}
          </span>
          {correspondence.date && <span className="mt-1">{` • ${datetimeService.format(correspondence.date, 'd MMMM yyyy h:mm aaa')}`}</span>}
        </Text>
      </div>
      <div className="flex items-center invisible group-hover:visible">
        <Button
          onClick={() => openNarrowDrawer(<ContractViewCorrespondenceDrawer correspondence={correspondence} contractShellId={contractShellId} />)}
          state="outline"
          variant="secondary"
          size="xs"
          className="mr-4"
        >
          View
        </Button>
        <CorrespondenceUpdateDeleteCta contractShellId={contractShellId} correspondence={correspondence} />
      </div>
    </div>
  )
})
