import React, { memo, useState, useMemo, useEffect, FormEvent } from 'react'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex, flatMap } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { StepModel } from '@cotiss/common/components/steps.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Text } from '@cotiss/common/components/text.component'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  contractShellId: string
}

type Step = 'name' | 'upload'

const STEPS: StepModel<Step>[] = [
  { id: 'name', label: 'Document name' },
  { id: 'upload', label: 'Document upload' },
]

export const ContractWizardStepSupportDocumentsUploadModal = memo(({ contractShellId }: Props) => {
  const [stepIndex, setStepIndex] = useState(0)
  const [transition, setTransition] = useState<TransitionType>('right')

  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useAnalytics()
  const { createContractDocumentShell, uploadContractDocumentShellAttachment } = useMutateContractShell()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const [isSaving, setIsSaving] = useState(false)
  const contract = useMemo(() => find(contractShell?.contracts, (contract) => contract.status === 'DRAFTING'), [contractShell])
  const [documentShellName, setDocumentShellName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleStepChange = (newStep: StepModel<Step>) => {
    const oldStepIndex = findIndex(STEPS, ({ id }) => id === STEPS[stepIndex].id)
    const newStepIndex = findIndex(STEPS, ({ id }) => id === newStep.id)

    setTransition(newStepIndex > oldStepIndex ? 'right' : 'left')
    setTimeout(() => setStepIndex(newStepIndex), 0)
  }

  const handleNext = () => {
    const newStepIndex = findIndex(STEPS, ({ id }) => id === STEPS[stepIndex].id) + 1

    STEPS[newStepIndex] && handleStepChange(STEPS[newStepIndex])
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user?.account?.organisation || !contract) {
      sentryService.captureException({ exception: 'Failed to load contract wizard upload document modal.', extras: { contractShellId } })
      openToast(<>Couldn&apos;t load {contract ? 'user information' : 'contract'}. Please try again.</>, 'danger')
      closeModal()
    }
  }, [isLoading, user, contract])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (STEPS[stepIndex].id === 'name') {
      const existingShell = find(
        flatMap(contractShell?.contracts, ({ documentShells }) => documentShells),
        ({ name }) => name === documentShellName
      )

      // Check if one of the same name already exists - these must be unique
      // Can remove this once BE is updated to return document shell on creation
      if (existingShell) {
        setErrorMessage('There is already a supporting document with that name. Please use a unique name.')
        return
      }

      handleNext()
      return
    }
  }

  const handleUpload = async (file: File) => {
    if (!contract) {
      return
    }

    track('contract_wizard_metadata_update_submit')

    try {
      setIsSaving(true)

      const _contractShell = await createContractDocumentShell(contractShellId, contract?._id, {
        name: documentShellName,
        type: 'SUPPORTING',
      })

      // TODO: remove/update this when BE is updated to return document shell on creation
      const _documentShell = find(
        flatMap(_contractShell.contracts, ({ documentShells }) => documentShells),
        ({ name }) => name === documentShellName
      )

      if (!_documentShell) {
        setIsSaving(false)
        return
      }

      await uploadContractDocumentShellAttachment(contractShellId, contract?._id, _documentShell._id, file)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload document" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={STEPS[stepIndex].id} transition={transition}>
            {STEPS[stepIndex].id === 'name' && (
              <>
                <Label>Document name</Label>
                <Input
                  value={documentShellName}
                  onChange={({ target }) => setDocumentShellName(target.value)}
                  isRequired
                  isError={Boolean(errorMessage)}
                />
                {errorMessage && (
                  <Text className="text-sm mt-1" variant="danger">
                    {errorMessage}
                  </Text>
                )}
                <div className="w-full flex justify-end mt-4">
                  <Button variant="secondary" size="sm" type="submit">
                    Next
                  </Button>
                </div>
              </>
            )}
            {STEPS[stepIndex].id === 'upload' && <DocumentUpload onUpload={handleUpload} className="my-6" isDisabled={isLoading} isSingle />}
          </TransitionContainer>
        </AnimatePresence>
      </ModalContent>
    </Form>
  )
})
