import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricCycleScoreInput,
  GqlUpdatePerformanceScorecardMetricCycleScoreInput,
  GqlPerformanceScorecardMetricCycleScoreFieldsFragment,
  GqlPerformanceScorecardMetricCycleScoreListInput,
  GqlPerformanceScorecardMetricCycleScoreViewInput,
} from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecardMetricCycleScore } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/mutate-create-performance-scorecard-metric-cycle-score.graphql'
import { mutateUpdatePerformanceScorecardMetricCycleScore } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/mutate-update-performance-scorecard-metric-cycle-score.graphql'
import { queryPerformanceScorecardMetricCycleScoreList } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/performance-scorecard-metric-cycle-score-list.graphql'
import { queryPerformanceScorecardMetricCycleScoreView } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/performance-scorecard-metric-cycle-score-view.graphql'

const performanceScorecardMetricCycleScoreVar = makeVar<GqlPerformanceScorecardMetricCycleScoreFieldsFragment | null>(null)
const performanceScorecardMetricCycleScoresVar = makeVar<GqlPerformanceScorecardMetricCycleScoreFieldsFragment[]>([])

export const usePerformanceScorecardMetricCycleScore = () => {
  const performanceScorecardMetricCycleScore = useReactiveVar(performanceScorecardMetricCycleScoreVar)
  const performanceScorecardMetricCycleScores = useReactiveVar(performanceScorecardMetricCycleScoresVar)

  return {
    performanceScorecardMetricCycleScore,
    performanceScorecardMetricCycleScores,
    setPerformanceScorecardMetricCycleScore: performanceScorecardMetricCycleScoreVar,
    setPerformanceScorecardMetricCycleScores: performanceScorecardMetricCycleScoresVar,
    queryPerformanceScorecardMetricCycleScoreList: async (input: GqlPerformanceScorecardMetricCycleScoreListInput) => {
      const { items: performanceScorecardMetricCycleScores, pagination } = await queryPerformanceScorecardMetricCycleScoreList(input)

      performanceScorecardMetricCycleScoresVar(performanceScorecardMetricCycleScores)

      return { performanceScorecardMetricCycleScores, pagination }
    },
    queryPerformanceScorecardMetricCycleScoreView: async (input: GqlPerformanceScorecardMetricCycleScoreViewInput) => {
      const performanceScorecardMetricCycleScore = await queryPerformanceScorecardMetricCycleScoreView(input)

      performanceScorecardMetricCycleScoreVar(performanceScorecardMetricCycleScore)

      return performanceScorecardMetricCycleScore
    },
    mutateCreatePerformanceScorecardMetricCycleScore: async (input: GqlCreatePerformanceScorecardMetricCycleScoreInput) => {
      const createdPerformanceScorecardScore = await mutateCreatePerformanceScorecardMetricCycleScore(input)

      performanceScorecardMetricCycleScoreVar(createdPerformanceScorecardScore)
      performanceScorecardMetricCycleScoresVar(uniqBy([createdPerformanceScorecardScore, ...performanceScorecardMetricCycleScores], 'id'))

      return createdPerformanceScorecardScore
    },
    mutateUpdatePerformanceScorecardMetricCycleScore: async (input: GqlUpdatePerformanceScorecardMetricCycleScoreInput) => {
      const updatedPerformanceScorecardMetricCycleScore = await mutateUpdatePerformanceScorecardMetricCycleScore(input)

      performanceScorecardMetricCycleScoreVar(updatedPerformanceScorecardMetricCycleScore)
      performanceScorecardMetricCycleScoresVar(uniqBy([updatedPerformanceScorecardMetricCycleScore, ...performanceScorecardMetricCycleScores], 'id'))

      return updatedPerformanceScorecardMetricCycleScore
    },
  }
}

export const clearReactivePerformanceScorecardMetricCycleScore = async () => {
  performanceScorecardMetricCycleScoreVar(null)
  performanceScorecardMetricCycleScoresVar([])
}
