import React, { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { routerService } from '@cotiss/common/services/router.service'
import { ContractApprovalHeader } from '@cotiss/contract/components/contract-approval-header.component'
import { ContractApprovalHistory } from '@cotiss/contract/components/contract-approval-history.component'
import { ContractApproversTable } from '@cotiss/contract/components/contract-approvers-table.component'
import { ContractOwnersTable } from '@cotiss/contract/components/contract-owners-table.component'
import { ContractSummaryCessationDetails } from '@cotiss/contract/components/contract-summary-cessation-details.component'
import { ContractSummaryScheduleOfRates } from '@cotiss/contract/components/contract-summary-schedule-of-rates.component'
import { ContractSummarySignaturesTab } from '@cotiss/contract/components/contract-summary-signatures-tab.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { ContractSummaryContractTab } from '@cotiss/contract/tabs/contract-summary-contract.tab'
import { ContractSummaryMilestoneTab } from '@cotiss/contract/tabs/contract-summary-milestone.tab'
import { ContractSummaryPriceDurationTab } from '@cotiss/contract/tabs/contract-summary-price-duration.tab'
import { ContractSummarySupportingDocumentsTab } from '@cotiss/contract/tabs/contract-summary-supporting-documents.tab'
import { ContractSummaryTermsAndMetadataTab } from '@cotiss/contract/tabs/contract-summary-terms-and-metadata.tab'

type ContractApprovalViewTab =
  | 'price-duration'
  | 'contract'
  | 'owners'
  | 'approvals'
  | 'cessation'
  | 'metadata'
  | 'scheduled-rates'
  | 'milestones'
  | 'supporting-docs'
  | 'signatures'
  | 'history'

export const ContractApprovalViewPage = memo(() => {
  const { contractShellId, contractId, approvalId, tab } = useParams<{
    contractShellId: string
    contractId: string
    approvalId: string
    tab: ContractApprovalViewTab
  }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { replace, push } = useHistory()
  const { step, transition, onTransition } = useTransition()

  const { contract, approval } = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)
    let approval = null
    if (approvalId) {
      approval = find(contract?.approvals, ({ _id }) => _id === approvalId)
    }
    if (!approvalId) {
      approval = contractService.getApproval(contract?.approvals, ['PENDING_APPROVAL', 'APPROVED', 'REJECTED', 'DRAFTING'])
    } // Only show when drafting if rejected/changes requested
    if (approval?.status === 'DRAFTING' && !some(approval.approvers, { status: 'REQUESTED_CHANGES' })) {
      approval = null
    }
    return { contract, approval }
  }, [contractShell])

  const isEsignEnabled = useFeature('contract-management-e-sign')
  const isUsingEsign = isEsignEnabled && contract && find(contract?.documentShells, (ds) => ds.signatureType === 'ESIGN_DOCUSIGN')
  const getContractApprovalViewHref = (_tab: ContractApprovalViewTab) =>
    routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?', {
      contractShellId,
      contractId,
      approvalId: approval?._id,
      tab: _tab,
    })

  const tabs = useMemo(() => {
    const tabs: (VerticalTabModel<ContractApprovalViewTab> & { isHidden?: boolean })[] = [
      {
        id: 'cessation',
        label: 'Cessation',
        isHidden: !contract?.cessationDate,
        href: getContractApprovalViewHref('cessation'),
      },
      {
        id: 'metadata',
        label: 'Terms and metadata',
        href: getContractApprovalViewHref('metadata'),
      },
      {
        id: 'supporting-docs',
        label: 'Supporting documents',
        isHidden: !find(contract?.documentShells, { type: 'SUPPORTING' }),
        href: getContractApprovalViewHref('supporting-docs'),
      },
      {
        id: 'scheduled-rates',
        label: 'Schedule of rates',
        href: getContractApprovalViewHref('scheduled-rates'),
      },
      {
        id: 'price-duration',
        label: 'Price and duration',
        isHidden: contractShell?.type === 'MILESTONE',
        href: getContractApprovalViewHref('price-duration'),
      },
      {
        id: 'milestones',
        label: 'Milestones',
        isHidden: contractShell?.type !== 'MILESTONE',
        href: getContractApprovalViewHref('milestones'),
      },
      {
        id: 'contract',
        label: 'Contract',
        href: getContractApprovalViewHref('contract'),
      },
      {
        id: 'owners',
        label: 'Owners',
        href: getContractApprovalViewHref('owners'),
      },
      {
        id: 'approvals',
        label: 'Approvals',
        href: getContractApprovalViewHref('approvals'),
      },
      {
        id: 'signatures',
        label: 'Signatures',
        isHidden: !isUsingEsign,
        href: getContractApprovalViewHref('signatures'),
      },
      {
        id: 'history',
        label: 'History',
        href: getContractApprovalViewHref('history'),
      },
    ]
    return filter(tabs, (tab) => !tab.isHidden)
  }, [contract, contractShell])

  const activeTabId = useMemo(() => tab || tabs[step - 1].id, [step, tabs])

  useEffect(() => {
    // If contract shell has not loaded, tabs will not be accurate yet
    if (!contractShell) {
      return
    }

    if (!tab || !some(tabs, { id: tab })) {
      replace(getContractApprovalViewHref(tabs[0].id))
    }
  }, [contractShell])

  useEffect(() => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [tab, tabs])

  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'contract' }),
      },
      {
        label: 'Contract approval',
        href: routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?', {
          contractShellId,
          contractId,
          approvalId: approval?._id,
        }),
      },
      {
        label: `${contractShell?.title || ''}${contract?.metadata?.internalReference ? ` (${contract?.metadata?.internalReference})` : ''}`,
      },
    ]
  }, [contract])

  if ((!isLoading && !contract) || !approval) {
    return <FourOhFourPage />
  }

  return (
    <Page>
      <ContractApprovalHeader contractShellId={contractShellId} contractId={contractId} breadcrumbs={breadcrumbs} />
      <PageContent>
        <div className="flex">
          <div className="flex flex-col">
            <VerticalTabs<ContractApprovalViewTab>
              className="sticky top-28 mr-8"
              tab={activeTabId}
              tabs={tabs}
              onChange={({ id }) => push(getContractApprovalViewHref(id))}
            />
          </div>

          <Card className="w-full min-w-0">
            <CardHeader>
              <div>
                <Text size="sm" variant="light">
                  {contractShell?.title}
                </Text>
                <Text size="lg" className="font-medium">
                  Summary
                </Text>
              </div>
            </CardHeader>
            <AnimatePresence initial={false} mode="wait">
              <TransitionContainer key={step} transition={transition}>
                {activeTabId === 'cessation' && <ContractSummaryCessationDetails contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'metadata' && (
                  <ContractSummaryTermsAndMetadataTab contractId={contractId} contractShellId={contractShellId} isApproval />
                )}
                {activeTabId === 'supporting-docs' && (
                  <ContractSummarySupportingDocumentsTab contractId={contractId} contractShellId={contractShellId} />
                )}
                {activeTabId === 'price-duration' && <ContractSummaryPriceDurationTab contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'milestones' && <ContractSummaryMilestoneTab contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'contract' && <ContractSummaryContractTab contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'owners' && <ContractOwnersTable contractShellId={contractShellId} contractId={contractId} />}
                {activeTabId === 'scheduled-rates' && <ContractSummaryScheduleOfRates contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'approvals' && (
                  <ContractApproversTable contractId={contractId} contractShellId={contractShellId} approvalId={approval._id} />
                )}
                {activeTabId === 'signatures' && <ContractSummarySignaturesTab contractId={contractId} contractShellId={contractShellId} />}
                {activeTabId === 'history' && (
                  <ContractApprovalHistory contractId={contractId} contractShellId={contractShellId} approvalId={approval._id} />
                )}
              </TransitionContainer>
            </AnimatePresence>
          </Card>
        </div>
      </PageContent>
    </Page>
  )
})
