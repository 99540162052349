import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  onClick?: () => void
  isFirst?: boolean
  isLast?: boolean
}

export const AccordionContent = memo(({ className, children, onClick, isFirst, isLast }: Props) => {
  const classes = classNames(className, 'flex items-center bg-white shadow px-4 py-2', {
    'rounded-t-lg': isFirst,
    'rounded-b-lg': isLast,
    'border-t border-gray-200': !isFirst,
    'cursor-pointer hover:bg-primary-50': onClick,
  })

  return (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  )
})
