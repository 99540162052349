import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { find, forEach, includes } from 'lodash'
import { loggerService } from '@cotiss/common/services/logger.service'
import { UserMeModel } from '@cotiss/user/user.models'
import { userService } from '@cotiss/user/user.service'

const SENTRY_EXCLUDED_ERRORS = ['Invalid request', 'Invalid email or password'] as const

type CaptureExceptionParam = {
  exception: any
  tags?: Record<string, string | number | undefined>
  extras?: Record<string, string | number | boolean | undefined>
  transaction?: string
  level?: Sentry.SeverityLevel
  userId?: string
}

class SentryService {
  public _isInitialised = false

  init = async () => {
    if (this._isInitialised) {
      return
    }

    const integrations: Array<BrowserTracing> = [new BrowserTracing()]

    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: `cotiss-app@${process.env.npm_package_version}`,
      integrations,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.ENVIRONMENT,
      beforeSend(event) {
        const { values = [] } = event?.exception || {}
        const { frames = [] } = values[0]?.stacktrace || {}

        // Ignoring any errors that come from the newrelic.js script, and if they are in the excluded list of errors.
        if (frames[0]?.filename === `/newrelic.js` || includes(SENTRY_EXCLUDED_ERRORS, values[0]?.value || '')) {
          return null
        }

        return event
      },
    })

    this._isInitialised = true
  }

  setUser = async (user: UserMeModel) => {
    if (!this._isInitialised || !user?._id) {
      return
    }

    const fullName = userService.getFullName(user)

    Sentry.setUser({
      id: user._id,
      email: user.email,
      username: fullName,
      segment: user.account?._id,
    })
  }

  captureException = ({ exception, tags, extras, transaction, level = 'error' }: CaptureExceptionParam) => {
    loggerService.error(exception)

    if (!this._isInitialised) {
      return
    }

    if (find(SENTRY_EXCLUDED_ERRORS, (errorMessage) => errorMessage === exception || errorMessage === exception.message)) {
      return
    }

    Sentry.withScope((scope) => {
      scope.setExtra('pathname', location.pathname)

      if (level) {
        // ! There is a bug in the Sentry TypeScript definitions, so casting this to any as a workaround.
        scope.setLevel(level as any)
      }

      forEach(tags, (value, key) => {
        if (value) {
          scope.setTag(key, value)
        }
      })

      forEach(extras, (value, key) => {
        if (value) {
          scope.setExtra(key, value)
        }
      })

      if (transaction) {
        scope.setTransactionName(transaction)
      }

      Sentry.captureException(exception)
    })
  }
}

export const sentryService = new SentryService()
