import React, { memo, useEffect, useMemo, useState } from 'react'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { DocumentViewer } from '@cotiss/document/components/document-viewer.component'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmissionDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-submission-document.hook'

export const EvaluationEventEnvelopeSubmissionDocumentsTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const [documentIdToPreview, setDocumentIdToPreview] = useState('')
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToPreview } = useGetDocument(documentIdToPreview)
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { evaluationSubmissionDocuments, queryEvaluationSubmissionDocumentList } = useEvaluationSubmissionDocument()
  const { evaluationEnvelopeId, evaluationSubmissionId } = useParams<{ evaluationEnvelopeId: string; evaluationSubmissionId: string }>()

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Document',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileName }) => ({
          content: () => (
            <Text className="truncate" variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>
              {fileName}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{ label: 'Preview', onClick: () => setDocumentIdToPreview(documentId) }}
              actions={[{ label: 'Download', onClick: () => setDocumentIdToDownload(documentId) }]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Size',
        rows: map(evaluationSubmissionDocuments, ({ documentId, fileSize }) => ({
          content: () => <Text variant={documentIdToPreview === documentId ? 'secondary' : 'primary'}>{documentService.formatSize(fileSize)}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [evaluationSubmissionDocuments, documentIdToPreview])

  useAsyncEffect(async () => {
    if (!evaluationEnvelopeId || !evaluationSubmissionId || !evaluationEvent) {
      return
    }

    try {
      const { evaluationSubmissionDocuments } = await queryEvaluationSubmissionDocumentList({
        filter: {
          evaluationEventId: evaluationEvent.id,
          evaluationSubmissionId,
          evaluationEnvelopeId,
        },
      })

      // TODO: Handle the case where there aren't any documents or price items to preview. Is this possible?
      setDocumentIdToPreview(evaluationSubmissionDocuments[0]?.documentId || '')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [evaluationEnvelopeId, evaluationSubmissionId, evaluationEvent])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  return (
    <div className="flex justify-between h-full  w-full">
      <PageContent className="flex-1 h-full overflow-hidden">
        <div className="bg-gray-100 rounded-lg p-6 max-w-[800px] w-full h-full">
          {documentToPreview && <DocumentViewer document={documentToPreview} isDownloadable />}
        </div>
      </PageContent>
      <div className="flex-0 bg-white overflow-y-scroll w-[400px] min-h-full">
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
      </div>
    </div>
  )
})
