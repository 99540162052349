import { gql } from '@apollo/client'
import { GqlEvaluationSubmissionViewInput, GqlEvaluationSubmissionViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SUBMISSION_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-submission/evaluation-submission-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const queryEvaluationSubmissionView = async (input: GqlEvaluationSubmissionViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationSubmissionViewQuery>({
    query: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_SUBMISSION_FRAGMENT}

      query EvaluationSubmissionView($input: EvaluationSubmissionViewInput!) {
        evaluationSubmissionView(input: $input) {
          ...EvaluationSubmissionFields
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationSubmissionView
}
