import React, { memo, useMemo, useState } from 'react'
import { GqlPagination, GqlPerformanceScorecardUserFieldsFragment } from '@gql'
import { parseISO } from 'date-fns'
import { filter, includes, map } from 'lodash'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { PerformanceScorecardOwnerAddDrawer } from '@cotiss/performance/drawers/performance-scorecard-owner-add.drawer'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { userService } from '@cotiss/user/user.service'

export const PerformanceScorecardViewTeamOwnersTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const { openDrawer, openModal } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardId } = useParams<{ performanceScorecardId: string }>()
  const [performanceScorecardUsers, setPerformanceScorecardUsers] = useState<GqlPerformanceScorecardUserFieldsFragment[]>([])
  const { performanceScorecardUserInSession, queryPerformanceScorecardUserList, mutateUpdatePerformanceScorecardUser } = usePerformanceScorecardUser()

  const { isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    return { isOwner }
  }, [performanceScorecardUserInSession])

  const refreshPerformanceScorecardUsers = async () => {
    try {
      setIsLoading(true)
      const { items: performanceScorecardUsers, pagination } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, role: 'owner' },
        pagination: {
          page: currentPage,
          pageSize: 50,
        },
      })

      setPerformanceScorecardUsers(performanceScorecardUsers)
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useAsyncEffect(async () => {
    refreshPerformanceScorecardUsers()
  }, [])

  const handleDelete = async ({ id: performanceScorecardUserId, roles }: GqlPerformanceScorecardUserFieldsFragment) => {
    await mutateUpdatePerformanceScorecardUser({ performanceScorecardUserId, roles: filter(roles, (role) => role !== 'owner') })
    await refreshPerformanceScorecardUsers()
  }

  const renderCta = (performanceScorecardUser: GqlPerformanceScorecardUserFieldsFragment) => {
    if (!isOwner) {
      return
    }

    if (performanceScorecardUser.id === performanceScorecardUserInSession?.id) {
      return (
        <Tooltip_DEPRECATED tooltipClassName="z-1" tooltip="You cannot remove yourself as an owner.">
          <Icon icon="lock" />
        </Tooltip_DEPRECATED>
      )
    }

    return (
      <TableRowCta
        actions={[
          {
            label: 'Delete',
            onClick: () =>
              openModal(
                <ConfirmModal
                  heading="Remove owner"
                  description="Are you sure you want to delete this owner?"
                  onSubmit={() => handleDelete(performanceScorecardUser)}
                />
              ),
          },
        ]}
      />
    )
  }

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Name',
        rows: map(performanceScorecardUsers, (performanceScorecardUser) => {
          const fullName = userService.getFullName(performanceScorecardUser.user)

          return {
            content: () => (
              <div className="flex items-center truncate">
                <Text className="truncate" title={fullName}>
                  {fullName}
                </Text>
                {performanceScorecardUserInSession?.id === performanceScorecardUser.id && (
                  <Text className="ml-1" variant="light">
                    (you)
                  </Text>
                )}
              </div>
            ),
            cta: renderCta(performanceScorecardUser),
          }
        }),
      },
      {
        heading: 'Email',
        rows: map(performanceScorecardUsers, ({ user }) => ({
          content: () => <Text>{user.email}</Text>,
        })),
      },
      {
        heading: 'Date added',
        rows: map(performanceScorecardUsers, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(parseISO(createdAt), 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { columns }
  }, [performanceScorecardUsers, performanceScorecardUserInSession])

  if (isError) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader className="flex items-center justify-between" variant="white">
        <div>
          <Text className="font-medium" size="lg">
            Owners
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            Adding new users to your account must be done in{' '}
            <Button
              size="sm"
              href={routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' })}
              state="text"
              variant="link"
              isLink
            >
              Settings
            </Button>
            . Make sure that the user you are adding has the &quot;Performance Manager&quot; permission
          </Text>
        </div>
        {isOwner && (
          <Button
            className="ml-2 gap-1"
            onClick={() =>
              openDrawer(
                <PerformanceScorecardOwnerAddDrawer performanceScorecardId={performanceScorecardId} onSubmit={refreshPerformanceScorecardUsers} />
              )
            }
            variant="secondary"
            state="filled"
            size="sm"
          >
            <Icon icon="plus-01" /> Add owner
          </Button>
        )}
      </TableHeader>
      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
