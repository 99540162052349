import React, { memo, useMemo } from 'react'
import { map } from 'lodash'
import { ScrollContainerButton } from '@cotiss/common/components/scroll-container-button.component'
import { StatusCard } from '@cotiss/common/components/status-card.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useAutoScrollContainer } from '@cotiss/common/hooks/use-auto-scroll-container.hook'
import { Filter } from '@cotiss/common/models/filter.model'
import { useContractSearchFiltersContext } from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { CONTRACT_LIST_PRIMARY_TAB_FILTERS, CONTRACT_LIST_STATUS_CARD_FILTERS } from '@cotiss/contract/contract.constants'
import { useContractListFilterTotals } from '@cotiss/contract/hooks/use-contract-list-filter-totals.hook'

type Props = {
  isDisabled?: boolean
}

/* This util transforms the CONTRACT_LIST_STATUS_CARD_FILTERS into the format required by the B/E
1. It removes the additional keys from the CONTRACT_LIST_STATUS_CARD_FILTERS (e.g. iconFill, iconStroke) etc. which are used only by the F/E
2. It spreads the CONTRACT_LIST_PRIMARY_TAB_FILTERS for each item as each filter must include the filters from the currently active primary tab
*/
const transformStatusCardFilters = (statusCardFilters: Record<string, { filters: Filter[] }>, activePrimaryTabFilters: Filter[]) => {
  return Object.entries(statusCardFilters).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        filters: [...(value?.filters || []), ...activePrimaryTabFilters],
      },
    }
  }, {})
}

export const ContractListStatusCards = memo(({ isDisabled }: Props) => {
  const { queryState, queryStateDispatch } = useContractSearchFiltersContext()
  const { track } = useAnalytics()
  const transformedStatusCardFilters = useMemo(() => {
    const activePrimaryTabFilters = CONTRACT_LIST_PRIMARY_TAB_FILTERS[queryState.primaryTab]?.filters || []
    return transformStatusCardFilters(CONTRACT_LIST_STATUS_CARD_FILTERS, activePrimaryTabFilters)
  }, [queryState.primaryTab])

  const { totals, isLoading } = useContractListFilterTotals({ totalFilters: transformedStatusCardFilters })

  const { scrollContainerRef, canScrollLeft, canScrollRight, handleScrollLeft, handleScrollRight } = useAutoScrollContainer()

  return (
    <div className="relative">
      {canScrollLeft ? <ScrollContainerButton inset={1} direction="left" onClick={handleScrollLeft} /> : null}
      <div ref={scrollContainerRef} className="flex overflow-x-auto bg-white scrollbar-hidden border">
        {map(CONTRACT_LIST_STATUS_CARD_FILTERS, ({ label, icon, filters, iconFill, iconStroke, tooltip }, key) => {
          const { filteredSubtotal, total } = totals[key] || {}

          const isActive = queryState.statusCard === key

          return (
            <StatusCard
              key={key}
              label={label}
              icon={icon}
              iconFill={iconFill}
              iconStroke={iconStroke}
              shouldShowTotal={Boolean(queryState.searchQuery || queryState.advancedFilters.length > 0)}
              filteredSubtotal={filteredSubtotal}
              total={total}
              onClick={() => {
                // Only fire the event if they are activating (not de-activating) the card
                if (!isActive) track('contract_list_status_card_click', { statusCard: key })
                queryStateDispatch({
                  type: 'UPDATE_STATUS_CARD',
                  payload: isActive ? { statusCard: null, statusCardFilters: [] } : { statusCard: key, statusCardFilters: filters },
                })
              }}
              isActive={isActive}
              isDisabled={isDisabled}
              isLoading={isLoading}
              className="last:border-r-0 border-r"
              tooltip={tooltip}
            />
          )
        })}
      </div>
      {canScrollRight ? <ScrollContainerButton inset={1} direction="right" onClick={handleScrollRight} /> : null}
    </div>
  )
})
