import React, { useState, useEffect, MutableRefObject } from 'react'
import classNames from 'classnames'
import { findIndex } from 'lodash'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'

type Props<T> = {
  tabButtonRefs: MutableRefObject<(HTMLButtonElement | HTMLAnchorElement)[]>
  activeTab?: VerticalTabModel<T>
  tabs: VerticalTabModel<T>[]
}

export function VerticalTabsActiveIndicator<T>({ tabButtonRefs, activeTab, tabs }: Props<T>) {
  const [style, setStyle] = useState<{ top: string; height: string }>()
  const classes = classNames('absolute top-0.5 bottom-0.5 w-full h-full')
  const contentClasses = classNames('absolute bg-secondary-100 rounded-md w-full h-full duration-300 ease-in-out ')

  useEffect(() => {
    const activeIndex = findIndex(tabs, ({ id }) => id === activeTab?.id)

    if (activeIndex === -1) {
      setStyle(undefined)
      return
    }
    const activeTabButtonRef = tabButtonRefs.current[activeIndex]

    if (!activeTabButtonRef) {
      return
    }

    let top = 2

    for (let i = 0; i < activeIndex; i += 1) {
      const tabButtonHeight = tabButtonRefs.current[i]?.getBoundingClientRect().height || 0

      // Adding 8px to account for the margin top on the tab buttons.
      top += tabButtonHeight + 12
    }

    setStyle({ top: `${top}px`, height: `${tabButtonRefs.current[0]?.getBoundingClientRect().height || 0}px` })
  }, [tabButtonRefs, activeTab, tabs])

  if (!style) {
    return null
  }

  return (
    <div className={classes}>
      <div className={contentClasses} style={style} />
    </div>
  )
}
