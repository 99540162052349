import React, { ReactNode } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
  children: ReactNode
  tooltip: ReactNode
  align?: TooltipPrimitive.TooltipContentProps['align']
  side?: TooltipPrimitive.TooltipContentProps['side']
  isEnabled?: boolean
  maxWidth?: number
}

export const TooltipProvider = TooltipPrimitive.Provider

const MotionContent = motion(TooltipPrimitive.Content)

export const Tooltip = ({ className, children, tooltip, side, align, isEnabled = true, maxWidth = 250 }: Props) => {
  if (!isEnabled) {
    return <>{children}</>
  }

  return (
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <MotionContent
          sideOffset={10}
          collisionPadding={10}
          side={side}
          align={align}
          className={classNames('bg-white shadow rounded px-2 py-1 z-tooltip', className)}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 5 }}
          transition={{ duration: 0.2 }}
          style={{ maxWidth }}
        >
          <Text className="whitespace-pre-wrap" size="sm">
            {tooltip}
          </Text>
        </MotionContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  )
}
