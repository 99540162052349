import React, { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { filter, find, includes, map, uniq } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { ContractStepCardSkeletonLoading } from '@cotiss/contract/components/contract-step-card-skeleton-loading.component'
import { ContractDocumentShellHierarchy, contractService } from '@cotiss/contract/contract.service'
import { ContractWizardStepDocumentUploadModal } from '@cotiss/contract/modals/contract-wizard-step-document-upload.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

type Props = {
  onNext: () => void
  onBack: () => void
  contractShellId: string
}

export const ContractWizardContractStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openModal } = useCallout()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateContractDocumentShell, removeContractDocumentShellAttachment, removeContractDocumentShell } = useMutateContractShell()
  const { openToast } = useToast()
  const { track } = useAnalytics()

  const { contract, hasExistingDocuments, documentShell, masterDocumentShellNameOptions } = useMemo(() => {
    const contract = find(contractShell?.contracts, { status: 'DRAFTING' })
    const hasExistingDocuments = find(contractShell?.contracts, ({ documentShells, _id: contractId }) =>
      find(filter(documentShells, { type: 'CONTRACT' }), ({ attachments }) => contractId !== contract?._id && attachments.length)
    ) // Do any existing contract shells have documents (excluding this contact)

    const documentShellNames = contractShell
      ? uniq(
          map(
            contractService.getAllApprovedContractDocumentShellsOfType({ contracts: contractShell.contracts, type: 'CONTRACT' }),
            (documentShell) => documentShell.name
          )
        )
      : []
    const masterDocumentShellNameOptions = uniq(map(documentShellNames, (name) => ({ label: name, value: name })))

    // Currently there should only be one document shell of type CONTRACT per contract
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })

    return { contract, hasExistingDocuments, documentShell, masterDocumentShellNameOptions }
  }, [contractShell])

  const [documentShellName, setDocumentShellName] = useState(documentShell?.name || '')
  const [areDocumentsRequired, setAreDocumentsRequired] = useState(contract?.variationTypes.length ? (documentShell ? true : false) : true)
  const [isSaving, setIsSaving] = useState(false)
  const [documentShellHierarchy, setDocumentShellHierarchy] = useState<ContractDocumentShellHierarchy>(
    hasExistingDocuments ? contractService.getContractDocumentShellHierarchy({ contractShell, documentShellName }) : 'master'
  )

  useEffect(() => {
    track('contract_wizard_contract_view')
  }, [])

  useEffect(() => {
    if (masterDocumentShellNameOptions.length === 1 && !documentShellName) {
      // Pre-select the document name if there is only one
      setDocumentShellName(masterDocumentShellNameOptions[0].value || '')
    }
  }, [masterDocumentShellNameOptions])

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="flex items-between p-6">
          <Skeleton className="bg-primary-200 h-6 w-1/4 mr-12" />
          <Skeleton className="bg-primary-200 h-6 w-1/3" />
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contract || !contractShell) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  const handleRemove = async (documentIdsToRemove: string[]) => {
    if (!documentShell) {
      return
    }

    track('contract_wizard_contract_delete_submit')

    const attachmentsToRemove = filter(documentShell?.attachments, ({ document }) => includes(documentIdsToRemove, document._id))

    setIsSaving(true)

    try {
      await Promise.all(
        map(attachmentsToRemove, (attachment) => {
          return removeContractDocumentShellAttachment({
            contractShellId,
            contractId: contract._id,
            documentShellId: documentShell._id,
            attachmentId: attachment._id,
          })
        })
      )
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('contract_wizard_contract_update_submit')

    setIsSaving(true)

    try {
      if (documentShell && !areDocumentsRequired) {
        await removeContractDocumentShell(contractShellId, contract?._id, documentShell._id)
        setIsSaving(false)
        onNext()
        return
      }

      // Only update a document shell if documents are required and there are changes to be saved
      if (!areDocumentsRequired || (documentShell && documentShell.name === documentShellName)) {
        setIsSaving(false)
        onNext()
        return
      }

      // This would mean there have been no documents uploaded - the UI should stop this from happening
      if (!documentShell) {
        return
      }

      await updateContractDocumentShell(contractShellId, contract?._id, documentShell._id, { name: documentShellName })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    onNext()
  }

  const handleChangeDocumentsRequired = async (documentsRequired: boolean) => {
    setAreDocumentsRequired(documentsRequired)

    // Only remove here if applicable; don't create a shell if required.
    // We require a name in order to create a shell so can't create one here
    if (!documentsRequired && documentShell) {
      try {
        setIsSaving(true)
        await removeContractDocumentShell(contractShellId, contract?._id, documentShell._id)
        setIsSaving(false)
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        openToast(error.message, 'danger')
        setIsSaving(false)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5">
              Contract
            </Text>
          </div>
          <div className="flex flex-col items-end ml-4">
            <div>
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isSaving}>
                Back
              </Button>
              <Button
                type="submit"
                variant="secondary"
                size="sm"
                isDisabled={isSaving || (areDocumentsRequired && !documentShell?.attachments.length)}
              >
                Continue
              </Button>
            </div>
            {areDocumentsRequired && !documentShell?.attachments.length && (
              <Text className="mt-2" size="sm" variant="light">
                Please upload a contract before continuing
              </Text>
            )}
          </div>
        </CardHeader>
        <div className="p-6">
          <Field label="Is a contract required?">
            <div className="flex items-start">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={() => handleChangeDocumentsRequired(true)}
                name="contract-documents-required"
                isChecked={areDocumentsRequired}
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={() => handleChangeDocumentsRequired(false)}
                name="contract-documents-required"
                isChecked={areDocumentsRequired === false}
              >
                No
              </Radio>
            </div>
          </Field>
          {areDocumentsRequired && (
            <>
              {Boolean(hasExistingDocuments) && (
                <Field
                  label="What is the hierarchy of this contract?"
                  supplementary="This could be a variation of an existing contract in this shell or is this a new master contract"
                  className="mt-6"
                >
                  <Select_DEPRECATED<ContractDocumentShellHierarchy>
                    value={documentShellHierarchy}
                    options={[
                      { value: 'variation', label: 'Variation' },
                      { value: 'master', label: 'Master contract' },
                    ]}
                    onChange={(value) => {
                      setDocumentShellHierarchy(value)
                      setDocumentShellName('')
                    }}
                    placeholder
                    isRequired
                  />
                </Field>
              )}
              {/* TODO: validation to check the new name doesn't match an existing document shell name */}
              {documentShellHierarchy === 'master' && (
                <Field label="Name contract" supplementary="Create a name for this new master contract" className="mt-6">
                  <Input
                    value={documentShellName}
                    onChange={({ target }) => setDocumentShellName(target.value)}
                    isRequired
                    isDisabled={isLoading || isSaving}
                  />
                </Field>
              )}
              {documentShellHierarchy === 'variation' && (
                <Field label="Master contract name" supplementary="Select the relevant master contract that this variates from" className="mt-6">
                  <Select_DEPRECATED<string>
                    value={documentShellName}
                    options={masterDocumentShellNameOptions}
                    onChange={(value) => setDocumentShellName(value)}
                    placeholder
                    isRequired
                  />
                </Field>
              )}
            </>
          )}
          {areDocumentsRequired && Boolean(documentShell?.attachments.length) && (
            <DocumentList
              documents={map(documentShell?.attachments, ({ document }) => document)}
              isLoading={isSaving}
              className="mt-6"
              isDisabled={isLoading || isSaving}
              onBulkRemove={handleRemove}
            />
          )}
          {user?.account?.organisation?._id && areDocumentsRequired && !documentShell?.attachments.length && (
            <NoDataPlaceholder
              label="No contract uploaded"
              ctaLabel="+ Upload"
              className="mt-6"
              isDisabled={isLoading || isSaving}
              onCtaClick={() => openModal(<ContractWizardStepDocumentUploadModal contractShellId={contractShellId} />)}
            />
          )}
        </div>
      </Card>
    </Form>
  )
})
