import React, { memo, useMemo, useState } from 'react'
import { map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type EvaluationChairConsensusSortKey = 'name' | 'progress' | 'avg-score' | 'group-score'

type Props = {
  className?: string
  tenderId: string
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const TenderResponseReportConsensusList = memo(({ tenderId, onView }: Props) => {
  const { tenderResponseReports, isLoading } = useListTenderResponseReport(tenderId)
  const { sortKey, sortDirection, onSort } = useSortTable<EvaluationChairConsensusSortKey>({ initialKey: 'name' })
  const [currentPage, setCurrentPage] = useState(1)
  const { sortedTenderResponseReports, pagination } = useMemo(() => {
    const result = tenderResponseReports.sort((a, b) => {
      if (sortKey === 'name') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'progress') {
        return sortService.sortNumber(
          a.consensusScoredQuestions / a.consensusUnscoredQuestions,
          b.consensusScoredQuestions / b.consensusUnscoredQuestions
        )
      } else if (sortKey === 'avg-score') {
        return sortService.sortNumber(a.avgScore, b.avgScore)
      } else if (sortKey === 'group-score') {
        return sortService.sortNumber(a.groupScore, b.groupScore)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: sortedTenderResponseReports, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { sortedTenderResponseReports, pagination }
  }, [tenderResponseReports, sortKey, sortDirection])

  if (!isLoading && !sortedTenderResponseReports.length) {
    return (
      <div className="flex items-center justify-center h-[calc(100%-64px)]">
        <NoDataPlaceholder label="Something has gone wrong, please contract support for assistance." />
      </div>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Supplier',
      onSort: () => onSort('name'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => <Text className="truncate">{tenderResponseReport.procurementResponse.supplier.name}</Text>,
        cta: (
          <Button onClick={onView && (() => onView(tenderResponseReport))} state="outline" variant="secondary" size="xs" isLink>
            View <Icon className="ml-1" icon="arrow-right" />
          </Button>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Progress',
      onSort: () => onSort('progress'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => {
          const total = tenderResponseReport.consensusUnscoredQuestions + tenderResponseReport.consensusScoredQuestions
          const isComplete = total === tenderResponseReport.consensusScoredQuestions

          return (
            <Badge variant={isComplete ? 'success' : 'neutral'} state="translucent">
              {isComplete ? 'Complete' : `${tenderResponseReport.consensusScoredQuestions}/${total}`}
            </Badge>
          )
        },
      })),
    },
    {
      heading: 'Average Score',
      onSort: () => onSort('avg-score'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => (
          <Text className="text-blue-500" variant="none">
            {tenderResponseReport.avgScore.toFixed(2)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Consensus Score',
      onSort: () => onSort('group-score'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => (
          <Text className="font-semibold bg-secondary-300 rounded px-2 py-0.5" variant="secondary" isInline>
            {tenderResponseReport.groupScore.toFixed(2)}
          </Text>
        ),
      })),
    },
  ]

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} pagination={pagination} onPageChange={setCurrentPage} />
})
