import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEventInput,
  GqlCreateEvaluationEventMutation,
  GqlCreateEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const mutateCreateEvaluationEvent = async (input: GqlCreateEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEventMutation, GqlCreateEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation CreateEvaluationEvent($input: CreateEvaluationEventInput!) {
        createEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEvent as GqlEvaluationEventFieldsFragment
}
