import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { MetricUnit } from '@cotiss/common/models/units.model'

export const METRIC_UNIT_OPTIONS: SelectOption_DEPRECATED<MetricUnit>[] = [
  // Generic
  { label: 'Number', value: 'number' },

  // Length
  { label: 'Kilometre', value: 'km' },
  { label: 'Metre', value: 'm' },
  { label: 'Centimetre', value: 'cm' },
  { label: 'Millimetre', value: 'mm' },

  // Mass
  { label: 'Kilogram', value: 'kg' },
  { label: 'Gram', value: 'g' },
  { label: 'Milligram', value: 'mg' },

  // Volume
  { label: 'Kilolitre', value: 'kl' },
  { label: 'Litre', value: 'l' },
  { label: 'Millilitre', value: 'ml' },
  { label: 'Metres Cubed', value: 'm3' },
  { label: 'Centimetres Cubed', value: 'cm3' },
  { label: 'Millimetres Cubed', value: 'mm3' },

  // Area
  { label: 'Kilometres Squared', value: 'km2' },
  { label: 'Metres Squared', value: 'm2' },
  { label: 'Centimetres Squared', value: 'cm2' },
  { label: 'Millimetres Square', value: 'mm2' },
  { label: 'Hectares', value: 'ha' },

  // Time
  { label: 'Year', value: 'y' },
  { label: 'Month', value: 'mth' },
  { label: 'Week', value: 'w' },
  { label: 'Day', value: 'd' },
  { label: 'Half-day', value: 'hd' },
  { label: 'Hour', value: 'h' },
  { label: 'Minute', value: 'min' },
  { label: 'Second', value: 's' },
  { label: 'Millisecond', value: 'ms' },
]

export const METRIC_UNIT_NAME_MAP: Record<MetricUnit, string> = {
  // Generic
  number: 'Number',

  // Length
  km: 'Kilometre',
  m: 'Metre',
  cm: 'Centimetre',
  mm: 'Millimetre',

  // Mass
  kg: 'Kilogram',
  g: 'Gram',
  mg: 'Milligram',

  // Volume
  kl: 'Kilolitre',
  l: 'Litre',
  ml: 'Millilitre',
  m3: 'Metres Cubed',
  cm3: 'Centimetres Cubed',
  mm3: 'Millimetres Cubed',

  // Area
  km2: 'Kilometres Squared',
  m2: 'Metres Squared',
  cm2: 'Centimetres Squared',
  mm2: 'Millimetres Square',
  ha: 'Hectares',

  // Time
  y: 'Year',
  mth: 'Month',
  w: 'Week',
  d: 'Day',
  hd: 'Half-day',
  h: 'Hour',
  min: 'Minute',
  s: 'Second',
  ms: 'Millisecond',
}
