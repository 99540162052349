import React, { ReactNode, HTMLProps, memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'

type Props = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode
  className?: string
  onSort?: () => void
}

export const Th_DEPRECATED = memo(({ className, children, onSort, ...rest }: Props) => {
  const classes = classNames(className, 'font-normal text-gray-400 text-left text-2xs whitespace-nowrap uppercase px-4 py-2 first:pl-4 last:pr-4')

  const renderContent = () => {
    if (onSort) {
      return (
        <Button className="flex items-center font-medium text-gray-400 text-left text-2xs whitespace-nowrap uppercase" onClick={onSort} state="raw">
          {children}
          <Icon className="ml-1.5" icon="chevron-selector-vertical" />
        </Button>
      )
    }

    return <>{children}</>
  }

  return (
    <th {...rest} className={classes}>
      {renderContent()}
    </th>
  )
})
