import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { includes, map } from 'lodash'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { EvaluationEventDuplicateModal } from '@cotiss/evaluation-event/modals/evaluation-event-duplicate.modal'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { EvaluationEventStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-status-badge.component'
import { EvaluationEventCreateModal } from '@cotiss/evaluation-event/modals/evaluation-event-create.modal'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  isArchived?: boolean
}

export const EvaluationEventList = memo(({ isArchived = false }: Props) => {
  const { track } = useAnalytics()
  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const canCreateEvaluationEvent = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])
  const { evaluationEvents, setEvaluationEvent, queryEvaluationEventList, mutateUpdateEvaluationEvent } = useEvaluationEvent()

  const handleQueryEvaluationEventList = async () => {
    try {
      setEvaluationEvent(null)

      const { pagination } = await queryEvaluationEventList({
        filter: {
          isArchived,
          isUserContext: true,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    track(isArchived ? 'evaluation_event_list_archived_view' : 'evaluation_event_list_active_view')
  }, [isArchived])

  useAsyncEffect(async () => {
    handleQueryEvaluationEventList()
  }, [currentPage, isArchived])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  const handleArchive = async (evaluationEventId: string, isArchived: boolean) => {
    track(isArchived ? 'evaluation_event_list_archive_submit' : 'evaluation_event_list_unarchive_submit')

    await mutateUpdateEvaluationEvent({ evaluationEventId, isArchived })
    await handleQueryEvaluationEventList()
  }

  const evaluationEventListTableHeader = (
    <TableHeader className="flex justify-between items-center" variant="white">
      <Text size="md" className="flex items-center gap-1">
        Results{' '}
        {isLoading ? (
          <Skeleton className="h-3 w-6 inline-block" />
        ) : (
          <Text isInline variant="light">
            ({pagination?.totalCount})
          </Text>
        )}
      </Text>
    </TableHeader>
  )

  const emptyState = isArchived ? (
    <NoDataPlaceholder variant="white" label="You have not been added to any archived evaluation events." />
  ) : (
    <NoDataPlaceholder
      variant="white"
      label="You have not been added to any evaluation events yet."
      ctaLabel={canCreateEvaluationEvent ? '+ New evaluation' : undefined}
      onCtaClick={canCreateEvaluationEvent ? () => openModal(<EvaluationEventCreateModal />) : undefined}
    />
  )

  if (!evaluationEvents.length && !isLoading) {
    return (
      <div>
        {evaluationEventListTableHeader}
        <div className="flex items-center justify-center h-60 bg-white rounded border">{emptyState}</div>
      </div>
    )
  }

  const columns: TableColumn[] = [
    {
      heading: 'Evaluation event',
      rows: map(evaluationEvents, (evaluationEvent) => ({
        content: () => (
          <Text className="font-medium truncate" title={evaluationEvent.name}>
            {evaluationEvent.name}
          </Text>
        ),
        cta: (
          <TableRowCta
            cta={
              !isArchived
                ? {
                    href: routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', {
                      evaluationEventId: evaluationEvent.id,
                    }),
                    label: (
                      <>
                        View <Icon className="ml-1" icon="arrow-right" />
                      </>
                    ),
                  }
                : undefined
            }
            actions={
              canCreateEvaluationEvent
                ? [
                    {
                      onClick: () => openModal(<EvaluationEventDuplicateModal evaluationEvent={evaluationEvent} />),
                      label: 'Duplicate',
                    },
                    {
                      onClick: () =>
                        openModal(
                          <ConfirmModal
                            heading={evaluationEvent.isArchived ? 'Unarchive' : 'Archive'}
                            description={`Are you sure you want to ${evaluationEvent.isArchived ? 'unarchive' : 'archive'} this evaluation event?`}
                            onSubmit={() => handleArchive(evaluationEvent.id, !evaluationEvent.isArchived)}
                          />
                        ),
                      label: evaluationEvent.isArchived ? 'Unarchive' : 'Archive',
                    },
                  ]
                : undefined
            }
          />
        ),
      })),
    },
    {
      heading: 'Status',
      rows: map(evaluationEvents, ({ status }) => ({
        content: () => <EvaluationEventStatusBadge status={status} size="sm" />,
      })),
    },
  ]

  return (
    <div>
      {evaluationEventListTableHeader}
      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </div>
  )
})
