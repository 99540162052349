import { gql } from '@apollo/client'
import { GqlApprovalTemplateGroupListInput, GqlApprovalTemplateGroupListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template/graphql/approval-template-fragment.graphql'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group/graphql/approval-template-group-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryApprovalTemplateGroupList = async (input: GqlApprovalTemplateGroupListInput) => {
  const { data } = await apolloService.query<GqlApprovalTemplateGroupListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      query ApprovalTemplateGroupList($input: ApprovalTemplateGroupListInput) {
        approvalTemplateGroupList(input: $input) {
          items {
            ...ApprovalTemplateGroupFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.approvalTemplateGroupList
}
