import { gql } from '@apollo/client'
import { GqlEvaluationEventResultsByCriteriaCsvQuery, GqlEvaluationEventViewInput } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const queryEvaluationEventResultsByCriteriaCsv = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventResultsByCriteriaCsvQuery>({
    query: gql`
      query EvaluationEventResultsByCriteriaCsv($input: EvaluationEventViewInput!) {
        evaluationEventResultsByCriteriaCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.evaluationEventResultsByCriteriaCsv
}
