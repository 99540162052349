import { gql } from '@apollo/client'
import {
  GqlUpdatePerformanceScorecardUserInput,
  GqlUpdatePerformanceScorecardUserMutation,
  GqlUpdatePerformanceScorecardUserMutationVariables,
  GqlPerformanceScorecardUserFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateUpdatePerformanceScorecardUser = async (input: GqlUpdatePerformanceScorecardUserInput) => {
  const { data } = await apolloService.mutate<GqlUpdatePerformanceScorecardUserMutation, GqlUpdatePerformanceScorecardUserMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      mutation UpdatePerformanceScorecardUser($input: UpdatePerformanceScorecardUserInput!) {
        updatePerformanceScorecardUser(input: $input) {
          ...PerformanceScorecardUserFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceScorecardUser as GqlPerformanceScorecardUserFieldsFragment
}
