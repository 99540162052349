import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricCycleViewInput, GqlPerformanceScorecardMetricCycleViewQuery } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle/performance-scorecard-metric-cycle-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardMetricCycleView = async (input: GqlPerformanceScorecardMetricCycleViewInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleViewQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      query PerformanceScorecardMetricCycleView($input: PerformanceScorecardMetricCycleViewInput!) {
        performanceScorecardMetricCycleView(input: $input) {
          ...PerformanceScorecardMetricCycleFields
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleView
}
