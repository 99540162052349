import React from 'react'
import { Text } from '@cotiss/common/components/text.component'
import { USER_PERMISSION_MAP, USER_ROLE_DESCRIPTION } from '@cotiss/user/user.constants'
import { UserPermission } from '@cotiss/user/user.models'

type Props = {
  permission: UserPermission
}

export const UserPermissionCard = ({ permission }: Props) => (
  <div className="bg-secondary-50 border border-secondary-200 rounded p-4">
    <Text className=" font-semibold">{USER_PERMISSION_MAP[permission]}</Text>
    <Text className="mt-1" size="sm" variant="light">
      {USER_ROLE_DESCRIPTION[permission]}
    </Text>
  </div>
)
