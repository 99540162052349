import React, { memo, useMemo } from 'react'
import { some } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ContractShellFilterPopulatedModel } from '@cotiss/contract/contract.model'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  contractShell: ContractShellFilterPopulatedModel
  userId?: string
  onSuccess: () => void
}

export const ContractListItemCta = memo(({ contractShell, userId, onSuccess }: Props) => {
  const { openModal } = useCallout()
  const { actionContractShell, deleteContractShell } = useMutateContractShell()

  const isContractOwner = useMemo(() => {
    if (!userId) {
      return false
    }

    return some(contractShell.contracts.metadata.owners, (owner) => owner._id === userId)
  }, [userId, contractShell])

  const handleArchiveContractSubmit = async () => {
    await actionContractShell({ contractShellId: contractShell._id, action: contractShell.archived ? 'unarchive' : 'archive' })
    onSuccess()
  }

  const handleDeleteContractSubmit = async () => {
    await deleteContractShell(contractShell._id)
    onSuccess()
  }

  return (
    <TableRowCta
      cta={
        !contractShell.archived
          ? {
              label: (
                <>
                  View <Icon className="ml-1" icon="arrow-right" />
                </>
              ),
              href: routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId: contractShell._id }),
            }
          : undefined
      }
      actions={[
        {
          label: contractShell.archived ? 'Unarchive' : 'Archive',
          onClick: () =>
            openModal(
              <ConfirmModal
                heading={contractShell.archived ? 'Unarchive contract' : 'Archive contract'}
                description={
                  contractShell.archived ? 'Are you sure you want to unarchive this contract?' : 'Are you sure you want to archive this contract?'
                }
                onSubmit={handleArchiveContractSubmit}
              />
            ),
        },
        ...(contractShell.archived && isContractOwner
          ? [
              {
                label: 'Delete',
                onClick: () =>
                  openModal(
                    <ConfirmModal
                      heading="Delete contract"
                      description="Are you sure you want to delete this contract? This action cannot be undone."
                      onSubmit={handleDeleteContractSubmit}
                    />
                  ),
              },
            ]
          : []),
      ]}
    />
  )
})
