import { gql } from '@apollo/client'
import { GqlEvaluationListInput, GqlEvaluationOverviewListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation/evaluation-overview-criteria-breakdown-fragment.graphql'
import { EVALUATION_OVERVIEW_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation/evaluation-overview-fragment.graphql'

export const queryEvaluationOverviewList = async (input: GqlEvaluationListInput) => {
  const { data } = await apolloService.query<GqlEvaluationOverviewListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_OVERVIEW_FRAGMENT}
      ${EVALUATION_OVERVIEW_CRITERIA_BREAKDOWN_FRAGMENT}

      query EvaluationOverviewList($input: EvaluationListInput!) {
        evaluationOverviewList(input: $input) {
          items {
            ...EvaluationOverviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationOverviewList
}
