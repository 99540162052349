import { AccessControlRole } from '@cotiss/access-control/access-control.models'

export const ACCESS_CONTROL_QUERY_KEYS = ['/v2/access-control'] as const

export const ACCESS_CONTROL_ROLE_DESCRIPTION: Record<AccessControlRole, string> = {
  'procurement:owner': 'Owner',
  'procurement:collaborator': 'Collaborator',
  'tender:chair': 'Chair',
  'tender:evaluator': 'Evaluator',
  'tender:approval:go-to-market': 'Go To Market Approval',
  'tender:approval:recommendation': 'Recommendation Approval',
}
