import React, { memo, useMemo, useState } from 'react'
import { map } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { ConflictOfInterestListItemCta } from '@cotiss/conflict-of-interest/components/conflict-of-interest-list-item-cta.component'
import { ConflictOfInterestOutcomeBadge } from '@cotiss/conflict-of-interest/components/conflict-of-interest-outcome-badge.component'
import { ConflictOfInterestStatusBadge } from '@cotiss/conflict-of-interest/components/conflict-of-interest-status-badge.component'
import { conflictOfInterestService } from '@cotiss/conflict-of-interest/conflict-of-interest.service'
import { useListConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-list-conflict-of-interest.resource'
import { userService } from '@cotiss/user/user.service'

type SortKey = 'recipient-name' | 'date-sent'

type Props = {
  procurementId?: string
  isAllConflicts?: boolean
}

export const ConflictOfInterestList = memo(({ procurementId, isAllConflicts }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { conflictOfInterests, isLoading } = useListConflictOfInterest({ procurementId, isAllConflicts })
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'recipient-name' })
  const { processedConflictOfInterests, pagination } = useMemo(() => {
    const result = conflictOfInterests.sort((a, b) => {
      if (sortKey === 'recipient-name') {
        return sortService.sortString(userService.getFullName(a.recipient) || '', userService.getFullName(a.recipient) || '')
      } else if (sortKey === 'date-sent') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedConflictOfInterests, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedConflictOfInterests, pagination }
  }, [conflictOfInterests, sortKey, sortDirection, currentPage])

  if (!isLoading && !processedConflictOfInterests.length) {
    return (
      <div className="flex items-center justify-center h-[calc(100%-64px)]">
        <NoDataPlaceholder label="No conflicts declarations have been sent" ctaLabel="No conflict declarations have been sent" />
      </div>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Name',
      onSort: () => onSort('recipient-name'),
      rows: map(processedConflictOfInterests, (conflictOfInterest) => ({
        content: () => <Text className="truncate">{userService.getFullName(conflictOfInterest.recipient)}</Text>,
        cta: <ConflictOfInterestListItemCta conflictOfInterest={conflictOfInterest} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Procurement',
      rows: map(processedConflictOfInterests, ({ procurement }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {procurement.title}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date sent',
      onSort: () => onSort('date-sent'),
      rows: map(processedConflictOfInterests, ({ createdAt }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(createdAt, 'do MMM yyyy')}
          </Text>
        ),
      })),
    },
    {
      heading: 'Status',
      rows: map(processedConflictOfInterests, (conflictOfInterest) => ({
        content: () => <ConflictOfInterestStatusBadge status={conflictOfInterestService.getConflictStatus(conflictOfInterest)} />,
      })),
    },
    {
      heading: 'Resolution',
      rows: map(processedConflictOfInterests, (conflictOfInterest) => ({
        content: () => <ConflictOfInterestOutcomeBadge outcome={conflictOfInterestService.getConflictOutcome(conflictOfInterest)} />,
      })),
    },
  ]

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
