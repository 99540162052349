import React, { forwardRef, HTMLProps, MouseEvent as ReactMouseEvent } from 'react'
import classNames from 'classnames'
import { includes } from 'lodash'
import { Link } from 'react-router-dom'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text, TextSize, TextVariant } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'

export type ButtonShape = 'rect' | 'square'
export type ButtonVariant = 'primary' | 'secondary' | 'secondary-dark' | 'tertiary' | TextVariant
export type ButtonState = 'filled' | 'outline' | 'ghost' | 'translucent' | 'text' | 'raw'
export type ButtonClickHandler = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state' | 'href' | 'size'> & {
  type?: 'button' | 'submit' | 'reset'
  size?: 'xs' | 'sm' | 'md' | 'lg' | TextSize
  variant?: ButtonVariant
  state?: ButtonState
  shape?: ButtonShape
  href?: string
  onClick?: ButtonClickHandler
  isRounded?: boolean
  isLoading?: boolean
  isTruncated?: boolean
  isLink?: boolean
  isOpenNewTab?: boolean
  isDisabled?: boolean
  isExternalLink?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className,
    onClick,
    href,
    size = 'md',
    shape = 'rect',
    type = 'button',
    state = 'filled',
    variant = 'primary',
    isRounded,
    isLoading,
    isLink,
    isOpenNewTab,
    isTruncated,
    isDisabled,
    isExternalLink,
    ...rest
  } = props
  let classes = classNames(className, 'text-left outline-none', {
    'pointer-events-none': isDisabled || isLoading,
    'opacity-50': isLoading,
    'truncate w-full': isTruncated,
  })

  if (!includes<ButtonState>(['text', 'raw'], state)) {
    classes = classNames(classes, 'text-sm', {
      'px-2': shape === 'rect' && includes(['xs', 'sm', 'md'], size),
      'px-6': shape === 'rect' && includes(['lg'], size),
      'h-6': size === 'xs',
      'h-8': size === 'sm',
      'h-10': size === 'md',
      'h-11': size === 'lg',
      'w-6': shape === 'square' && size === 'xs',
      'w-8': shape === 'square' && size === 'sm',
      'w-10': shape === 'square' && size === 'md',
      'w-11': shape === 'square' && size === 'lg',
      'rounded-full': isRounded,
      rounded: isRounded === undefined,
    })
  }

  if (state === 'filled') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center', {
      'bg-gray-200 text-gray-400 pointer-events-none': isDisabled && !isLoading,
      'bg-primary-500 text-white hover:bg-primary-800 focus:ring-2 focus:ring-primary-400': !isDisabled && variant === 'primary',
      'bg-secondary-500 text-white hover:bg-secondary-700 focus:ring-2 focus:ring-secondary-400': !isDisabled && variant === 'secondary',
      'bg-secondary-200 text-secondary-500 hover:bg-secondary-400 focus:ring-2 focus:ring-secondary-400': !isDisabled && variant === 'tertiary',
    })
  }

  if (state === 'translucent') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center', {
      'bg-gray-200 text-gray-400 pointer-events-none': isDisabled && !isLoading,
      'bg-secondary-100 text-secondary-500 hover:bg-secondary-300 focus:ring-2 focus:ring-secondary-400': !isDisabled && variant === 'secondary',
      'bg-red-100 text-red-500 hover:bg-red-300 focus:ring-2 focus:ring-red-400': !isDisabled && variant === 'danger',
      'bg-secondary-300 text-secondary-500 hover:bg-secondary-400 focus:ring-2 focus:ring-secondary-500': !isDisabled && variant === 'secondary-dark',
    })
  }

  if (state === 'outline') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center bg-white border', {
      'border-primary-500 text-primary-500': variant === 'primary' && !isDisabled,
      'border-secondary-500 text-secondary-500': variant === 'secondary' && !isDisabled,
      'border-gray-200 text-gray-400 pointer-events-none': isDisabled,
    })
  }

  if (state === 'text') {
    classes = classNames(classes, 'inline-block shrink-0 background-transparent')
  }

  if (state === 'ghost') {
    classes = classNames(classes, 'inline-flex items-center justify-center shrink-0', {
      'text-light': isDisabled,
      'text-secondary-500': !isDisabled && (variant === 'link' || variant === 'secondary'),
      'text-primary-900 hover:bg-primary-50': !isDisabled && variant === 'neutral',
    })
  }

  const renderContent = () => {
    if (state === 'text') {
      const textClasses = classNames({
        truncate: isTruncated,
        'text-gray-400 pointer-events-none': isDisabled,
      })

      return (
        <Text className={textClasses} variant={isDisabled ? 'none' : (variant as TextVariant)} size={size}>
          {children}
        </Text>
      )
    }

    return (
      <>
        {isLoading && <Spinner className="-ml-1 mr-2" colour="currentColor" />}
        {children}
      </>
    )
  }

  if (isExternalLink && href) {
    return (
      <a className={classes} href={utilService.getExternalHref(href)} target="_blank" rel="noopener noreferrer">
        {renderContent()}
      </a>
    )
  }

  if (isLink && href) {
    return (
      <Link className={classes} to={href} target={isOpenNewTab ? '_blank' : undefined}>
        {renderContent()}
      </Link>
    )
  }

  return (
    <button {...rest} className={classes} type={type} disabled={isDisabled} onClick={onClick as ButtonClickHandler} ref={ref}>
      {renderContent()}
    </button>
  )
})
