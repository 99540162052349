import React, { memo, useEffect, useMemo } from 'react'
import { includes } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationEventList } from '@cotiss/evaluation-event/components/evaluation-event-list.component'
import { EvaluationEventCreateModal } from '@cotiss/evaluation-event/modals/evaluation-event-create.modal'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type EvaluationEventListTab = 'archived' | 'active'

const EVALUATION_EVENT_LIST_TABS: TabModel<EvaluationEventListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archive' },
]

export const EvaluationEventListPage = memo(() => {
  const { openModal } = useCallout()
  const { track } = useAnalytics()
  const { user } = useGetLoggedInUser()
  const { tab } = useParams<{ tab?: EvaluationEventListTab }>()
  const { replace, push } = useHistory()

  useEffect(() => {
    track('evaluation_event_list_view')

    if (!tab) {
      replace(routerService.getHref('/evaluation-event/list/:tab?', { tab: 'active' }))
    }
  }, [tab])

  const canCreateEvaluationEvent = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])

  const handleTabChange = (newTab: EvaluationEventListTab) => {
    push(routerService.getHref('/evaluation-event/list/:tab?', { tab: newTab }))
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Evaluate
        </Text>
        {canCreateEvaluationEvent && (
          <Button onClick={() => openModal(<EvaluationEventCreateModal />)} size="md">
            + New evaluation
          </Button>
        )}
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <Tabs<EvaluationEventListTab> variant="raised" tab={tab} tabs={EVALUATION_EVENT_LIST_TABS} onChange={({ id }) => handleTabChange(id)} />
      </div>
      <PageContent>
        <EvaluationEventList isArchived={tab === 'archived'} />
      </PageContent>
    </Page>
  )
})
