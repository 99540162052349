import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationEventDocumentInput,
  GqlCreateEvaluationEventDocumentMutation,
  GqlCreateEvaluationEventDocumentMutationVariables,
  GqlEvaluationEventDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event-document/evaluation-event-document-fragment.graphql'

export const mutateCreateEvaluationEventDocument = async (input: GqlCreateEvaluationEventDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreateEvaluationEventDocumentMutation, GqlCreateEvaluationEventDocumentMutationVariables>({
    mutation: gql`
      ${EVALUATION_EVENT_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationEventDocument($input: CreateEvaluationEventDocumentInput!) {
        createEvaluationEventDocument(input: $input) {
          ...EvaluationEventDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationEventDocument as GqlEvaluationEventDocumentFieldsFragment
}
