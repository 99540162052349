import React, { memo } from 'react'
import { Doc, Heading, MarkMap, RemirrorRenderer, TextHandler, ThemeProvider, createLinkHandler } from '@remirror/react'
import { AllStyledComponent } from '@remirror/styles/emotion'
import { RemirrorJSON } from 'remirror'
import { Text, TextSize, TextVariant } from '@cotiss/common/components/text.component'
import { richTextService } from '@cotiss/common/services/rich-text.service'

const typeMap: MarkMap = {
  bulletList: 'ul',
  doc: Doc,
  hardBreak: 'br',
  heading: Heading,
  horizontalRule: 'hr',
  listItem: 'li',
  paragraph: 'p',
  orderedList: 'ol',
  text: TextHandler,
}

const markMap: MarkMap = {
  italic: 'em',
  bold: 'strong',
  link: createLinkHandler({
    target: '_blank',
  }),
  underline: 'u',
}

type Props = {
  className?: string
  value?: RemirrorJSON
  variant?: TextVariant
  size?: TextSize
  emptyValue?: string
}

// TODO: These is a bug with the renderer using unrecognized properties in a react environment. E.g. `class` and `ignoreBidiAutoUpdate`. Causing
// TODO: console warnings to be thrown.

export const RichText = memo(({ className, value, variant, size, emptyValue = '--' }: Props) => {
  if (!value) {
    return null
  }

  return (
    <AllStyledComponent>
      <ThemeProvider
        theme={{
          fontFamily: { heading: 'Plus Jakarta Sans, sans-serif', default: 'Inter, sans-serif' },
        }}
      >
        <Text className={className} variant={variant} size={size}>
          {richTextService.isEmpty(value) ? emptyValue : <RemirrorRenderer json={value} typeMap={typeMap} markMap={markMap} />}
        </Text>
      </ThemeProvider>
    </AllStyledComponent>
  )
})
