import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { TRANSITION_DURATION } from '@cotiss/common/constants/transition.constants'

type Props = {
  className?: string
  total: number
  completed: number
  size?: 'sm' | 'md'
  isFakeLoader?: boolean
}

export const ProgressBar = memo(({ className, total, completed, size = 'md', isFakeLoader }: Props) => {
  const width = useMemo(() => (completed / total) * 100, [total, completed])
  const classes = classNames(className, 'relative bg-secondary-200 rounded-full overflow-hidden', {
    'h-1': size === 'sm',
    'h-2': size === 'md',
  })

  return (
    <div className={classes}>
      <AnimatePresence>
        <motion.div
          className="bg-secondary-500 rounded-full h-full transition duration-500 ease-in-out"
          initial={{ width: 0 }}
          animate={{ width: width ? `${width}%` : '8px' }}
          transition={{ duration: isFakeLoader ? 5 : TRANSITION_DURATION.lg, delay: TRANSITION_DURATION.sm }}
        />
      </AnimatePresence>
    </div>
  )
})
