import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { ApprovalModel, ApprovalType } from '@cotiss/approval/approval.models'

type Props = {
  limit?: number
  offset?: number
  tenderId?: string
  userId?: string
  approvalType?: ApprovalType
  invalidated?: boolean
}

export const useListApproval = ({ offset = 0, limit = -1, ...params }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<ApprovalModel>>('/v2/approvals', {
    route: `/v2/approvals`,
    params: { ...params, offset, limit },
    isEnabled: Boolean(params.userId) || Boolean(params.tenderId),
  })

  return { approvals: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
