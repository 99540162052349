import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'

type UpdateRecommendationBody = {
  recommendation?: RemirrorJSON
  shortListComments?: RemirrorJSON
}

type GenerateRecommendationReportBody = {
  tenderId: string
}

export const useMutateRecommendation = () => {
  const { mutate } = useMutate()

  return {
    updateRecommendation: async (id: string, body: UpdateRecommendationBody) => {
      await mutate({ route: `/v2/recommendations/${id}`, body, invalidate: ['/v2/recommendations', '/v2/procurements'] })
    },
    generateRecommendationReport: async (body: GenerateRecommendationReportBody) => {
      await mutate({ route: `/v2/reports/recommendation-report`, body })
    },
  }
}
