import { gql } from '@apollo/client'
import { GqlPerformanceScorecardMetricCycleListInput, GqlPerformanceScorecardMetricCycleListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle/performance-scorecard-metric-cycle-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardMetricCycleList = async (input: GqlPerformanceScorecardMetricCycleListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardMetricCycleListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      query PerformanceScorecardMetricCycleList($input: PerformanceScorecardMetricCycleListInput!) {
        performanceScorecardMetricCycleList(input: $input) {
          items {
            ...PerformanceScorecardMetricCycleFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardMetricCycleList
}
