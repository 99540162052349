import { gql } from '@apollo/client'
import { GqlEvaluationUserEnvelopeListQuery, GqlEvaluationUserEnvelopeListInput } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_USER_ENVELOPE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-user/evaluation-user-envelope-fragment.graphql'

export const queryEvaluationUserEnvelopeList = async (input: GqlEvaluationUserEnvelopeListInput) => {
  const { data } = await apolloService.query<GqlEvaluationUserEnvelopeListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_USER_ENVELOPE_FRAGMENT}

      query EvaluationUserEnvelopeList($input: EvaluationUserEnvelopeListInput!) {
        evaluationUserEnvelopeList(input: $input) {
          items {
            ...EvaluationUserEnvelopeFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationUserEnvelopeList
}
