import { RemirrorJSON } from 'remirror'
import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { ForumQuestionModel } from '@cotiss/forum/forum.models'

type CreateForumQuestionBody = {
  tenderId: string
  content: RemirrorJSON
  attachments?: string[]
  isProtected: boolean
}

type UpdateForumQuestionBody = {
  content?: RemirrorJSON
  attachments?: string[]
  response?: RemirrorJSON
  responseAttachments?: string[]
  isProtected?: boolean
  pinned?: boolean
}

export const useMutateForum = () => {
  const { mutate } = useMutate()

  return {
    createForumQuestion: async (body: CreateForumQuestionBody) => {
      await mutate<ForumQuestionModel>({ route: `/v2/forum-questions`, body })
    },
    updateForumQuestion: async (id: string, body: UpdateForumQuestionBody) => {
      await mutate<ForumQuestionModel>({ route: `/v2/forum-questions/${id}`, body, invalidate: ['/v2/forum-questions'] })
    },
    deleteForumQuestion: async (id: string) => {
      await mutate<ForumQuestionModel>({ route: `/v2/forum-questions/${id}`, method: 'DELETE', invalidate: ['/v2/forum-questions'] })
    },
  }
}
