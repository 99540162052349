import React, { memo } from 'react'
import { isBefore, parseISO } from 'date-fns'
import { map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ForumQuestionSkeleton } from '@cotiss/forum/components/forum-question-skeleton.component'
import { ForumQuestion } from '@cotiss/forum/components/forum-question.component'
import { useListForumQuestion } from '@cotiss/forum/resources/use-list-forum-questions.resource'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  className?: string
  tenderId: string
}

export const TenderResponseForumQuestions = memo(({ className, tenderId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(tender?.procurement)
  const { forumQuestions, isLoading: isForumQuestionsLoading } = useListForumQuestion({ tenderId })
  const canRespond = Boolean(procurement?.procuringEntity === user?.account.organisation?._id)
  const isLoading = isTenderLoading || isProcurementLoading || isForumQuestionsLoading
  const isForumClosed = Boolean(tender?.forumCloseDate?.endDate && isBefore(parseISO(tender.forumCloseDate.endDate), new Date()))

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {map(Array(3), (_, index) => (
            <ForumQuestionSkeleton key={index} className="mt-4" />
          ))}
        </>
      )
    }

    return (
      <>
        {!forumQuestions.length && (
          <div className="bg-gray-200 rounded-lg p-4">
            <Text className="font-medium text-center">No questions have been asked yet</Text>
            <Text className="text-center mt-1" variant="light">
              Questions from all suppliers will display here.
            </Text>
          </div>
        )}
        {Boolean(forumQuestions.length) && (
          <>
            {map(forumQuestions, (forumQuestion) => (
              <ForumQuestion key={forumQuestion._id} className="mt-4" forumQuestion={forumQuestion} canRespond={canRespond} variant="white" />
            ))}
          </>
        )}
      </>
    )
  }

  return (
    <div className={className}>
      <div className="min-h-[2rem] mb-4 flex items-center">
        <Text className="uppercase mr-4" variant="light">
          Questions and answers ({forumQuestions.length})
        </Text>
        {isForumClosed && (
          <Badge state="outline" variant="neutral">
            Closed
          </Badge>
        )}
        {!isForumClosed && tender?.forumCloseDate?.endDate && (
          <Badge state="outline" variant="secondary">
            Closes: {datetimeService.format(tender?.forumCloseDate.endDate, 'do MMM yyyy')}
          </Badge>
        )}
      </div>
      {renderContent()}
    </div>
  )
})
