import React, { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { FormHiddenInput } from '@cotiss/common/components/form-hidden-input.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { accountService } from '@cotiss/account/account.service'
import { SignUpBuyerFormData } from '@cotiss/auth/pages/sign-up-buyer.page'

type Props = {
  formData: SignUpBuyerFormData
  transition: TransitionType
  setFormData: (formData: SignUpBuyerFormData) => void
  isDisabled?: boolean
}

export const SignUpBuyerStepPassword = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label className="mt-6">Choose password</Label>
    <Input
      value={formData.password}
      placeholder="Enter your password"
      onChange={({ target }) => setFormData({ ...formData, password: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Confirm password</Label>
    <Input
      value={formData.passwordConfirm}
      placeholder="Confirm your password"
      onChange={({ target }) => setFormData({ ...formData, passwordConfirm: target.value })}
      type="password"
      autoComplete="new-password"
      isDisabled={isDisabled}
      isRequired
    />
    <Button
      className="mt-6 w-full"
      type="submit"
      isLoading={isDisabled}
      isDisabled={!formData.password || formData.password !== formData.passwordConfirm}
    >
      Join now
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.firstname} name="First name" />
    <FormHiddenInput value={formData.surname} name="Last name" />
    <FormHiddenInput value={formData.phone} name="Phone number" />
    <FormHiddenInput value={formData.companyName} name="Company name" />
    <FormHiddenInput value={accountService.formatLocation(formData.location)} name="Address" />
  </TransitionContainer>
))
