import { gql } from '@apollo/client'
import { GqlEvaluationScoreListInput, GqlEvaluationScoreListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SCORE_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-score/evaluation-score-fragment.graphql'

export const queryEvaluationScoreList = async (input: GqlEvaluationScoreListInput) => {
  const { data } = await apolloService.query<GqlEvaluationScoreListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_SCORE_FRAGMENT}

      query EvaluationScoreList($input: EvaluationScoreListInput!) {
        evaluationScoreList(input: $input) {
          items {
            ...EvaluationScoreFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationScoreList
}
