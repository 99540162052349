import { isArray } from 'lodash'
import { Filter } from '@cotiss/common/models/filter.model'
import { utilService } from '@cotiss/common/services/util.service'

class FilterService {
  getFiltersFromUrl = (): Filter[] => {
    try {
      const { filters } = utilService.getUrlSearchParams({ params: ['filters'] })

      if (!filters) {
        return []
      }

      const parsedFilters = JSON.parse(filters)
      const filtersArray = isArray(parsedFilters) ? parsedFilters : []

      // Transform filters where value is an object with a property 'value'
      const transformedFilters = filtersArray.map((filter) => {
        return isArray(filter.value) ? { ...filter, value: filter.value.map((value: any) => value?.value ?? value) } : filter
      })

      return transformedFilters
    } catch (error: any) {
      // This will throw if there are no filters in the URL, or the filters are in the incorrect format.
      return []
    }
  }
}

export const filterService = new FilterService()
