import React, { MouseEvent, ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
// TODO: Remove this in favour of <Badge>

type Props = {
  className?: string
  children: ReactNode
  onRemove?: () => void
  isDisabled?: boolean
}

export const Pill = memo(({ className, children, onRemove, isDisabled }: Props) => {
  const classes = classNames(className, 'inline-flex items-center justify-center bg-secondary-200 text-xs text-secondary-500 rounded-full px-3 py-1')

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    onRemove && onRemove()
  }

  return (
    <div className={classes}>
      {children}
      {onRemove && (
        <Button onClick={handleRemove} state="text" isDisabled={isDisabled}>
          <Icon icon="x-close" variant="link" size={16} />
        </Button>
      )}
    </div>
  )
})
