import React, { FormEvent, memo, useState } from 'react'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useMutateConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-mutate-conflict-of-interest.resource'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'

type FormData = {
  userId?: string
}

export const ConflictOfInterestCreateNewModal = memo(({ procurementId }: { procurementId: string }) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createConflictOfInterest } = useMutateConflictOfInterest()
  const [formData, setFormData] = useState<FormData>({ userId: undefined })
  const { users } = useListUser()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      formData.userId && (await createConflictOfInterest({ procurementId, userId: formData.userId }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Send conflict of interest declaration" isDisabled={isSaving} />
      <ModalContent>
        <Label>Select user</Label>
        <Select_DEPRECATED<string>
          value={formData.userId}
          options={users.map((user) => ({ value: user._id, label: `${user.firstname} ${user.surname}` }))}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving}
          placeholder
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={!formData.userId} isForm />
    </Form>
  )
})
