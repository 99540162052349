import React, { ReactNode, HTMLProps, memo } from 'react'
import classNames from 'classnames'

export type TrVariant_DEPRECATED = 'default' | 'white'
type Props = Omit<HTMLProps<HTMLTableRowElement>, 'onClick'> & {
  children: ReactNode
  className?: string
  variant?: TrVariant_DEPRECATED
}

export const Tr_DEPRECATED = memo(({ className, children, variant = 'default', ...rest }: Props) => {
  const classes = classNames(className, {
    'bg-primary-50 rounded-lg': variant === 'default',
    'bg-white rounded-lg shadow-sm': variant === 'white',
  })

  return (
    <tr className={classes} {...rest}>
      {children}
    </tr>
  )
})
