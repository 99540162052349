import { gql } from '@apollo/client'
import {
  GqlApprovalTemplateGroupFieldsFragment,
  GqlUpdateApprovalTemplateGroupInput,
  GqlUpdateApprovalTemplateGroupMutation,
  GqlUpdateApprovalTemplateGroupMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { APPROVAL_TEMPLATE_FRAGMENT } from '@cotiss/approval-template/graphql/approval-template-fragment.graphql'
import { APPROVAL_TEMPLATE_GROUP_FRAGMENT } from '@cotiss/approval-template-group/graphql/approval-template-group-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const mutateUpdateApprovalTemplateGroup = async (input: GqlUpdateApprovalTemplateGroupInput) => {
  const { data } = await apolloService.mutate<GqlUpdateApprovalTemplateGroupMutation, GqlUpdateApprovalTemplateGroupMutationVariables>({
    mutation: gql`
      ${USER_FRAGMENT}
      ${APPROVAL_TEMPLATE_FRAGMENT}
      ${APPROVAL_TEMPLATE_GROUP_FRAGMENT}

      mutation UpdateApprovalTemplateGroup($input: UpdateApprovalTemplateGroupInput!) {
        updateApprovalTemplateGroup(input: $input) {
          ...ApprovalTemplateGroupFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateApprovalTemplateGroup as GqlApprovalTemplateGroupFieldsFragment
}
