import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { ProcurementPopulatedModel } from '@cotiss/procurement/procurement.models'

export const useGetProcurement = (id?: string) => {
  const { data: procurement, ...rest } = useFetch<ProcurementPopulatedModel>('/v2/procurements', {
    route: `/v2/procurements/${id}`,
    isEnabled: Boolean(id),
  })

  return { procurement, ...rest }
}
