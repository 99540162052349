import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { UserModel, UserPermission } from '@cotiss/user/user.models'

type Props = {
  permissions?: UserPermission[]
}

export const useListUser = ({ permissions }: Props = {}) => {
  const { data: users = [], ...rest } = useFetch<UserModel[]>('/v2/users', { params: { permissions } })

  return { users, ...rest }
}
