import React, { memo, useEffect, useMemo } from 'react'
import { map, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { routerService } from '@cotiss/common/services/router.service'
import { ProcurementViewHeader } from '@cotiss/procurement/components/procurement-view-header.component'
import { ProcurementResponseUserAddModal } from '@cotiss/procurement-response/modals/procurement-response-user-add.modal'
import { useGetProcurementResponse } from '@cotiss/procurement-response/resources/use-get-procurement-response.resource'
import { useMutateProcurementResponseUser } from '@cotiss/procurement-response-user/resources/use-mutate-procurement-response-user.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { userService } from '@cotiss/user/user.service'

export const ProcurementResponseViewProcurementResponseTeamPage = memo(() => {
  const { push, replace } = useHistory()
  const { openModal } = useCallout()
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const { procurementResponseId } = useParams<{ procurementResponseId: string }>()
  const { procurementResponse, isLoading: isLoadingProcurementResponse } = useGetProcurementResponse(procurementResponseId)
  const { deleteProcurementResponseUser } = useMutateProcurementResponseUser()
  const isLoading = isLoggedInUserLoading || isLoadingProcurementResponse

  const isUserInProcurementResponse = useMemo(() => {
    if (!procurementResponse || !loggedInUser) {
      return false
    }

    return some(procurementResponse.procurementResponseUsers, ({ user }) => loggedInUser._id === user._id)
  }, [procurementResponse, loggedInUser])

  useEffect(() => {
    if (!isLoading && !isUserInProcurementResponse) {
      replace(
        procurementResponse
          ? routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId: procurementResponse.procurement._id })
          : routerService.getHref('/procurement-response/list')
      )
    }
  }, [isLoading, isUserInProcurementResponse, procurementResponse])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Name',
        rows: map(procurementResponse?.procurementResponseUsers, ({ _id: procurementResponseUserId, user }) => ({
          content: () => <Text className="truncate">{userService.getFullName(user)}</Text>,
          cta:
            loggedInUser?._id === user._id ? (
              <Icon icon="lock" variant="light" size={20} />
            ) : (
              <TableRowCta
                actions={[
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete team member"
                          description="Are you sure you want to remove this team member from this procurement response?"
                          onSubmit={() => deleteProcurementResponseUser(procurementResponseUserId)}
                        />
                      ),
                  },
                ]}
              />
            ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Email',
        rows: map(procurementResponse?.procurementResponseUsers, ({ user }) => ({
          content: () => <Text>{user.email}</Text>,
        })),
      },
      {
        heading: 'Date added',
        rows: map(procurementResponse?.procurementResponseUsers, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [loggedInUser, procurementResponse])

  return (
    <Page>
      <ProcurementViewHeader
        activeTab="procurement-team"
        procurementId={procurementResponse?.procurement._id}
        procurementResponseId={procurementResponse?._id}
      />
      <PageContent className="w-full">
        <TableHeader variant="white">
          <div className="flex justify-between items-center">
            <div>
              <Text className="font-semibold" size="h7">
                Team
              </Text>
              <Text size="sm" variant="light">
                Users must be added to your organisation in your
                <Button
                  className="ml-1"
                  state="text"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
                  }
                >
                  Settings.
                </Button>
              </Text>
            </div>

            <Button
              size="sm"
              variant="secondary"
              onClick={
                procurementResponse
                  ? () => openModal(<ProcurementResponseUserAddModal procurementResponseId={procurementResponse?._id} />)
                  : undefined
              }
              isDisabled={isLoading}
            >
              <Icon icon="plus-01" />
              Add team member
            </Button>
          </div>
        </TableHeader>
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
      </PageContent>
    </Page>
  )
})
