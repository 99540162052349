import { GqlCreateEvaluationAccessControlsInput, GqlDeleteEvaluationAccessControlsInput } from '@gql'
import { mutateCreateEvaluationAccessControls } from '@cotiss/evaluation-event/graphql/evaluation-access-control/mutate-create-evaluation-access-controls.graphql'
import { mutateDeleteEvaluationAccessControls } from '@cotiss/evaluation-event/graphql/evaluation-access-control/mutate-delete-evaluation-access-controls.graphql'

export const useEvaluationAccessControl = () => {
  return {
    mutateCreateEvaluationAccessControls: async (input: GqlCreateEvaluationAccessControlsInput) => {
      await mutateCreateEvaluationAccessControls(input)
    },
    mutateDeleteEvaluationAccessControls: async (input: GqlDeleteEvaluationAccessControlsInput) => {
      await mutateDeleteEvaluationAccessControls(input)
    },
  }
}
