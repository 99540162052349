import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardUserInput,
  GqlCreatePerformanceScorecardUsersInput,
  GqlPerformanceScorecardUserFieldsFragment,
  GqlPerformanceScorecardUserInSessionViewInput,
  GqlPerformanceScorecardUserListInput,
  GqlPerformanceScorecardUserViewInput,
  GqlUpdatePerformanceScorecardUserInput,
} from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecardUser } from '@cotiss/performance/graphql/performance-scorecard-user/mutate-create-performance-scorecard-user.graphql'
import { mutateCreatePerformanceScorecardUsers } from '@cotiss/performance/graphql/performance-scorecard-user/mutate-create-performance-scorecard-users.graphql'
import { mutateUpdatePerformanceScorecardUser } from '@cotiss/performance/graphql/performance-scorecard-user/mutate-update-performance-scorecard-user.graphql'
import { queryPerformanceScorecardUserInSessionView } from '@cotiss/performance/graphql/performance-scorecard-user/query-performance-scorecard-user-in-session-view.graphql'
import { queryPerformanceScorecardUserList } from '@cotiss/performance/graphql/performance-scorecard-user/query-performance-scorecard-user-list.graphql'
import { queryPerformanceScorecardUserView } from '@cotiss/performance/graphql/performance-scorecard-user/query-performance-scorecard-user-view.graphql'

const performanceScorecardUserVar = makeVar<GqlPerformanceScorecardUserFieldsFragment | null>(null)
const performanceScorecardUserInSessionVar = makeVar<GqlPerformanceScorecardUserFieldsFragment | null>(null)
const performanceScorecardUsersVar = makeVar<GqlPerformanceScorecardUserFieldsFragment[]>([])

export const usePerformanceScorecardUser = () => {
  const performanceScorecardUser = useReactiveVar(performanceScorecardUserVar)
  const performanceScorecardUserInSession = useReactiveVar(performanceScorecardUserInSessionVar)
  const performanceScorecardUsers = useReactiveVar(performanceScorecardUsersVar)

  return {
    performanceScorecardUser,
    performanceScorecardUserInSession,
    performanceScorecardUsers,
    setPerformanceScorecardUser: performanceScorecardUserVar,
    setPerformanceScorecardUserInSession: performanceScorecardUserInSessionVar,
    setPerformanceScorecardUsers: performanceScorecardUsersVar,
    queryPerformanceScorecardUserList: async (input: GqlPerformanceScorecardUserListInput) => {
      const { items, pagination } = await queryPerformanceScorecardUserList(input)

      performanceScorecardUsersVar(items)

      return { items, pagination }
    },
    queryPerformanceScorecardUserView: async (input: GqlPerformanceScorecardUserViewInput) => {
      const performanceScorecardUser = await queryPerformanceScorecardUserView(input)

      performanceScorecardUserVar(performanceScorecardUser)
      performanceScorecardUsersVar(uniqBy([performanceScorecardUser, ...performanceScorecardUsers], 'id'))

      return performanceScorecardUser
    },
    queryPerformanceScorecardUserInSessionView: async (input: GqlPerformanceScorecardUserInSessionViewInput) => {
      const performanceScorecardUserInSession = await queryPerformanceScorecardUserInSessionView(input)

      performanceScorecardUserInSessionVar(performanceScorecardUserInSession)
      performanceScorecardUsersVar(uniqBy([performanceScorecardUserInSession, ...performanceScorecardUsers], 'id'))

      return performanceScorecardUserInSession
    },
    mutateCreatePerformanceScorecardUser: async (input: GqlCreatePerformanceScorecardUserInput) => {
      const createdPerformanceScorecardUser = await mutateCreatePerformanceScorecardUser(input)

      performanceScorecardUserVar(createdPerformanceScorecardUser)
      performanceScorecardUsersVar(uniqBy([createdPerformanceScorecardUser, ...performanceScorecardUsers], 'id'))

      return createdPerformanceScorecardUser
    },
    mutateCreatePerformanceScorecardUsers: async (input: GqlCreatePerformanceScorecardUsersInput) => {
      const createdPerformanceScorecardUsers = await mutateCreatePerformanceScorecardUsers(input)

      performanceScorecardUsersVar(uniqBy([...createdPerformanceScorecardUsers, ...performanceScorecardUsers], 'id'))

      return createdPerformanceScorecardUsers
    },
    mutateUpdatePerformanceScorecardUser: async (input: GqlUpdatePerformanceScorecardUserInput) => {
      const updatedPerformanceScorecardUser = await mutateUpdatePerformanceScorecardUser(input)

      performanceScorecardUsersVar(uniqBy([updatedPerformanceScorecardUser, ...performanceScorecardUsers], 'id'))

      return updatedPerformanceScorecardUser
    },
  }
}

export const clearReactivePerformanceScorecardUser = async () => {
  performanceScorecardUserVar(null)
  performanceScorecardUserInSessionVar(null)
  performanceScorecardUsersVar([])
}
