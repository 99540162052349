import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text } from '@cotiss/common/components/text.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ContractReminderList } from '@cotiss/contract/components/contract-reminder-list.component'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractReminderAddModal } from '@cotiss/contract/modals/contract-reminder-add.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

export const ContractViewRemindersTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Reminders
            </Text>
          </div>
        </CardHeader>
        <div className="flex items-center h-36">
          <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        </div>
      </Card>
    )
  }

  if (!contract || !(contract.status === 'PUBLISHED' || contract.status === 'CEASED')) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div>
            {isLoading && <Skeleton className="h-2 w-1/3 mb-1" />}
            {!isLoading && (
              <Text className="mb-1" size="sm" variant="light">
                {contractShell?.title || ''}
              </Text>
            )}
            <Text className="font-semibold" size="h5">
              Reminders
            </Text>
          </div>
        </div>
        <div>
          <ContractStatusBadge className="mr-4" status={contract.status} />
          <Button variant="secondary" size="sm" onClick={() => openModal(<ContractReminderAddModal contractShellId={contractShellId} />)}>
            + Add reminder
          </Button>
        </div>
      </CardHeader>
      <ContractReminderList contractShellId={contractShellId} isDisabled={contract.status === 'CEASED'} />
    </Card>
  )
})
