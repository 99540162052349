import { read, utils } from 'xlsx'
import { find, forEach, indexOf, map, toLower } from 'lodash'
import { METRIC_UNIT_OPTIONS } from '@cotiss/common/constants/units.constants'
import { MetricUnit } from '@cotiss/common/models/units.model'

type PriceItemsResult = {
  description: string
  category: string
  unit: MetricUnit
  quantity: number
}

class PriceItemService {
  handlePriceItemCsvUpload = async (): Promise<PriceItemsResult[]> => {
    return new Promise((resolve, reject) => {
      try {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.csv,.xlsx'
        fileInput.addEventListener('change', async (event) => {
          const target = event.target as HTMLInputElement
          const file = target.files?.[0]

          if (!file) {
            return reject(new Error('Unexpected error'))
          }

          const reader = new FileReader()
          reader.onload = ({ target }) => {
            const data = new Uint8Array(target?.result as ArrayBuffer)
            const workBook = read(data, { type: 'array' })
            const workSheet = workBook.Sheets[workBook.SheetNames[0]]
            const csvData = utils.sheet_to_json(workSheet, { header: 1 })

            // Extract the fields from the CSV data
            const [headerRow, ...rows] = csvData as string[][]
            const descriptionIndex = indexOf(map(headerRow, toLower), 'description')
            const categoryIndex = indexOf(map(headerRow, toLower), 'category')
            const unitIndex = indexOf(map(headerRow, toLower), 'unit')
            const quantityIndex = indexOf(map(headerRow, toLower), 'quantity')

            // Populate the form with the CSV data
            const priceItems: PriceItemsResult[] = []
            forEach(rows, (row) => {
              const description = row[descriptionIndex]
              const category = row[categoryIndex]
              const unit = toLower(row[unitIndex])
              const quantity = row[quantityIndex]

              if (description && category && quantity) {
                priceItems.push({
                  description,
                  category,
                  unit: find(METRIC_UNIT_OPTIONS, ({ value }) => value === unit)?.value || 'number',
                  quantity: Number(quantity) || 0,
                })
              }

              resolve(priceItems)
            })
          }

          reader.readAsArrayBuffer(file)
        })

        fileInput.click()
      } catch (error: any) {
        reject(error)
      }
    })
  }
}

export const priceItemService = new PriceItemService()
