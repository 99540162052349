import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type Props = {
  limit?: number
  offset?: number
  resourceId?: string
  resourceIds?: string[]
  isEnabled?: boolean
}

export const useListMetafieldValue = ({ offset = 0, limit = -1, isEnabled = true, ...params }: Props = {}) => {
  const { data, ...rest } = useFetch<PaginatedModel<MetafieldValueModel>>('/v2/metafield-values', {
    route: '/v2/metafield-values',
    params: { ...params, offset, limit },
    isEnabled: Boolean(isEnabled && (params.resourceId || params.resourceIds?.length)),
  })

  return { metafieldValues: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
