import React, { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { EvaluationEventViewTeamPanelTab } from '@cotiss/evaluation-event/tabs/evaluation-event-view-team-panel.tab'
import { EvaluationEventViewTeamScoringTab } from '@cotiss/evaluation-event/tabs/evaluation-event-view-team-scoring.tab'
import { EvaluationEventViewTeamUsersTab } from '@cotiss/evaluation-event/tabs/evaluation-event-view-team-users.tab'

type EvaluationEventViewTeamTab = 'panel' | 'scoring' | 'owners'
const EVALUATION_EVENT_VIEW_TEAM_TABS: VerticalTabModel<EvaluationEventViewTeamTab>[] = [
  { id: 'panel', label: 'Panel' },
  { id: 'scoring', label: 'Scoring' },
  { id: 'owners', label: 'Owners' },
]

export const EvaluationEventViewTeamTab = memo(() => {
  const { replace, push } = useHistory()
  const { track } = useEvaluationEventAnalytics()
  const { step, transition, onTransition } = useTransition()
  const { evaluationEventId, tab, nestedTab } = useParams<{ evaluationEventId: string; tab?: string; nestedTab?: EvaluationEventViewTeamTab }>()

  useEffect(() => {
    track('evaluation_event_view_team_tab_view')
  }, [])

  useEffect(() => {
    if (!nestedTab) {
      replace(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', {
          evaluationEventId,
          tab,
          nestedTab: EVALUATION_EVENT_VIEW_TEAM_TABS[0].id,
        })
      )
    }

    const newStep = findIndex(EVALUATION_EVENT_VIEW_TEAM_TABS, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [nestedTab])

  return (
    <div className="flex items-start">
      <VerticalTabs<EvaluationEventViewTeamTab>
        className="sticky top-28 mr-8"
        tab={nestedTab}
        tabs={EVALUATION_EVENT_VIEW_TEAM_TABS}
        onChange={({ id: nestedTab }) =>
          push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab, nestedTab }))
        }
      />

      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="w-full" transition={transition}>
          {step === 1 && <EvaluationEventViewTeamPanelTab />}
          {step === 2 && <EvaluationEventViewTeamScoringTab />}
          {step === 3 && <EvaluationEventViewTeamUsersTab />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
