import { gql } from '@apollo/client'
import { GqlEvaluationEventResultsBySubCriteriaCsvQuery, GqlEvaluationEventViewInput } from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const queryEvaluationEventResultsBySubCriteriaCsv = async (input: GqlEvaluationEventViewInput) => {
  const { data } = await apolloService.query<GqlEvaluationEventResultsBySubCriteriaCsvQuery>({
    query: gql`
      query EvaluationEventResultsBySubCriteriaCsv($input: EvaluationEventViewInput!) {
        evaluationEventResultsBySubCriteriaCsv(input: $input)
      }
    `,
    variables: { input },
  })

  return data.evaluationEventResultsBySubCriteriaCsv
}
