import React, { ReactNode, useRef, MutableRefObject, memo, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { TRANSITION_DURATION } from '@cotiss/common/constants/transition.constants'

type Props = {
  children: ReactNode
  className?: string
  id?: string
  isOpen?: boolean
  onClose: () => void
  toggleRef?: MutableRefObject<any>
}

export const Dropdown = memo(({ id, children, className, isOpen, onClose, toggleRef }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const classes = classNames(className, 'absolute flex flex-col bg-white rounded-md shadow-lg p-1 z-dropdown', {
    visible: isOpen,
    hidden: !isOpen,
  })

  const handleClickOutside = useCallback(({ target }: MouseEvent | TouchEvent) => {
    if (!dropdownRef.current || dropdownRef.current.contains(target as any) || (toggleRef?.current && toggleRef.current.contains(target))) {
      return
    }

    if (onClose) {
      onClose()
    }
  }, [])

  const handleKeydown = useCallback(({ code }: KeyboardEvent) => {
    if (code === 'Escape' && onClose) {
      onClose()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [dropdownRef, onClose, toggleRef])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          id={id}
          className={classes}
          ref={dropdownRef}
          onClick={onClose}
          hidden={!isOpen}
          initial={{ y: -5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: TRANSITION_DURATION.md }}
          exit={{ y: -5, opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
})
