import React, { memo } from 'react'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Tooltip_DEPRECATED } from '@cotiss/common/components/deprecated/tooltip.component'
import { useGetPublicOrganisation } from '@cotiss/organisation/resources/use-get-public-organisation.resource'
import { OrganisationSearchResponse } from '@cotiss/organisation/resources/use-list-organisation-search.resource'
import { PreferredSupplierAddUsersTable } from '@cotiss/preferred-supplier/components/preferred-supplier-add-users-table.component'
import { PreferredSupplierOrganisationCard } from '@cotiss/preferred-supplier/components/preferred-supplier-organisation-card.component'
import { PreferredSupplierAddFormData } from '@cotiss/preferred-supplier/drawers/preferred-supplier-add.drawer'
import { useListPreferredSupplier } from '@cotiss/preferred-supplier/resources/use-list-preferred-supplier.resource'

type Props = {
  breadcrumbs?: BreadcrumbModel[]
  formData: PreferredSupplierAddFormData
  isDisabled: boolean
  isSaving: boolean
  isTenderInvitation?: boolean
  onBack: () => void
  organisation: OrganisationSearchResponse
  setFormData: (formData: PreferredSupplierAddFormData) => void
}

export const PreferredSupplierOrganisationPreviewStep = memo((props: Props) => {
  const { breadcrumbs, formData, isDisabled, isSaving, isTenderInvitation, onBack, organisation, setFormData } = props
  const { publicOrganisation } = useGetPublicOrganisation(organisation.internalId)
  const { preferredSuppliers } = useListPreferredSupplier()
  const isPreferredSupplier = preferredSuppliers.some((preferredSupplier) => {
    if (preferredSupplier.supplierOrganisation && organisation.internalId) {
      return preferredSupplier.supplierOrganisation?._id === organisation.internalId
    }

    return false
  })

  const isContactsRequired = Boolean(organisation.status === 'UNCLAIMED' && formData.contacts.length === 0)

  return (
    <>
      <Breadcrumb className="mb-6" breadcrumbs={breadcrumbs} onBack={onBack} />
      <PreferredSupplierOrganisationCard
        organisation={{
          logo: publicOrganisation?.logo,
          name: organisation.name,
          claimedStatus: organisation.status,
          organisationId: publicOrganisation?._id,
          city: publicOrganisation?.account?.location?.city,
          country: publicOrganisation?.account?.location?.country,
          website: publicOrganisation?.website,
          contactPhone: publicOrganisation?.contactPhone,
        }}
        cta={
          <Tooltip_DEPRECATED tooltip="Please add at least one contact" isEnabled={isContactsRequired}>
            <Button type="submit" variant="secondary" isDisabled={isPreferredSupplier || isContactsRequired} isLoading={isSaving}>
              {isPreferredSupplier ? 'Contact already added' : 'Add to contacts'}
            </Button>
          </Tooltip_DEPRECATED>
        }
      />
      {organisation.status === 'UNCLAIMED' && !isPreferredSupplier && (
        <PreferredSupplierAddUsersTable
          className="mt-6"
          formData={formData}
          isDisabled={isDisabled}
          isSaving={isSaving}
          isTenderInvitation={isTenderInvitation}
          setFormData={setFormData}
        />
      )}
    </>
  )
})
