import { useMutation, useQueryClient } from '@tanstack/react-query'
import { forEach, uniq } from 'lodash'
import { MutateType, QueryKey, queryService } from '@cotiss/common/services/query.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'

type MutationFnParam = {
  route: string
  body?: Record<string, any> | FormData
  method?: 'POST' | 'PUT' | 'DELETE'
  type?: MutateType
  controller?: AbortController
  invalidate?: QueryKey[]
  params?: Record<string, any>
}

export const useMutate = () => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: async ({ route, body = {}, method, type, controller, params }: MutationFnParam) => {
      const queryRoute = `${route}${utilService.generateUrlSearchParams(params)}`
      return await queryService.mutate(queryRoute, { body, method, type, controller })
    },
    onSuccess: async (data, { route, invalidate = [] }) => {
      forEach(uniq([route, ...invalidate]), (queryToInvalidate) => {
        queryClient.invalidateQueries({ queryKey: [queryToInvalidate] })
      })
    },
    onError: (exception, { route, method, type }) => {
      sentryService.captureException({ exception, extras: { route, method, type } })
    },
  })

  return {
    mutate: async <R = void>(param: MutationFnParam) => {
      return (await mutateAsync(param)) as R
    },
  }
}
