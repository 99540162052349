import React, { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useEvaluationEnvelopeDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope-document.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

export const EvaluationEventEnvelopeDocumentUploadModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const { mutateCreateEvaluationEnvelopeDocuments } = useEvaluationEnvelopeDocument()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!evaluationEnvelope || !evaluationUserInSession) {
      return
    }

    try {
      setIsSaving(true)
      await mutateCreateEvaluationEnvelopeDocuments({
        evaluationEnvelopeId: evaluationEnvelope.id,
        evaluationUserId: evaluationUserInSession.id,
        documents: map(documents, ({ _id, fileName, fileSize, mimeType }) => ({
          documentId: _id,
          fileName,
          fileSize,
          mimeType,
        })),
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload resources" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <DocumentUpload documents={documents} onChange={setDocuments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
