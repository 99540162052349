import { GqlEvaluationScoreListInput, GqlUpdateEvaluationScoreInput, GqlUpsertEvaluationScoreInput } from '@gql'
import { mutateUpsertEvaluationScore } from '@cotiss/evaluation-event/graphql/evaluation-score/mutate-create-evaluation-score.graphql'
import { mutateUpdateEvaluationScore } from '@cotiss/evaluation-event/graphql/evaluation-score/mutate-update-evaluation-score.graphql'
import { queryEvaluationScoreList } from '@cotiss/evaluation-event/graphql/evaluation-score/query-evaluation-score-list.graphql'

export const useEvaluationScore = () => {
  return {
    queryEvaluationScoreList: async (input: GqlEvaluationScoreListInput) => {
      const { items: evaluationScores, pagination } = await queryEvaluationScoreList(input)

      return { evaluationScores, pagination }
    },
    mutateUpsertEvaluationScore: async (input: GqlUpsertEvaluationScoreInput) => {
      return await mutateUpsertEvaluationScore(input)
    },
    mutateUpdateEvaluationScore: async (input: GqlUpdateEvaluationScoreInput) => {
      return await mutateUpdateEvaluationScore(input)
    },
  }
}
