import React, { HTMLProps } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'

export type SelectOption_DEPRECATED<T = string> = {
  label: string
  value: T
  isDisabled?: boolean
}

export type SelectProps_DEPRECATED<T> = Omit<
  HTMLProps<HTMLSelectElement>,
  'onChange' | 'required' | 'disabled' | 'size' | 'value' | 'defaultValue' | 'placeholder'
> & {
  onChange: (value: SelectOption_DEPRECATED<T>['value']) => void
  options: SelectOption_DEPRECATED<T>[]
  size?: 'sm' | 'md' | 'lg'
  value?: T
  defaultValue?: T
  placeholder?: string | true
  isFull?: boolean
  isError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
}

export function Select_DEPRECATED<T extends string>(props: SelectProps_DEPRECATED<T>) {
  const { className, options, onChange, size = 'md', value, placeholder, isFull = true, isError, isRequired, isDisabled, ...rest } = props
  const classes = classNames(className, 'relative bg-white', {
    'pointer-events-none opacity-50': isDisabled,
    'w-full': isFull,
    'inline-block': !isFull,
  })
  const selectClasses = classNames(
    'relative bg-transparent text-primary-500 border rounded-md appearance-none w-full focus:shadow-none focus:outline-none',
    {
      'border-gray-300 focus:border-secondary-600': !isError,
      'border-pink-500 focus:border-pink-500': isError,
      'text-sm min-w-[50px] pr-6 pl-2 py-0.5': size === 'sm',
      'pr-8 h-11 pl-3 py-2': size === 'md',
      'pr-8 pl-4 py-4': size === 'lg',
    }
  )

  return (
    <div className={classes}>
      {size === 'sm' && <Icon className="absolute right-1 top-1/2 -translate-y-1/2" icon="chevron-down" variant="dark" size={16} />}
      {size !== 'sm' && <Icon className="absolute right-3 top-1/2 -translate-y-1/2" icon="chevron-down" variant="dark" size={20} />}
      <select
        {...rest}
        className={selectClasses}
        onChange={({ target }) => onChange(target.value as T)}
        value={value === undefined ? '' : value}
        disabled={isDisabled}
        required={isRequired}
      >
        {!rest.defaultValue && placeholder !== undefined && (
          <option disabled value="">
            {placeholder === true ? '- Select an option -' : placeholder}
          </option>
        )}
        {map(options, ({ label, value, isDisabled }) => (
          <option key={value} value={value} disabled={isDisabled}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}
