import { useQuery } from '@tanstack/react-query'
import { compact, uniq } from 'lodash'
import { QueryKey, queryService } from '@cotiss/common/services/query.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useAuth } from '@cotiss/auth/auth.provider'

type UseFetchOptionsParam = {
  route?: string
  params?: Record<string, any>
  isEnabled?: boolean
  isPublicApi?: boolean
  willRetry?: boolean
}

export const useFetch = <T>(queryKey: QueryKey, { route, params, isEnabled = true, isPublicApi = false, willRetry }: UseFetchOptionsParam = {}) => {
  const { isAuthenticated } = useAuth()
  const queryRoute = `${route || queryKey}${utilService.generateUrlSearchParams(params)}`

  return useQuery<T>(compact(uniq([queryKey, route, params])), () => queryService.query({ route: queryRoute }), {
    enabled: (isAuthenticated || isPublicApi) && isEnabled,
    onError: (exception) => {
      sentryService.captureException({ exception, extras: { queryKey, queryRoute } })
    },
    refetchOnWindowFocus: false,
    retry: willRetry,
  })
}
