import React, { memo, useState } from 'react'
import { find } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useListEvaluation } from '@cotiss/evaluation/resources/use-list-evaluation.resource'
import { useMutateEvaluation } from '@cotiss/evaluation/resources/use-mutate-evaluation.resource'

type Props = {
  onView: () => void
  userId: string
  tenderId: string
}

export const EvaluationIndividualListItemCta = memo(({ onView, userId, tenderId }: Props) => {
  const { resendEvaluationInvitation } = useMutateEvaluation()
  const { evaluations } = useListEvaluation({ tenderId })
  const { openToast } = useToast()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const handleResend = async () => {
    setIsDropdownOpen(false)

    const evaluation = find(evaluations, ({ user: { _id } }) => _id === userId)
    if (!evaluation) {
      return
    }

    try {
      setIsSaving(true)
      await resendEvaluationInvitation(evaluation._id)
      openToast('The evaluation invitation has been resent.')
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        isDisabled={isSaving}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent className="flex items-center" onClick={() => handleResend()}>
          Resend
        </DropdownContent>
      </Dropdown>
      <Button onClick={onView} state="outline" variant="secondary" size="xs" isLink>
        View <Icon className="ml-1" icon="arrow-right" />
      </Button>
    </div>
  )
})
