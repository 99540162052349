import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { PreferredSupplierBuyerListPage } from '@cotiss/preferred-supplier/pages/preferred-supplier-buyer-list.page'
import { PreferredSupplierListPage_DEPRECATED } from '@cotiss/preferred-supplier/pages/preferred-supplier-list-deprecated.page'
import { PreferredSupplierListPage as NewPreferredSupplierListPage } from '@cotiss/preferred-supplier/pages/preferred-supplier-list.page'

export const PreferredSupplierLayout = memo(() => {
  const isPreferredSupplierListFiltersEnabled = useFeature('enable-preferred-supplier-list-filters')
  return (
    <Switch>
      <Route path="/preferred-supplier/buyer/list" exact>
        <PreferredSupplierBuyerListPage />
      </Route>
      <Route path="/preferred-supplier/list/:tab?" exact>
        {isPreferredSupplierListFiltersEnabled ? <NewPreferredSupplierListPage /> : <PreferredSupplierListPage_DEPRECATED />}
      </Route>
      <Redirect from="/preferred-supplier" to="/preferred-supplier/list/:tab?" exact />
    </Switch>
  )
})
