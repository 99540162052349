import React, { FormEvent, memo, useState } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { Table_DEPRECATED } from '@cotiss/common/components/deprecated/table.component'
import { Tbody_DEPRECATED } from '@cotiss/common/components/deprecated/tbody.component'
import { Th_DEPRECATED } from '@cotiss/common/components/deprecated/th.component'
import { Thead_DEPRECATED } from '@cotiss/common/components/deprecated/thead.component'
import { Text } from '@cotiss/common/components/text.component'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { RecommendationShortListItem } from '@cotiss/recommendation/components/recommendation-short-list-item.component'
import { RecommendationModel } from '@cotiss/recommendation/recommendation.models'
import { useMutateRecommendation } from '@cotiss/recommendation/resources/use-mutate-recommendation.resource'
import { ShortListPopulatedModel } from '@cotiss/short-list/short-list.models'

type Props = {
  className?: string
  recommendation: RecommendationModel
  shortLists: ShortListPopulatedModel[]
  isEditable?: boolean
}

export const RecommendationView = memo(({ className, recommendation, shortLists, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(isEditable && Boolean(recommendation.recommendation))
  const { updateRecommendation } = useMutateRecommendation()
  const classes = classNames(className, 'flex items-start justify-between')
  const [formData, setFormData] = useState({ recommendation: recommendation.recommendation || richTextService.getEmptyRemirrorJson() })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleToggleEdit = (isEditing: boolean) => {
    setFormData({ recommendation: recommendation.recommendation || richTextService.getEmptyRemirrorJson() })
    setIsEditing(isEditing)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateRecommendation(recommendation._id, formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className={classes}>
      <div className="w-1/2 mr-8">
        <Table_DEPRECATED className={className} isSeparate>
          <Thead_DEPRECATED>
            <Th_DEPRECATED>Supplier name</Th_DEPRECATED>
            <Th_DEPRECATED className="w-28">Due diligence</Th_DEPRECATED>
            <Th_DEPRECATED className="w-24">Rank</Th_DEPRECATED>
          </Thead_DEPRECATED>
          <Tbody_DEPRECATED>
            {map(shortLists, (shortList) => (
              <RecommendationShortListItem key={shortList._id} shortList={shortList} shortListCount={shortLists.length} isEditable={isEditable} />
            ))}
          </Tbody_DEPRECATED>
        </Table_DEPRECATED>
      </div>
      <div className="w-1/2">
        <Text className="uppercase mb-2" variant="light" size="sm">
          Write recommendation
        </Text>
        <div className="bg-white rounded-lg shadow-lg w-full px-8 py-6 relative">
          {!isEditing && !richTextService.isEmpty(recommendation.recommendation) && (
            <div className="flex flex-col">
              <div className="flex justify-between">
                <Text>Recommendation</Text>
                <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
                  <Icon icon="dots" variant="light" size={20} />
                </Button>
                <Dropdown className="top-8 right-4 absolute" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  <DropdownContent
                    className="flex items-center"
                    onClick={() => {
                      handleToggleEdit(true)
                      setIsDropdownOpen(false)
                    }}
                  >
                    Edit
                  </DropdownContent>
                </Dropdown>
              </div>
              <RichText value={recommendation.recommendation} />
            </div>
          )}
          {!isEditing && richTextService.isEmpty(recommendation.recommendation) && isEditable && (
            <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
              + Add recommendation
            </Button>
          )}
          {!isEditing && richTextService.isEmpty(recommendation.recommendation) && !isEditable && <Text>--</Text>}
          {isEditing && (
            <Form onSubmit={handleSubmit}>
              <RichTextEditor
                value={formData.recommendation}
                onChange={(recommendation) => setFormData({ ...formData, recommendation })}
                isDisabled={isSaving}
              />
              <div className="flex justify-end mt-2">
                <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                  Cancel
                </Button>
                <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  )
})
