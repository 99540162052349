import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { performanceService } from '@cotiss/performance/performance.service'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'

export const PerformanceScorecardViewTrackingTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { performanceScorecard } = usePerformanceScorecard()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardId } = useParams<{ performanceScorecardId: string }>()
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetrics, ({ id: performanceScorecardMetricId, performanceMetric }) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref('/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId', {
                  performanceScorecardId,
                  performanceScorecardMetricId,
                }),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Target',
        rows: map(performanceScorecardMetrics, (performanceScorecardMetric) => ({
          content: () => (
            <Text>
              {performanceService.formatPerformanceValue({
                value: performanceScorecardMetric.target,
                performanceMetric: performanceScorecardMetric.performanceMetric,
              })}
            </Text>
          ),
        })),
      },
      {
        heading: 'Evaluators',
        rows: map(performanceScorecardMetrics, ({ evaluators }) => ({
          content: () => <UserAvatarGroup users={map(evaluators, 'user')} />,
        })),
      },
      {
        heading: 'Latest value',
        rows: map(performanceScorecardMetrics, ({ performanceMetric, performanceScorecardMetricCycles }) => ({
          content: () => (
            <Text>
              {performanceScorecardMetricCycles[0]?.score
                ? performanceService.formatPerformanceValue({ performanceMetric, value: performanceScorecardMetricCycles[0]?.score })
                : '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Latest date',
        rows: map(performanceScorecardMetrics, ({ performanceScorecardMetricCycles }) => ({
          content: () => (
            <Text>
              {performanceScorecardMetricCycles[0]?.createdAt
                ? datetimeService.format(performanceScorecardMetricCycles[0]?.createdAt, 'do MMM yyyy')
                : '--'}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardMetrics])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricList({
        filter: {
          performanceScorecardId,
          isArchived: false,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  if (!isLoading && (isError || !performanceScorecardMetrics.length)) {
    return <ErrorPanel />
  }

  if (!performanceScorecard) {
    return null
  }

  return (
    <>
      <TableHeader variant="white">
        <div>
          <Text className="font-medium" size="lg">
            Tracking
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            Track the progress of active metrics associated to this scorecard
          </Text>
        </div>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
