import React, { useState, memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ScheduledRateModel } from '@cotiss/contract/contract.model'
import { ContractWizardScheduleRatesModal } from '@cotiss/contract/modals/contract-wizard-schedule-rates.modal'

type Props = {
  contractShellId: string
  scheduledRate: ScheduledRateModel
  onDelete: (id: string) => void
}

export const ContractScheduledRateCta = memo(({ contractShellId, scheduledRate, onDelete }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className="relative flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="fixed top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          className="flex items-center"
          onClick={() => openModal(<ContractWizardScheduleRatesModal contractShellId={contractShellId} scheduledRate={scheduledRate} />)}
        >
          Edit
        </DropdownContent>
        <DropdownContent
          className="flex items-center"
          onClick={() =>
            openModal(
              <ConfirmModal heading="Delete" description="Are you sure you want to delete this rate?" onSubmit={() => onDelete(scheduledRate._id)} />
            )
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </div>
  )
})
