import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { PaginationParam } from '@cotiss/common/services/query.service'
import { PriceItemModel } from '@cotiss/price-item/price-item.models'

type Props = PaginationParam & {
  tenderId?: string
}

export const useListPriceItem = ({ tenderId, limit = -1, offset = 0 }: Props) => {
  const { data, ...rest } = useFetch<PaginatedModel<PriceItemModel>>('/v2/price-item', {
    params: { limit, offset, tenderId },
    isEnabled: Boolean(tenderId),
  })

  return { priceItems: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
