import { gql } from '@apollo/client'
import {
  GqlCreatePlanEventDocumentInput,
  GqlCreatePlanEventDocumentMutation,
  GqlCreatePlanEventDocumentMutationVariables,
  GqlPlanEventDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PLAN_EVENT_DOCUMENT_FRAGMENT } from '@cotiss/plan-event/graphql/plan-event-document/plan-event-document-fragment.graphql'

export const mutateCreatePlanEventDocument = async (input: GqlCreatePlanEventDocumentInput) => {
  const { data } = await apolloService.mutate<GqlCreatePlanEventDocumentMutation, GqlCreatePlanEventDocumentMutationVariables>({
    mutation: gql`
      ${PLAN_EVENT_DOCUMENT_FRAGMENT}

      mutation CreatePlanEventDocument($input: CreatePlanEventDocumentInput!) {
        createPlanEventDocument(input: $input) {
          ...PlanEventDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createPlanEventDocument as GqlPlanEventDocumentFieldsFragment
}
