import { gql } from '@apollo/client'
import { GqlPerformanceScorecardListInput, GqlPerformanceScorecardListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { CONTACT_FRAGMENT } from '@cotiss/contact/graphql/contact-fragment.graphql'
import { CONTRACT_SHELL_FRAGMENT } from '@cotiss/contract/graphql/contract-shell-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'
import { PERFORMANCE_SCORECARD_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard/performance-scorecard-fragment.graphql'
import { PERFORMANCE_SCORECARD_USER_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-user/performance-scorecard-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPerformanceScorecardList = async (input: GqlPerformanceScorecardListInput) => {
  const { data } = await apolloService.query<GqlPerformanceScorecardListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${CONTACT_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${ORGANISATION_FRAGMENT}
      ${CONTRACT_SHELL_FRAGMENT}
      ${PERFORMANCE_SCORECARD_FRAGMENT}
      ${PERFORMANCE_SCORECARD_USER_FRAGMENT}

      query PerformanceScorecardList($input: PerformanceScorecardListInput!) {
        performanceScorecardList(input: $input) {
          items {
            ...PerformanceScorecardFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.performanceScorecardList
}
