import React, { ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  variant?: 'default' | 'white'
}

export const TableSubHeader = memo(({ className, children, variant = 'default' }: Props) => {
  const classes = classNames(className, 'border-x border-t border-grey-200 p-4 w-full', {
    'bg-primary-50': variant === 'default',
    'bg-white': variant === 'white',
  })

  return <div className={classes}>{children}</div>
})
