import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

export const useListTenderResponseReport = (tenderId?: string, includeInProgressEvaluations?: boolean) => {
  const { data, ...rest } = useFetch<{ responses: TenderResponseReportModel[] }>('/v2/reports/evaluations-by-tender-response', {
    route: `/v2/reports/evaluations-by-tender-response`,
    params: { tenderId, includeInProgressEvaluations },
    isEnabled: Boolean(tenderId),
  })

  return { tenderResponseReports: data?.responses || [], ...rest }
}
