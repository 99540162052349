import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceMetricInput,
  GqlPerformanceMetricFieldsFragment,
  GqlPerformanceMetricListInput,
  GqlPerformanceMetricViewInput,
  GqlUpdatePerformanceMetricInput,
} from '@gql'
import { uniqBy } from 'lodash'
import { mutateCreatePerformanceMetric } from '@cotiss/performance/graphql/performance-metric/mutate-create-performance-metric.graphql'
import { mutateUpdatePerformanceMetric } from '@cotiss/performance/graphql/performance-metric/mutate-update-performance-metric.graphql'
import { queryPerformanceMetricGroupList } from '@cotiss/performance/graphql/performance-metric/query-performance-metric-group-list.graphql'
import { queryPerformanceMetricList } from '@cotiss/performance/graphql/performance-metric/query-performance-metric-list.graphql'
import { queryPerformanceMetricView } from '@cotiss/performance/graphql/performance-metric/query-performance-metric-view.graphql'

const performanceMetricVar = makeVar<GqlPerformanceMetricFieldsFragment | null>(null)
const performanceMetricsVar = makeVar<GqlPerformanceMetricFieldsFragment[]>([])
const performanceMetricGroupsVar = makeVar<string[]>([])

export const usePerformanceMetric = () => {
  const performanceMetric = useReactiveVar(performanceMetricVar)
  const performanceMetrics = useReactiveVar(performanceMetricsVar)
  const performanceMetricGroups = useReactiveVar(performanceMetricGroupsVar)

  return {
    performanceMetric,
    performanceMetrics,
    performanceMetricGroups,
    setPerformanceMetric: performanceMetricVar,
    setPerformanceMetrics: performanceMetricsVar,
    setPerformanceMetricGroups: performanceMetricGroupsVar,
    queryPerformanceMetricList: async (input: GqlPerformanceMetricListInput) => {
      const { items: performanceMetrics, pagination } = await queryPerformanceMetricList(input)

      performanceMetricsVar(performanceMetrics)

      return { performanceMetrics, pagination }
    },
    queryPerformanceMetricGroupList: async (input: GqlPerformanceMetricListInput) => {
      const { items: performanceMetrics, pagination } = await queryPerformanceMetricGroupList(input)

      performanceMetricGroupsVar(performanceMetrics)

      return { performanceMetrics, pagination }
    },
    queryPerformanceMetricView: async (input: GqlPerformanceMetricViewInput) => {
      const performanceMetric = await queryPerformanceMetricView(input)

      performanceMetricVar(performanceMetric)
      performanceMetricsVar(uniqBy([performanceMetric, ...performanceMetrics], 'id'))

      return performanceMetric
    },
    mutateCreatePerformanceMetric: async (input: GqlCreatePerformanceMetricInput) => {
      const createdPerformanceMetric = await mutateCreatePerformanceMetric(input)

      performanceMetricsVar(uniqBy([createdPerformanceMetric, ...performanceMetrics], 'id'))

      return createdPerformanceMetric
    },
    mutateUpdatePerformanceMetric: async (input: GqlUpdatePerformanceMetricInput) => {
      const updatedPerformanceMetric = await mutateUpdatePerformanceMetric(input)

      performanceMetricVar(updatedPerformanceMetric)
      performanceMetricsVar(uniqBy([updatedPerformanceMetric, ...performanceMetrics], 'id'))

      return updatedPerformanceMetric
    },
  }
}

export const clearReactivePerformanceMetric = async () => {
  performanceMetricVar(null)
  performanceMetricsVar([])
}
