import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { PaginatedModel } from '@cotiss/common/models/paginated.model'
import { MetafieldEntityType, MetafieldModel } from '@cotiss/metafield/metafield.model'

type Props = {
  limit?: number
  offset?: number
  entityType: MetafieldEntityType
  sort?: keyof MetafieldModel
  order?: 1 | -1
  isArchived?: boolean
  isEnabled?: boolean
}

export const useListMetafield = ({ offset = 0, limit = -1, sort = 'order', order = 1, isArchived, isEnabled = true, ...params }: Props) => {
  const { data, ...rest } = useFetch<PaginatedModel<MetafieldModel>>('/v2/metafields', {
    route: '/v2/metafields',
    params: { ...params, offset, limit, sort, order, isArchived },
    isEnabled,
  })

  return { metafields: data?.items || [], count: data?.count || 0, meta: data?.meta, ...rest }
}
