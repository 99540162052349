import { gql } from '@apollo/client'
import {
  GqlBeginEvaluationEventInput,
  GqlBeginEvaluationEventMutation,
  GqlBeginEvaluationEventMutationVariables,
  GqlEvaluationEventFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_EVENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-event/evaluation-event-fragment.graphql'
import { ORGANISATION_FRAGMENT } from '@cotiss/organisation/graphql/organisation-fragment.graphql'

export const mutateBeginEvaluationEvent = async (input: GqlBeginEvaluationEventInput) => {
  const { data } = await apolloService.mutate<GqlBeginEvaluationEventMutation, GqlBeginEvaluationEventMutationVariables>({
    mutation: gql`
      ${ORGANISATION_FRAGMENT}
      ${EVALUATION_EVENT_FRAGMENT}

      mutation BeginEvaluationEvent($input: BeginEvaluationEventInput!) {
        beginEvaluationEvent(input: $input) {
          ...EvaluationEventFields
        }
      }
    `,
    variables: { input },
  })

  return data?.beginEvaluationEvent as GqlEvaluationEventFieldsFragment
}
