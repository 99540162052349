import React, { memo } from 'react'
import { Banner } from '@cotiss/common/components/banner.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { featureService } from '@cotiss/common/services/feature.service'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const StagingBanner = memo(() => {
  const { user } = useGetLoggedInUser()

  useAsyncEffect(async () => {
    if (user) {
      // In order for the feature to be linked to the correct user groups, we have the force an identify before the variation to ensure we don't run
      // into timing issues during our normal authentication flow.
      await featureService.identify(user)
    }
  }, [user])

  return (
    <Banner className="fixed top-0 left-0 flex items-center w-full z-side-nav" variant="warning">
      <div className="flex items-center justify-center bg-white rounded-md w-9 h-9 mr-4">
        <Icon icon="globe" variant="warning" size={20} />
      </div>
      <div className="flex items-center justify-between w-full">
        <Text className="font-semibold" variant="heading">
          This is a staging version of Cotiss.
        </Text>
      </div>
    </Banner>
  )
})
