import React, { FormEvent, memo, useState } from 'react'
import { parseISO } from 'date-fns'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TenderFieldWithHistory, TenderModel, TenderPopulatedModel } from '@cotiss/tender/tender.models'

type Props = {
  tender: TenderPopulatedModel | TenderModel
  field: TenderFieldWithHistory
}

export const TenderUpdateEndDateModal = memo(({ tender, field }: Props) => {
  const { openModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [endDate, setEndDate] = useState<Date | null>(tender[field]?.endDate ? parseISO(tender[field]?.endDate as string) : null)

  const handleUpdateTenderEndDate = async () => {
    if (!endDate) {
      return
    }

    await updateTender(tender._id, { [field]: { endDate: endDate.toISOString() } })
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    openModal(
      <ConfirmModal
        heading={`Are you sure you want to edit the ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date?`}
        description={`Vendors will be able to view a history log of all changes to the ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date`}
        onSubmit={handleUpdateTenderEndDate}
      />
    )
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`Extend ${field === 'tenderPeriod' ? 'tender' : 'forum'} close date`} />
      <ModalContent>
        <Label>New close date</Label>
        <DatetimeInput value={endDate} onChange={(endDate) => setEndDate(endDate)} isFuture isRequired />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
