import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'

export type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'

export const MONTHS_WITH_31_DAYS: Month[] = ['January', 'March', 'May', 'July', 'August', 'October', 'December']
export const MONTHS_WITH_30_DAYS: Month[] = ['April', 'June', 'September', 'November']

export const MONTH_OPTIONS: SelectOption_DEPRECATED<Month>[] = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
]

type MonthConfig = {
  numberOfDays: number
}

export const MONTH_CONFIG: Record<Month, MonthConfig> = {
  January: { numberOfDays: 31 },
  February: { numberOfDays: 28 },
  March: { numberOfDays: 31 },
  April: { numberOfDays: 30 },
  May: { numberOfDays: 31 },
  June: { numberOfDays: 30 },
  July: { numberOfDays: 31 },
  August: { numberOfDays: 31 },
  September: { numberOfDays: 30 },
  October: { numberOfDays: 31 },
  November: { numberOfDays: 30 },
  December: { numberOfDays: 31 },
}

export type Weekday = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'

export const WEEKDAY_OPTIONS: SelectOption_DEPRECATED<Weekday>[] = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
]
