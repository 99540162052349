import { gql } from '@apollo/client'
import { GqlPlanUserListInput, GqlPlanUserListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PLAN_USER_FRAGMENT } from '@cotiss/plan-event/graphql/plan-user/plan-user-fragment.graphql'
import { USER_FRAGMENT } from '@cotiss/user/graphql/user-fragment.graphql'

export const queryPlanUserList = async (input: GqlPlanUserListInput) => {
  const { data } = await apolloService.query<GqlPlanUserListQuery>({
    query: gql`
      ${USER_FRAGMENT}
      ${PAGINATION_FRAGMENT}
      ${PLAN_USER_FRAGMENT}

      query PlanUserList($input: PlanUserListInput!) {
        planUserList(input: $input) {
          items {
            ...PlanUserFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.planUserList
}
