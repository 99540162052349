import { Filter } from '@cotiss/common/models/filter.model'

export const PREFERRED_SUPPLIER_ROUTES = ['/preferred-supplier', '/preferred-supplier/list/:tab?', '/preferred-supplier/buyer/list'] as const
export const PREFERRED_SUPPLIER_QUERY_KEYS = ['/v2/preferred-suppliers', '/v2/preferred-suppliers/tags', '/v2/preferred-suppliers/buyer'] as const

export type PreferredSupplierPrimaryTabKeys = 'active' | 'archived'
export const PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB: PreferredSupplierPrimaryTabKeys = 'active'

// EXCLUDES: archived
export const PREFERRED_SUPPLIER_FILTER_FIELDS_WHITELIST = ['name', 'tags', 'businessNumber']

export const PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS: Record<PreferredSupplierPrimaryTabKeys, { filters: Filter[] }> = {
  active: {
    filters: [
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
    ],
  },
  archived: {
    filters: [
      {
        field: 'archived',
        operation: 'IS_TRUE',
        value: true,
      },
    ],
  },
}
