import React, { FormEvent, memo, useState } from 'react'
import classNames from 'classnames'
import { find, values } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon, IconType } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ContractVariation } from '@cotiss/contract/contract.model'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  contractShellId: string
  contractId: string
  onSuccess: () => void
}

type RenderVariationTypeParam = {
  id: ContractVariation
  title: string
  description: string
  icon: IconType
  iconClasses?: string
}

export const ContractRequestVariationModal = memo(({ contractShellId, contractId, onSuccess }: Props) => {
  const { contractShell } = useGetContractShell(contractShellId)
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { createContractVariation } = useMutateContractShell()
  const [isSaving, setIsSaving] = useState(false)
  const [variationTypes, setVariationTypes] = useState<Record<ContractVariation, boolean>>({
    metadata_terms: false,
    price_duration: false,
    contract_renewal: false,
    cessation: false,
    scheduled_rates: false,
    milestone: false,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await createContractVariation(contractShellId, contractId, {
        types: (Object.keys(variationTypes) as ContractVariation[]).filter((variationType) => variationTypes[variationType]),
      })
      setIsSaving(false)
      closeModal()
      onSuccess()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderVariationType = ({ id, title, description, icon, iconClasses }: RenderVariationTypeParam) => {
    return (
      <Button
        state="text"
        isDisabled={isSaving}
        onClick={() => setVariationTypes({ ...variationTypes, [id]: !variationTypes[id] })}
        className={classNames('border border-primary-100 rounded p-4', { 'bg-secondary-50': variationTypes[id] })}
      >
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center justify-center rounded bg-secondary-100 h-7 w-7">
            <Icon icon={icon} className={classNames('text-secondary-500', iconClasses)} />
          </div>
          <Checkbox
            isDisabled={isSaving}
            isChecked={variationTypes[id]}
            onChange={() => setVariationTypes({ ...variationTypes, [id]: !variationTypes[id] })}
          />
        </div>
        <Text className="mb-2 font-semibold">{title}</Text>
        <Text variant="light">{description}</Text>
      </Button>
    )
  }

  return (
    <Form className="w-[850px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading="Select the type(s) of variations you want to make"
        supplementary="You may select one or multiple variation options"
        isDisabled={isSaving}
      />
      <ModalContent className="grid gap-3 grid-cols-3">
        {renderVariationType({
          id: 'metadata_terms',
          title: 'Variation to conditions or metadata',
          description: 'Update metadata stored against this contract.',
          icon: 'edit',
          iconClasses: 'stroke-2',
        })}
        {contractShell?.type !== 'MILESTONE' &&
          renderVariationType({
            id: 'price_duration',
            title: 'Variation to price or duration',
            description: 'Adjust, add or remove the contract renewal lengths and values.',
            icon: 'settings',
            iconClasses: 'stroke-2',
          })}
        {contractShell?.type === 'MILESTONE' &&
          renderVariationType({
            id: 'milestone',
            title: 'Variation to milestones',
            description: 'Adjust, add or remove the contract renewal lengths and values.',
            icon: 'settings',
            iconClasses: 'stroke-2',
          })}
        {renderVariationType({
          id: 'scheduled_rates',
          title: 'Variation to schedule of rates',
          description: 'Specify changes or additions to your schedule of rates and submit for review and approval.',
          icon: 'table',
        })}
        {renderVariationType({
          id: 'cessation',
          title: 'Cessation of contract',
          description: 'Cease contract. Once a contract is ceased it will not be able to be variated.',
          icon: 'x-square',
        })}
        {renderVariationType({
          id: 'contract_renewal',
          title: 'Exercising contract renewal',
          description: 'Exercise a contract renewal or initial term. Once exercised it cannot be adjusted.',
          icon: 'refresh-02',
        })}
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!find(values(variationTypes))}>
          Get started <Icon icon="arrow-right" />
        </Button>
      </ModalFooter>
    </Form>
  )
})
