import React, { memo } from 'react'
import classNames from 'classnames'
import { Icon } from '@cotiss/common/components/icon.component'

type Props = {
  className?: string
  isExpanded?: boolean
}

export const AccordionIcon = memo(({ className, isExpanded }: Props) => {
  const classes = classNames(className, 'inline-flex items-center justify-center shrink-0 border rounded-full w-4 h-4 transition-transform', {
    'border-secondary-500 bg-secondary-500 rotate-90': isExpanded,
    'border-gray-300': !isExpanded,
  })

  return (
    <div className={classes}>
      <Icon icon="chevron-right" variant={isExpanded ? 'white' : 'light'} size={14} />
    </div>
  )
})
