import React, { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex, includes, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PerformanceScorecardViewTeamTab } from '@cotiss/performance/tabs/performance-scorecard-view-team.tab'
import { PerformanceScorecardViewTrackingTab } from '@cotiss/performance/tabs/performance-scorecard-view-tracking.tab'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { usePerformanceScorecardAnalytics } from '@cotiss/performance/hooks/use-performance-scorecard-analytics.hook'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { Button } from '@cotiss/common/components/button.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { Header } from '@cotiss/common/components/header.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { PerformanceScorecardStatusBadge } from '@cotiss/performance/components/performance-scorecard-status-badge.component'
import { PerformanceScorecardViewMetricsTab } from '@cotiss/performance/tabs/performance-scorecard-view-metrics.tab'
import { PerformanceScorecardViewResourcesTab } from '@cotiss/performance/tabs/performance-scorecard-view-resources.tab'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

export type PerformanceScorecardViewTab = 'tracking' | 'metrics' | 'team' | 'resources'

export const PerformanceScorecardViewPage = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { push, replace } = useHistory()
  const { permissions } = useUserAccess()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { track } = usePerformanceScorecardAnalytics()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { step, transition, onTransition } = useTransition({ initialStep: 2 })
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()
  const { performanceScorecardUserInSession, queryPerformanceScorecardUserInSessionView } = usePerformanceScorecardUser()
  const { performanceScorecardId, tab } = useParams<{ performanceScorecardId: string; tab?: PerformanceScorecardViewTab }>()
  const { performanceScorecard, queryPerformanceScorecardView, mutatePublishPerformanceScorecard } = usePerformanceScorecard()

  const tabs: TabModel<PerformanceScorecardViewTab>[] = [
    {
      id: 'tracking',
      label: 'Tracking',
      isDisabled: performanceScorecard?.status !== 'published' ? 'Tracking tab will be unlocked once scorecard is published.' : false,
    },
    { id: 'metrics', label: 'Metrics' },
    { id: 'team', label: 'Team' },
    { id: 'resources', label: 'Resources' },
  ]

  const backHref = routerService.getHref('/performance/scorecard/list/:tab?')
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Performance',
      href: backHref,
    },
    {
      label: performanceScorecard?.name || '',
      isLoading,
    },
  ]

  const { isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    return { isOwner }
  }, [performanceScorecardUserInSession])

  useAsyncEffect(async () => {
    try {
      const [performanceScorecard, performanceScorecardUserInSession] = await Promise.all([
        queryPerformanceScorecardView({ performanceScorecardId }),
        queryPerformanceScorecardUserInSessionView({ performanceScorecardId }),
        queryPerformanceScorecardMetricList({ filter: { performanceScorecardId } }),
      ])

      track('performance_scorecard_view')

      if (performanceScorecard.status === 'draft' && !includes(performanceScorecardUserInSession.roles, 'owner')) {
        openToast('You do not have permission to view this draft performance scorecard.', 'danger')
        replace(backHref)
        return
      }

      if (!tab || !some(tabs, { id: tab })) {
        push(
          routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
            performanceScorecardId,
            tab: performanceScorecard.status === 'published' ? 'tracking' : 'metrics',
          })
        )
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!performanceScorecard) {
      return
    }

    if (performanceScorecard.isArchived) {
      openToast('You cannot access an archived performance scorecard.', 'danger')
      return push(routerService.getHref('/performance/scorecard/list/:tab?'))
    }
  }, [performanceScorecard])

  useEffect(() => {
    if (isLoading) {
      return
    }

    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [isLoading, tab])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !performanceScorecard) {
    return <FourOhFourPage />
  }

  if (!isPerformanceScorecardEnabled) {
    return <FourOhThreePage />
  }

  const handlePublishPerformanceScorecard = async () => {
    await mutatePublishPerformanceScorecard({ performanceScorecardId })
    await queryPerformanceScorecardView({ performanceScorecardId })
    push(routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId, tab: 'tracking' }))
  }

  return (
    <Page>
      <Header>
        <div className="flex items-start justify-between">
          <div>
            <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
            {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
            {!isLoading && performanceScorecard && (
              <Text className="font-medium flex items-center" size="h7" variant="heading">
                {performanceScorecard.name}
                <PerformanceScorecardStatusBadge className="ml-2" status={performanceScorecard.status} size="sm" />
              </Text>
            )}
            <div className="flex items-center mt-1">
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && performanceScorecard && (
                <div>
                  {performanceScorecard.contact.organisationId && (
                    <div className="flex items-center">
                      <Text className="mr-1" variant="light" size="sm">
                        Vendor:
                      </Text>
                      <Button
                        href={routerService.getHref('/supplier/view/:id/:tab?', { id: performanceScorecard.contact.organisationId })}
                        size="sm"
                        state="text"
                        variant="link"
                        isLink
                      >
                        {performanceScorecard.contact.name}
                      </Button>
                    </div>
                  )}
                  {performanceScorecard.contractShell && (
                    <div className="flex items-center mt-1 gap-1">
                      <Text className="mr-1" variant="light" size="sm">
                        Contract:
                      </Text>
                      <Button
                        href={routerService.getHref('/contract/view/:contractShellId/:tab?', {
                          contractShellId: performanceScorecard.contractShell.id,
                        })}
                        size="sm"
                        state="text"
                        variant="link"
                        isDisabled={!permissions.hasContractAccess}
                        isLink
                      >
                        {performanceScorecard.contractShell.title}
                      </Button>
                      {!permissions.hasContractAccess && (
                        <Tooltip tooltip="You do not have permission to view this contract.">
                          <div className="inline-flex items-center">
                            <Icon className="text-light" icon="lock" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {isOwner && performanceScorecard?.status === 'draft' && (
            <Tooltip
              isEnabled={!performanceScorecardMetrics.length}
              tooltip="Publishing is locked until at least one metric has been added to the scorecard."
            >
              <div className="inline-block">
                <Button
                  className="ml-4"
                  size="sm"
                  isDisabled={!performanceScorecardMetrics.length}
                  onClick={() =>
                    openModal(
                      <ConfirmModal
                        heading="Are you sure you want to publish this performance scorecard?"
                        description="Once the scorecard is published, metrics can only be archived and not permanently removed."
                        onSubmit={handlePublishPerformanceScorecard}
                      />
                    )
                  }
                >
                  Publish
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm px-10 z-30">
        {!isLoading && (
          <Tabs<PerformanceScorecardViewTab>
            className="w-full"
            tab={tab}
            tabs={tabs}
            onChange={({ id: tab }) =>
              push(routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId, tab }))
            }
            variant="underline"
          />
        )}
      </div>
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <PerformanceScorecardViewTrackingTab />}
            {step === 2 && <PerformanceScorecardViewMetricsTab />}
            {step === 3 && <PerformanceScorecardViewTeamTab />}
            {step === 4 && <PerformanceScorecardViewResourcesTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
