import { gql } from '@apollo/client'
import {
  GqlPerformanceScorecardMetricCycleScoreFieldsFragment,
  GqlUpdatePerformanceScorecardMetricCycleScoreInput,
  GqlUpdatePerformanceScorecardMetricCycleScoreMutation,
  GqlUpdatePerformanceScorecardMetricCycleScoreMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT } from '@cotiss/performance/graphql/performance-scorecard-metric-cycle-score/performance-scorecard-metric-cycle-score-fragment.graphql'

export const mutateUpdatePerformanceScorecardMetricCycleScore = async (input: GqlUpdatePerformanceScorecardMetricCycleScoreInput) => {
  const { data } = await apolloService.mutate<
    GqlUpdatePerformanceScorecardMetricCycleScoreMutation,
    GqlUpdatePerformanceScorecardMetricCycleScoreMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_SCORE_FRAGMENT}

      mutation UpdatePerformanceScorecardMetricCycleScore($input: UpdatePerformanceScorecardMetricCycleScoreInput!) {
        updatePerformanceScorecardMetricCycleScore(input: $input) {
          ...PerformanceScorecardMetricCycleScoreFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceScorecardMetricCycleScore as GqlPerformanceScorecardMetricCycleScoreFieldsFragment
}
