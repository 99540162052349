import { gql } from '@apollo/client'
import { GqlEvaluationCriteriaListInput, GqlEvaluationCriteriaListQuery } from '@gql'
import { PAGINATION_FRAGMENT } from '@cotiss/common/graphql/pagination-fragment.graphql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_CRITERIA_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-criteria/evaluation-criteria-fragment.graphql'

export const queryEvaluationCriteriaList = async (input: GqlEvaluationCriteriaListInput) => {
  const { data } = await apolloService.query<GqlEvaluationCriteriaListQuery>({
    query: gql`
      ${PAGINATION_FRAGMENT}
      ${EVALUATION_CRITERIA_FRAGMENT}

      query EvaluationCriteriaList($input: EvaluationCriteriaListInput!) {
        evaluationCriteriaList(input: $input) {
          items {
            ...EvaluationCriteriaFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    `,
    variables: { input },
  })

  return data.evaluationCriteriaList
}
