import { gql } from '@apollo/client'
import {
  GqlUpdateEvaluationCriteriaInput,
  GqlUpdateEvaluationCriteriaMutation,
  GqlUpdateEvaluationCriteriaMutationVariables,
  GqlEvaluationCriteriaFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_CRITERIA_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-criteria/evaluation-criteria-fragment.graphql'

export const mutateUpdateEvaluationCriteria = async (input: GqlUpdateEvaluationCriteriaInput) => {
  const { data } = await apolloService.mutate<GqlUpdateEvaluationCriteriaMutation, GqlUpdateEvaluationCriteriaMutationVariables>({
    mutation: gql`
      ${EVALUATION_CRITERIA_FRAGMENT}

      mutation UpdateEvaluationCriteria($input: UpdateEvaluationCriteriaInput!) {
        updateEvaluationCriteria(input: $input) {
          ...EvaluationCriteriaFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updateEvaluationCriteria as GqlEvaluationCriteriaFieldsFragment
}
