import { useFetch } from '@cotiss/common/hooks/use-fetch.hook'
import { ConflictOfInterestTemplateModel } from '@cotiss/conflict-of-interest-template/conflict-of-interest-template.model'

export const useGetConflictOfInterestTemplate = () => {
  const { data: conflictOfInterestTemplate, ...rest } = useFetch<ConflictOfInterestTemplateModel>('/v2/conflict-of-interest-template', {
    route: `/v2/conflict-of-interest-template`,
  })

  return { conflictOfInterestTemplate, ...rest }
}
