import React, { memo } from 'react'
import classNames from 'classnames'
import { Skeleton, SkeletonVariant } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
  variant?: SkeletonVariant
  hasIcon?: boolean
}

export const BannerSkeleton = memo(({ className, hasIcon }: Props) => (
  <Skeleton className={classNames(className, 'flex items-center bg-gray-200 w-full py-4 px-6')} variant="none">
    {hasIcon && <Skeleton className="bg-primary-200 w-9 h-9 mr-4" variant="none" />}
    <div className="w-full">
      <Skeleton className="bg-primary-200 h-4 w-1/4" variant="none" />
      <Skeleton className="bg-primary-200 h-3 w-1/3 mt-1" variant="none" />
    </div>
  </Skeleton>
))
