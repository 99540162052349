import { gql } from '@apollo/client'

export const PERFORMANCE_SCORECARD_METRIC_FRAGMENT = gql`
  fragment PerformanceScorecardMetricFields on PerformanceScorecardMetric {
    id
    performanceScorecardId
    performanceMetricId
    performanceMetric {
      ...PerformanceMetricFields
    }
    performanceScorecardMetricCycles {
      ...PerformanceScorecardMetricCycleFields
    }
    evaluators {
      ...PerformanceScorecardUserFields
    }
    startDate
    target
    frequency
    frequencyEndOnDay
    frequencyEndOnMonth
    frequencyEndOnWeekday
    frequencyMonthRepeat
    isArchived
    createdAt
    updatedAt
    deletedAt
  }
`
