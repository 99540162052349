import { format, isAfter, isBefore, isSameDay, parse, parseISO } from 'date-fns'

export type DatetimeFormat = 'do MMM yyyy' | 'MMM yyyy' | 'd MMMM yyyy h:mm aaa' | 'yyyy-MM-dd' | 'MMMM' | 'EEEE' | 'do'

type IsDateBetweenParam = {
  dateFrom: Date
  dateTo: Date
}

class DatetimeService {
  parse = (datetime: Date | string, formatStr?: DatetimeFormat) => {
    if (formatStr) {
      return typeof datetime === 'string' ? parse(datetime, formatStr, new Date()) : datetime
    } else {
      return typeof datetime === 'string' ? parseISO(datetime) : datetime
    }
  }

  format = (datetime: Date | string, formatStr: DatetimeFormat) => {
    return format(this.parse(datetime), formatStr)
  }

  formatNumberAsDayOfTheMonth = (dayOfTheMonth: number) => {
    if (!dayOfTheMonth || dayOfTheMonth < 1 || dayOfTheMonth > 31) {
      return null
    }

    return format(parse(dayOfTheMonth.toString(), 'd', new Date()), 'do')
  }

  getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  isDateBetween = (date: Date, { dateFrom, dateTo }: IsDateBetweenParam) => {
    return isSameDay(date, dateFrom) || isSameDay(date, dateFrom) || (isAfter(date, dateFrom) && isBefore(date, dateTo))
  }
}

export const datetimeService = new DatetimeService()
