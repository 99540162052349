import { gql } from '@apollo/client'
import {
  GqlDeletePerformanceScorecardMetricInput,
  GqlDeletePerformanceScorecardMetricMutation,
  GqlDeletePerformanceScorecardMetricMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeletePerformanceScorecardMetric = async (input: GqlDeletePerformanceScorecardMetricInput) => {
  const { data } = await apolloService.mutate<GqlDeletePerformanceScorecardMetricMutation, GqlDeletePerformanceScorecardMetricMutationVariables>({
    mutation: gql`
      mutation DeletePerformanceScorecardMetric($input: DeletePerformanceScorecardMetricInput!) {
        deletePerformanceScorecardMetric(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deletePerformanceScorecardMetric as boolean
}
