import { makeVar, useReactiveVar } from '@apollo/client'
import {
  GqlCreatePerformanceScorecardMetricUserInput,
  GqlCreatePerformanceScorecardMetricUsersInput,
  GqlDeletePerformanceScorecardMetricUserInput,
  GqlPerformanceScorecardMetricUserFieldsFragment,
  GqlPerformanceScorecardMetricUserListInput,
} from '@gql'
import { filter, uniqBy } from 'lodash'
import { mutateCreatePerformanceScorecardMetricUser } from '@cotiss/performance/graphql/performance-scorecard-metric-user/mutate-create-performance-scorecard-metric-user.graphql'
import { mutateCreatePerformanceScorecardMetricUsers } from '@cotiss/performance/graphql/performance-scorecard-metric-user/mutate-create-performance-scorecard-metric-users.graphql'
import { mutateDeletePerformanceScorecardMetricUser } from '@cotiss/performance/graphql/performance-scorecard-metric-user/mutate-delete-performance-scorecard-metric-user.graphql'
import { queryPerformanceScorecardMetricUserList } from '@cotiss/performance/graphql/performance-scorecard-metric-user/query-performance-scorecard-metric-user-list.graphql'

const performanceScorecardMetricUsersVar = makeVar<GqlPerformanceScorecardMetricUserFieldsFragment[]>([])

export const usePerformanceScorecardMetricUser = () => {
  const performanceScorecardMetricUsers = useReactiveVar(performanceScorecardMetricUsersVar)

  return {
    performanceScorecardMetricUsers,
    setPerformanceScorecardMetricUsers: performanceScorecardMetricUsersVar,
    queryPerformanceScorecardMetricUserList: async (input: GqlPerformanceScorecardMetricUserListInput) => {
      const { items, pagination } = await queryPerformanceScorecardMetricUserList(input)

      performanceScorecardMetricUsersVar(items)

      return { items, pagination }
    },
    mutateCreatePerformanceScorecardMetricUser: async (input: GqlCreatePerformanceScorecardMetricUserInput) => {
      const createdPerformanceScorecardMetricUser = await mutateCreatePerformanceScorecardMetricUser(input)

      performanceScorecardMetricUsersVar(uniqBy([createdPerformanceScorecardMetricUser, ...performanceScorecardMetricUsers], 'id'))

      return createdPerformanceScorecardMetricUser
    },
    mutateCreatePerformanceScorecardMetricUsers: async (input: GqlCreatePerformanceScorecardMetricUsersInput) => {
      const createdPerformanceScorecardMetricUsers = await mutateCreatePerformanceScorecardMetricUsers(input)

      performanceScorecardMetricUsersVar(uniqBy([...createdPerformanceScorecardMetricUsers, ...performanceScorecardMetricUsers], 'id'))

      return createdPerformanceScorecardMetricUsers
    },
    mutateDeletePerformanceScorecardMetricUser: async (input: GqlDeletePerformanceScorecardMetricUserInput) => {
      await mutateDeletePerformanceScorecardMetricUser(input)

      performanceScorecardMetricUsersVar(filter(performanceScorecardMetricUsers, ({ id }) => id !== input.performanceScorecardMetricUserId))
    },
  }
}

export const clearReactivePerformanceScorecardMetricUser = async () => {
  performanceScorecardMetricUsersVar([])
}
