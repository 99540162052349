import React, { memo, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex } from 'lodash'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { ContractApproversTable } from '@cotiss/contract/components/contract-approvers-table.component'
import { ContractSignedDocumentViewer } from '@cotiss/contract/components/contract-signed-document-viewer.component'
import { ContractSummarySignaturesTab } from '@cotiss/contract/components/contract-summary-signatures-tab.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { DocumentModel } from '@cotiss/document/document.models'

type Props = {
  approvedDocument: DocumentModel
  signedDocument?: DocumentModel
  contractId: string
  contractShellId: string
  title: string
}

type ContractDocumentDrawerTab = 'contract-preview' | 'approvals' | 'signatures'

export const ContractDocumentDrawer = memo(({ approvedDocument, signedDocument, contractId, contractShellId, title }: Props) => {
  const { step, transition, onTransition } = useTransition()
  const { contractShell } = useGetContractShell(contractShellId)
  const isEsignEnabled = useFeature('contract-management-e-sign')

  const { tabs, approval } = useMemo(() => {
    const contract = find(contractShell?.contracts, ({ _id }) => _id === contractId)
    const approval = contractService.getApproval(contract?.approvals, ['APPROVED'])
    const isUsingEsign = isEsignEnabled && contract && contract?.documentShells.find((ds) => ds.signatureType === 'ESIGN_DOCUSIGN')
    const tabs: TabModel<ContractDocumentDrawerTab>[] = [
      { id: 'contract-preview', label: 'Contract preview' },
      { id: 'approvals', label: 'Approvals', isHidden: !approval }, // TODO: approval count on label
      { id: 'signatures', label: 'Signatures', isHidden: !isUsingEsign }, // TODO: signature count on label
    ]

    return { tabs: filter(tabs, (tab) => !tab.isHidden), approval }
  }, [isEsignEnabled, contractShell, contractId])

  const activeTabId = useMemo(() => tabs[step - 1].id, [step, tabs])

  const handleTabChange = (tab: ContractDocumentDrawerTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      {title}
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <div className="flex flex-col h-full">
        <div className="flex-0">
          <Tabs<ContractDocumentDrawerTab>
            className="border-b border-gray-300 w-full"
            tabs={tabs}
            onChange={({ id }) => handleTabChange(id)}
            variant="underline"
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition} className="h-full">
              {activeTabId === 'contract-preview' && (
                <ContractSignedDocumentViewer approvedDocument={approvedDocument} signedDocument={signedDocument} />
              )}
              {activeTabId === 'approvals' && (
                <ContractApproversTable contractId={contractId} contractShellId={contractShellId} approvalId={approval?._id} />
              )}
              {activeTabId === 'signatures' && <ContractSummarySignaturesTab contractId={contractId} contractShellId={contractShellId} />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </div>
    </Drawer>
  )
})
