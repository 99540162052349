import React, { memo, useMemo, useState, useEffect } from 'react'
import { filter, forEach, includes, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { sortService } from '@cotiss/common/services/sort.service'
import { DocumentModel } from '@cotiss/document/document.models'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { routerService } from '@cotiss/common/services/router.service'
import { datetimeService } from '@cotiss/common/services/datetime.service'

type Props = {
  className?: string
  documents?: DocumentModel[]
  title?: string
  onBulkRemove?: (documentIdsToRemove: string[]) => void | Promise<void>
  onUpload?: () => void
  isLoading?: boolean
  isDisabled?: boolean
}

type SortKey = 'filename' | 'size' | 'date'

export const DocumentList = memo(({ className, documents = [], title, onBulkRemove, onUpload, isLoading, isDisabled }: Props) => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)

  const { isAllSelected, isSomeSelected } = useMemo(() => {
    const isAllSelected = documents.length === selectedDocumentIds.length
    const isSomeSelected = Boolean(!isAllSelected && selectedDocumentIds.length)

    return { isAllSelected, isSomeSelected }
  }, [selectedDocumentIds, documents])

  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'filename' })
  const sortedDocuments = useMemo(() => {
    const result = documents.sort((a, b) => {
      if (sortKey === 'filename') {
        return sortService.sortString(a.fileName, b.fileName)
      } else if (sortKey === 'size') {
        return sortService.sortNumber(a.fileSize, b.fileSize)
      } else if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    return sortDirection === 'asc' ? result : result.reverse()
  }, [documents, sortKey, sortDirection])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const handleAllCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedDocumentIds(map(sortedDocuments, (document) => document._id))
    } else {
      setSelectedDocumentIds([])
    }
  }

  const handleCheckboxChange = (documentId?: string) => {
    if (!documentId) {
      return
    }

    setSelectedDocumentIds(
      includes(selectedDocumentIds, documentId) ? filter(selectedDocumentIds, (id) => id !== documentId) : [...selectedDocumentIds, documentId]
    )
  }

  const handleBulkDownload = () => {
    forEach(selectedDocumentIds, (id) => {
      window.open(routerService.getHref('/document/view/:id', { id }), '_blank')
    })
  }

  const handleBulkRemove = async () => {
    if (onBulkRemove) {
      await onBulkRemove(selectedDocumentIds)
      setSelectedDocumentIds([])
    }
  }
  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: (
          <Checkbox
            onChange={() => handleAllCheckboxChange(!isAllSelected)}
            isChecked={isAllSelected}
            isIndeterminate={isSomeSelected}
            isDisabled={isDisabled || isLoading}
          />
        ),
        thClassName: 'w-12',
        rows: map(sortedDocuments, (document) => ({
          content: () => (
            <Checkbox
              onChange={() => handleCheckboxChange(document._id)}
              isChecked={includes(selectedDocumentIds, document._id)}
              isDisabled={isDisabled}
            />
          ),
        })),
      },
      {
        heading: 'File name',
        onSort: () => onSort('filename'),
        rows: map(sortedDocuments, (document) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer  inline-block align-middle"
              onClick={() => setDocumentIdToDownload(document._id)}
              state="raw"
              isDisabled={isDisabled}
              isTruncated={true}
            >
              {document.fileName}
            </Button>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Size',
        onSort: () => onSort('size'),
        rows: map(sortedDocuments, (document) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(document.fileSize)}
            </Text>
          ),
        })),
      },
      {
        heading: 'Date',
        onSort: () => onSort('date'),
        rows: map(sortedDocuments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [sortedDocuments, selectedDocumentIds, isDisabled, sortKey, sortDirection])

  return (
    <div className={className}>
      <TableHeader className="flex items-center justify-between mt-8">
        <Text className="font-medium" size="lg">
          {title || 'Files'}
          <Text className="text-light" isInline>
            {' '}
            ({documents.length})
          </Text>
        </Text>
        <div className="flex items-center whitespace-nowrap">
          {Boolean(selectedDocumentIds.length) && (
            <>
              {onBulkRemove && (
                <Button onClick={handleBulkRemove} size="sm" state="translucent" variant="secondary-dark" isDisabled={isDisabled}>
                  Delete
                </Button>
              )}
              <Button className="ml-2" onClick={handleBulkDownload} size="sm" state="translucent" variant="secondary-dark" isDisabled={isDisabled}>
                Download
              </Button>
            </>
          )}
          {onUpload && (
            <Button className="ml-2" onClick={onUpload} variant="secondary" size="sm" isDisabled={isDisabled}>
              + Upload
            </Button>
          )}
        </div>
      </TableHeader>
      {!isLoading && !sortedDocuments.length && (
        <NoDataPlaceholder label="No documents uploaded" ctaLabel="+ Upload documents" onCtaClick={onUpload} />
      )}

      {(isLoading || Boolean(sortedDocuments.length)) && <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />}
    </div>
  )
})
