import { RouterPath } from '@cotiss/common/services/router.service'

export const BUYER_ROUTES: Array<RouterPath> = [
  '/project/list/:tab?',
  '/preferred-supplier/list/:tab?',
  '/buyer/view/:id/:tab?',
  '/contract/list',
  '/conflict-of-interest/list',
]

export const SUPPLIER_ROUTES: Array<RouterPath> = ['/procurement-response/list', '/supplier/view/:id/:tab?']

export const SERVICE_ROUTES: Array<RouterPath> = ['/task/list']
