import { gql } from '@apollo/client'
import {
  GqlCreateEvaluationSubmissionDocumentsInput,
  GqlCreateEvaluationSubmissionDocumentsMutation,
  GqlCreateEvaluationSubmissionDocumentsMutationVariables,
  GqlEvaluationSubmissionDocumentFieldsFragment,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT } from '@cotiss/evaluation-event/graphql/evaluation-submission-document/evaluation-submission-document-fragment.graphql'

export const mutateCreateEvaluationSubmissionDocuments = async (input: GqlCreateEvaluationSubmissionDocumentsInput) => {
  const { data } = await apolloService.mutate<
    GqlCreateEvaluationSubmissionDocumentsMutation,
    GqlCreateEvaluationSubmissionDocumentsMutationVariables
  >({
    mutation: gql`
      ${EVALUATION_SUBMISSION_DOCUMENT_FRAGMENT}

      mutation CreateEvaluationSubmissionDocuments($input: CreateEvaluationSubmissionDocumentsInput!) {
        createEvaluationSubmissionDocuments(input: $input) {
          ...EvaluationSubmissionDocumentFields
        }
      }
    `,
    variables: { input },
  })

  return data?.createEvaluationSubmissionDocuments as GqlEvaluationSubmissionDocumentFieldsFragment[]
}
