import React, { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex, map, some, sortBy } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ProcurementViewHeader } from '@cotiss/procurement/components/procurement-view-header.component'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { ProcurementViewTenderResponseTab } from '@cotiss/procurement/tabs/procurement-view-tender-response.tab'
import { ProcurementViewTenderTab, ProcurementViewTenderTabs } from '@cotiss/procurement/tabs/procurement-view-tender.tab'
import { useListProcurementResponse } from '@cotiss/procurement-response/resources/use-list-procurement-response.resource'
import { useMutateProcurementResponseUser } from '@cotiss/procurement-response-user/resources/use-mutate-procurement-response-user.resource'
import { TenderSummaryCardSkeleton } from '@cotiss/tender/components/tender-summary-card-skeleton.component'
import { TenderSummaryCard } from '@cotiss/tender/components/tender-summary-card.component'
import { useListTender } from '@cotiss/tender/resources/use-list-tender.resource'
import { tenderService } from '@cotiss/tender/tender.service'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { TenderResponseStatus } from '@cotiss/tender-response/tender-response.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type ProcurementViewTab = 'tender' | 'tender-response'

export const ProcurementViewPage = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { replace, push } = useHistory()
  const { procurementId, tab, nestedTab } = useParams<{ procurementId: string; tab?: ProcurementViewTab; nestedTab?: ProcurementViewTenderTabs }>()

  const [isSaving, setIsSaving] = useState(false)
  const [selectedTenderId, setSelectedTenderId] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const isProcurementsEnabled = useFeature('procurements')

  const { createTenderResponse, updateTenderResponse, deleteTenderResponse } = useMutateTenderResponse()
  const { createProcurementResponseUser } = useMutateProcurementResponseUser()

  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(procurementId)
  // If we are logged as a supplier, this request will only ever return 1 procurement response if one exists for the procurement.
  const { procurementResponses, isLoading: isProcurementResponsesLoading } = useListProcurementResponse({
    procurementId,
    organisationId: loggedInUser?.account.organisation?._id,
  })
  const procurementResponse = procurementResponses.length ? procurementResponses[0] : undefined
  const isUserProcuringEntity = loggedInUser?.account.organisation?._id === procurement?.procuringEntity
  const { tenders, isLoading: isTendersLoading } = useListTender({ procurementId: procurementId, isOpenOnly: true })

  // We need to get the populated version of the tender response inferred by the procurement response we are currently viewing. This is a bit of a
  // juggling act, and a hack to get the right data to show. So we should re-look at this once we fix our models and API.
  const procurementResponseTenderResponse = find(procurementResponse?.tenderResponses, ({ tender }) => tender._id === selectedTenderId)
  const { tenderResponse, isLoading: isTenderResponseLoading } = useGetTenderResponse(procurementResponseTenderResponse?._id)
  // This is another small hack we need to add, as we need to make sure that we un-set the tender response, if one doesn't exist for the selected
  // tender.
  const tenderResponseToUse = procurementResponseTenderResponse && tenderResponse

  const isLoading =
    isLoggedInUserLoading ||
    isProcurementLoading ||
    isProcurementResponsesLoading ||
    isTendersLoading ||
    Boolean(procurementResponseTenderResponse && isTenderResponseLoading)

  const isTenderClosed = useMemo(() => {
    if (!tenders.length || !selectedTenderId) {
      return false
    }

    const tender = find(tenders, { _id: selectedTenderId })

    return Boolean(tender && tenderService.isTenderClosed(tender))
  }, [tenders, selectedTenderId])

  const isUserInProcurementResponse = useMemo(() => {
    if (!procurementResponse || !loggedInUser) {
      return false
    }

    return some(procurementResponse.procurementResponseUsers, ({ user }) => loggedInUser._id === user._id)
  }, [procurementResponse, loggedInUser])

  const procurementViewTabs: TabModel<ProcurementViewTab>[] = [
    { id: 'tender', label: 'Details and documents' },
    {
      id: 'tender-response',
      label: 'Tender response',
      isHidden: isUserProcuringEntity,
      isDisabled: isUserProcuringEntity || !isUserInProcurementResponse || !procurementResponse || !tenderResponseToUse,
    },
  ]

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(procurementViewTabs, { id: tab }) + 1 })

  useEffect(() => {
    if (!tenders.length) {
      return
    }

    if (!selectedTenderId) {
      setSelectedTenderId(tenders[0]._id)
    }
  }, [tenders])

  useEffect(() => {
    if (!tab || !find(procurementViewTabs, ({ id }) => id === tab)) {
      replace(
        routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
          procurementId,
          tab: procurementViewTabs[0].id,
          nestedTab,
        })
      )
    }

    const newStep = findIndex(procurementViewTabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  const handleJoinResponseTeam = async () => {
    if (!procurementResponse) {
      return
    }

    try {
      setIsSaving(true)
      await createProcurementResponseUser({ procurementResponseId: procurementResponse._id })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleCreateTenderResponse = async () => {
    if (!selectedTenderId) {
      return
    }

    try {
      setIsSaving(true)
      await createTenderResponse({ tenderId: selectedTenderId })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleUpdateTenderResponseStatus = async (status: TenderResponseStatus) => {
    if (!tenderResponseToUse) {
      return
    }

    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponseToUse._id, { status: status })
      push(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId, tab: 'tender-response' }))
      push
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleDeleteTenderResponse = async () => {
    if (!tenderResponseToUse) {
      return
    }

    try {
      setIsSaving(true)
      await deleteTenderResponse(tenderResponseToUse._id)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderBanner = () => {
    if (isUserProcuringEntity) {
      return null
    }

    if (!procurementResponse) {
      return (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Your organisation has not subscribed to this listing
            </Text>
            <Text size="sm">You will be able to view the tender once you have subscribed.</Text>
          </div>
          <Button size="sm" className="flex-shrink-0" onClick={handleCreateTenderResponse} isLoading={isSaving}>
            Subscribe to view
          </Button>
        </Banner>
      )
    }

    if (procurementResponse && !isUserInProcurementResponse) {
      return (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Your organisation has already subscribed to this listing
            </Text>
            <Text size="sm">You will be able to view the tender details once you have joined the response as a collaborator.</Text>
          </div>
          <Button size="sm" className="flex-shrink-0" onClick={handleJoinResponseTeam} isLoading={isSaving}>
            Join team to view
          </Button>
        </Banner>
      )
    }

    if (!tenderResponseToUse) {
      return (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Your organisation has not subscribed to this listing
            </Text>
            <Text size="sm">You will be able to respond to the tender once you have subscribed.</Text>
          </div>
          <Button onClick={() => handleCreateTenderResponse()} variant="secondary" size="sm" isLoading={isSaving}>
            Subscribe to respond
          </Button>
        </Banner>
      )
    }

    if (isTenderClosed) {
      return (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              The tender is closed
            </Text>
            <Text size="sm">You cannot respond to this tender</Text>
          </div>
          <Button variant="secondary" size="sm" isDisabled={isTenderClosed} isLoading={isSaving}>
            Closed
          </Button>
        </Banner>
      )
    }

    if (tenderResponseToUse.status === 'watching') {
      return (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              You are watching this tender
            </Text>
            <Text size="sm">Start your response to this tender</Text>
          </div>
          <div className="flex items-center relative">
            <Button className="mr-4" onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost" size="xs" isRounded>
              <Icon icon="dots" variant="light" />
            </Button>
            <Dropdown className="top-1/2 left-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent
                className="flex items-center"
                onClick={() =>
                  openModal(
                    <ConfirmModal heading="Unsubscribe" description="Are you sure you want to unsubscribe?" onSubmit={handleDeleteTenderResponse} />
                  )
                }
                isDisabled={isSaving}
              >
                Unsubscribe
              </DropdownContent>
            </Dropdown>
            <Button
              onClick={() => handleUpdateTenderResponseStatus('drafting')}
              variant="secondary"
              size="sm"
              isDisabled={isTenderClosed}
              isLoading={isSaving}
            >
              Start response
            </Button>
          </div>
        </Banner>
      )
    }
  }

  if (!isProcurementsEnabled) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <ProcurementViewHeader activeTab="procurement" procurementId={procurementId} procurementResponseId={procurementResponse?._id} />
      <PageContent className="flex w-full">
        <div className="flex flex-col mr-8">
          {isLoading && <TenderSummaryCardSkeleton />}
          {map(
            sortBy(tenders, (tender) => tender.createdAt),
            (tender) => (
              <TenderSummaryCard
                key={tender._id}
                className="mb-4"
                tender={tender}
                onChange={() => setSelectedTenderId(tender._id)}
                isSelected={tender._id === selectedTenderId}
                isTenderFlow={false}
              />
            )
          )}
        </div>
        <div className="w-full min-w-0">
          {!isLoading && renderBanner()}
          <Tabs<ProcurementViewTab>
            className="border-b border-gray-300 w-full mb-6"
            tab={tab}
            tabs={procurementViewTabs}
            onChange={({ id: tab }) => push(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId, tab }))}
            variant="underline"
          />

          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && <ProcurementViewTenderTab tenderId={selectedTenderId} tenderResponse={tenderResponseToUse} />}
              {step === 2 && <ProcurementViewTenderResponseTab tenderId={selectedTenderId} tenderResponse={tenderResponseToUse} />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </PageContent>
    </Page>
  )
})
