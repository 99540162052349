import React from 'react'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { usePreferredSupplierSearchFiltersContext } from '@cotiss/preferred-supplier/components/preferred-supplier-search-filters-context.component'
import { PreferredSupplierPrimaryTabKeys } from '@cotiss/preferred-supplier/preferred-supplier.constants'

type Props = {
  tabs: TabModel<PreferredSupplierPrimaryTabKeys>[]
  tab: PreferredSupplierPrimaryTabKeys
}

export const PreferredSupplierListTabs = ({ tabs, tab }: Props) => {
  const { track } = useAnalytics()
  const { queryStateDispatch } = usePreferredSupplierSearchFiltersContext()

  const handleTabChange = (_tab: PreferredSupplierPrimaryTabKeys) => {
    if (tab === 'active') {
      track('preferred_supplier_list_active_view')
    }

    if (tab === 'archived') {
      track('preferred_supplier_list_archived_view')
    }
    queryStateDispatch({ type: 'UPDATE_PRIMARY_TAB', payload: _tab })
  }
  return <Tabs<PreferredSupplierPrimaryTabKeys> tabs={tabs} tab={tab} onChange={({ id }) => handleTabChange(id)} variant="raised" />
}
