import { gql } from '@apollo/client'
import {
  GqlDeleteEvaluationEventDocumentInput,
  GqlDeleteEvaluationEventDocumentMutation,
  GqlDeleteEvaluationEventDocumentMutationVariables,
} from '@gql'
import { apolloService } from '@cotiss/common/services/apollo.service'

export const mutateDeleteEvaluationEventDocument = async (input: GqlDeleteEvaluationEventDocumentInput) => {
  const { data } = await apolloService.mutate<GqlDeleteEvaluationEventDocumentMutation, GqlDeleteEvaluationEventDocumentMutationVariables>({
    mutation: gql`
      mutation DeleteEvaluationEventDocument($input: DeleteEvaluationEventDocumentInput!) {
        deleteEvaluationEventDocument(input: $input)
      }
    `,
    variables: { input },
  })

  return data?.deleteEvaluationEventDocument as boolean
}
