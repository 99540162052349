import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { MetricUnit } from '@cotiss/common/models/units.model'

export type UpsertPriceItemBulkBodyItem = {
  priceItemId?: string
  body: {
    index?: number
    description?: string
    category?: string
    unit?: MetricUnit
    quantity?: number
  }
}

type UpsertPriceItemsBulkBody = {
  tenderId: string
  items: UpsertPriceItemBulkBodyItem[]
}

export const useMutatePriceItem = () => {
  const { mutate } = useMutate()

  return {
    upsertPriceItemBulk: async (body: UpsertPriceItemsBulkBody) => {
      return await mutate({ route: '/v2/price-item/bulk', body, invalidate: ['/v2/price-item', '/v2/tenders'] })
    },
  }
}
