import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { BuyerViewPage } from '@cotiss/buyer/pages/buyer-view.page'

export const BuyerLayout = memo(() => (
  <Switch>
    <Route path="/buyer/view/:id/:tab?" exact>
      <BuyerViewPage />
    </Route>
  </Switch>
))
