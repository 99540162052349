import { useMutate } from '@cotiss/common/hooks/use-mutate.hook'
import { PreferencesEmailNotificationPeriod } from '@cotiss/preferences/preferences.models'
import { UserMeModel, UserPermission } from '@cotiss/user/user.models'

type UpdateUserPermissionsBody = {
  permissions: UserPermission[]
}

type CreatePendingUsersBody = {
  email: string
  permissions: UserPermission[]
}[]

type UpdateUserMeBody = {
  firstname?: string
  surname?: string
  email?: string
  phone?: string
  keywords?: string[]
  excludedKeywords?: string[]
  categories?: string[]
  regions?: string[]
  notificationPeriod?: PreferencesEmailNotificationPeriod
  password?: { currentPassword: string; newPassword: string }
  onBoarding?: {
    profileBuilder?: boolean
    viewListing?: boolean
  }
}

type CreateRequestedUserBody = {
  account: string
  email: string
}

type CreateUserBody = {
  accountId: string
  firstname: string
  surname: string
  email: string
  password: string
  phone: string
  regions: string[]
}

export const useMutateUser = () => {
  const { mutate } = useMutate()

  return {
    createPendingUsers: async (body: CreatePendingUsersBody) => {
      return await mutate({ route: '/v2/pending-users', body, invalidate: ['/v2/users/me'] })
    },
    resendPendingUserInvite: async (id: string) => {
      return await mutate({ route: `/v2/pending-users/${id}/resend` })
    },
    deletePendingUserInvite: async (id: string) => {
      return await mutate({ route: `/v2/pending-users/${id}`, method: 'DELETE', invalidate: ['/v2/users/me', '/v2/pending-users'] })
    },
    updateUserPermissions: async (id: string, body: UpdateUserPermissionsBody) => {
      return await mutate({ route: `/v2/users/${id}/permissions`, body, invalidate: ['/v2/users', '/v2/users/me'] })
    },
    createRequestedUser: async (body: CreateRequestedUserBody) => {
      return await mutate({ route: '/v2/requested-users', body })
    },
    acceptRequestedUser: async (id: string) => {
      return await mutate({ route: `/v2/requested-users/${id}/accept`, invalidate: ['/v2/users', '/v2/requested-users', '/v2/users/me'] })
    },
    rejectRequestedUser: async (id: string) => {
      return await mutate({ route: `/v2/requested-users/${id}`, method: 'DELETE', invalidate: ['/v2/users', '/v2/requested-users', '/v2/users/me'] })
    },
    deleteUser: async (id: string) => {
      return await mutate({ route: `/v2/users/${id}`, method: 'DELETE', invalidate: ['/v2/users', '/v2/users/me'] })
    },
    updateUserMe: async (body: UpdateUserMeBody) => {
      return await mutate({ route: '/v2/users/me', body })
    },
    updateUserPassword: async (body: { currentPassword: string; newPassword: string }) => {
      return await mutate({ route: '/v2/users/update-password', body })
    },
    createUser: async (body: CreateUserBody) => {
      return await mutate<UserMeModel>({ route: '/v2/users', body })
    },
  }
}
