import React, { forwardRef, ReactNode } from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { transitionService } from '@cotiss/common/services/transition.service'

type Props = {
  children?: ReactNode
  className?: string
  transition: TransitionType
}

export const TransitionContainer = forwardRef<HTMLDivElement, Props>(({ children, className, transition }, ref) => {
  const additionalProps: HTMLMotionProps<'div'> = {}

  return (
    <motion.div className={className} {...transitionService.getTransitionConfig(transition)} {...additionalProps} ref={ref}>
      {children}
    </motion.div>
  )
})
