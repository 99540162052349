import React, { memo } from 'react'
import classNames from 'classnames'
import { lowerCase, upperFirst } from 'lodash'
import { useParams } from 'react-router-dom'
import { IconType } from '@cotiss/common/components/icon.component'
import { StepButton, StepButtonVariant } from '@cotiss/common/components/step-button.component'
import { EvaluationChairStep } from '@cotiss/evaluation/evaluation.constants'

const getIcon = (step: EvaluationChairStep): IconType => {
  switch (step) {
    case 'individual-evaluation':
      return 'user-circle'
    case 'consensus-scoring':
      return 'users-01'
    case 'price-scoring':
      return 'dollar'
    default:
      return 'lock'
  }
}

type Props = {
  className?: string
  step: EvaluationChairStep
  variant?: StepButtonVariant
  onStepChange: (step: EvaluationChairStep) => void
}

export const EvaluationChairStepButton = memo(({ className, step, variant = 'default', onStepChange }: Props) => {
  const classes = classNames(className, 'w-full')
  const { step: currentStep } = useParams<{ step: EvaluationChairStep }>()

  return (
    <StepButton
      className={classes}
      onClick={() => onStepChange(step)}
      variant={variant}
      icon={getIcon(step)}
      isActive={step === currentStep}
      isLast={step === 'price-scoring'}
    >
      {upperFirst(lowerCase(step))}
    </StepButton>
  )
})
